import { useCallback } from 'react';
import { getMessage2, toastWithPromise } from '../../components/Toast/Toast';


import { CardActions } from 'src/config/CardConstants';
import useReactivateCard from '../api/cards/useReactivateCard';
import useResolveCard from '../api/cards/useResolveCard';
import useUpdateCardStatus from '../api/cards/useUpdateCardStatus';

export default function useCardActions() {
  const updateCardStatus = useUpdateCardStatus();
  const resolveCardStatus = useResolveCard();
  const reactivateCard = useReactivateCard();

  const cardActions = useCallback(
    (action, cardId, lastFour, data, onActionSuccess) => {
      const { statusApiName, ...formData } = data;
      console.log('cardActions', action, statusApiName);
      switch (action) {
        case CardActions.Reactivate:
          toastWithPromise(
            reactivateCard.mutateAsync(
              {
                id: cardId,
                data: {
                  ...formData,
                },
              },
              {
                onSuccess: onActionSuccess,
              }
            ),
            {
              loading: `Updating card *${lastFour}`,
              success: `Card: ${lastFour} successfully reactivated`,
              error: `An error occurred while updating Card * ${lastFour}`,
            },
            statusApiName
          );
          break;
        case CardActions.Resolve:
          toastWithPromise(
            resolveCardStatus.mutateAsync({
              id: cardId,
              data,
            }, {
              onSuccess: onActionSuccess
            }),
            {
              loading: `Updating card *${lastFour}`,
              success: `Card: ${lastFour} updated successfully`,
              error: `An error occurred while updating Card * ${lastFour}`,
            },
            statusApiName
          );
          break;
        case CardActions.UpdateStatus:
          toastWithPromise(
            updateCardStatus.mutateAsync({
              id: cardId,
              data,
            }, {
              onSuccess: onActionSuccess
            }),
            {
              loading: {
                message1: 'Updating card',
                message2: lastFour,
              },
              success: {
                message1: 'Card successfully',
                message2: getMessage2(statusApiName),
              },
              error: `An error occurred while updating Card ${lastFour}`,
            },
            statusApiName
          );
          break;
      }
    },
    [updateCardStatus, resolveCardStatus]
  );

  return cardActions;
}
