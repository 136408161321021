import { useMutation } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';

export default function useGetUserValidationReportDownloadUrl(
  options,
) {
  const client = useApiClient();

  return useMutation(
    (data) => api.admin.validationRules.fetchUserValidationReportDownloadUrl(client, data),
    {
      onError: (error) => {
        logger.error(error);
      },
      ...options,
    },
  );
}
