import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AscIcon } from '../../assets/icons/asc-icon.svg';
import { ReactComponent as DescIcon } from '../../assets/icons/desc-icon.svg';

export const TABLE_SORT_ASC = 'asc';
export const TABLE_SORT_DESC = 'desc';
export default function CardSortableHeader({
  columnName,
  sortable,
  sort,
  field,
  sortDirection,
}) {
  return (
    <div className="flex items-center">
      {columnName}
      {sortable && (
        <div className="ml-2 text-gray-400">
          <AscIcon className={`stroke-current w-2.5 mb-1 ${sort === field && sortDirection === TABLE_SORT_ASC && 'text-gray-700'}`} />
          <DescIcon className={`stroke-current w-2.5 ${sort === field && sortDirection === TABLE_SORT_DESC && 'text-gray-700'}`} />
        </div>
      )}
    </div>
  );
}
CardSortableHeader.propTypes = {
  columnName: PropTypes.string.isRequired,
  sortable: PropTypes.bool.isRequired,
  sort: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf([TABLE_SORT_ASC, TABLE_SORT_DESC]).isRequired,
};
