export enum CardActions {
  Reactivate = 'Reactivate',
  Resolve = 'Resolve',
  UpdateStatus = 'StatusUpdate',
}

export default {
  actions: {
    viewFullCardNumber: 'view-full-card-number',
    hideFullCardNumber: 'hide-full-card-number',
    cardInMerchantAttack: 'card-in-merchant-attack',
    comment: 'comment',
  },
  cardAttackType: {
    merchantAttack: 'merchantAttack',
    rule: 'rule',
  },
  changedByType: {
    rule: 'rule',
    system: 'system',
  },
};
