import React from 'react';
import PropTypes from 'prop-types';
import { CubeTransparentIcon } from '@heroicons/react/outline';
import Spinner from '../Spinner';

export const TABLE_HEADER_CLASS = 'px-5 py-3 text-xs font-medium text-gray-900 uppercase tracking-wider';
export const TABLE_HEADER_RIGHT_CLASS = `${TABLE_HEADER_CLASS} text-right break-words`;
export const TABLE_HEADER_LEFT_CLASS = `${TABLE_HEADER_CLASS} text-left`;
export const TABLE_ROW_CLASS = 'bg-white border-b border-gray-200';
export const TABLE_CELL_CLASS_BASE = 'px-5 py-4 text-sm text-gray-700 text-left align-top';
export const TABLE_CELL_CLASS = `${TABLE_CELL_CLASS_BASE} text-left`;
export const TABLE_CELL_RIGHT_CLASS = `${TABLE_CELL_CLASS_BASE} text-right`;
export const TABLE_CELL_CENTER_CLASS = `${TABLE_CELL_CLASS_BASE} text-center`;

export function DefaultEmptyStateContent() {
  return (
    <div className="flex justify-center py-6">
      <div className="inline-flex items-center px-4 py-3 text-xs text-gray-500 border">
        <CubeTransparentIcon className="w-5 h-5 mr-2" />
        <span data-cy="no-data-available-table">No data available</span>
      </div>
    </div>
  );
}
export function EmptyStateTable({ colSpan, children }) {
  return (
    <tr className={TABLE_ROW_CLASS}>
      <td colSpan={colSpan}>
        {children}
      </td>
    </tr>
  );
}

EmptyStateTable.propTypes = {
  colSpan: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

EmptyStateTable.defaultProps = {
  colSpan: 1,
  children: null,
};

export default function Table({ children, loading }) {
  return (
    <div className="relative flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden border border-b-0 border-gray-200 sm:rounded-sm">
            <table className="min-w-full border-collapse divide-y divide-gray-200">
              {children}
            </table>
          </div>
        </div>
      </div>
      {loading && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full p-2 bg-black bg-opacity-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  loading: PropTypes.bool,
};

Table.defaultProps = {
  loading: false,
};
