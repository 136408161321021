import React from 'react';
import PropTypes from 'prop-types';
import TwoRowsColumn from './TwoRowsColumn';
import * as DateUtils from '../../../utils/date';
import BaseColumn from './BaseColumn';

export default function SummaryStatColumn(props) {
  const { column, row: { type, name } } = props;
  const { field } = column;
  switch (type) {
    case 'date':
      return (
        <TwoRowsColumn
          {...props}
          column={{
            ...column,
            className: 'items-center space-x-2',
            field2: field,
            valueGetter: (valueField) => DateUtils.formatDateOnly(valueField),
            valueGetter2: (valueField) => DateUtils.formatTimeOnly(valueField),
          }}
          key={`${field}-${name}`}
        />
      );
    default:
      return (
        <BaseColumn
          {...props}
          key={`${field}-${name}`}
        />
      );
  }
}

SummaryStatColumn.propTypes = {
  row: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};
