import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useManualBlockedMerchants(
  tenantId, timeSelected, page, itemsPerPage, search, options,
) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['manual-blocked', tenantId, timeSelected, page, itemsPerPage, search],
    async () => api.merchants.fetchManualBlockedMerchants(
      apiClient,
      {
        tenantId,
        page,
        itemsPerPage,
        search,
        timeSelected,
      },
    ),
    options,
  );
}
