import React, { useState } from 'react';
import ReasonDescriptionMain from './reasonDescription/ReasonDescriptionMain';
import ReasonDescriptionDetail from './reasonDescription/ReasonDescriptionDetail';

export default function ReasonDescription(props) {
  const [reasonSelected, setReasonSelected] = useState();

  return (
    <>
      {!reasonSelected && (
        <ReasonDescriptionMain
          {...props}
          onReasonSelected={(reason) => setReasonSelected(reason)}
        />
      )}
      {reasonSelected && (
        <ReasonDescriptionDetail
          {...props}
          reason={reasonSelected}
          onBack={() => setReasonSelected(null)}
        />
      )}
    </>
  );
}
