import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BanIcon,
  CheckCircleIcon,
  ClockIcon,
  XIcon,
  PlusIcon,
  MinusIcon,
  ChatIcon,
} from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';
import { checkPermission, getName } from '../../utils/userUtil';
import { formatStringLength } from '../../utils/stringUtils';
import useGetMerchant from '../../hooks/api/merchants/useGetMerchant';
import useGetActiveMerchantAttacks from '../../hooks/api/merchants/useGetActiveMerchantAttacks';
import Spinner from '../Spinner';
import { StatusBadge } from '../table/columns/MerchantStatusColumn';
import useGetMyPermissions from '../../hooks/api/users/useGetMyPermissions';
import PermissionConstants from '../../config/PermissionConstants';
import StatusConstants from '../../config/StatusConstants';
import MerchantConfirmationModal from '../modals/MerchantConfirmationModal';
import { toastWithPromise } from '../Toast/Toast';
import useUpdateMerchantStatus from '../../hooks/api/merchants/useUpdateMerchantStatus';
import { formatDayMonth, formatTimeOnly } from '../../utils/date';
import ardenShieldOnly from '../../assets/arden-shield-bg-blue.svg';
import CommentModal from '../modals/CommentModal';
import BlockLoadingModal from '../modals/BlockLoadingModal';
import useCreateMerchantComment from '../../hooks/api/merchants/useCreateMerchantComment';
import MerchantConstants from '../../config/MerchantConstants';
import useBottomSheetContext from '../../hooks/bottomSheet/useBottomSheet';
import MccDetails from '../mccDetails';
import ActiveAlerts from '../activeAlerts';
import LabelBadge from '../LabelBadge'
import ActionButton from '../buttons/ActionButton';
import useTrackEvent from '../appInsights/useTrackEvent';

const labelClass = 'block text-xs text-gray-500 mb-1';
const valueClass = 'block text-gray-800 font-semibold';
export const MERCHANT_UPDATE_STATUS_ACTION = 'merchant-update-status';


const getModifiedBy = (details) => {
  if (!details || !details.lastLog) {
    return null;
  }
  if (!details.lastLog.changedByType || details.lastLog.changedByType === 'rule') {
    return 'ARDEN';
  }

  return getName(details.lastLog.user);
};

export default function MerchantDetailsSidebar({ merchantTenantId }) {
  const { data: details, isLoading } = useGetMerchant(merchantTenantId);
  const { data: activeMerchantAttacks, isLoading: activeMerchantAttacksLoading } =
    useGetActiveMerchantAttacks(merchantTenantId);

  const { data: permissions } = useGetMyPermissions();
  const updateMerchantStatus = useUpdateMerchantStatus(merchantTenantId);
  const createMerchantComment = useCreateMerchantComment();
  const bottomSheet = useBottomSheetContext();
  const [modal, setModal] = useState({ show: false });
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [showBlockModalLoading, setShowBlockModalLoading] = useState(false);
  const trackMerchantDetailsSidebarAction = useTrackEvent('Merchant Details Sidebar Action');
  const isThreat = details?.statusApiName === StatusConstants.pendingReviewApiName
  const isBlockConfirmed = details?.statusApiName === StatusConstants.blockedApiName && details?.lastLog?.changedByType === "rule"
  const isTrust = details?.statusApiName === StatusConstants.trustedApiName
  const isAttackActive = details?.statusApiName === StatusConstants.trustedApiName && details?.hasActiveAttack;
  const isApproved = details?.statusApiName === StatusConstants.approvedApiName
  const isTrustedOrApproved = details?.statusApiName === StatusConstants.trustedApiName || details?.statusApiName === StatusConstants.approvedApiName;
  const isBlocked = details?.isBlockedById || details?.isBlockedByName;
  const isNotThreat = isThreat  && (!details.isBlockedById || !details.isBlockedByName);

  const showConfirmationModal = (newStatus, isAbleToRemove = false) => {
    if (details && !modal.show) {
      setModal({
        show: true,
        newStatus,
        isAbleToRemove
      });
    }
  };

  const closeConfirmationModal = () => {
    if (modal.show) {
      setModal({ ...modal, show: false });
    }
  };

  const handleCommentSubmit = (comment) => {
    setShowAddCommentModal(false);
    toastWithPromise(
      createMerchantComment.mutateAsync({
        id: merchantTenantId,
        comment,
      }),
      {
        loading: 'Creating merchant comment',
        success: `Comment added successfully`,
        error: `An error occurred while creating a comment`,
      },
      MerchantConstants.actions.comment
    );
  };

  const updateMerchantStatusAction = ({ status, merchant: merchantArg, actionById, actionByName,...rest }) => {
    const { id, name } = merchantArg;

    closeConfirmationModal();

    trackMerchantDetailsSidebarAction({
      action: MERCHANT_UPDATE_STATUS_ACTION,
      ...merchantArg,
      status,
    });

    setShowBlockModalLoading(modal.newStatus === StatusConstants.blockedApiName);

    const merchantUpdate = {
      id,
      statusApiName: modal.newStatus,
      actionById,
      actionByName,
      ...rest,
    }

    toastWithPromise(
      updateMerchantStatus.mutateAsync(merchantUpdate),
      {
        loading: `Updating merchant ${name}`,
        success: `Merchant ${name} updated successfully`,
        error: `An error occurred while updating merchant ${name}`,
      },
      modal.newStatus
    ).finally(() => {
      setShowBlockModalLoading(false);
    });
  };

  useEffect(() => {
    if (details) {
      bottomSheet.updateTab({
        id: details.id,
        type: 'merchant',
        title: details.name,
        subtitle: `ID: ${details.number}`,
      });
    }
  }, [details]);

  return (
    <>
      {details && (
        <>
          <div className="flex flex-col space-y-3">
            <div>
              <span className={labelClass}>Merchant</span>
              <span className={valueClass}>{details.name}</span>
              <span className="text-sm text-gray-500">
                ID:&nbsp;
                {details.number}
              </span>
            </div>
            <div>
              <span className={labelClass}>Status</span>
              <StatusBadge
                testId={`status-${details.statusApiName}`}
                statusApiName={details.statusApiName}
                changedByType={details && details.lastLog && details.lastLog.changedByType}
              />
            </div>
            {(details.isBlockedById || details.isBlockedByName) && (
              <div className="flex flex-col items-start w-full">
                <span className={labelClass}>Blocked by</span>
                {details.isBlockedById && <LabelBadge title="ID" content={details.number} />}
                {details.isBlockedByName && <LabelBadge className="mt-1" title="Name" content={formatStringLength(details.name, 22)} />}
              </div>
            )}
          </div>
          {checkPermission(permissions, PermissionConstants.UPDATE_MERCHANTS_LIST) && (
            <>
              <hr className="mt-2" />
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button data-cy="merchant-actions" className="w-full py-2 font-medium text-left text-blue-900 bg-white rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <div className="flex">
                        <div className="flow-root w-full">
                          <span className="float-left">
                            <img
                              src={ardenShieldOnly}
                              className="w-4 h-4 mt-2 mr-3"
                              alt="arden shield only logo"
                            />
                          </span>
                          <span className="float-left mt-1">Actions</span>
                        </div>

                        {open ? (
                          <MinusIcon className="w-5 h-5 mt-2 mr-5" />
                        ) : (
                          <PlusIcon className="w-5 h-5 mt-2 mr-5 bg-white" />
                        )}
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="grid grid-cols-1 gap-x-4 gap-y-2 ">
                        <ActionButton
                          isDisabled={isTrust || isBlocked}
                          testId="merchant-details-sidebar-trust-button"
                          onClick={() => showConfirmationModal(StatusConstants.trustedApiName)}
                          iconClassName="text-green-600"
                          Icon={CheckCircleIcon}
                        >
                          Trust
                        </ActionButton>
                        <ActionButton
                          testId="merchant-details-sidebar-approve-button"
                          isDisabled={isApproved || isBlocked || isAttackActive}
                          onClick={() => showConfirmationModal(StatusConstants.approvedApiName)}
                          iconClassName="text-blue-600"
                          Icon={ClockIcon}
                        >
                          Trust for 24h
                        </ActionButton>
                        {isBlockConfirmed && (
                          <ActionButton
                          testId="merchant-details-sidebar-block-button"
                            isDisabled={details.isBlockedById && details.isBlockedByName}
                            onClick={() => showConfirmationModal(StatusConstants.blockedApiName)}
                            iconClassName="text-red-600"
                            Icon={BanIcon}
                          >
                            Confirm Block
                          </ActionButton>
                        )}

                        <ActionButton
                          testId="merchant-details-sidebar-block-button"
                          isDisabled={details.isBlockedById && details.isBlockedByName}
                          onClick={() => showConfirmationModal(StatusConstants.blockedApiName)}
                          iconClassName="text-red-600"
                          Icon={BanIcon}
                        >
                          Block
                        </ActionButton>

                        {(isNotThreat || isAttackActive) && (
                          <ActionButton
                            testId="merchant-details-sidebar-remove-button"
                            isDisabled={
                              !isThreat &&
                              !isTrustedOrApproved &&
                              !details?.isBlockedById &&
                              !details?.isBlockedByName
                            }
                            onClick={() => showConfirmationModal(StatusConstants.reviewedApiName)}
                            iconClassName="text-gray-600"
                            Icon={XIcon}
                          >
                            Not a threat
                          </ActionButton>
                        )}

                        {(!isNotThreat && !isAttackActive) && (
                          <ActionButton
                            testId="merchant-details-sidebar-remove-button"
                            isDisabled={
                              !isThreat &&
                              !isTrustedOrApproved &&
                              !details?.isBlockedById &&
                              !details?.isBlockedByName
                            }
                            onClick={() =>
                              showConfirmationModal(
                                StatusConstants.reviewedApiName,
                                details?.statusApiName === StatusConstants.blockedApiName
                              )
                            }
                            iconClassName="text-gray-600"
                            Icon={XIcon}
                          >
                            Remove from list
                          </ActionButton>
                        )}

                        <ActionButton
                          testId="merchant-details-sidebar-add-comment-button"
                          onClick={() => setShowAddCommentModal(true)}
                          iconClassName="text-gray-700"
                          Icon={ChatIcon}
                        >
                          Add comment
                        </ActionButton>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <hr className="mt-2 mb-8" />
            </>
          )}

          {activeMerchantAttacks && (
            <ActiveAlerts
              title="Active alerts of Rules"
              quantity={activeMerchantAttacks.count}
              alerts={activeMerchantAttacks.rows}
            />
          )}

          <div className="mb-8 mt-8">
            <span className={labelClass}>MCC</span>
            <MccDetails key={details.id} value={details.mcc} id={details.id} />
          </div>
          {details && details.lastAttack && (
            <div className="mb-8">
              <span className={labelClass}>Threat identified</span>
              <span className={valueClass}>
                <span className="mr-4">{formatDayMonth(details.lastAttack.createdAt)}</span>
                <span className="text-sm font-medium text-gray-500">
                  {formatTimeOnly(details.lastAttack.createdAt)}
                </span>
              </span>
            </div>
          )}
          <div className="mb-8">
            <span className={labelClass}>Last modified on</span>
            <span className={valueClass}>
              <span className="mr-4">{formatDayMonth(details.updatedAt)}</span>
              <span className="text-sm font-medium text-gray-500">{formatTimeOnly(details.updatedAt)}</span>
            </span>
          </div>
          <div className="mb-8">
            <span className={labelClass}>Last modified by</span>
            <span className={valueClass}>{getModifiedBy(details)}</span>
          </div>
          <MerchantConfirmationModal
            showModal={modal.show}
            merchant={details}
            newStatus={modal.newStatus}
            isAbleToRemove={modal.isAbleToRemove}
            onClose={closeConfirmationModal}
            primaryAction={updateMerchantStatusAction}
          />
          <CommentModal
            show={showAddCommentModal}
            onSubmit={handleCommentSubmit}
            onClose={() => setShowAddCommentModal(false)}
          />
          <BlockLoadingModal show={showBlockModalLoading} />
        </>
      )}
      {(isLoading || updateMerchantStatus.isLoading || activeMerchantAttacksLoading) && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
          <Spinner />
        </div>
      )}
    </>
  );
}

MerchantDetailsSidebar.propTypes = {
  merchantTenantId: PropTypes.number.isRequired,
};
