import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlideOver from './SlideOver';
import CardTransactions from '../table/CardTransactions';
import { setShowSelectedCardTransactions } from '../../store/features/card/cardSlice';
import StatusConstants from '../../config/StatusConstants';

export default function SlideOverSelectedCardTransactions() {
  const showSelectedCardTransactions = useSelector(
    (state) => state.card.showSelectedCardTransactions,
  );
  const selectedCard = useSelector((state) => state.card.selectedCard);
  const dispatch = useDispatch();
  return (
    <SlideOver
      isOpen={showSelectedCardTransactions}
      setOpen={(value) => dispatch(setShowSelectedCardTransactions(value))}
      title={StatusConstants.authTitle}
    >
      {selectedCard && <CardTransactions cardId={selectedCard.id} />}
    </SlideOver>
  );
}
