export const reasons = {
  2106: 'Card may have compromised',
  2107: 'Pending verification of authorization',
  1226: 'Card confirmed not compromised',
  1227: 'Low risk',
  1228: 'No abnormal pattern of use',
  4106: 'All card information has been compromised',
  3101: 'False positive – card not compromised',
};

const getReason = (key) => {
  const value = reasons[key];
  if (!value) {
    return key;
  }
  return value;
};

export default getReason;
