import React from 'react';
import PropTypes from 'prop-types';


export default function Title({ icon, title }) {
  return (
    <div className="flex items-center">
      {icon && <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">{icon}</div>}
      {title}
    </div>
  );
}

Title.propTypes = {
  icon: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
};

Title.defaultProps = {
  icon: null
}
