import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import CardKeys from './Card.keys';

const getCard = async (
  client,
  cardId,
) => api.cards.fetchCardDetails(client, cardId);

export default function useGetCard(cardId, options) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    CardKeys.detail(cardId),
    async () => getCard(apiClient, cardId),
    { enabled: true, ...options }
  );
}
