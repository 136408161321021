import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetMerchantSummary(
  merchantTenantId,
  duration,
) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'merchantSummary',
      merchantTenantId,
      duration,
    ],
    () => api.merchants.fetchMerchantSummary(apiClient, merchantTenantId, { duration }),
  );
}
