import React from 'react';
import PropTypes from 'prop-types';
import MerchantActions from '../MerchantActions';
import { TABLE_CELL_CLASS } from '../Table';
import { MERCHANT_UPDATE_STATUS_ACTION } from '../MerchantsTable';

export default function MerchantActionsColumn({ row, onAction }) {
  const { id } = row;
  return (
    <td key={`cel-${id}-actions`} className={TABLE_CELL_CLASS}>
      <MerchantActions
        currentView="threats"
        merchant={row}
        onClick={(...e) => onAction && onAction(MERCHANT_UPDATE_STATUS_ACTION, e)}
      />
    </td>
  );
}

MerchantActionsColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      statusApiName: PropTypes.string,
    },
  ).isRequired,
  onAction: PropTypes.func,
};

MerchantActionsColumn.defaultProps = {
  onAction: null,
};
