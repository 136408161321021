import React from 'react';
import PropTypes from 'prop-types';

export default function Subtitle({ children, className }) {
  return (
    <h2 className={`text-xl leading-6 font-semibold text-left ${className}`}>{children}</h2>
  );
}

Subtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Subtitle.defaultProps = {
  className: '',
};
