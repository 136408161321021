import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDidMount } from 'beautiful-react-hooks';

import Spinner from '../Spinner';
import DataTable, {
  TABLE_SORT_ACTION,
  TABLE_SORT_ASC,
  TABLE_SORT_DESC,
} from './DataTable';
import PaginationConfig from '../../config/PaginationConfig';
import RefreshTable from '../RefreshTable';
import DateColumn from './columns/DateColumn';
import useGetResponseCodes from '../../hooks/api/transactions/useGetResponseCodes';
import useGetPaymentMethods from '../../hooks/api/transactions/useGetPaymentMethods';
import useGetMerchantTransactions from '../../hooks/api/transactions/useGetMerchantTransactions';
import TwoRowsColumn from './columns/TwoRowsColumn';
import FiltersContainer from '../filters/FiltersContainer';
import FMCombobox from '../forms/FMCombobox';
import TextButton from '../buttons/TextButton';
import numberUtils from '../../utils/numberUtils';
import ResponseColumn from './columns/ResponseColumn';
import filterUtils from '../../utils/filterUtils';
import { CARD_OPEN_DETAILS } from './CardsTable';
import useBottomSheetContext from '../../hooks/bottomSheet/useBottomSheet';
import useGetReasonCodes from '../../hooks/api/transactions/useGetReasonCodes';
import TransactionDetailsRow from './expanded/TransactionDetailsRow';
import useGetMe from '../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';
import MerchantCardDetailsColumn from './columns/MerchantCardDetailsColumn';
import useGetActiveMerchantAttacksFilter from '../../hooks/api/transactions/useGetActiveMerchantAttacksFilter';
import CardExternalStatusFilter from '../filters/CardExternalStatusFilter';
import CustomTimePeriodWrapper from '../forms/CustomTimePeriodWrapper'
import timeLimitUtils from '../../utils/timeLimitUtils'
import { handleCurrentDay } from '../../utils/date'


const formatPaymentMethod = (field) =>
  field || 'Not available';

const columns = [
  DateColumn('utcTxnDate', 'Transaction date'),

  {
    field: 'card',
    headerName: 'Card Details',
    render: MerchantCardDetailsColumn,
    headerClassName: 'whitespace-nowrap',
    actionName: CARD_OPEN_DETAILS,
    sortable: true,
    sortField: 'lastFour',
  },
  {
    field: 'requestCodeDescription',
    headerName: 'Payment Method',
    valueGetter: (field) => formatPaymentMethod(field),
    sortable: true,
    sortField: 'request.description',
  },
  {
    key: 'id',
    field: 'clientName',
    field2: 'subProgramName',
    linkGetter: (row) => `merchants/${row.id}`,
    useLink: true,
    headerName: 'Client (Subprogram)',
    headerClassName: 'text-left',
    render: TwoRowsColumn,
    sortable: true,
    sortField: 'client.name',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    valueGetter: (field) => numberUtils.currencyFormat(field),
    headerClassName: 'text-right',
    className: 'text-right',
    sortable: true,
  },
  {
    field: 'response',
    headerName: 'Response',
    headerClassName: 'text-left',
    className: 'text-left',
    render: ResponseColumn,
    sortable: true,
  },
];

const viewKey = "merchantTransactions";

export default function MerchantTransactions({ id }) {
  const bottomSheet = useBottomSheetContext();

  const groupView = useGroupViewContext();
  const tabState = groupView.state(viewKey);

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    PaginationConfig.itemsPerPage
  );
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [authTypeFilter, setAuthTypeFilter] = useState(null);
  const [authResponseCode, setAuthResponseCode] = useState(null);
  const [authReasonCode, setAuthReasonCode] = useState(null);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState(null);
  const [cardStatusFilter, setCardStatusFilter] = useState(null);
  const [timeFilter, setTimeFilter] = useState(null);
  const [sort, setSort] = useState('utcTxnDate');
  const [mounted, setIsMounted] = useState(false);
  const [sortDir, setSortDir] = useState(TABLE_SORT_DESC);
  const { data: me } = useGetMe();

  const [maxEndDateLimit, setMaxEndDateLimit] = useState(new Date());
  const [dateFilter, setDateFilter] = useState(null);

  // figure out what this is for :/
  // it seems its related to keeping the tab state
  // when reopening a tab from the bottom tray
  useEffect(() => {
    if (tabState === undefined) return;
    if (tabState.search) setSearch(tabState.search)
    if (tabState.searchFilter) setSearchFilter(tabState.searchFilter)
    if (tabState.authTypeFilter) setAuthTypeFilter(tabState.authTypeFilter)
    if (tabState.authResponseCode) setAuthResponseCode(tabState.authResponseCode)
    if (tabState.authReasonCode) setAuthReasonCode(tabState.authReasonCode)
    if (tabState.paymentMethodFilter) setPaymentMethodFilter(tabState.paymentMethodFilter)
    if (tabState.cardStatusFilter) setCardStatusFilter(tabState.cardStatusFilter)
    if (tabState.timeFilter) setTimeFilter(tabState.timeFilter)
    if (tabState.sortTable) setSort(tabState.sortTable)
    if (tabState.sortDir) setSortDir(tabState.sortDir)
    if (tabState.page) setPage(tabState.page)
    if (tabState.itemsPerPage) setItemsPerPage(tabState.itemsPerPage)
  }, []);

  const onMount = useDidMount();
  onMount(() => setIsMounted(true));

  useEffect(() => {
    if (!mounted) {
      return;
    }
    groupView.setState(viewKey, {
      timeFilter,
      paymentMethodFilter,
      authResponseCode,
      authTypeFilter,
      cardStatusFilter,
      search,
      searchFilter,
      sortTable: sort,
      sortDir,
      page,
      itemsPerPage
    })
  }, [
    mounted, timeFilter, paymentMethodFilter, authResponseCode, authTypeFilter, cardStatusFilter,
    search, searchFilter, sort, sortDir, page, itemsPerPage
  ])

  const {
    data: responseCodes,
    refetch: refetchResponseCodes,
  } = useGetResponseCodes({
    merchantTenantId: id,
    txnDateIntervalInHours: 7 * 24
  });
  const {
    data: reasonCodes,
    refetch: refetchReasonCodes,
  } = useGetReasonCodes({
    merchantTenantId: id,
    txnDateIntervalInHours: 7 * 24
  });
  const {
    data: paymentMethods,
    refetch: refetchPaymentMethods,
  } = useGetPaymentMethods({
    merchantTenantId: id,
    txnDateIntervalInHours: 7 * 24
  });

  const {
    data: activeMerchantAttacks,
  } = useGetActiveMerchantAttacksFilter(
    id
  );

  const authTypeFilterOptions = activeMerchantAttacks?.length ?
    [...filterUtils.CardAuthTypeOptions, ...activeMerchantAttacks.map((option) => ({
      ...option,
      label: `Rule: ${option.label}`
    }))] : filterUtils.CardAuthTypeOptions;
  const selectedPeriod = timeFilter?.value !== 'custom' ? timeFilter?.value : null

  const { data, isLoading, refetch } = useGetMerchantTransactions({
    merchantId: id,
    search,
    page: page - 1,
    itemsPerPage,
    duringAttack: authTypeFilter?.value === 'attack-auth',
    responseCode: authResponseCode?.value,
    reasonCode: authReasonCode?.value,
    requestCode: paymentMethodFilter?.value,
    externalStatusCode: cardStatusFilter && cardStatusFilter.value !== 'compromised'
      ? cardStatusFilter.code
      : null,
    compromised: cardStatusFilter?.value === 'compromised' ? true : null,
    duration: dateFilter?.startDate ?? selectedPeriod,
    sort,
    sortDir,
    isCardPresent: filterUtils.getIsCardPresentFromAuthTypeFilter(authTypeFilter),
    isInternationTransaction: filterUtils.getIsInternationalTransactionFromAuthTypeFilter(authTypeFilter),
    ruleId: filterUtils.getRuleIdFromAuthTypeFilter(authTypeFilter),
    endDate: dateFilter?.endDate,
  });

  const onSearchChangeProxy = (value) => {
    setSearchFilter(value);
    if (value && value.trim().length > 2) {
      setSearch(value.trim());
    } else {
      setSearch('');
    }
  };

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const changeSort = (field) => {
    if (field !== sort) {
      setSort(field);
      setSortDir(TABLE_SORT_DESC);
      return;
    }

    setSortDir(sortDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onAction = (action, actionData) => {
    switch (action) {
      case CARD_OPEN_DETAILS:
        bottomSheet.addTab({
          id: actionData.cardId,
          title: `* ${actionData.lastFour}`,
          subtitle: `Balance: ${numberUtils.currencyFormat(
            actionData.lastCardBalance
          )}`,
          type: 'card',
        });
        break;
      case TABLE_SORT_ACTION:
        changeSort(actionData.sortField || actionData.field);
        break;
      default:
        break;
    }
  };

  const refresh = () => {
    refetchResponseCodes();
    refetch();
    refetchPaymentMethods();
    refetchReasonCodes();
  };

  const onAuthTypeChange = (value) => {
    resetPage();
    setAuthTypeFilter(value);
  };

  const onResponseCodeChange = (value) => {
    resetPage();
    setAuthResponseCode(value);
  };

  const onReasonCodeChange = (value) => {
    resetPage();
    setAuthReasonCode(value);
  };

  const onPaymentMethodChange = (value) => {
    resetPage();
    setPaymentMethodFilter(value);
  };

  const onCardStatusChange = (value) => {
    resetPage();
    setCardStatusFilter(value);
  };


  const filters = useMemo(() => {
    const newFilters = [];

    if (timeFilter) {
      newFilters.push({
        name: 'Time period:',
        value: timeFilter.value,
      });
    }

    if (paymentMethodFilter) {
      newFilters.push({
        name: 'Payment method:',
        value: paymentMethodFilter.label,
      });
    }

    if (authResponseCode) {
      newFilters.push({
        name: 'Response description:',
        value: authResponseCode.label,
      });
    }

    if (authTypeFilter) {
      newFilters.push({
        name: 'Auth Type:',
        value: authTypeFilter.label,
      });
    }

    if (cardStatusFilter) {
      newFilters.push({
        name: 'Card status:',
        value: cardStatusFilter.label,
      });
    }

    if (authReasonCode) {
      newFilters.push({
        name: 'Reason description',
        value: authReasonCode.label,
      })
    }
    return newFilters;
  }, [timeFilter,paymentMethodFilter, authResponseCode, authTypeFilter, authReasonCode, cardStatusFilter]);

  const handleDateLimit = (date) => {
    handleCurrentDay(date, setMaxEndDateLimit, timeLimitUtils.TimeLimit.MBS_CBS_DATE_LIMIT);
  }

  const clearFilters = () => {
    setSearch('');
    setSearchFilter('');
    setTimeFilter(null);
    setPaymentMethodFilter(null);
    setAuthResponseCode(null);
    setAuthTypeFilter(null);
    setCardStatusFilter(null);
    setDateFilter(null);
    setAuthReasonCode(null);
  };


  const renderFilterTooltip = () => {
    if (filters.length === 0) {
      return null;
    }
    return <FiltersContainer.Tooltip filters={filters} />;
  };

  const useExpandable =
    me && me.featureFlags[FeatureFlagConstants.useTransactionDetails];

  useEffect(() => {
    if (!dateFilter) setTimeFilter(null);
  }, [dateFilter]);

  const resetTimeFilter = () => {
    setTimeFilter(null);
  }

  return (
    <div data-cy="merchant-transactions-table">
      <div className="flex items-start justify-between">
        <div>
          <FiltersContainer
            tooltip={renderFilterTooltip()}
            search={searchFilter}
            onSearchChange={onSearchChangeProxy}
          >

          <CustomTimePeriodWrapper
            timeFilter={timeFilter}
            data={filterUtils.timeFilterWithCustomOption}
            handleComboBoxChange={setTimeFilter}
            maxEndDateLimit={maxEndDateLimit}
            handleDateLimit={handleDateLimit}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleResetDateFilter={resetTimeFilter}
          />

            {paymentMethods && (
              <FMCombobox
                placeholder="Payment method"
                data={paymentMethods.map((paymentMethod) => ({
                  label: paymentMethod.description,
                  value: paymentMethod.code,
                }))}
                selected={paymentMethodFilter}
                onChange={onPaymentMethodChange}
              />
            )}
            {responseCodes && (
              <FMCombobox
                placeholder="Response description"
                data={responseCodes.map((responseCode) => ({
                  label: responseCode.description,
                  value: responseCode.code,
                }))}
                selected={authResponseCode}
                onChange={onResponseCodeChange}
              />
            )}
            {reasonCodes && (
              <FMCombobox
                placeholder="Reason description"
                data={reasonCodes.map((reasonCode) => ({
                  label: reasonCode.description,
                  value: reasonCode.code,
                }))}
                selected={authReasonCode}
                onChange={onReasonCodeChange}
              />
            )}
            <FMCombobox
              placeholder="Auth type"
              data={authTypeFilterOptions}
              selected={authTypeFilter}
              onChange={onAuthTypeChange}
            />
            <CardExternalStatusFilter
              onChange={onCardStatusChange}
              selected={cardStatusFilter}
              placeholder="Card status"
            />
            {(search.length > 0 || filters.length > 0) && (
              <TextButton onClick={() => clearFilters()}>Clear all</TextButton>
            )}
          </FiltersContainer>
        </div>
        <div className="flex flex-col items-end">
          <RefreshTable setRefreshTable={refresh} />
        </div>
      </div>
      {isLoading ? (
        <div className="pl-2">
          <Spinner />
        </div>
      ) : (
        <>
          <DataTable
            data={data && data.rows}
            columns={columns}
            paginationProps={{
              totalItems: data ? data.count : 0,
              currentPage: page,
              itemsPerPage,
              onPageClick,
              onItemsPerPageChange: (e) => {
                resetPage();
                setItemsPerPage(e);
              },
            }}
            onAction={onAction}
            sort={sort}
            sortDirection={sortDir}
            ExpandedRenderer={useExpandable ? TransactionDetailsRow : null}
          />
        </>
      )}
    </div>
  );
}

MerchantTransactions.propTypes = {
  id: PropTypes.number.isRequired,
};
