import api from '../../../api';
import useApiClient from '../../useApiClient';
import MccKeys from './Mcc.keys';
import useLoggedInQuery from '../../useLoggedInQuery';

const getMccByMccId = async (
  client,
  mcc,
) => api.mccs.fetchMccByMccId(client, mcc);


export default function useGetMccByMccId(mcc, options={}) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MccKeys.detail(mcc),
    async () => getMccByMccId(apiClient, mcc),
    options
  );
}
