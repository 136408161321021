import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Modal from '../Modal';
import Checkbox from '../forms/Checkbox';
import Textarea from '../forms/Textarea';

const getTitle = (title) => (
  <div className="flex items-center">
    <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
      <ExclamationCircleIcon className="w-5 text-blue-900" />
    </div>
    {title}
  </div>
);
export default function StatusConfirmationModal({
  title,
  content,
  primaryAction,
  onClose,
  primaryText,
  ...rest
}) {
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState('');

  const resetFields = () => {
    setShowComment(false);
    setComment('');
  };
  const onShowCommentChange = (value) => {
    setShowComment(value);
    if (!value) {
      setComment('');
    }
  };

  const renderComment = () => (
    <>
      <Checkbox
        className="mb-3"
        isChecked={showComment}
        onChange={onShowCommentChange}
      >
        Add Comment
      </Checkbox>
      {showComment && <Textarea value={comment} onChange={setComment} maxLength="250" />}
    </>
  );
  const onPrimaryAction = () => {
    primaryAction(comment);
    resetFields();
  };
  const onCloseAciton = () => {
    onClose();
    resetFields();
  };
  return (
    <Modal
      {...rest}
      primaryAction={onPrimaryAction}
      onClose={onCloseAciton}
      primaryText={primaryText}
      title={getTitle(title)}
      divider={false}
    >
      <div className="ml-10 ">
        <p className="text-sm text-left text-gray-500 pr-1 mb-4">{content}</p>
        <div className="mt-4">
          {renderComment()}
        </div>
      </div>
    </Modal>
  );
}

StatusConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  primaryAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  primaryText: PropTypes.string,
};
StatusConfirmationModal.defaultProps = {
  primaryText: 'Confirm',
};
