/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';
import CardTransactions from '../table/CardTransactions';
import CardActivityLog from '../cardActivityLog/CardActivityLog';
import TabsContainer from '../TabsContainer';
import CardSummary from '../table/CardSummary';
import CardMerchants from '../cards/CardMerchants';
import CardDetailsSidebar from './CardDetailsSidebar';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';

const tabs = [
  {
    name: 'Authorization',
    value: 'authorization',
  },
  {
    name: 'Merchants',
    value: 'merchants',
  },
  {
    name: 'Summary',
    value: 'summary',
  },
  {
    name: 'Activity',
    value: 'activity',
  },
];
export default function CardDetails({ id }) {
  const viewKey = `card-${id}`;
  const bottomSheetGroupContext = useGroupViewContext();
  const persistedState = bottomSheetGroupContext.state(viewKey) || {};
  const [selectedTab, setSelectedTab] = useState(persistedState.selectedTab || tabs[0].value);

  useEffect(() => {
    if (persistedState.selectedTab !== selectedTab) {
      bottomSheetGroupContext.setState(viewKey, { ...persistedState, selectedTab });
    }
  }, [selectedTab]);

  const renderActivityTab = () => (
    <CardActivityLog id={id} />
  );

  const renderAuthenticationTab = () => (
    <CardTransactions cardId={id} />
  );
  const renderMerchantsTab = () => (
    <CardMerchants cardId={id} />
  );
  const renderSummary = () => (
    <CardSummary cardId={id} />
  );
  const tabsContent = {
    authorization: renderAuthenticationTab,
    merchants: renderMerchantsTab,
    summary: renderSummary,
    activity: renderActivityTab,
  };

  return (
    <div>
      <Grid className="sm:gap-10">
        <div className="col-span-12 pt-4 sm:col-span-3">
          <CardDetailsSidebar cardId={id} />
        </div>
        <div className="col-span-12 sm:col-span-9">
          <TabsContainer
            className="py-3"
            tabs={tabs}
            tabsContent={tabsContent}
            selectedTab={selectedTab}
            onSelectedTabChange={setSelectedTab}
            onStateChange={(state) => {
              bottomSheetGroupContext.setState(viewKey, { ...persistedState, tabContainer: state });
            }}
            initialState={persistedState.tabContainer || {}}
            />
        </div>
      </Grid>
    </div>
  );
}

CardDetails.propTypes = {
  id: PropTypes.number.isRequired,
};
