import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import PDSKeys from './PDS.keys';

const getPDSCards = async (client, params) => api.pds.fetchPDSCards(client, params);

export default function useGetPDSCards(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    PDSKeys.cards(params),
    async () => getPDSCards(apiClient, params),
  );
}
