import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDidMount } from 'beautiful-react-hooks';
import PaginationConfig from '../../config/PaginationConfig';
import useGetCardMerchants from '../../hooks/api/cards/useGetCardMerchants';
import TextButton from '../buttons/TextButton';
import FiltersContainer from '../filters/FiltersContainer';
import FMCombobox from '../forms/FMCombobox';
import DataTable, { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../table/DataTable';
import RefreshTable from '../RefreshTable';
import Spinner from '../Spinner';
import merchantTableUtils from '../../utils/merchantTableUtils';
import filterUtils from '../../utils/filterUtils';
import useBottomSheetContext from '../../hooks/bottomSheet/useBottomSheet';
import { MERCHANT_OPEN_MERCHANT } from '../table/MerchantsTable';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';
import timeLimitUtils from '../../utils/timeLimitUtils'
import CustomTimePeriodWrapper from '../forms/CustomTimePeriodWrapper'
import { handleCurrentDay } from '../../utils/date'

const columns = merchantTableUtils.getMerchantTableColumns('merchantsByCard');

export default function CardMerchants({ cardId }) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [searchFilter, setSearchFilter] = useState('');
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [timeFilter, setTimeFilter] = useState(null);
  const [authTypeFilter, setAuthTypeFilter] = useState(null);
  const [order, setOrder] = useState('name');
  const [orderDir, setOrderDir] = useState(TABLE_SORT_ASC);
  const [filters, setFilters] = useState([]);
  const bottomSheet = useBottomSheetContext();

  const viewName = "merchants"
  const groupView = useGroupViewContext();
  const tabState = groupView.state(viewName);
  const [mounted, setIsMounted] = useState(false);
  const onMount = useDidMount();
  onMount(() => setIsMounted(true));

  const [maxEndDateLimit, setMaxEndDateLimit] = useState(new Date());
  const [dateFilter, setDateFilter] = useState(null);

  useEffect(() =>{
    if(tabState === undefined) return
    if(tabState.search) setSearch(tabState.search)
    if(tabState.searchFilter) setSearchFilter(tabState.searchFilter)
    if(tabState.authTypeFilter) setAuthTypeFilter(tabState.authTypeFilter)
    if(tabState.timeFilter) setTimeFilter(tabState.timeFilter)
    if(tabState.selectedStatus) setSelectedStatus(tabState.selectedStatus)
    if(tabState.orderDir) setOrderDir(tabState.orderDir)
    if(tabState.order) setOrder(tabState.order)
    if(tabState.page) setPage(tabState.page)
    if(tabState.filters) setFilters(tabState.filters)
    if(tabState.itemsPerPage) setItemsPerPage(tabState.itemsPerPage)
  }, [])

  useEffect(() =>{
    if (!mounted) {
      return;
    }
    groupView.setState(viewName, {
      timeFilter,
      searchFilter,
      authTypeFilter,
      selectedStatus,
      search,
      orderDir,
      order,
      page,
      itemsPerPage,
      filters
    })
  }, [mounted, filters, page, itemsPerPage, search])

  const selectedPeriod = timeFilter?.value !== 'custom' ? timeFilter?.value : null

  const { data, refetch, isLoading } = useGetCardMerchants(cardId, {
    search,
    period: dateFilter ? dateFilter.startDate : selectedPeriod,
    page,
    itemsPerPage,
    order,
    orderDir,
    statusApiName: selectedStatus ? selectedStatus.value : null,
    duringAttack: authTypeFilter && authTypeFilter.value === 'attack-auth',
    endDate:  dateFilter && dateFilter.endDate,
  });

  useEffect(() => {
    const newFilters = [];

    if (timeFilter) {
      newFilters.push({
        name: 'Time period:',
        value: timeFilter.value,
      });
    }

    if (selectedStatus) {
      newFilters.push({
        name: 'Merchant status:',
        value: selectedStatus.value,
      });
    }

    if (authTypeFilter) {
      newFilters.push({
        name: 'Auth Type:',
        value: authTypeFilter.label,
      });
    }
    setFilters(newFilters);
  }, [timeFilter, selectedStatus, authTypeFilter]);

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const onAuthTypeChange = (value) => {
    resetPage();
    setAuthTypeFilter(value);
  };

  const onSearchChangeProxy = (value) => {
    setSearchFilter(value);
    if (value && value.trim().length > 2) {
      setSearch(value.trim());
    } else {
      setSearch('');
    }
  };

  const renderFilterTooltip = () => {
    if (filters.length === 0) {
      return null;
    }
    return (<FiltersContainer.Tooltip filters={filters} />);
  };

  const clearFilters = () => {
    setSearch('');
    setSearchFilter('');
    setTimeFilter(null);
    setAuthTypeFilter(null);
    setDateFilter(null);
  };

  const resetTimeFilter = () => {
    setTimeFilter(null);
  }

  const changeSort = (field) => {
    if (field !== order) {
      setOrder(field);
      setOrderDir(TABLE_SORT_DESC);
      return;
    }

    setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const handleDateLimit = (date) => {
    handleCurrentDay(date, setMaxEndDateLimit, timeLimitUtils.TimeLimit.MBS_CBS_DATE_LIMIT)
  }

  useEffect(() => {
    if (!dateFilter) setTimeFilter(null);
  }, [dateFilter]);


  const onAction = (action, actionData) => {
    switch (action) {
      case MERCHANT_OPEN_MERCHANT:
        bottomSheet.addTab({
          id: actionData.id,
          title: actionData.name,
          subtitle: `ID: ${actionData.number}`,
          type: 'merchant',
        });
        break;
      case TABLE_SORT_ACTION:
        changeSort(actionData.sortField || actionData.field);
        break;
      default:
        break;
    }
  };

  const handleResetDateFilter = () => {
    setTimeFilter(null)
  }

  return (
    <>
      <div className="flex items-start justify-between">
        <FiltersContainer
          tooltip={renderFilterTooltip()}
          search={searchFilter}
          onSearchChange={onSearchChangeProxy}
        >
          <CustomTimePeriodWrapper
            timeFilter={timeFilter}
            data={filterUtils.timeFilterWithCustomOption}
            handleComboBoxChange={setTimeFilter}
            maxEndDateLimit={maxEndDateLimit}
            handleDateLimit={handleDateLimit}
            dateFilter={dateFilter}
            resetTimeFilter={resetTimeFilter}
            setDateFilter={setDateFilter}
            handleResetDateFilter={handleResetDateFilter}
          />

          <FMCombobox
            placeholder="Merchant Status"
            data={filterUtils.StatusOptions}
            selected={selectedStatus}
            onChange={setSelectedStatus}
          />
          <FMCombobox placeholder="Auth type" data={filterUtils.AuthTypeOptions} selected={authTypeFilter} onChange={onAuthTypeChange} />
          {(search.length > 0 || filters.length > 0) && (
            <TextButton onClick={() => clearFilters()}>
              Clear all
            </TextButton>
          )}
        </FiltersContainer>
        <div className="flex flex-col items-end">
          <RefreshTable setRefreshTable={refetch} />
        </div>
      </div>
      {isLoading
        ? <div className="pl-2"><Spinner /></div>
        : (
          <>
            <DataTable
              data={data?.rows}
              columns={columns}
              paginationProps={{
                totalItems: data?.count,
                currentPage: page,
                itemsPerPage,
                onPageClick,
                onItemsPerPageChange: (e) => { resetPage(); setItemsPerPage(e); },
              }}
              onAction={onAction}
              sort={order}
              sortDirection={orderDir}
            />
          </>
        )}
    </>
  );
}

CardMerchants.propTypes = {
  cardId: PropTypes.number.isRequired,
};
