import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import CardsTable, { CARD_ASSIGN_BULK_ACTION, CARD_BULK_ACTION} from '../../components/table/CardsTable';
import useConstant from '../../hooks/useConstant';
import cardTableUtils from '../../utils/cardTableUtils';
import PaginationConfig from '../../config/PaginationConfig';
import useGetPDSCards from '../../hooks/api/pds/useGetPDSCards';
import useAssignCard from '../../hooks/api/cards/useAssignCard';
import useBulkUpdateCardStatus from '../../hooks/api/cards/useBulkUpdateCardStatus'
import { TABLE_SORT_DESC } from '../../components/table/CardSortableHeader';
import { TABLE_SORT_ACTION, TABLE_SORT_ASC } from '../../components/table/DataTable';
import { toastWithPromise } from '../../components/Toast/Toast';
import PDSKeys from '../../hooks/api/pds/PDS.keys';

export default function PDSCards({
  mcc, status, timeSelected, excludeMcc, search, authCount, refreshTick, reasonCode, startDate, endDate
}) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [order, setOrder] = useState('updatedAt');
  const [orderDir, setOrderDir] = useState(TABLE_SORT_DESC);
  const columns = useConstant(() => cardTableUtils.getCardTableColumns('pds'));
  const isCustomRange = timeSelected === 'custom';
  const assignCard = useAssignCard();
  const bulkUpdateCardStatus = useBulkUpdateCardStatus();
  const queryClient = useQueryClient();
  const request = {
    mcc,
    excludeMcc,
    externalStatusCode: status,
    page,
    itemsPerPage,
    timeSelected,
    startDate: isCustomRange && startDate,
    endDate: isCustomRange ? endDate : undefined,
    search,
    authCount,
    order,
    orderDir,
    reasonCode,
  }
  const { isLoading, data, refetch } = useGetPDSCards(request);
  const onActionSuccess = () => queryClient.invalidateQueries(PDSKeys.cards(request));

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const changeSort = (field) => {
    if (field !== order) {
      setOrder(field);
      setOrderDir(TABLE_SORT_DESC);
      return;
    }

    setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const updateCardAssignBulkUserAction = (card, user) => {
    toastWithPromise(
      Promise.all(
        card.ids.map((e) =>
          assignCard.mutateAsync({
            id: e,
            data: {
              oldUserId: null,
              userId: user.value,
            },
          })
        )
      ),
      {
        loading: 'Updating card details...',
        success: 'Card details updated successfully',
        error: 'An error occurred while updating card details',
      }
    );
  };

  const updateCardStatusBulkUserAction = (card) => {
    const { ids, status: statusApiName, reason, comment } = card;
    toastWithPromise(
      bulkUpdateCardStatus.mutateAsync({
        ids,
        statusApiName,
        reason,
        comment
      }),
      {
        loading: 'Updating card details...',
        success: 'Card details updated successfully',
        error: 'An error occurred while updating card details',
      }
    );
  };

  const onTableAction = (action, args, rest) => {
    switch (action) {
      case TABLE_SORT_ACTION:
        changeSort(args.field);
        break;
      case CARD_ASSIGN_BULK_ACTION:
        updateCardAssignBulkUserAction(args, rest);
        break;
      case CARD_BULK_ACTION:
        updateCardStatusBulkUserAction(args);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (refreshTick > 0) {
      refetch();
    }
  }, [refreshTick]);

  return (
    <CardsTable
      columns={columns}
      data={data ? data.rows : []}
      loading={isLoading}
      onActionSuccess={onActionSuccess}
      paginationProps={{
        totalItems: data && data.count ? data.count : 0,
        currentPage: page,
        itemsPerPage,
        onPageClick,
        onItemsPerPageChange: (e) => { resetPage(); setItemsPerPage(e); },
      }}
      onAction={onTableAction}
      sort={order}
      sortDirection={orderDir}
    />
  );
}

PDSCards.propTypes = {
  mcc: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  timeSelected: PropTypes.string.isRequired,
  excludeMcc: PropTypes.bool,
  authCount: PropTypes.string,
  search: PropTypes.string,
  refreshTick: PropTypes.number,
  reasonCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

PDSCards.defaultProps = {
  mcc: [],
  status: null,
  excludeMcc: false,
  authCount: null,
  search: null,
  refreshTick: 0,
  reasonCode: null,
  startDate: null,
  endDate: null,
};
