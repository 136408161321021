import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetReasonCodes(params = {}) {
  const apiClient = useApiClient();
  return useLoggedInQuery(['reasonCodes', ...Object.values(params)], () =>
    api.transactions.fetchReasonCodes(apiClient, params)
  );
}
