import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '../../components/Grid';
import TextButton from '../../components/buttons/TextButton';
import PaginationConfig from '../../config/PaginationConfig';
import FiltersContainer from '../../components/filters/FiltersContainer';
import FMCombobox from '../../components/forms/FMCombobox';
import CardsTable, {
  CARD_BULK_ACTION,
} from '../../components/table/CardsTable';
import cardTableUtils from '../../utils/cardTableUtils';
import {
  TABLE_SORT_ACTION,
  TABLE_SORT_ASC,
  TABLE_SORT_DESC,
} from '../../components/table/DataTable';
import Subtitle from '../../components/headings/Subtitle';
import CardHomeMetrics from './CardHomeMetrics';
import { toastWithPromise } from '../../components/Toast/Toast';

import RefreshTable from '../../components/RefreshTable';
import useGetCompromisedCards from '../../hooks/api/cards/useGetCompromisedCards';
import numberUtils from '../../utils/numberUtils';
import useAssignCard from '../../hooks/api/cards/useAssignCard';
import useGetCompromisedCardsMerchantsFilter from '../../hooks/api/cards/useGetCompromisedCardsMerchantsFilter';
import ArdenRounded from '../../components/ArdenRounded';
import CardConstants from '../../config/CardConstants';
import useGetCompromisedCardsRulesFilter from '../../hooks/api/cards/useGetCompromisedCardsRulesFilter';
import CardExternalStatusFilter from '../../components/filters/CardExternalStatusFilter';

function useGetFiltersForLiveCardDetails(isFromRules) {
  const getCompromisedCardsRulesFilter = useGetCompromisedCardsRulesFilter({
    enabled: isFromRules,
  });
  const getCompromisedCardsMerchantsFilter = useGetCompromisedCardsMerchantsFilter({
    enabled: !isFromRules,
  });

  return isFromRules ? getCompromisedCardsRulesFilter : getCompromisedCardsMerchantsFilter;
}

export default function LiveCardDetails({ isFromRules }) {
  const [search, setSearch] = useState('');
  const [assignedFilter, setAssignedFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [merchantFilter, setMerchantFilter] = useState(null);
  const [ruleFilter, setRuleFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    PaginationConfig.itemsPerPage
  );
  const [order, setOrder] = useState('lastCompromisedAt');
  const [orderDir, setOrderDir] = useState(TABLE_SORT_DESC);
  const [filtersDescription, setFiltersDescription] = useState([]);
  const assignCard = useAssignCard();

  const filters = {
    search,
    page,
    itemsPerPage,
    order,
    orderDir,
    cardAttackType: isFromRules
      ? CardConstants.cardAttackType.rule
      : CardConstants.cardAttackType.merchantAttack,
  };

  if (assignedFilter && assignedFilter.value === 'me') {
    filters.assignedToMe = true;
  }

  if (merchantFilter) {
    filters.merchantTenantId = merchantFilter.value;
  }
  if (ruleFilter) {
    filters.ruleId = ruleFilter.value;
  }
  filters.externalStatusCode = statusFilter ? statusFilter.code : '';
  const { data, isLoading, refetch } = useGetCompromisedCards(filters);

  const getMerchantOrRulesFilters = useGetFiltersForLiveCardDetails(isFromRules);
  const merchantOrRuleFilterData = getMerchantOrRulesFilters.data
    ? getMerchantOrRulesFilters.data.map((item) => ({
      value: item.id,
      label: item.name,
    }))
    : [];


  const cards = data ? data.rows : [];
  const count = data ? data.count : 0;

  useEffect(() => {
    const newFilters = [];
    if (statusFilter) {
      newFilters.push({
        name: 'Status:',
        value: statusFilter.label,
      });
    }

    if (merchantFilter) {
      newFilters.push({
        name: 'Confirmed Threat:',
        value: merchantFilter.label,
      });
    }

    if (ruleFilter) {
      newFilters.push({
        name: 'Active Alert:',
        value: ruleFilter.label,
      });
    }

    if (assignedFilter) {
      newFilters.push({
        name: 'Assigned to:',
        value: assignedFilter.label,
      });
    }

    setFiltersDescription(newFilters);
  }, [
    assignedFilter,
    merchantFilter,
    ruleFilter,
    statusFilter,
    setFiltersDescription,
  ]);

  const renderFilterTooltip = () => {
    if (filtersDescription.length === 0) {
      return null;
    }
    return <FiltersContainer.Tooltip filters={filtersDescription} />;
  };

  const columnsToUse = cardTableUtils.getCardTableColumns(
    'compromised',
    isFromRules
  );

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const clearFilters = () => {
    setSearch('');
    setMerchantFilter(null);
    setRuleFilter(null);
    setStatusFilter(null);
    setAssignedFilter(null);
    resetPage();
  };

  // @TODO Create a route on backend to handle multiple user assign
  const updateCardAssignAction = (Card, userId) => {
    toastWithPromise(
      Promise.all(
        Card.ids.map((e) =>
          assignCard.mutateAsync({
            id: e,
            data: {
              oldUserId: null,
              userId: userId.value,
            },
          })
        )
      ),
      {
        loading: `Updating card ${Card.cardNumber}`,
        success: `Card ${Card.cardNumber} updated successfully`,
        error: `An error occurred while updating Card ${Card.cardNumber}`,
      }
    );
  };


  const changeSort = (field) => {
    if (field !== order) {
      setOrder(field);
      setOrderDir(TABLE_SORT_DESC);
      return;
    }

    setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onCardAction = (action, args, rest) => {
    switch (action) {
      case TABLE_SORT_ACTION:
        changeSort(args.field);
        break;
      // @TODO refactor this to be part of CardsTable, no need to redefine the same
      // functionality on every screen that uses CardsTable
      // i.e. bulk assign doesn't work on "All Cards or Resolved screens"
      case CARD_BULK_ACTION:
        if (args.status === 'card-assign-bulk-action') {
          updateCardAssignAction(args, rest);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Grid>
      <div className="col-span-12" data-cy="merchants-screen">
        {false && (
          <>
            <CardHomeMetrics isFromRules={isFromRules} />
            <hr className=" my-8" />
          </>
        )}

        <Subtitle className="mb-5 my-8">
          Compromised Cards ({numberUtils.numberFormat(count)})
        </Subtitle>
        <div className="items-start justify-between lg:flex">
          <FiltersContainer
            search={search}
            onSearchChange={setSearch}
            tooltip={renderFilterTooltip()}
          >
            <CardExternalStatusFilter
              onChange={(e) => {
                resetPage();
                setStatusFilter(e);
              }}
              selected={statusFilter}
              placeholder="Select Status"
            />

            {isFromRules ? (
              <FMCombobox
                placeholder="Select Active Alert"
                data={merchantOrRuleFilterData}
                selected={ruleFilter}
                onChange={(e) => {
                  resetPage();
                  setRuleFilter(e);
                }}
              />
            ) : (
              <FMCombobox
                placeholder="Confirmed Threats"
                data={merchantOrRuleFilterData}
                selected={merchantFilter}
                onChange={(e) => {
                  resetPage();
                  setMerchantFilter(e);
                }}
              />
            )}
            <FMCombobox
              placeholder="Assigned to"
              data={[
                {
                  label: 'Self',
                  value: 'me',
                },
              ]}
              selected={assignedFilter}
              onChange={(e) => {
                resetPage(1);
                setAssignedFilter(e);
              }}
            />
            {(search.length > 0 || filtersDescription.length > 0) && (
              <TextButton onClick={() => clearFilters()}>Clear all</TextButton>
            )}
          </FiltersContainer>

          <RefreshTable setRefreshTable={() => refetch()} />
        </div>
        <CardsTable
          columns={columnsToUse}
          data={cards}
          loading={isLoading}
          paginationProps={{
            totalItems: count,
            currentPage: page,
            itemsPerPage,
            onItemsPerPageChange: (e) => {
              resetPage();
              setItemsPerPage(e);
            },
            onPageClick: (e) => setPage(e),
          }}
          onAction={onCardAction}
          sort={order}
          sortDirection={orderDir}
          emptyStateContent={<ArdenRounded />}
        />
      </div>
    </Grid>
  );
}

LiveCardDetails.propTypes = {
  isFromRules: PropTypes.bool,
};

LiveCardDetails.defaultProps = {
  isFromRules: false,
};
