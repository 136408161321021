import api from 'src/api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import UserKeys from './User.keys';

const getMe = async (client) => api.users.fetchMe(client);

export default function useGetMe() {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    UserKeys.me(),
    async () => getMe(apiClient),
  );
}
