import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/ui';

export default function BulkActionsButton(props) {
  const { icon, onClick, bulkActionTitle, bulkSize, bulkType, className, testId } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(className,"min-w-xs max-w-xl h-10 flex bg-white hover:bg-gray-50 flex-row justify-center items-center self-stretch flex-grow-0 flex-none rounded border-gray-300")}
      data-cy={testId ?? 'bulk-actions-button'}
    >
      {icon}
      <span className="text-sm font-medium">
        {bulkActionTitle}&nbsp;
        {bulkSize}&nbsp;
        {bulkType}
      </span>
    </button>
  );
}

BulkActionsButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  bulkActionTitle: PropTypes.string.isRequired,
  bulkSize: PropTypes.number.isRequired,
  bulkType: PropTypes.string.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
};

BulkActionsButton.defaultProps = {
  className: '',
};
