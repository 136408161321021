import { useQuery } from 'react-query';
import api from '../../../api';
import { getApiClient } from '../../../api/base';

const getInvitation = async (client, id) => api.admin.invitations.getInvitationById(client, id);

export default function useGetInvitation(id) {
  const apiClient = getApiClient();
  return useQuery(['getInvitation', id], async () => getInvitation(apiClient, id), { retry: false });
}
