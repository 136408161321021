import React from 'react';
import FMSelect from '../forms/FMSelect';

interface AssignListProps {
  value: number;
  label: string;
}

interface CardAssignProps {
  labelClass: string;
  disabled: boolean;
  assignList: AssignListProps[];
  value: string;
  handleUpdateCardAssign: () => void;
}

export const CardAssign = ({
  labelClass,
  disabled,
  assignList,
  value,
  handleUpdateCardAssign,
}: CardAssignProps) => {
  return (
    <>
      <hr className="col-span-12 mt-6 mb-6" />
      <div>
        <div className="flex justify-between mb-1">
          <span className={labelClass}>Assigned to</span>
        </div>
        <FMSelect
          className="w-full"
          size="small"
          isSearchable={false}
          value={value}
          options={assignList}
          getOptionLabel={(user: AssignListProps) => user.label}
          getOptionValue={(user: AssignListProps) => user.value}
          disabled={disabled}
          menuPlacement="bottom"
          onChange={handleUpdateCardAssign}
          isMulti={false}
          allowSelectAll={false}
          allOption={null}
          prefix={null}
          testSelector={null}
        />
      </div>
      <hr className="col-span-12 mt-4 mb-2" />
    </>
  );
};
