import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import MerchantKeys from './Merchant.keys';

export const updateBulkMerchantStatus = (
  client,
  ids,
  statusApiName,
  reason,
  comment
) => {
  if (!ids) {
    throw new Error('id is required');
  }
  return api.merchants.updateMerchantTenantBulkStatus(client, ids, statusApiName, reason, comment);
};

export default function useBulkUpdateMerchantStatus(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({
    ids,
    statusApiName,
    reason,
    comment
  }) => updateBulkMerchantStatus(client, ids, statusApiName, reason, comment), {
    onSuccess: () => {
      queryClient.invalidateQueries(MerchantKeys.all);
      queryClient.invalidateQueries(MerchantKeys.details());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
