import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getUserLogs = async (client, params) => api.account.fetchActivityLog(client, params);

export default function useGetUserLogs(userId) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'getUserLogs',
      userId,
    ],
    async () => getUserLogs(apiClient, { userId }),
  );
}
