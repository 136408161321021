import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '../../../components/ButtonGroup';
import RulesPerformanceSummary from './RulesPerformanceSummary';
import RulesPerformanceList from './RulesPerformanceList';

const viewOptions = [
  {
    label: 'Summary',
    value: 'summary',
  },
  {
    label: 'List',
    value: 'list',
  },
];

const timeOptions = [
  {
    label: '1d',
    value: '1d',
  },
  {
    label: '1w',
    value: '1w',
  },
  {
    label: '1m',
    value: '1m',
  },
  {
    label: '3m',
    value: '3m',
  },
  {
    label: '6m',
    value: '6m',
  },
  {
    label: '1y',
    value: '1y',
  },
  {
    label: 'all',
    value: 'all',
  },
];

const typeOptions = [
  {
    label: 'Merchant',
    value: 'MerchantTenant',
  },
  {
    label: 'Card',
    value: 'Card',
  },
];

export default function RulesPerformance({ tenantId }) {
  const [type, setTypeSelected] = useState(typeOptions[0].value);
  const [viewSelected, setViewSelected] = useState(viewOptions[0].value);
  const [timeSelected, setTimeSelected] = useState(timeOptions[0].value);

  return (
    <div>
      <div className="flex justify-between mb-8">
        <div className="flex space-x-4">
          <ButtonGroup
            roundedCorners={false}
            itemClassName="text-sm"
            options={typeOptions}
            selected={type}
            onChange={setTypeSelected}
          />
          <ButtonGroup
            roundedCorners={false}
            itemClassName="text-sm"
            options={viewOptions}
            selected={viewSelected}
            onChange={setViewSelected}
          />
        </div>
        <ButtonGroup
          roundedCorners={false}
          itemClassName="text-sm w-auto"
          options={timeOptions}
          selected={timeSelected}
          onChange={setTimeSelected}
        />
      </div>
      {viewSelected === 'summary' && (
        <RulesPerformanceSummary
          tenantId={tenantId}
          timeSelected={timeSelected}
          relatedModel={type}
        />
      )}
      {viewSelected === 'list' && (
        <RulesPerformanceList
          tenantId={tenantId}
          timeSelected={timeSelected}
          relatedModel={type}
        />
      )}
    </div>
  );
}

RulesPerformance.propTypes = {
  tenantId: PropTypes.number,
};

RulesPerformance.defaultProps = {
  tenantId: null,
};
