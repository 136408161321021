import React from 'react';
import PropTypes from 'prop-types';
import { Surface, Symbols } from 'recharts';
import { classNames } from '../../utils/ui';
import Checkbox from '../forms/Checkbox';

const SIZE = 32;

const EventKeys = [
  'dangerouslySetInnerHTML',
  'onCopy',
  'onCopyCapture',
  'onCut',
  'onCutCapture',
  'onPaste',
  'onPasteCapture',
  'onCompositionEnd',
  'onCompositionEndCapture',
  'onCompositionStart',
  'onCompositionStartCapture',
  'onCompositionUpdate',
  'onCompositionUpdateCapture',
  'onFocus',
  'onFocusCapture',
  'onBlur',
  'onBlurCapture',
  'onChange',
  'onChangeCapture',
  'onBeforeInput',
  'onBeforeInputCapture',
  'onInput',
  'onInputCapture',
  'onReset',
  'onResetCapture',
  'onSubmit',
  'onSubmitCapture',
  'onInvalid',
  'onInvalidCapture',
  'onLoad',
  'onLoadCapture',
  'onError',
  'onErrorCapture',
  'onKeyDown',
  'onKeyDownCapture',
  'onKeyPress',
  'onKeyPressCapture',
  'onKeyUp',
  'onKeyUpCapture',
  'onAbort',
  'onAbortCapture',
  'onCanPlay',
  'onCanPlayCapture',
  'onCanPlayThrough',
  'onCanPlayThroughCapture',
  'onDurationChange',
  'onDurationChangeCapture',
  'onEmptied',
  'onEmptiedCapture',
  'onEncrypted',
  'onEncryptedCapture',
  'onEnded',
  'onEndedCapture',
  'onLoadedData',
  'onLoadedDataCapture',
  'onLoadedMetadata',
  'onLoadedMetadataCapture',
  'onLoadStart',
  'onLoadStartCapture',
  'onPause',
  'onPauseCapture',
  'onPlay',
  'onPlayCapture',
  'onPlaying',
  'onPlayingCapture',
  'onProgress',
  'onProgressCapture',
  'onRateChange',
  'onRateChangeCapture',
  'onSeeked',
  'onSeekedCapture',
  'onSeeking',
  'onSeekingCapture',
  'onStalled',
  'onStalledCapture',
  'onSuspend',
  'onSuspendCapture',
  'onTimeUpdate',
  'onTimeUpdateCapture',
  'onVolumeChange',
  'onVolumeChangeCapture',
  'onWaiting',
  'onWaitingCapture',
  'onAuxClick',
  'onAuxClickCapture',
  'onClick',
  'onClickCapture',
  'onContextMenu',
  'onContextMenuCapture',
  'onDoubleClick',
  'onDoubleClickCapture',
  'onDrag',
  'onDragCapture',
  'onDragEnd',
  'onDragEndCapture',
  'onDragEnter',
  'onDragEnterCapture',
  'onDragExit',
  'onDragExitCapture',
  'onDragLeave',
  'onDragLeaveCapture',
  'onDragOver',
  'onDragOverCapture',
  'onDragStart',
  'onDragStartCapture',
  'onDrop',
  'onDropCapture',
  'onMouseDown',
  'onMouseDownCapture',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseMove',
  'onMouseMoveCapture',
  'onMouseOut',
  'onMouseOutCapture',
  'onMouseOver',
  'onMouseOverCapture',
  'onMouseUp',
  'onMouseUpCapture',
  'onSelect',
  'onSelectCapture',
  'onTouchCancel',
  'onTouchCancelCapture',
  'onTouchEnd',
  'onTouchEndCapture',
  'onTouchMove',
  'onTouchMoveCapture',
  'onTouchStart',
  'onTouchStartCapture',
  'onPointerDown',
  'onPointerDownCapture',
  'onPointerMove',
  'onPointerMoveCapture',
  'onPointerUp',
  'onPointerUpCapture',
  'onPointerCancel',
  'onPointerCancelCapture',
  'onPointerEnter',
  'onPointerEnterCapture',
  'onPointerLeave',
  'onPointerLeaveCapture',
  'onPointerOver',
  'onPointerOverCapture',
  'onPointerOut',
  'onPointerOutCapture',
  'onGotPointerCapture',
  'onGotPointerCaptureCapture',
  'onLostPointerCapture',
  'onLostPointerCaptureCapture',
  'onScroll',
  'onScrollCapture',
  'onWheel',
  'onWheelCapture',
  'onAnimationStart',
  'onAnimationStartCapture',
  'onAnimationEnd',
  'onAnimationEndCapture',
  'onAnimationIteration',
  'onAnimationIterationCapture',
  'onTransitionEnd',
  'onTransitionEndCapture',
];

const getEventHandlerOfChild = (originalHandler, data, index) => (e) => {
  originalHandler(data, index, e);

  return null;
};

const adaptEventsOfChild = (
  props,
  data,
  index,
) => {
  if (typeof props !== 'object') {
    return null;
  }

  let out = null;

  Object.keys(props).forEach((key) => {
    const item = props[key];

    if (EventKeys.includes(key) && typeof item === 'function') {
      if (!out) out = {};

      out[key] = getEventHandlerOfChild(item, data, index);
    }
  });

  return out;
};

export default function CheckboxLegend(props) {
  const {
    payload,
    layout,
    align,
    iconSize,
    formatter,
    inactiveColor,
  } = props;
  if (!payload || !payload.length) {
    return null;
  }

  const finalStyle = {
    padding: 0,
    margin: 0,
    textAlign: layout === 'horizontal' ? align : 'left',
  };

  const renderIcon = (data) => {
    const halfSize = SIZE / 2;
    const sixthSize = SIZE / 6;
    const thirdSize = SIZE / 3;
    const color = data.inactive ? inactiveColor : data.color;

    if (data.type === 'plainline') {
      return (
        <line
          strokeWidth={4}
          fill="none"
          stroke={color}
          strokeDasharray={data.payload.strokeDasharray}
          x1={0}
          y1={halfSize}
          x2={SIZE}
          y2={halfSize}
          className="recharts-legend-icon"
        />
      );
    }
    if (data.type === 'line') {
      return (
        <>
          <path
            strokeWidth={2}
            stroke={data.payload.dot.fill}
            d={`M0,${halfSize}h${SIZE}`}
            className="recharts-legend-icon"
            strokeDasharray={data.payload.strokeDasharray}
          />
          <path
            strokeWidth={2}
            fill={data.payload.dot.fill}
            stroke={data.payload.dot.stroke}
            d={`M${thirdSize},${halfSize}
            A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
            A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
            className="recharts-legend-icon"
          />
        </>
      );
    }
    if (data.type === 'rect') {
      return (
        <path
          stroke="none"
          fill={color}
          d={`M0,${SIZE / 8}h${SIZE}v${(SIZE * 3) / 4}h${-SIZE}z`}
          className="recharts-legend-icon"
        />
      );
    }
    if (React.isValidElement(data.legendIcon)) {
      const iconProps = { ...data };
      delete iconProps.legendIcon;
      return React.cloneElement(data.legendIcon, iconProps);
    }

    return (
      <Symbols
        fill={color}
        cx={halfSize}
        cy={halfSize}
        size={SIZE}
        sizeType="diameter"
        type={data.type}
      />
    );
  };

  const renderItems = () => {
    const viewBox = {
      x: 0, y: 0, width: SIZE, height: SIZE,
    };
    const itemStyle = {
      display: layout === 'horizontal' ? 'inline-block' : 'flex',
      marginRight: 10,
    };
    const svgStyle = { display: 'inline-block', verticalAlign: 'middle', marginRight: 4 };

    return payload.map((entry, i) => {
      const finalFormatter = entry.formatter || formatter;
      const className = classNames([
        'recharts-legend-item',
        `legend-item-${i}`,
        entry.inactive && 'inactive',
      ]);

      if (entry.type === 'none') {
        return null;
      }

      return (
        <li
          className={classNames([
            className,
            'flex items-center justify-between my-2',
          ])}
          style={itemStyle}
          key={`legend-item-${i}`} // eslint-disable-line react/no-array-index-key
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...adaptEventsOfChild(props, entry, i)}
        >
          <Checkbox isChecked={entry.payload.isActive}>
            <span className="text-xs text-black recharts-legend-item-text">
              {finalFormatter ? finalFormatter(entry.value, entry, i) : entry.value}
            </span>
          </Checkbox>
          <div className="mr-4">
            <Surface width={iconSize} height={iconSize} viewBox={viewBox} style={svgStyle}>
              {renderIcon(entry)}
            </Surface>
          </div>
        </li>
      );
    });
  };

  return (
    <ul className="recharts-default-legend" style={finalStyle}>
      {renderItems()}
    </ul>
  );
}

CheckboxLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({})),
  formatter: PropTypes.shape({}),
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  iconSize: PropTypes.number,
  inactiveColor: PropTypes.string,
};

CheckboxLegend.defaultProps = {
  payload: null,
  layout: 'horizontal',
  align: 'center',
  iconSize: 14,
  inactiveColor: '#ccc',
  formatter: null,
};
