import React from 'react';
import PropTypes from 'prop-types';

function trackPresentFormat(trackPresent) {
  switch (trackPresent) {
    case '0':
      return 'Neither track1 or track2 present';
    case '1':
      return 'Track1 present only';
    case '2':
      return 'Track2 present only';
    case '3':
      return 'Both track1 and track2 present';
    default:
      return '';
  }
}

function cvcResultFormat(cvcRc) {
  switch (cvcRc) {
    case '0':
      return 'Passed';
    case '1':
      return 'Fail';
    case '2':
      return 'Not Processed';
    default:
      return '';
  }
}

function avsResultFormat(avsRc) {
  switch (avsRc) {
    case 'N':
      return 'Zip Code Mismatch';
    case 'U':
      return 'Not verified';
    case 'Z':
      return 'Zip Code Match, Address Not Verified';
    case 'Y':
      return 'Zip Code and Address Match';
    default:
      return '';
  }
}

function panEntryFormat(panEntry) {
  switch (panEntry) {
    case '00':
      return 'UNKNOWN';
    case '01':
      return 'MANUAL';
    case '02':
      return 'MAGNETIC STRIPE';
    case '05':
      return 'ICC';
    case '06':
      return 'CUSTOMER KEYED';
    case '07':
      return 'CONTACTLESS';
    case '08':
      return 'ECOMMERCE';
    case '10':
      return 'CREDENTIALS ON FILE';
    case '80':
      return 'FALLBACK MAGSTRIPE';
    case '91':
      return 'CONTACTLESS MAGNETIC STRIPE';
    case 'A':
      return 'ACCOUNT DATA ON FILE';
    case 'S':
      return 'KEYED';
    case 'T':
      return 'LEGACY NO T1 T2 ONLY EXPIRY WITH MANUAL CSC';
    case 'V':
      return 'VOICE';
    case 'W':
      return 'MAGSTRIPE READ CSC KEYED ';
    default:
      return '';
  }
}

export default function TransactionDetailsRow({ row }) {
  return (
    <div className="m-6 bg-white border border-gray-200 rounded-md">
      <table className="min-w-full border-collapse divide-y divide-gray-200 rounded-sm">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-4 py-2 text-xs font-normal text-left text-gray-500">
              trackPresent
            </th>
            <th className="px-4 py-2 text-xs font-normal text-left text-gray-500">
              cvcRc
            </th>
            <th className="px-4 py-2 text-xs font-normal text-left text-gray-500">
              avsRc
            </th>
            <th className="px-4 py-2 text-xs font-normal text-left text-gray-500">
              Authorizer values
            </th>
            <th className="px-4 py-2 text-xs font-normal text-left text-gray-500">
              PAN entry values
            </th>
            <th className="px-4 py-2 text-xs font-normal text-left text-gray-500">
              Network
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2 text-sm text-gray-800">
              {trackPresentFormat(row.trackPresent)}
            </td>
            <td className="px-4 py-2 text-sm text-gray-800">
              {cvcResultFormat(row.cvcResultCode)}
            </td>
            <td className="px-4 py-2 text-sm text-gray-800">
              {avsResultFormat(row.avsResultCode)}
            </td>
            <td className="px-4 py-2 text-sm text-gray-800">
              {row.authorizer}
            </td>
            <td className="px-4 py-2 text-sm text-gray-800">
              {panEntryFormat(row.panEntry)}
            </td>
            <td className="px-4 py-2 text-sm text-gray-800">
              {row.source}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

TransactionDetailsRow.propTypes = {
  row: PropTypes.shape({
    trackPresent: PropTypes.string,
    cvcResultCode: PropTypes.string,
    avsResultCode: PropTypes.string,
    authorizer: PropTypes.string,
    panEntry: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
};

TransactionDetailsRow.displayName = 'TransactionDetailsRow'
