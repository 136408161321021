import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon, XIcon, BanIcon } from '@heroicons/react/outline';

import StatusConstants from '../../../config/StatusConstants';
import Title from '../Title';



export default function BulkEditMerchantModalTitle({ status }) {
  switch (status) {
    case StatusConstants.trustedApiName:
      return <Title title="Trust Merchants" icon={<CheckCircleIcon className="w-5 text-green-600" />} />;
    case StatusConstants.blockedApiName:
      return <Title title="Block Merchants" icon={<BanIcon className="mr- text-red-600" />} />;
    case StatusConstants.reviewedApiName:
      return <Title title="Remove Merchants From List" icon={<XIcon className="mr- text-gray-600" />} />;
    default:
      return <Title title="Confirm"/>;
  }
}

BulkEditMerchantModalTitle.propTypes = {
  status: PropTypes.string.isRequired,
};
