import React from 'react';
import ardenShieldOnly from '../assets/arden-shield-only.svg';

export default function ArdenRounded() {
  return (
    <div className="flex justify-center py-6 bg-white h-64 border-b border-l border-r border-gray">
      <div className="relative w-5 h-5 mr-4">
        <div className="bg-gray-50 rounded-full h-36 w-36 relative">
          <div className="arden-cicle-inner absolute bg-gray-100 rounded-full h-28 w-28 mt-4 mr-0 mb-0 ml-4">
            <img src={ardenShieldOnly} className="w-14 h-14 ml-7 mt-7" alt="arden shield only logo" />
          </div>
        </div>
      </div>
      <div
        className="relative text-center text-xs text-gray-500 top-36 -left-12"
      >
        <span>
          Everything is under control,
          <br />
          Arden has your back...
        </span>
      </div>
    </div>
  );
}
