import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDidMount } from 'beautiful-react-hooks';
import ButtonGroup from '../ButtonGroup';
import DataTable from './DataTable';
import SummaryStatColumn from './columns/SummaryStatColumn';
import useGetCardSummary from '../../hooks/api/cards/useGetCardSummary';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';
import filterUtils from '../../utils/filterUtils';

const columns = [
  {
    field: 'name',
    headerName: '',
  },
  {
    field: 'stat',
    headerName: 'Auths',
    render: SummaryStatColumn,
  },
  {
    field: 'attackStat',
    headerName: 'Attack Auths',
    render: SummaryStatColumn,
  },
];

const viewKey = 'summary';

export default function CardSummary({ cardId }) {
  const groupView = useGroupViewContext();
  const tabState = groupView.state(viewKey);
  const timeFilterOptions = filterUtils.timeFilterWithAllOptions;
  const [timeFilter, setTimeFilter] = useState(
    '12h',
  );
  const [mounted, setIsMounted] = useState(false);
  const onMount = useDidMount();
  onMount(() => setIsMounted(true));

  useEffect(() =>{
    if(tabState === undefined) return
    if(tabState.timeFilter) setTimeFilter(tabState.timeFilter)
  }, [])
  useEffect(() =>{
    if (!mounted) {
      return;
    }
    groupView.setState('summary', {
      timeFilter,
    });
  }, [timeFilter])

  const { data } = useGetCardSummary(cardId, timeFilter);

  return (
    <div>
      <div className="flex justify-end mb-3">
        <ButtonGroup
          itemClassName="w-16 text-xs"
          selected={timeFilter}
          onChange={(value) => setTimeFilter(value)}
          options={timeFilterOptions}
        />
      </div>
      <DataTable
        showPagination={false}
        columns={columns}
        data={data}
      />
    </div>
  );
}

CardSummary.propTypes = {
  cardId: PropTypes.number.isRequired,
};
