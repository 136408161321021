import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDidMount } from 'beautiful-react-hooks';
import { useQueryClient } from 'react-query';

import PaginationConfig from '../../config/PaginationConfig';
import CardsTable from './CardsTable';
import cardTableUtils from '../../utils/cardTableUtils';
import RefreshTable from '../RefreshTable';
import useGetCardsByMerchant from '../../hooks/api/cards/useGetCardsByMerchant';
import FiltersContainer from '../filters/FiltersContainer';
import FMCombobox from '../forms/FMCombobox';
import TextButton from '../buttons/TextButton';
import filterUtils from '../../utils/filterUtils';
import { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from './DataTable';
import useGetMe from '../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';
import CardExternalStatusFilter from '../filters/CardExternalStatusFilter';
import CustomTimePeriodWrapper from '../forms/CustomTimePeriodWrapper'
import timeLimitUtils from '../../utils/timeLimitUtils'
import { handleCurrentDay } from '../../utils/date'
import MerchantKeys from '../../hooks/api/merchants/Merchant.keys';

const columnsToUse = cardTableUtils.getCardTableColumns('merchantCards');

export default function MerchantCards({ merchantTenantId, merchantName }) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [searchFilter, setSearchFilter] = useState('');
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [timeFilter, setTimeFilter] = useState(null);
  const [authTypeFilter, setAuthTypeFilter] = useState(null);
  const [order, setOrder] = useState('name');
  const [orderDir, setOrderDir] = useState(TABLE_SORT_ASC);
  const [filters, setFilters] = useState([]);
  const [mounted, setIsMounted] = useState(false);
  const onMount = useDidMount();
  onMount(() => setIsMounted(true));

  const [maxEndDateLimit, setMaxEndDateLimit] = useState(new Date());
  const [dateFilter, setDateFilter] = useState(null);
  const queryClient = useQueryClient();

  const groupViewKey = "cards";
  const groupView = useGroupViewContext();
  const tabState = groupView.state(groupViewKey);
  const selectedPeriod = timeFilter?.value !== 'custom' ? timeFilter?.value : null
  const merchantCardsRequest = {
    page,
    itemsPerPage,
    search,
    period: dateFilter ? dateFilter.startDate : selectedPeriod,
    externalStatusCode: selectedStatus ? selectedStatus.code : null,
    duringAttack: authTypeFilter && authTypeFilter.value === filterUtils.AuthTypeValues.attackAuth,
    isApproved: filterUtils.handleIsApproved(authTypeFilter?.value),
    responseCode: filterUtils.handleResponseCode(authTypeFilter?.value),
    reasonCode: filterUtils.handleReasonCode(authTypeFilter?.value),
    order,
    orderDir,
    endDate: dateFilter && dateFilter.endDate
  }
  const { isLoading, data, refetch, isFetching } = useGetCardsByMerchant(merchantTenantId, merchantCardsRequest);
  const onActionSuccess = () => queryClient.invalidateQueries(MerchantKeys.cards(merchantTenantId, merchantCardsRequest));
  const { data: me } = useGetMe();

  let { timeFilterWithCustomOption } = filterUtils
  if (me && me.featureFlags[FeatureFlagConstants.isPerformancePOC]) {
    timeFilterWithCustomOption = timeFilterWithCustomOption.slice(0, 3);
  }

  useEffect(() => {
    if (tabState === undefined) return
    if (tabState.search) setSearch(tabState.search)
    if (tabState.searchFilter) setSearchFilter(tabState.searchFilter)
    if (tabState.authTypeFilter) setAuthTypeFilter(tabState.authTypeFilter)
    if (tabState.timeFilter) setTimeFilter(tabState.timeFilter)
    if (tabState.selectedStatus) setSelectedStatus(tabState.selectedStatus)
    if (tabState.orderDir) setOrderDir(tabState.orderDir)
    if (tabState.order) setOrder(tabState.order)
    if (tabState.page) setPage(tabState.page)
    if (tabState.itemsPerPage) setItemsPerPage(tabState.itemsPerPage)
  }, [])

  useEffect(() => {
    if (!mounted) {
      return;
    }
    groupView.setState('cards', {
      timeFilter,
      searchFilter,
      authTypeFilter,
      selectedStatus,
      search,
      orderDir,
      order,
      page,
      itemsPerPage,
      filters
    })
  }, [mounted, filters, page, itemsPerPage, search])

  useEffect(() => {
    const newFilters = [];

    if (timeFilter) {
      newFilters.push({
        name: 'Time period:',
        value: timeFilter.value,
      });
    }

    if (selectedStatus) {
      newFilters.push({
        name: 'Card status:',
        value: selectedStatus.label,
      });
    }

    if (authTypeFilter) {
      newFilters.push({
        name: 'Auth type:',
        value: authTypeFilter.label,
      });
    }

    setFilters(newFilters);
  }, [timeFilter, selectedStatus, authTypeFilter]);

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const onAuthTypeChange = (value) => {
    resetPage();
    setAuthTypeFilter(value);
  };

  const onSearchChangeProxy = (value) => {
    setSearchFilter(value);
    if (value && value.trim().length > 2) {
      setSearch(value.trim());
    } else {
      setSearch('');
    }
  };

  const renderFilterTooltip = () => {
    if (filters.length === 0) {
      return null;
    }
    return (<FiltersContainer.Tooltip filters={filters} />);
  };

  const handleDateLimit = (date) => {
    handleCurrentDay(date,setMaxEndDateLimit, timeLimitUtils.TimeLimit.MBS_CBS_DATE_LIMIT)
  }

  const clearFilters = () => {
    setSearch('');
    setSearchFilter('');
    setTimeFilter(null);
    setSelectedStatus(null);
    setAuthTypeFilter(null);
    setDateFilter(null);
  };

  useEffect(() => {
    if (!dateFilter) setTimeFilter(null);
  }, [dateFilter]);

  const resetTimeFilter = () => {
    setTimeFilter(null);
  }

  const changeSort = (field) => {
    if (field !== order) {
      setOrder(field);
      setOrderDir(TABLE_SORT_DESC);
      return;
    }

    setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onAction = (action, actionData) => {
    switch (action) {
      case TABLE_SORT_ACTION:
        changeSort(actionData.sortField || actionData.field);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {merchantName && (
        <span className="block mb-3 text-sm font-semibold">
          Merchant:&nbsp;
          {merchantName}
        </span>
      )}
      <div className="flex items-start justify-between">

        <FiltersContainer
          tooltip={renderFilterTooltip()}
          search={searchFilter}
          onSearchChange={onSearchChangeProxy}
        >

          <CustomTimePeriodWrapper
            timeFilter={timeFilter}
            data={timeFilterWithCustomOption}
            handleComboBoxChange={setTimeFilter}
            maxEndDateLimit={maxEndDateLimit}
            handleDateLimit={handleDateLimit}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleResetDateFilter={resetTimeFilter}
          />

          <CardExternalStatusFilter
            onChange={setSelectedStatus}
            selected={selectedStatus}
            placeholder="Card Status"
          />
          <FMCombobox placeholder="Auth type" data={filterUtils.AuthTypeOptions} selected={authTypeFilter} onChange={onAuthTypeChange} />
          {(search.length > 0 || filters.length > 0) && (
            <TextButton onClick={() => clearFilters()}>
              Clear all
            </TextButton>
          )}
        </FiltersContainer>
        <div className="flex flex-col items-end">
          <RefreshTable setRefreshTable={refetch} />
        </div>
      </div>
      <CardsTable
        columns={columnsToUse}
        data={data ? data.rows : []}
        loading={isLoading || isFetching}
        paginationProps={{
          totalItems: data ? data.count : 0,
          search: searchFilter,
          currentPage: page,
          itemsPerPage,
          onItemsPerPageChange: (e) => { resetPage(); setItemsPerPage(e); },
          onPageClick: (e) => setPage(e),
        }}
        onAction={onAction}
        sort={order}
        sortDirection={orderDir}
        onActionSuccess={onActionSuccess}
      />
    </div>
  );
}

MerchantCards.propTypes = {
  merchantName: PropTypes.string,
  merchantTenantId: PropTypes.number.isRequired,
};

MerchantCards.defaultProps = {
  merchantName: null,
};
