import React from 'react';
import PropTypes from 'prop-types';
import StatusConstants from '../../../config/StatusConstants';
import { TABLE_CELL_CLASS } from '../Table';

const getBadgeTypeFromCardStatus = (statusApiName) => {
  switch (statusApiName) {
    case 'reissued-card':
    case StatusConstants.replacedCardApiName:
    case StatusConstants.approvedTransactionApiName:
      return 'success';
    case StatusConstants.suspendCardApiName:
      return 'warning';
    case StatusConstants.activeCardApiName:
    case 'compromised':
    case StatusConstants.declinedTransactionApiName:
      return 'danger';
    default:
      return 'default';
  }
};
const getBadgeColors = (apiName) => {
  switch (getBadgeTypeFromCardStatus(apiName)) {
    case 'success':
      return 'green-600';
    case 'teal':
      return 'teal-600';
    case 'danger':
      return 'red-600';
    case 'warning':
      return 'yellow-400';
    case 'orange':
      return 'orange-600';
    default:
      return 'gray-600';
  }
};
const getBadgeText = (statusApiName) => {
  switch (statusApiName) {
    case StatusConstants.activeCardApiName:
      return 'Active';
    case StatusConstants.suspendCardApiName:
      return 'Suspended';
    case StatusConstants.replacedCardApiName:
      return 'Replaced';
    default:
      return 'Unknown';
  }
};

const getRender = (children, statusApiName) => {
  if (children) {
    return children;
  }
  return getBadgeText(statusApiName);
};

const style = 'items-center px-2.5 py-0.5 pt-0.5 pb-0.5 rounded-md text-xs font-medium bg-gray-50 border';
export function CardBorderStatusBadge({ statusApiName, children }) {
  return (
    <span className={`text-black ${style} border-${getBadgeColors(statusApiName)}`}>
      {getRender(children, statusApiName)}
    </span>
  );
}

export function CardTextStatusBadge({ statusApiName, children }) {
  return (
    <span className={` border-gray-200 rounded-full ${style} text-${getBadgeColors(statusApiName)}`}>
      {getRender(children, statusApiName)}
    </span>
  );
}

export function CardRowStatusBadge(
  {
    row: { id, statusApiName },
    children,
  },
) {
  return (
    <td key={`cel-${id}-status`} className={TABLE_CELL_CLASS}>
      {statusApiName && (
        <span className={`text-black ${style} 
        border-${getBadgeColors(statusApiName)}`}
        >
          {getRender(children, statusApiName)}
        </span>
      )}
    </td>
  );
}

CardRowStatusBadge.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      statusApiName: PropTypes.string,
    },
  ).isRequired,
  children: PropTypes.node,
};
CardRowStatusBadge.defaultProps = {
  children: null,
};
CardTextStatusBadge.propTypes = {
  statusApiName: PropTypes.string,
  children: PropTypes.node,
};
CardTextStatusBadge.defaultProps = {
  children: null,
};
CardBorderStatusBadge.propTypes = CardTextStatusBadge.propTypes;
CardBorderStatusBadge.defaultProps = CardTextStatusBadge.defaultProps;
