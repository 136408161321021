import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import MerchantKeys from './Merchant.keys';

export const updateMerchantStatus = (
  client,
  id,
  data,
) => {
  if (!id) {
    throw new Error('id is required');
  }
  return api.merchants.updateMerchantStatus(client, id, data);
};

export default function useUpdateMerchantStatus(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({ id, ...rest }) => updateMerchantStatus(client, id, rest), {
    onSuccess: () => {
      queryClient.invalidateQueries(MerchantKeys.all);
      queryClient.invalidateQueries(MerchantKeys.lists());
      queryClient.invalidateQueries(MerchantKeys.detail());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
