import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Table, {
  DefaultEmptyStateContent,
  EmptyStateTable,
  TABLE_HEADER_CLASS,
  TABLE_ROW_CLASS,
} from './Table';
import PaginationHeader from '../pagination/PaginationHeader';
import Pagination from '../pagination/Pagination';
import { ReactComponent as AscIcon } from '../../assets/icons/asc-icon.svg';
import { ReactComponent as DescIcon } from '../../assets/icons/desc-icon.svg';
import { classNames } from '../../utils/ui';
import BaseColumn from './columns/BaseColumn';

export const TABLE_SORT_ACTION = 'table-sort';
export const TABLE_SORT_ASC = 'asc';
export const TABLE_SORT_DESC = 'desc';

function getColumnKey(row, column) {
  return `column-${row.id}-${column.key || column.field}`;
}

export default function DataTable({
  columns,
  data,
  paginationProps,
  onAction,
  onHeaderAction,
  sort,
  sortDirection,
  emptyStateContent,
  showPagination,
  ExpandedRenderer,
  ...rest
}) {
  const [expanded, setExpanded] = useState([]);
  const expandable = !!ExpandedRenderer;
  useEffect(() => {
    if (expandable) {
      setExpanded([]);
    }
  }, [ExpandedRenderer, data]);
  return (
    <>
      {showPagination && <PaginationHeader {...paginationProps} />}

      <div className="table-container">
        <Table {...rest}>
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column, i) => (
                <th
                  key={`th-${column.key || column.field}`}
                  scope="col"
                  className={classNames(
                    TABLE_HEADER_CLASS,
                    column.sortable && 'cursor-pointer'
                  )}
                  onClick={() =>
                    column.sortable && onAction(TABLE_SORT_ACTION, column)
                  }
                  colSpan={expandable && i === 0 ? 2 : 1}
                >
                  <div className={classNames('flex', column.headerClassName)}>
                    {column.headerRender
                      ? column.headerRender(column, onHeaderAction)
                      : column.headerName}
                    {column.sortable && (
                      <div className="ml-2 text-gray-400">
                        <AscIcon
                          className={`stroke-current w-2.5 mb-1 ${
                            (sort === column.sortField ||
                              sort === column.field) &&
                            sortDirection === TABLE_SORT_ASC &&
                            'text-gray-700'
                          }`}
                        />
                        <DescIcon
                          className={`stroke-current w-2.5 ${
                            (sort === column.sortField ||
                              sort === column.field) &&
                            sortDirection === TABLE_SORT_DESC &&
                            'text-gray-700'
                          }`}
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((row, index) => {
                const rowId = row.id || index;
                const isExpanded = expanded.includes(rowId);
                return (
                  <React.Fragment key={rowId}>
                    <tr
                      data-cy="merchant-result"
                      className={TABLE_ROW_CLASS}
                    >
                      {expandable && (
                        <th>
                          <button
                            type="button"
                            className="outline-none focus:outline-none"
                            onClick={() =>
                              setExpanded(
                                isExpanded
                                  ? expanded.filter((id) => id !== rowId)
                                  : [...expanded, rowId]
                              )
                            }
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'w-8 transform transition-transform',
                                isExpanded && 'rotate-180'
                              )}
                            />
                          </button>
                        </th>
                      )}
                      {columns.map((column) =>
                        column.render ? (
                          <column.render
                            key={getColumnKey(row, column)}
                            column={column}
                            row={row}
                            onAction={onAction}
                          />
                        ) : (
                          <BaseColumn
                            key={`cel-${column.key || column.field}`}
                            row={row}
                            column={column}
                          />
                        )
                      )}
                    </tr>
                    {ExpandedRenderer && isExpanded && (
                      <tr className={TABLE_ROW_CLASS}>
                        <td colSpan={columns.length + 1}>
                          <ExpandedRenderer row={row} />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <EmptyStateTable colSpan={columns.length}>
                {emptyStateContent || <DefaultEmptyStateContent />}
              </EmptyStateTable>
            )}
          </tbody>
        </Table>
      </div>
      {showPagination && <Pagination {...paginationProps} />}
    </>
  );
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      headerClassName: PropTypes.string,
      valueGetter: PropTypes.func,
      render: PropTypes.func,
      className: PropTypes.string,
      actionName: PropTypes.string,
      childClassName: PropTypes.string,
      sortable: PropTypes.bool,
      sortField: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  paginationProps: PropTypes.shape({
    optionsAdd: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onAction: PropTypes.func,
  onHeaderAction: PropTypes.func,
  sort: PropTypes.string,
  sortDirection: PropTypes.oneOf([TABLE_SORT_ASC, TABLE_SORT_DESC]),
  emptyStateContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showPagination: PropTypes.bool,
  ExpandedRenderer: PropTypes.elementType,
};

DataTable.defaultProps = {
  data: null,
  onAction: null,
  onHeaderAction: null,
  sort: null,
  sortDirection: TABLE_SORT_DESC,
  emptyStateContent: null,
  showPagination: true,
  paginationProps: null,
  ExpandedRenderer: null,
};
