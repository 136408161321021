import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGetPDSReasons from '../../../hooks/api/pds/useGetPDSReasons';
import DataTable, { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../../../components/table/DataTable';
import TextButtonColumn from '../../../components/table/columns/TextButtonColumn';
import PaginationConfig from '../../../config/PaginationConfig';
import numberUtils from '../../../utils/numberUtils';
import useGetMe from '../../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../../config/FeatureFlagConstants';

const REASON_VIEW_ACTION = 'reason-view-action';

const columns = [
  {
    field: 'description',
    headerName: 'Reason Description',
    valueGetter: (field) => field || 'unknown',
  },
  {
    field: 'amount',
    headerName: 'Total Amount',
    className: 'text-right',
    headerClassName: 'justify-end',
    valueGetter: numberUtils.currencyFormat,
  },
  {
    field: 'auths',
    headerName: 'Total Auths',
    className: 'text-right',
    headerClassName: 'justify-end',
    valueGetter: numberUtils.numberFormat,
  },
  {
    field: 'cards',
    headerName: 'Total Cards',
    className: 'text-right',
    headerClassName: 'justify-end',
    valueGetter: numberUtils.numberFormat,
  },
  {
    field: 'code',
    headerName: 'Details',
    render: TextButtonColumn,
    actionName: REASON_VIEW_ACTION,
    valueGetter: () => 'View',
  },
];

export default function ReasonDescriptionMain({
  mcc,
  excludeMcc,
  status,
  timeSelected,
  onReasonSelected,
  search,
  authCount,
  refreshTick,
  startDate,
  endDate
}) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [order, setOrder] = useState('description');
  const [orderDir, setOrderDir] = useState(TABLE_SORT_ASC);
  const selectedPeriod = timeSelected !== 'custom' ? timeSelected : '30min'

  const { isLoading, isFetching, data, refetch } = useGetPDSReasons({
    mcc,
    excludeMcc,
    statusApiName: status,
    timeSelected: startDate || selectedPeriod,
    endDate: endDate || undefined,
    search,
    itemsPerPage,
    page,
    order,
    authCount,
    orderDir,
  });
  const { data: me } = useGetMe();
  const showPaging = me && !me.featureFlags[FeatureFlagConstants.isPerformancePOC]

  useEffect(() => {
    if (refreshTick > 0) {
      refetch();
    }
  }, [refreshTick]);

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const handleReasonSelected = (reason) => {
    onReasonSelected(reason);
  };

  const changeSort = (field) => {
    if (field !== order) {
      setOrder(field);
      setOrderDir(TABLE_SORT_DESC);
      return;
    }

    setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onTableAction = (action, args) => {
    switch (action) {
      case REASON_VIEW_ACTION:
        handleReasonSelected(args);
        break;
      case TABLE_SORT_ACTION:
        changeSort(args.field);
        break;
      default:
        break;
    }
  };

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <DataTable
        loading={isLoading || isFetching}
        columns={columns}
        data={data ? data.rows : []}
        onAction={onTableAction}
        paginationProps={{
          totalItems: data && data.count ? data.count : 0,
          currentPage: page,
          itemsPerPage,
          onPageClick,
          onItemsPerPageChange: (e) => { resetPage(); setItemsPerPage(e); },
          className: 'px-5',
          optionsAdd: !showPaging ? [{ value: 1000, label: 1000 }] : [],
          hideInfo: showPaging,
        }}
        sort={order}
        sortDirection={orderDir}
      />
    </>
  );
}

ReasonDescriptionMain.propTypes = {
  mcc: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  timeSelected: PropTypes.string.isRequired,
  onReasonSelected: PropTypes.func.isRequired,
  excludeMcc: PropTypes.bool,
  authCount: PropTypes.string,
  search: PropTypes.string,
  refreshTick: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

ReasonDescriptionMain.defaultProps = {
  mcc: [],
  status: null,
  excludeMcc: false,
  authCount: null,
  search: null,
  refreshTick: 0,
  startDate: null,
  endDate: null
};
