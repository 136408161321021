import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../utils/ui';

export default function Filters({ children, className }) {
  return (
    <div className={classNames(
      'flex flex-wrap items-center gap-4 mb-5',
      className,
    )}
    >
      {children}
    </div>
  );
}

Filters.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Filters.defaultProps = {
  children: null,
  className: null,
};
