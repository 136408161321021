import React from 'react';
import PropTypes from 'prop-types';

const getBadgeColors = (type) => {
  switch (type) {
    case 'success':
      return 'text-green-600';
    case 'teal':
      return 'text-teal-600';
    case 'danger':
      return 'text-red-600';
    case 'warning':
      return 'text-yellow-600';
    case 'orange':
      return 'text-orange-600';
    default:
      return 'text-gray-600';
  }
};

export default function Badge({ children, type, testId }) {
  return (
    <span data-cy={testId} className={`items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-50 border border-gray-200 text-center ${getBadgeColors(type)}`}>
      {children}
    </span>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
  type: PropTypes.oneOf([
    'success',
    'default',
    'warning',
    'orange',
    'teal',
    'danger',
  ]),
};

Badge.defaultProps = {
  type: 'default',
  testId: ''
};
