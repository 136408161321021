import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../forms/Input';
import Label from '../forms/Label';
import Modal from '../Modal';
import Button from '../buttons/Button';
import useGetMe from '../../hooks/api/users/useGetMe';

export default function UpdateProfileModal({
  primaryAction,
  ...rest
}) {
  const { data: me } = useGetMe();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const onPrimaryAction = () => {
    primaryAction(firstName, lastName);
    setFirstName('');
    setLastName('');
  };
  const getContent = () => (
    <div className="-mb-8">
      <div className="text-center mt-6">
        <span className="text-gray-900 font-medium text-base">Update Profile Information</span>
      </div>
      <div className="flex flex-col mt-8">
        <Label className="mb-1 text-sm text-gray-700 font-medium" htmlFor="email">
          Email
        </Label>
        <Label className="mb-1 text-sm text-gray-900 font-semibold">
          {(me && me.email) ? me.email : ''}
        </Label>
      </div>
      <div className="flex flex-col mt-4">
        <Label className="mb-1 text-sm text-gray-700 font-medium" htmlFor="first-name">
          First name
        </Label>
        <Input
          type="text"
          id="first-name"
          name="first-name"
          rows={3}
          maxLength="250"
          value={firstName}
          className="text-gray-900 font-semibold"
          onChange={({ target }) => setFirstName(target.value)}
        />
      </div>
      <div className="flex flex-col mt-4">
        <Label className="mb-1 text-sm text-gray-700 font-medium" htmlFor="last-name">
          Last name
        </Label>
        <Input
          type="text"
          id="last-name"
          name="last-name"
          rows={3}
          maxLength="250"
          value={lastName}
          className="text-gray-900 font-semibold"
          onChange={({ target }) => setLastName(target.value)}
        />
      </div>
      <div className="mt-5 sm:mt-6 flex flex-row justify-center">
        <Button
          buttonStyle="primary"
          className="w-full"
          rounded
          onClick={() => onPrimaryAction()}
        >
          Save
        </Button>
      </div>
    </div>
  );
  return (
    <Modal
      {...rest}
      primaryText="Save"
      secondaryShow={false}
      primaryShow={false}
      divider={false}
    >
      {getContent()}
    </Modal>
  );
}

UpdateProfileModal.propTypes = {
  primaryAction: PropTypes.func,
};

UpdateProfileModal.defaultProps = {
  primaryAction: null,
};
