import React from 'react';
import PropTypes from 'prop-types';

export default function Grid({ children, className }) {
  return (
    <div className={`grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-2 sm:gap-3 ${className}`}>
      {children}
    </div>
  );
}

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

Grid.defaultProps = {
  children: null,
  className: null,
};
