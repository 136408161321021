/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  CheckCircleIcon,
  BanIcon,
  DotsCircleHorizontalIcon,
} from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import FiltersContainer from '../components/filters/FiltersContainer';
import FMCombobox from '../components/forms/FMCombobox';
import Alert from '../components/Alert';
import Title from '../components/headings/Title';
import TextButton from '../components/buttons/TextButton';
import PaginationConfig from '../config/PaginationConfig';
import Grid from '../components/Grid';
import Tabs from '../components/Tabs';
import merchantTableUtils from '../utils/merchantTableUtils';
import MerchantsTable, {
  MERCHANT_UPDATE_STATUS_ACTION,
  MERCHANT_BULK_ACTION_DONE
} from '../components/table/MerchantsTable';
import RefreshTable from '../components/RefreshTable';
import { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../components/table/DataTable';
import { checkPermission, getName } from '../utils/userUtil';
import PermissionConstants from '../config/PermissionConstants';
import { useGetMerchants } from '../hooks/api/merchants/useGetMerchants';
import useGetMyPermissions from '../hooks/api/users/useGetMyPermissions';
import FeatureFlagConstants from '../config/FeatureFlagConstants';
import useGetMe from '../hooks/api/users/useGetMe';
import useGetTenantUsers from '../hooks/api/users/useGetTenantUsers';
import MerchantKeys from '../hooks/api/merchants/Merchant.keys';

const tabs = [
  {
    name: 'Blocked',
    value: 'blocked-merchant',
    icon: BanIcon,
  },
  {
    name: 'Trusted',
    value: 'trusted-merchant',
    icon: CheckCircleIcon,
  },
  {
    name: '24h Trusted',
    value: 'approved-merchant',
    icon: CheckCircleIcon,
  },
  {
    name: 'All',
    value: 'all',
    icon: DotsCircleHorizontalIcon,
  },
];

function Merchants() {
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [statusFilter, setStatusFilter] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [currentView, setCurrentView] = useState('blocked-merchant');
  const [sort, setSort] = useState('updatedAt');
  const [sortDir, setSortDir] = useState(TABLE_SORT_DESC);
  const [successMessage, setSuccessMessage] = useState({ show: false });
  const [page, setPage] = useState(1);
  const [filtersDescription, setFiltersDescription] = useState([]);
  const [canUpdate, setCanUpdate] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [disabledClearFilter, setDisabledClearFilter] = useState(true);
  const { data: permissions } = useGetMyPermissions();
  const lastMerchantUpdatedOutside = useSelector((state) => state.merchant.lastMerchantUpdated);
  const queryClient = useQueryClient();

  const { data: me } = useGetMe();
  const showPaging = me && !me.featureFlags[FeatureFlagConstants.isPerformancePOC];
  const request = {
    search,
    sort,
    sortDir,
    page,
    itemsPerPage,
    statusApiName: currentView !== 'all' ? currentView : (statusFilter && statusFilter.value),
    userId: userFilter && userFilter.value
  }

  const { data, isFetching } = useGetMerchants(request);
  const onActionSuccess = () => queryClient.invalidateQueries(MerchantKeys.list('all', request));

  const { data: tenantUsersData } = useGetTenantUsers();
  const tenantUsers = tenantUsersData ? tenantUsersData.rows : [];
  const userFilterOptions = [
    { value: '', label: 'All' },
    ...tenantUsers.map((userTenant) => ({
      value: userTenant.id,
      label: getName(userTenant),
    })),
  ];

  const columnsToUse = merchantTableUtils.getMerchantTableColumns(currentView);

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const updateClearAll = (status, user) => {
    if (!status && !user && !searchValue) {
      setDisabledClearFilter(true);
    } else {
      setDisabledClearFilter(false);
    }
  };

  const clearFilters = () => {
    setSearch('');
    setSearchValue('');
    setStatusFilter(null);
    setUserFilter(null);
    resetPage();
    setDisabledClearFilter(true);
  };

  const onViewChange = (view) => {
    if (view !== currentView) {
      setSort('updatedAt');
      setSortDir(TABLE_SORT_DESC);
      clearFilters();
      setCurrentView(view);
    }
  };

  const onSearchDebounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
      setPage(1);
    },
    [setSearch, setPage],
    500
  );

  const onSearchChange = (value) => {
    setSearchValue(value);
    if (value) {
      setDisabledClearFilter(false);
    } else if (currentView === 'all' && statusFilter) {
      setDisabledClearFilter(false);
    } else {
      setDisabledClearFilter(true);
    }
    if (value && value.trim().length > 2) {
      onSearchDebounced(value.trim());
    } else {
      onSearchDebounced('');
    }
  };

  const changeSort = (field) => {
    if (field !== sort) {
      setSort(field);
      setSortDir(TABLE_SORT_DESC);
      return;
    }

    setSortDir(sortDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onMerchantAction = (action, args) => {
    switch (action) {
      case MERCHANT_UPDATE_STATUS_ACTION:
      case MERCHANT_BULK_ACTION_DONE:
        onActionSuccess();
        break;
      case TABLE_SORT_ACTION:
        changeSort(args.field);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setCanUpdate(checkPermission(permissions, PermissionConstants.UPDATE_MERCHANTS_LIST));
  }, [permissions]);

  // force refresh when merchant was changed from a slide over
  useEffect(() => {
    if (data && data.rows.find((item) => item.id === lastMerchantUpdatedOutside.id)) {
      onActionSuccess();
    }
  }, [lastMerchantUpdatedOutside]);

  useEffect(() => {
    const newFilters = [];
    if (statusFilter) {
      newFilters.push({
        name: 'Status:',
        value: statusFilter.label,
      });
    }

    if (userFilter) {
      newFilters.push({
        name: currentView === 'all' ? 'Last Modified By:' : 'Added By:',
        value: userFilter.label,
      });
    }

    setFiltersDescription(newFilters);
  }, [statusFilter, userFilter, setFiltersDescription]);

  const renderFilterTooltip = () => {
    if (filtersDescription.length === 0) {
      return null;
    }
    return <FiltersContainer.Tooltip filters={filtersDescription} />;
  };

  return (
    <>
      <Grid>
        <div className="col-span-12 pt-5" data-cy="merchants-screen">
          <div className="flex justify-between mb-6">
            <Title>Merchants</Title>
          </div>
          <hr className="mb-4" />
          {/* <InsightItems fetcher={insightsFetcher} /> */}
          {successMessage.show && (
            <Alert
              show={successMessage.show}
              title={successMessage.title}
              message={successMessage.message}
              onClose={setSuccessMessage}
            />
          )}
          <Tabs className="mb-6" tabs={tabs} selectedTab={currentView} onClick={onViewChange} />
          <div className="items-start justify-between lg:flex">
            <FiltersContainer
              search={searchValue}
              onSearchChange={onSearchChange}
              tooltip={renderFilterTooltip()}
            >
              {currentView === 'all' && (
                <FMCombobox
                  placeholder="Status"
                  data={[
                    { value: '', label: 'All' },
                    { value: 'trusted-merchant', label: 'Trusted' },
                    { value: 'blocked-merchant', label: 'Blocked' },
                    { value: 'approved-merchant', label: 'Approved' },
                    { value: 'known-merchant', label: 'Known' },
                    { value: 'reviewed-merchant', label: 'Reviewed' },
                    { value: 'pending-review-merchant', label: 'Suspicious' },
                  ]}
                  selected={statusFilter}
                  onChange={(e) => {
                    resetPage();
                    setStatusFilter(e);
                    updateClearAll(e, userFilter);
                  }}
                />
              )}

              <FMCombobox
                placeholder={currentView === 'all' ? 'Last Modified By' : 'Added By'}
                data={userFilterOptions}
                selected={userFilter}
                onChange={(e) => {
                  resetPage();
                  setUserFilter(e);
                  updateClearAll(statusFilter, e);
                }}
              />

              {(search.length > 0 || filtersDescription.length > 0) && (
                <TextButton disabled={disabledClearFilter} onClick={clearFilters}>
                  Clear all
                </TextButton>
              )}
            </FiltersContainer>
            <RefreshTable setRefreshTable={onActionSuccess} />
          </div>
          <MerchantsTable
            canUpdate={canUpdate}
            columns={columnsToUse}
            selectedTab={currentView}
            data={data ? data.rows.map((merchant) => ({
              ...merchant,
              changedBy: merchant.changedBy ? merchant.changedBy : 'ARDEN',
            })) : []}
            paginationProps={{
              totalItems: !showPaging ? itemsPerPage : data ? data.count : 0,
              currentPage: page,
              itemsPerPage,
              onPageClick,
              optionsAdd: !showPaging ? [{ value: 1000, label: 1000 }] : [],
              onItemsPerPageChange: (e) => {
                resetPage();
                setItemsPerPage(e);
              },
              hideInfo: !showPaging,
            }}
            onAction={onMerchantAction}
            loading={isFetching}
            sort={sort}
            sortDirection={sortDir}
            onActionSuccess={onActionSuccess}
          />
        </div>
      </Grid>
    </>
  );
}

export default Merchants;
