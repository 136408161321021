import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from './Merchant.keys';

const getActiveMerchantAttacks = async (
  client,
  merchantTenantId,
) => api.merchants.fetchActiveMerchantAttacks(client, merchantTenantId);

export default function useGetActiveMerchantAttacks(merchantTenantId) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.activeMerchantAttacks(merchantTenantId),
    async () => getActiveMerchantAttacks(apiClient, merchantTenantId),
  );
}
