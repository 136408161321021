import React from 'react';
import InsightItems from './InsightItems';
import Panel from '../Panel';
import useGetInsightsSummary from '../../hooks/api/insights/useGetInsightsSummary';
import useInterval from '../../hooks/useInterval';

export default function InsightSummary() {
  const { data, refetch } = useGetInsightsSummary();
  useInterval(refetch, 10000);

  return (
    <Panel title="Summary">
      {data && <InsightItems items={data} />}
    </Panel>
  );
}
