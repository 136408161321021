import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import AdminRolesKeys from './AdminRoles.keys';

const getAdminRoles = async (client) => api.admin.roles.fetchRoles(client);

export default function useGetAdminRoles() {
  const apiClient = useApiClient();
  return useLoggedInQuery(AdminRolesKeys.all(), async () => getAdminRoles(apiClient));
}
