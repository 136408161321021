import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { GroupViewContext } from './useGroupViewContext';

export default function GroupViewContextProvider({ children, onStateChange, initialState }) {
  const [stateContent, setStateContent] = useState(initialState || {});

  const state = (key) => {
    if(key) return stateContent[key]
    return stateContent
  }

  const setState = (key, value) => {
    if (!key) {
      return;
    }
    const groupStateUpdate = {
      ...stateContent,
      [key]: value
    };
    setStateContent(groupStateUpdate);
    if(onStateChange) onStateChange(groupStateUpdate)
  };

  const removeState = (key) => {
    const { [key]: toRemove, ...rest } = stateContent;
    setStateContent(rest);
  };

  const cleanState = () => {
    setStateContent({});
  }

  return (
    <GroupViewContext.Provider
      value={{
        stateContent,
        setState,
        removeState,
        cleanState,
        state,
      }}
    >
      {children}
    </GroupViewContext.Provider>
  );
}

GroupViewContextProvider.propTypes = {
  children: PropTypes.node,
  onStateChange: PropTypes.func,
  initialState: PropTypes.shape({}),
};

GroupViewContextProvider.defaultProps = {
  children: null,
  onStateChange: null,
  initialState: null,
};
