import React from 'react';
import Subtitle from '../../components/headings/Subtitle';
import UserActivityLog from '../../components/UserActivityLog';
import useGetMe from '../../hooks/api/users/useGetMe';

export default function ProfileActivityLog() {
  const { data: me } = useGetMe();

  return (
    <div className="m-4">
      <Subtitle>Activity Log</Subtitle>
      {me && me.id && <UserActivityLog userId={me.id} className="p-8 mt-4 bg-white border-2 border-gray-100 border-solid" />}
    </div>
  );
}
