import React from 'react';
import PropTypes from 'prop-types';

export default function Title({ children }) {
  return (
    <h1 className="flex text-2xl text-left leading-9 font-bold">{children}</h1>
  );
}

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
