import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CENTER_CLASS } from '../Table';

export default function TenantUserDetails({ row }) {
  const { id } = row;

  return (
    <td key={`cel-user-details-${id}`} className={TABLE_CELL_CENTER_CLASS}>
      <div className="">
        {`Active: ${row.activeUsersCount}`}
      </div>
      <div className="text-sm text-gray-500 ">
        {`Total: ${row.usersCount}`}
      </div>
    </td>
  );
}

TenantUserDetails.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    activeUsersCount: PropTypes.number.isRequired,
    usersCount: PropTypes.number.isRequired,
  }).isRequired,
};
