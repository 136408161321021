import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftIcon, CreditCardIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import PDSMerchants from '../PDSMerchants';
import TextButton from '../../../components/buttons/TextButton';
import Panel from '../../../components/Panel';
import numberUtils from '../../../utils/numberUtils';
import { useGetPDSReason } from '../../../hooks/api/pds/useGetPDSReasons';
import Spinner from '../../../components/Spinner';
import TabsContainer from '../../../components/TabsContainer';
import useConstant from '../../../hooks/useConstant';
import PDSCards from '../PDSCards';
import useGetMe from '../../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../../config/FeatureFlagConstants';

export default function ReasonDescriptionDetail({
  reason, onBack, status, timeSelected, startDate, endDate, mcc, ...rest
}) {
  const { data: me } = useGetMe();

  const tabs = useConstant(() => {
    const tabsToReturn = [
      {
        name: 'Merchants',
        value: 'merchants',
        icon: ShoppingBagIcon,
      },
      {
        name: 'Cards',
        value: 'cards',
        icon: CreditCardIcon,
      },
    ];

    if (me && me.featureFlags[FeatureFlagConstants.isPerformancePOC]) {
      return tabsToReturn.filter(e=> e.value !== 'cards');
    }

    return tabsToReturn;
  });
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  const tabsContent = useMemo(() => {
    const renderMerchantsTab = () => (
      <PDSMerchants
        reasonCode={reason.code}
        status={status}
        timeSelected={timeSelected}
        mcc={mcc}
        startDate={startDate}
        endDate={endDate}
        {...rest}
      />
    );
    const renderCardsTab = () => (
      <PDSCards
        reasonCode={reason.code}
        status={status}
        mcc={mcc}
        timeSelected={timeSelected}
        startDate={startDate}
        endDate={endDate}
        {...rest}
      />
    );

    return {
      merchants: renderMerchantsTab,
      cards: renderCardsTab,
    };
  }, [reason.code, status, timeSelected, rest.search, rest.authCount, rest.refreshTick, startDate, endDate, mcc]);

  const isCustomRange = timeSelected === 'custom';

  const { data, isLoading } = useGetPDSReason({
    reasonId: reason.code,
    statusApiName: status,
    timeSelected,
    startDate: isCustomRange && startDate,
    endDate: isCustomRange ? endDate : undefined,
    excludeMcc: rest.excludeMcc,
    mcc,
    search: rest.search,
    refreshTick: rest.refreshTick,
    itemsPerPage: rest.itemsPerPage,
    page: rest.page,
    order: rest.order,
    authCount: rest.authCount,
    orderDir: rest.orderDir,
  });
  return (
    <>
      <div className="px-5 mt-2">
        <TextButton className="mb-4" onClick={() => onBack && onBack()}>
          <span className="flex">
            <ArrowLeftIcon className="w-5 mr-2" />
            Back
          </span>
        </TextButton>
        <Panel className="mb-6">
          <div className="flex space-x-10">
            {isLoading ? (
              <div className="flex flex-col flex-1">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="flex flex-col flex-1">
                  <span className="mb-1 text-xs text-gray-500">Reason description</span>
                  <span className="font-semibold">{data ? data.description : reason.description}</span>
                </div>
                <div className="flex flex-col">
                  <span className="mb-1 text-xs text-right text-gray-500">Total amount</span>
                  <span className="font-semibold text-right">{numberUtils.currencyFormat(data ? data.amount : 0)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="mb-1 text-xs text-right text-gray-500">Total auths</span>
                  <span className="font-semibold text-right">{data ? data.auths : 0}</span>
                </div>
                <div className="flex flex-col">
                  <span className="mb-1 text-xs text-right text-gray-500">Total cards</span>
                  <span className="font-semibold text-right">{data ? data.cards : 0}</span>
                </div>
              </>
            )}
          </div>
        </Panel>
      </div>
      <div className="px-5 mt-2">
        <TabsContainer
          tabs={tabs}
          tabsContent={tabsContent}
          selectedTab={selectedTab}
          onSelectedTabChange={setSelectedTab}
        />
      </div>
    </>
  );
}

ReasonDescriptionDetail.propTypes = {
  reason: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onBack: PropTypes.func,
  status: PropTypes.string,
  timeSelected: PropTypes.string.isRequired,
  mcc: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

ReasonDescriptionDetail.defaultProps = {
  onBack: null,
  status: null,
  startDate: null,
  endDate: null,
  mcc: ['']
};
