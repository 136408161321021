import { ClockIcon } from '@heroicons/react/outline';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import background from '../../assets/arden-shield-bg.svg';
import Button from '../../components/buttons/Button';
import Panel from '../../components/Panel';

export default function NetworkErrorScreen() {
  const { logout } = useAuth0();

  const handleLogin = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div
      className="flex items-center justify-center w-full h-full mt-6 bg-center bg-no-repeat bg-contain"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Panel className="max-w-md" containerClassName="flex flex-col items-center px-8 py-10">
        <ClockIcon className="mb-4 w-7" />
        <h2 className="mb-2 font-semibold text-center text-black">An error has occurred</h2>
        <p className="mb-6 text-sm text-center text-gray-600">
          Something went terribly wrong. Please come back later.
        </p>
        <Button fullWidth onClick={handleLogin}>
          Log back in
        </Button>
      </Panel>
    </div>
  );
}
