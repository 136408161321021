import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RefreshIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import ToolTip from './Tooltip';
import useInterval from '../hooks/useInterval';

export default function RefreshTable({ setRefreshTable }) {
  const [refMins, setRefMins] = useState('');
  const [lastUpdate, setLastUpdate] = useState(null);
  const [, setTempCount] = useState(0);

  useInterval(() => {
    setTempCount((_count) => {
      setRefMins(`${_count + 1}mins Ago`);
      return _count + 1;
    });
  }, 60000);

  const refreshTableAPIData = (executeCallback) => {
    setLastUpdate(format(new Date(), 'pp'));
    setRefMins('');
    setTempCount(0);
    setRefMins('0mins Ago');
    if (executeCallback) setRefreshTable(Date.now());
  };

  useEffect(() => {
    refreshTableAPIData();
  }, []);

  return (
    <div className="flex items-center">
      <div className="mr-2 text-right text-xs text-gray-600 ">
        Updated:
        <br />
        {refMins}
      </div>
      <div className="border border-gray-300 border-solid rounded-sm p-2">
        <RefreshIcon data-tip data-for="refresh" onClick={() => { refreshTableAPIData(true); }} className="h-5 w-5  cursor-pointer text-blue-600" />
      </div>
      {(lastUpdate && (
        <ToolTip id="refresh">
          Last update:
          {' '}
          {lastUpdate}
          {' '}
        </ToolTip>
      ))}

    </div>
  );
}

RefreshTable.propTypes = {
  setRefreshTable: PropTypes.func.isRequired,
};
