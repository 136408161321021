const CardKeys = {
  all: () => ['cards'],
  list: (listName, params) => [...CardKeys.all(), listName, JSON.stringify(params)],
  details: () => [...CardKeys.all(), 'detail'],
  detail: (id) => [...CardKeys.details(), id],
  cardNumber: (id) => [...CardKeys.detail(id), 'cardNumber'],
  log: (id) => [...CardKeys.detail(id), 'log'],
  transactions: (id) => [...CardKeys.detail(id), 'transactions'],
};

export default CardKeys;
