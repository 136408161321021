import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BanIcon, CheckCircleIcon, ClockIcon, XIcon } from '@heroicons/react/outline';
import StatusConstants from '../../config/StatusConstants';
import Modal from '../Modal';
import Input from '../forms/Input';
import RadioGroup from '../RadioGroup';
import Label from '../forms/Label';
import Checkbox from '../forms/Checkbox';
import Textarea from '../forms/Textarea';
import TooltipCheckbox from '../TooltipCheckbox';
import { formatStringLength, hasAsterisk } from '../../utils/stringUtils';
import useGetSimilarMerchantsById from '../../hooks/api/merchants/useGetSimilarMerchantsById';
import useGetSimilarMerchantsByName from '../../hooks/api/merchants/useGetSimilarMerchantsByName';
import LabelCustom from '../LabelCustom';

const otherValue = 'Other';

const modalContent = (status) => {
  switch (status) {
    case StatusConstants.trustedApiName:
      return 'All future transactions of this merchant will not be scanned by ARDEN. If you are sure. Please provide relevant details if you want to proceed.';
    case StatusConstants.approvedApiName:
      return 'All the transactions for next 24 hours for this merchant will not be scanned by ARDEN. Please provide relevant details if you want to proceed.';
    case StatusConstants.blockedApiName:
      return 'All future transactions for this merchant will be blocked by ARDEN. If you are sure. Please provide relevant details if you want to proceed.';
    case StatusConstants.reviewedApiName:
      return 'All future transactions for the selected merchant will be allowed by ARDEN for the selected merchant on the RAN list removed.';
    default:
      return null;
  }
};

const modalReasons = (status) => {
  switch (status) {
    case StatusConstants.trustedApiName:
      return [
        {
          value: 'Repeated false positives/requests to remove merchant block',
          label: 'Repeated false positives/requests to remove merchant block',
        },
        {
          value: 'Minimal Risk – EMV/face to face transactions',
          label: 'Minimal Risk – EMV/face to face transactions',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case StatusConstants.blockedApiName:
      return [
        {
          value: 'Known fraud merchant',
          label: 'Known fraud merchant',
        },
        {
          value: 'Merchant used for enumeration and fraudulent testing',
          label: 'Merchant used for enumeration and fraudulent testing',
        },
        {
          value: 'Merchant commonly disputed',
          label: 'Merchant commonly disputed',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case StatusConstants.reviewedApiName:
      return [
        {
          value: 'False positive – fraud not detected',
          label: 'False positive – fraud not detected',
        },
        {
          value: 'Fraud event has ceased',
          label: 'Fraud event has ceased',
        },
        {
          value: 'Previous merchant status no longer relevant',
          label: 'Previous merchant status no longer relevant',
        },
        {
          value: 'Previous merchant status incorrectly identified',
          label: 'Previous merchant status incorrectly identified',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case StatusConstants.approvedApiName:
      return [
        {
          value:
            'Merchant Name associated with prior reports of fraud testing but has multiple merchant identification numbers',
          label:
            'Merchant Name associated with prior reports of fraud testing but has multiple merchant identification numbers',
        },
        {
          value: 'Exception needed to allow short term purchase window',
          label: 'Exception needed to allow short term purchase window',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    default:
      return null;
  }
};

const optionTitle = (status) => {
  switch (status) {
    case StatusConstants.reviewedApiName:
      return 'Un-block by';
    case StatusConstants.blockedApiName:
      return 'Select block by';
    default:
      return null;
  }
};

const getTitle = (status) => {
  switch (status) {
    case StatusConstants.blockedApiName:
      return (
        <div className="flex items-center">
          <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
            <BanIcon className="w-5 text-blue-900" />
          </div>
          Block Merchant
        </div>
      );
    case StatusConstants.trustedApiName:
      return (
        <div className="flex items-center">
          <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
            <CheckCircleIcon className="mr- text-blue-900" />
          </div>
          Trust Merchant
        </div>
      );
    case StatusConstants.reviewedApiName:
      return (
        <div className="flex items-center">
          <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
            <XIcon className="mr- text-blue-900" />
          </div>
          Remove from block list
        </div>
      );
    case StatusConstants.approvedApiName:
      return (
        <div className="flex items-center">
          <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
            <ClockIcon className="mr- text-blue-900" />
          </div>
          24h Trust Merchant
        </div>
      );
    default:
      return 'Confirm';
  }
};

export default function MerchantConfirmationModal({ merchant, newStatus, primaryAction, onClose, showModal, isAbleToRemove, ...rest }) {
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [blockMerchantById, setBlockMerchantById] = useState(false);
  const [blockMerchantByName, setBlockMerchantByName] = useState(false);
  const { data: merchantIdsList } = useGetSimilarMerchantsById(merchant.id, newStatus !== StatusConstants.blockedApiName, {
    enabled: !!merchant.id && showModal,
  });
  const { data: merchantNamesList } = useGetSimilarMerchantsByName(merchant.id, newStatus !== StatusConstants.blockedApiName, {
    enabled: !!merchant.id && showModal,
  });

  const isBlockOrUnblockedByIdAndNameEnabled = newStatus === StatusConstants.blockedApiName || isAbleToRemove;

  const onPredefinedReasonChange = (value) => {
    setReason(value);
    if (value !== otherValue) {
      setOtherReason('');
    }
  };

  const onShowCommentChange = (value) => {
    setShowComment(value);
    if (!value) {
      setComment('');
    }
  };

  const handleSelectIdOption = (value) => {
    setBlockMerchantById(value);
  };

  const handleSelectNameOption = (value) => {
    setBlockMerchantByName(value);
  };

  const preProcessItems = (rawList) => rawList?.map(
      (element, index) => `${index + 1}. ${element.number}, ${element.name}`
  );

  const getMerchantsAffectedLabel = (items, discountNumber = 0) => {
    if(!items || !items.length) return '';
    const suffix = (items.length > 1) ? 's' : '';
    return `+${items.length - discountNumber} merchant${suffix} affected`;
  }

  const getProcessedItems = (rawItems) => {
    const preProcessedItems = preProcessItems(rawItems);

    if (preProcessedItems.length > 10) {
      return [...preProcessedItems.slice(0, 10), getMerchantsAffectedLabel(preProcessedItems, 10)]
    }
    return preProcessedItems;
  };

  const merchantNameAffectedList = merchantNamesList?.length ? getProcessedItems(merchantNamesList) : [];
  const merchantIdAffectedList = merchantIdsList?.length ? getProcessedItems(merchantIdsList) : [];

  const renderOptions = () => (
    <div className="flex flex-col">
      <Label className="inline-block mb-2" isRequired>
        {optionTitle(newStatus)}
      </Label>
      <TooltipCheckbox
        name="id"
        isChecked={blockMerchantById}
        onChange={handleSelectIdOption}
        testId="block-unblock-by-id"
        title="Merchant ID"
        value={merchant.number}
        listSize={merchantIdsList?.length}
        merchantsAffectedLabel={getMerchantsAffectedLabel(merchantIdsList)}
        tooltipId="tooltip-similar-by-id"
        disabled={
          newStatus === StatusConstants.reviewedApiName ? !merchant.isBlockedById : merchant.isBlockedById
        }
        affectedList={merchantIdAffectedList}
      />

      <TooltipCheckbox
        name="name"
        isChecked={blockMerchantByName}
        onChange={handleSelectNameOption}
        title="Merchant Name"
        testId="block-unblock-by-name"
        value={formatStringLength(merchant.name, 22)}
        listSize={merchantNamesList?.length}
        merchantsAffectedLabel={getMerchantsAffectedLabel(merchantNamesList)}
        tooltipId="tooltip-similar-by-name"
        disabled={
          hasAsterisk(merchant.name) ||
          (newStatus === StatusConstants.reviewedApiName
            ? !merchant.isBlockedByName
            : merchant.isBlockedByName)
        }
        affectedList={merchantNameAffectedList}
      />
      {hasAsterisk(merchant.name) && (
        <LabelCustom
          className="ml-3 -mt-2 mb-4"
          icon="info"
          message='Block by name disabled as name contains "*"'
          testId="label-info-block-by-name-disabled"
        />
      )}
    </div>
  );

  const renderReasons = () => {
    const reasons = modalReasons(newStatus);
    if (!reasons) {
      return null;
    }
    return (
      <>
        <div className="mb-8">
          <Label className="inline-block mb-2" isRequired htmlFor="other-reason">
            Select reason:
          </Label>
          <RadioGroup options={reasons} selected={reason} onChange={onPredefinedReasonChange} />
          {reason === otherValue && (
            <div className="flex flex-col">
              <Label className="mb-1" isRequired htmlFor="other-reason">
                Provide reason:
              </Label>
              <Input
                type="text"
                id="other-reason"
                name="other-reason"
                rows={3}
                value={otherReason}
                onChange={({ target }) => setOtherReason(target.value)}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const renderComment = () => (
    <>
      <Checkbox className="mb-3" isChecked={showComment} onChange={onShowCommentChange}>
        Add Comment
      </Checkbox>
      {showComment && <Textarea value={comment} onChange={setComment} />}
    </>
  );

  const validate = () => {
    if (
      isBlockOrUnblockedByIdAndNameEnabled &&
      !blockMerchantById &&
      !blockMerchantByName &&
      !merchantIdsList?.isBlockedById &&
      !merchantNamesList?.isBlockedByName
    ) {
      return false;
    }

    if (!reason) {
      return false;
    }

    if (reason === otherValue && !otherReason) {
      return false;
    }

    if (showComment && !comment) {
      return false;
    }

    return true;
  };

  const resetFields = () => {
    setReason('');
    setComment('');
    setOtherReason('');
    setShowComment(false);
    setBlockMerchantById(false);
    setBlockMerchantByName(false);
  };

  const onPrimaryAction = () => {
    const initialObject = {
      merchant,
      reason: reason === otherValue ? otherReason : reason,
      comment,
    };

    initialObject.actionById =
      (newStatus === StatusConstants.reviewedApiName ? merchant.isBlockedById : !merchant.isBlockedById) &&
      blockMerchantById;

    initialObject.actionByName =
      (newStatus === StatusConstants.reviewedApiName
        ? merchant.isBlockedByName
        : !merchant.isBlockedByName) && blockMerchantByName;

    if (primaryAction) {
      primaryAction(initialObject);
      resetFields();
    }
  };

  const onCloseAction = () => {
    if (onClose) {
      onClose();
      resetFields();
    }
  };

  const isValid = validate();

  return (
    <Modal
      {...rest}
      showModal={showModal}
      primaryAction={onPrimaryAction}
      onClose={onCloseAction}
      primaryEnabled={isValid}
      testId="merchant-action"
      primaryText="Confirm"
      size={merchant.name?.length > 30 ? '3xl' : 'xl'}
      title={getTitle(newStatus)}
    >
      <div className="mt-2">
        <p className="text-sm text-gray-500 mb-5">{modalContent(newStatus)}</p>
        {isBlockOrUnblockedByIdAndNameEnabled && renderOptions()}
        {renderReasons()}
        {renderComment()}
      </div>
    </Modal>
  );
}

MerchantConfirmationModal.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    name: PropTypes.string,
    statusApiName: PropTypes.string,
    isBlockedById: PropTypes.bool,
    isBlockedByName: PropTypes.bool,
  }).isRequired,
  newStatus: PropTypes.string,
  primaryAction: PropTypes.func,
  onClose: PropTypes.func,
  showModal: PropTypes.bool.isRequired,
  isAbleToRemove: PropTypes.bool
};

MerchantConfirmationModal.defaultProps = {
  newStatus: '',
  primaryAction: null,
  onClose: null,
  isAbleToRemove: false,
};
