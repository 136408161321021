import React, { useEffect } from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './components/Footer';
import Header from './components/Header';
import Routes from './Routes';
import GlobalModals from './components/modals/GlobalModals';
import useIdleTimeout from './hooks/useIdleTimeout';
import { getToken } from './store/features/me/meSlice';
import ardenLogoShieldBG from './assets/arden-shield-bg.svg';

function App() {
  const {
    user, isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const dispatch = useDispatch();
  useIdleTimeout();

  // @TODO remove once we move all queries to use react-query
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getToken(getAccessTokenSilently));
    }
  }, [dispatch, isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div className="App">
      <div
        className="bg-gray-50 main-container flex flex-col h-screen relative overflow-hidden bg-no-repeat bg-center"
        style={{
          backgroundImage: isAuthenticated === false ? `url(${ardenLogoShieldBG})` : '',
          backgroundSize: '30%',
        }}
      >
        <Header isAuthenticated={isAuthenticated} user={user} />
        <div id="scroll-container" className="overflow-y-auto flex flex-col flex-grow">
          <div className="max-w-screen-2xl mx-auto w-full px-2 sm:px-4 md:px-8 lg:px-12 pb-10 flex-grow">
            <Routes />
          </div>
          <Footer isAuthenticated={isAuthenticated} />
        </div>
        <GlobalModals />
        <Toaster
          position="top-right"
          toastOptions={{
            className: 'w-full',
            style: {
              justifyContent: 'flex-start',
            },
            success: {
              style: {
                background: 'rgba(255, 255, 255, 1)',
                justifyContent: 'flex-start',
              },
              className: 'w-full',
              icon: <CheckCircleIcon className="h-6 w-6 text-blue-900" />,
            },
            error: {
              style: {
                background: '#FEE2E2',
                justifyContent: 'flex-start',
              },
              className: 'w-full',
              icon: <ExclamationCircleIcon className="h-6 w-6 text-red-500" />,
            },
          }}
        />
      </div>
    </div>
  );
}

export default App;
