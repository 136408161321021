import { PencilIcon, UserIcon } from '@heroicons/react/outline';
import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import Grid from '../../components/Grid';
import Subtitle from '../../components/headings/Subtitle';
import UpdateProfileModal from '../../components/modals/UpdateProfileModal';
import { showToast, toastWithPromise, FAILED } from '../../components/Toast/Toast';

import ViewUserPermissionsModal from '../../components/modals/ViewUserPermissionsModal';
import useGetMe from '../../hooks/api/users/useGetMe';
import useUpdateUser from '../../hooks/api/users/useUpdateUser';
import useRemovePicture from '../../hooks/api/users/useRemovePicture';
import useUploadPicture from '../../hooks/api/users/useUploadPicture';

const basicButton = 'font-semibold text-sm focus:outline-none  cursor-pointer';
const buttonStyle = `${basicButton} mt-4 p-2 h-8`;
const titleStyle = 'text-gray-900 font-medium text-base';
const textStyle = 'text-gray-500 font-medium text-sm mt-4';
const valueStyle = 'text-gray-800 font-medium text-sm mt-4 ml-4';

const Profile = () => {
  const [model, setModel] = useState({ showModal: false });
  const [permissionModal, setPermissionModal] = useState({ showModal: false });

  const hiddenFileInput = useRef(null);
  const queryClient = useQueryClient();
  const { data: me } = useGetMe();
  const updateUser = useUpdateUser();
  const removePicture = useRemovePicture();
  const uploadPicture = useUploadPicture();

  const invalidateMeQuery = () => queryClient.invalidateQueries(['me', 'user']);
  const showConfirmModel = () => {
    setModel({ ...model, showModal: true });
  };
  const onComfirmPrimaryAction = (firstName, lastName) => {
    setModel({ ...model, showModal: false });

    if (!firstName && !lastName) {
      return;
    }
    const params = {
      firstName,
      lastName,
    };
    toastWithPromise(
      updateUser.mutateAsync({
        id: me.id,
        data: params,
      }).then(invalidateMeQuery),
      {
        loading: 'Updating profile details...',
        success: 'Profile details updated successfully',
        error: 'An error occurred while updating profile detials',
      },
    );
  };

  const openUploadDialog = () => {
    if (hiddenFileInput) {
      hiddenFileInput.current.click();
    }
  };
  const handleRemovePicture = () => {
    toastWithPromise(
      removePicture.mutateAsync(me.id).then(invalidateMeQuery),
      {
        loading: 'Removing profile picture...',
        success: 'Profile picture removed successfully',
        error: 'An error occurred while removing profile picture',
      },
    );
  };
  const handleUploadPicture = (file) => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.set('picture', file);
    toastWithPromise(
      uploadPicture.mutateAsync({
        id: me.id,
        data: formData,
      }).then(() => {
        invalidateMeQuery();
      }),
      {
        loading: 'Uploading profile picture...',
        success: 'Profile picture uploaded successfully',
        error: 'An error occurred while uploading profile picture',
      },
    );
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      const imageFile = e.target.files[0];
      img.onload = () => {
        if (img.width > 800 || img.height > 400) {
          showToast(FAILED, 'Plese select image with lower dimensions');
        } else {
          handleUploadPicture(imageFile);
        }
      };
    }
  };
  const showPermission = () => {
    setPermissionModal(
      {
        ...permissionModal,
        showModal: true,
        param: { roleId: me.roleId, tenantId: me.tenantId },
      },
    );
  };
  return (
    <>
      <div className="m-4">
        <Subtitle>Profile</Subtitle>
        <div>
          <input ref={hiddenFileInput} type="file" accept="image/*" onChange={onSelectFile} className="hidden" />
        </div>
        <div className="p-8 mt-4 bg-white border-2 border-gray-100 border-solid">
          <Grid>
            <div className="col-span-3">
              <span className={titleStyle}>Profile picture</span>
              <div className={textStyle}>Upload PNG, JPG or GIF (max 800 x 400px)</div>
            </div>
            <div className="flex col-span-8 mt-4 ml-8">
              <div className="relative w-16 h-16 mr-2 bg-gray-100 rounded-full">
                {me && me.picture && (
                  <img className="object-cover w-16 h-16 rounded-full" src={me.picture} alt="Avatar" />
                )}
                {me && !me.picture && (
                  <UserIcon className="w-12 h-12 mt-1 ml-2 text-gray-300" aria-hidden="true" />
                )}
              </div>
              <div className="flex align-middle">
                {me && me.picture && (
                  <button
                    type="button"
                    className={`text-blue-600 hover:text-blue-500 ${buttonStyle}`}
                    onClick={openUploadDialog}
                  >
                    Change
                  </button>
                )}
                {me && !me.picture && (
                  <button
                    type="button"
                    className={`text-blue-600 hover:text-blue-500 ${buttonStyle}`}
                    onClick={openUploadDialog}
                  >
                    Upload
                  </button>
                )}
                {me && me.picture && (
                  <button
                    type="button"
                    className={`text-blue-600 hover:text-blue-500 ${buttonStyle}`}
                    onClick={handleRemovePicture}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </Grid>
          <hr className="mt-8 mb-8" />
          <div>
            <div className="flex">
              <span className={titleStyle}>Personal information</span>
              <PencilIcon className="w-4 h-4 mt-1 ml-4 text-blue-600 cursor-pointer" onClick={showConfirmModel} />
            </div>
            <Grid className="mt-4">
              <div className="col-span-2 text-right">
                <span className={textStyle}>First Name</span>
              </div>
              <div className="col-span-10">
                <span className={valueStyle}>{me && me.firstName}</span>
              </div>
            </Grid>
            <Grid className="mt-4">
              <div className="col-span-2 text-right">
                <span className={textStyle}>Last Name</span>
              </div>
              <div className="col-span-10">
                <span className={valueStyle}>{me && me.lastName}</span>
              </div>
            </Grid>
            <Grid>
              <div className="col-span-2 mt-4 text-right">
                <span className={textStyle}>Email</span>
              </div>
              <div className="flex col-span-10 mt-1">
                <span className={`${textStyle} ml-4`}>{me && me.email}</span>
              </div>
            </Grid>
          </div>
          <hr className="mt-8 mb-8" />
          <div>
            <div className="flex">
              <span className={titleStyle}>Role information</span>
            </div>
            <Grid className="mt-4">
              <div className="col-span-2 text-right">
                <span className={textStyle}>Role assigned</span>
              </div>
              <div className="col-span-10">
                <span className={valueStyle}>{me && me.roleName}</span>
              </div>
            </Grid>
            <Grid className="mt-4">
              <div className="col-span-2 text-right">
                <span className={textStyle}>Role status</span>
              </div>
              <div className="col-span-10">
                <span className={valueStyle}>{me && me.isActive ? 'Active' : 'Inactive'}</span>
              </div>
            </Grid>
            <Grid className="mt-4">
              <div className="col-span-2 text-right">
                <span className={textStyle}>Permissions</span>
              </div>
              <div className="col-span-10">
                <button
                  onClick={me && me.isActive && showPermission}
                  type="button"
                  className={`ml-4 underline underline-offset-4 text-blue-500 hover:text-blue-400 ${basicButton}}`}
                >
                  View
                </button>
              </div>
            </Grid>
          </div>
        </div>
      </div>
      <ViewUserPermissionsModal
        {...permissionModal}
        onClose={() => setPermissionModal({
          ...permissionModal, param: null, showModal: false,
        })}
      />
      <UpdateProfileModal
        {...model}
        onClose={() => setModel({ ...model, showModal: false })}
        primaryAction={(firstName, lastName) => onComfirmPrimaryAction(firstName, lastName)}
      />
    </>
  );
};
export default Profile;
