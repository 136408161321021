import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from './Merchant.keys';

const getSimilarMerchantsByName = async (
  client, id, isBlockedByName,
) => api.merchants.fetchSimilarMerchantsByName(client, id, isBlockedByName);

export default function useGetSimilarMerchantsByName(id, isBlockedByName, options={}) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.blockedNameList(id, isBlockedByName),
    async () => getSimilarMerchantsByName(apiClient, id, isBlockedByName),
    options
  );
}
