import { PauseIcon, XIcon, PlayIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import StatusConstants from '../../config/StatusConstants';
import Label from '../forms/Label';
import Checkbox from '../forms/Checkbox';
import Textarea from '../forms/Textarea';
import RadioGroup from '../RadioGroup';
import Input from '../forms/Input';
import { CardActions } from 'src/config/CardConstants';

const otherValue = 'Other';

const modalContentByStatus = (status) => {
  switch (status) {
    case StatusConstants.suspendCardApiName:
    case StatusConstants.pFraudCardApiName:
      return 'All future transactions on these cards will be declined until reactivated. If you are sure. Please provide relevant details if you want to proceed.';
    case StatusConstants.assignBulkApiName:
      return 'Are you sure you want to assign all the selected cards to this user?';
    default:
      return null;
  }
};

function modalContent(action, status) {
  switch (action) {
    case CardActions.Reactivate:
      return 'All future transactions will be allowed and scanned by ARDEN. If you are sure. Please provide relevant details if you want to proceed.';
    case CardActions.Resolve:
      return 'These cards will no longer be considered as compromised and ARDEN will continue to scan the transactions. Please provide relevant details if you want to proceed.';
    case CardActions.UpdateStatus:
      return modalContentByStatus(status);
  }
}

const getTitleByStatus = (status) => {

  switch (status) {
    case StatusConstants.pFraudCardApiName:
      return ['Mark cards as PFraud', PauseIcon];
    case StatusConstants.suspendCardApiName:
      return ['Suspend Cards', PauseIcon];
    default:
      return ['Confirm', PauseIcon];
  }
};

function getTitle(action, status) {
  let title = '';
  let Icon = PlayIcon;
  switch (action) {
    case CardActions.Reactivate:
      title = 'Reactivate Cards';
      break
    case CardActions.Resolve:
      title = 'Mark the cards as Not Compromised';
      Icon = XIcon;
      break;
    default:
      [title, Icon] = getTitleByStatus(status);
  }

  return (
    <div className="flex items-center">
      <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
        <Icon className="mr- text-blue-900" />
      </div>
      {title}
    </div>
  );
}

const modalReasonsByStatus = (status) => {
  if (status === StatusConstants.suspendCardApiName || status === StatusConstants.pFraudCardApiName) {
      return [
        {
          value: '2106',
          label: 'Card may have compromised',
        },
        {
          value: '2107',
          label: 'Pending verification of authorization',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];

  }
};

function modalReasons(action, status) {
  if (action === CardActions.Reactivate) {
      return [
        {
          value: '1226',
          label: 'Card confirmed not compromised',
        },
        {
          value: '1227',
          label: 'Low risk',
        },
        {
          value: '1228',
          label: 'No abnormal pattern of use',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
  }
  if (action === CardActions.Resolve) {
    return [
      {
        value: '3101',
        label: 'False positive – card not compromised',
      },
      {
        value: otherValue,
        label: otherValue,
      },
    ];
  }
  return modalReasonsByStatus(status);
}

const renderReasons = (reason, setReason, otherReason, setOtherReason, action, toStatus) => {
  const reasons = modalReasons(action, toStatus);
  if (!reasons) {
    return null;
  }

  const handleReasonChange = (value) => {
    setReason(value);
    if (value !== otherValue) {
      setOtherReason('');
    }
  };

  return (
    <>
      <div className="mb-8">
        <Label className="inline-block mb-2" isRequired htmlFor="other-reason">
          Select reason:
        </Label>
        <RadioGroup options={reasons} selected={reason} onChange={handleReasonChange} testId='reason' />
        {reason === otherValue && (
          <div className="flex flex-col">
            <Label className="mb-1" isRequired htmlFor="other-reason">
              Provide reason:
            </Label>
            <Input
              type="text"
              id="other-reason"
              name="other-reason"
              rows={3}
              value={otherReason}
              onChange={({ target }) => setOtherReason(target.value)}
            />
          </div>
        )}
      </div>
    </>
  );
};

const renderComment = (showComment, comment, setComment, setShowComment) => {
  const handleCommentChange = (value) => {
    setShowComment(value);
    if (!value) {
      setComment('');
    }
  };

  return (
    <>
      <Checkbox className="mb-3" isChecked={showComment} onChange={handleCommentChange}>
        Add Comment
      </Checkbox>
      {showComment && <Textarea value={comment} onChange={setComment} />}
    </>
  );
};

export default function BulkEditCardModal({ toStatus, onClose, action, primaryAction, ...rest }) {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [comment, setComment] = useState('');
  const [showComment, setShowComment] = useState(false);

  const validate = () => {
    if (!reason) {
      return false;
    }

    if (reason === otherValue && !otherReason) {
      return false;
    }

    return !(showComment && !comment);
  };

  const isValid = validate();

  const resetFields = () => {
    setReason('');
    setComment('');
    setOtherReason('');
    setShowComment(false);
  };

  const onCloseAction = () => {
    if (onClose) {
      onClose();
      resetFields();
    }
  };

  const onPrimaryAction = () => {
    const initialObject = {
      reason: reason === otherValue ? otherReason : reason,
      comment,
    };

    if (primaryAction) {
      primaryAction(initialObject);
      resetFields();
    }
  };

  return (
    <Modal
      primaryAction={onPrimaryAction}
      onClose={onCloseAction}
      primaryEnabled={isValid}
      size="md"
      primaryText="Confirm"
      title={getTitle(action, toStatus)}
      testId="bulk-edit-card-modal"
      {...rest}
    >
      <div className="mt-2">
        <p className="text-sm text-gray-500 mb-5">{modalContent(action, toStatus)}</p>
        {renderReasons(reason, setReason, otherReason, setOtherReason, action, toStatus)}
        {renderComment(showComment, comment, setComment, setShowComment)}
      </div>
    </Modal>
  );
}

BulkEditCardModal.propTypes = {
  toStatus: PropTypes.string,
  primaryAction: PropTypes.func,
  onClose: PropTypes.func,
  action: PropTypes.string.isRequired
};

BulkEditCardModal.defaultProps = {
  primaryAction: null,
  onClose: null,
  action: CardActions.UpdateStatus
};
