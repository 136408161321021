import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let intervalId = null;
    if (callback && delay) {
      intervalId = setInterval(callback, delay);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [delay]);
}
