import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import UserKeys from './User.keys';

export default function useUpdateUserRole(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation((params) => api.admin.users.updateUserRole(client, params), {
    onSuccess: () => {
      queryClient.invalidateQueries(UserKeys.all());
      queryClient.invalidateQueries(UserKeys.details());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
