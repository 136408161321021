/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';
import MerchantTransactions from '../table/MerchantTransactions';
import ActivityLog from '../ActivityLog';
import TabsContainer from '../TabsContainer';
import MerchantSummary from './MerchantSummary';
import MerchantCards from '../table/MerchantCards';
import MerchantDetailsSidebar from './MerchantDetailsSidebar';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';

const tabs = [
  {
    name: 'Authorization',
    value: 'authorization',
  },
  {
    name: 'Cards',
    value: 'cards',
  },
  {
    name: 'Summary',
    value: 'summary',
  },
  {
    name: 'Activity',
    value: 'activity',
  },
];

export default function MerchantDetails({ id }) {
  const viewKey = `merchant-${id}`;

  const bottomSheetGroupContext = useGroupViewContext();
  const persistedState = bottomSheetGroupContext.state(viewKey) || {};
  const [selectedTab, setSelectedTab] = useState(persistedState.selectedTab || tabs[0].value);

  useEffect(() => {
    if (persistedState.selectedTab !== selectedTab) {
      bottomSheetGroupContext.setState(viewKey, { ...persistedState, selectedTab });
    }
  }, [selectedTab]);

  const renderActivityTab = () => (
    <div className="relative flex flex-col">
      <div className="flex-1 p-6 pb-0 overflow-auto border border-gray-300 rounded">
        <ActivityLog merchantTenantId={id} />
      </div>
    </div>
  );

  const renderAuthorizationTab = () => (
    <MerchantTransactions id={id} />
  );
  const renderCardsTab = () => (
    <MerchantCards merchantTenantId={id} />
  );
  const renderSummaryTab = () => (
    <div className="relative flex flex-col">
      <MerchantSummary merchantTenantId={id} />
    </div>
  );

  const tabsContent = {
    authorization: renderAuthorizationTab,
    cards: renderCardsTab,
    summary: renderSummaryTab,
    activity: renderActivityTab,
  };

  return (
    <>
      <Grid className="sm:gap-10">
        <div className="col-span-12 pt-4 sm:col-span-3">
          <MerchantDetailsSidebar merchantTenantId={id} />
        </div>
        <div className="col-span-12 sm:col-span-9">
          <TabsContainer
            className="py-3"
            tabs={tabs}
            tabsContent={tabsContent}
            selectedTab={selectedTab}
            onSelectedTabChange={setSelectedTab}
            onStateChange={(state) => {
              bottomSheetGroupContext.setState(viewKey, { ...persistedState, tabContainer: state });
            }}
            initialState={persistedState.tabContainer || {}}
          />
        </div>
      </Grid>
    </>
  );
}

MerchantDetails.propTypes = {
  id: PropTypes.number.isRequired,
};
