import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetMerchantTransactions(
  { merchantId, ...params }
) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'merchantTransactions',
      merchantId,
      params
    ],
    () =>
      api.transactions.fetchMerchantTransactions(apiClient, merchantId, params)
  );
}
