import {
  ChartSquareBarIcon, CheckCircleIcon, ClipboardListIcon, ChipIcon,
} from '@heroicons/react/outline';
import React, { useState, useMemo } from 'react';
import Grid from '../../components/Grid';
import Subtitle from '../../components/headings/Subtitle';
import TabsContainer from '../../components/TabsContainer';
import RulesManagement from './rules/RulesManagement';
import FMSelect from '../../components/forms/FMSelect';
import RulesPerformance from './rules/RulesPerformance';
import RulesValidation from './rules/RulesValidation';
import MockComponent from './rules/MockProcessor';
import useGetTenantsFilter from '../../hooks/admin/tenants/useGetTenantsFilter';
import useGetMe from '../../hooks/api/users/useGetMe';
import useConstant from '../../hooks/useConstant';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';

function useRulesTabs() {
  const { data: me } = useGetMe();
  const tabs = useConstant(() => {
    const tabsToReturn = [
      {
        name: 'Rule Management',
        value: 'ruleManagement',
        icon: ClipboardListIcon,
      },
      {
        name: 'Rule Performance',
        value: 'rulePerformance',
        icon: ChartSquareBarIcon,
      },
      {
        name: 'Rule Validation',
        value: 'ruleValidation',
        icon: CheckCircleIcon,
      },
      // Mock processor for lower envs only
    ];

    if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ARDEN_MOCK_PROCESSOR_URL) {
      tabsToReturn.push({
        name: 'Mock Processor',
        value: 'mockProcessor',
        icon: ChipIcon,
      });
    }

    return tabsToReturn;
  });

  return useMemo(() => {
    if (!me) {
      return [];
    }

    if (me && me.featureFlags[FeatureFlagConstants.hideRuleValidationTab]) {
      return tabs.filter((tab) => tab.value !== 'ruleValidation');
    }

    return tabs;
  }, [me]);
}

export default function Rules() {
  const tabs = useRulesTabs();
  const [selectedTenant, setSelectedTenant] = useState(null);
  const tabsContent = useMemo(() => ({
    ruleManagement: () => (
      <RulesManagement tenantId={selectedTenant.id} />
    ),
    rulePerformance: () => (
      <RulesPerformance tenantId={selectedTenant.id} />
    ),
    ruleValidation: () => (
      <RulesValidation tenantId={selectedTenant.id} />
    ),
    mockProcessor: () => (
      <MockComponent tenantId={selectedTenant.id} />
    ),
  }), [selectedTenant]);

  const { data: tenants } = useGetTenantsFilter({
    onSuccess: (tenantList) => {
       if (!selectedTenant && tenantList) {
       setSelectedTenant(tenantList[0]);
     }
   }
 });

  const updatedData = tenants?.map(tenant => ({
    id: tenant.id,
    name: tenant.name.replace('Prepaid Technologies', 'Dash Solutions')
  }));

  const [selectedTab, setSelectedTab] = useState('ruleManagement');


  return (
    <Grid>
      <div className="col-span-12" data-cy="rules-engine-tab">
        <div className="flex justify-between mb-6">
          <Subtitle>Rule Engine</Subtitle>
          <div className="flex items-center">
            <span className="mr-2 text-sm text-gray-600">Showing data for:</span>
            <FMSelect
              className="w-48"
              size="small"
              getOptionLabel={(tenant) => tenant.name}
              getOptionValue={(tenant) => tenant.id}
              options={updatedData}
              value={selectedTenant}
              onChange={setSelectedTenant}
              isSearchable={false}
              testSelector="tenant-select"
            />
          </div>
        </div>
        {selectedTenant && (
          <TabsContainer
            tabs={tabs}
            tabsContent={tabsContent}
            selectedTab={selectedTab}
            onSelectedTabChange={setSelectedTab}
          />
        )}
      </div>
    </Grid>
  );

}
