import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_FM_API_URL,
});

const getApiClient = () => {
  const headers = {};
  return axios.create({
    baseURL: process.env.REACT_APP_FM_API_URL,
    headers,
  });
};

const getAuthHeaders = (accessToken) => ({
  'Access-Control-Allow-Credentials': true,
  Authorization: `Bearer ${accessToken}`,
});

export {
  instance,
  getApiClient,
  getAuthHeaders,
};
