export default {
  merchantTypeId: 1,
  transactionTypeId: 2,
  cardStatusTypeName: 'Card',
  pendingReviewApiName: 'pending-review-merchant',
  knownApiName: 'known-merchant',
  trustedApiName: 'trusted-merchant',
  approvedApiName: 'approved-merchant',
  blockedApiName: 'blocked-merchant',
  reviewedApiName: 'reviewed-merchant',
  replacedCardApiName: 'replaced-card',
  assignBulkApiName:'card-assign-bulk-action',
  suspendCardApiName: 'suspended-card',
  unsuspendCardApiName: 'unsuspend-card',
  activeCardApiName: 'active-card',
  resolveCard: 'resolve-card',
  pFraudCardApiName: 'pfraud-card',
  closedCard: 'closed-card',
  approvedTransactionApiName: 'Approved',
  declinedTransactionApiName: 'Declined',
  approvedStatusCode: '0',
  statusActive: 'Active',
  statusInactive: 'inactive-card',
  statusPermisions: 'Permissions',
  adminApiName: 'admin',
  riskManagerApiName: 'manager',
  teamMemberApiName: 'team-member',
  permissionOverrideDecisions: 'override-decisions',
  authTitle: 'Authorizations',
  merchantCardsTitle: 'Total cards',
  rulesValidation: {
    new: 'new',
    progress: 'progress',
    complete: 'complete',
    failed: 'failed',
  },
  userInvitation: {
    pending: 'pending-user-invitation',
    accepted: 'accepted-user-invitation',
    declined: 'declined-user-invitation',
    cancelled: 'cancelled-user-invitation',
  },
};
