import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import Button from './buttons/Button';
import { classNames } from '../utils/ui';
import IconButton from './buttons/IconButton';
import expandIcon from '../assets/icons/expand.svg';

export default function MinimizedTabs({ tabs, handleShowTab, isOpen }) {
  const bottom = 'absolute bottom-0 right-4 h-10 w-64 rounded-t-lg';
  const transitionClasses = {
    enter: 'transition ease-out duration-200',
    enterFrom: 'opacity-0 -translate-y-1',
    enterTo: 'opacity-100 translate-y-0',
    leave: 'transition ease-in duration-150',
    leaveFrom: 'opacity-100 translate-y-0',
    leaveTo: 'opacity-0 -translate-y-1',
  };

  if (!tabs || tabs.length < 1) return null;

  const firstTab = tabs[0];
  return (
    <Transition show={!isOpen} {...transitionClasses} >
      <Button
        className={classNames(bottom, 'flex')}
        onClick={() => handleShowTab()}
        buttonStyle="light"
        buttonsize="buttonSize"
      >
        {firstTab.title && firstTab.title.length > 20 ? `${firstTab.title.substring(0, 20)}...` : firstTab.title}
        {tabs.length > 1 && ` +${tabs.length - 1}`}
        <IconButton
          buttonSize="sm"
          buttonStyle="custom"
          className="ml-auto"
          icon={
            <img
              src={expandIcon}
              className="w-4 h-4 ml-auto"
              alt="Expand all"
            />
          }
        />
      </Button>
    </Transition>
  );
}

MinimizedTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
  handleShowTab: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
