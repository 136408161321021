import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_ARDEN_MOCK_PROCESSOR_URL,
});

const fetchApiStatus = async (mockProcessorUnit) => instance.get(`/api/${mockProcessorUnit}`).then((response) => response.data);

const fetchStartMock = async (mockProcessorUnit, body) => instance.post(`/api/${mockProcessorUnit}/start`, { body: { ...body } }).then((response) => response.data);

const fetchStopMock = async (mockProcessorUnit, body) => instance.post(`/api/${mockProcessorUnit}/stop`, { body: { ...body } }).then((response) => response.data);

const fetchAttackMock = async (mockProcessorUnit, body) => instance.post(`/api/${mockProcessorUnit}/attack/velocity`, { body: { ...body } }).then((response) => response.data);

export default {
  instance,
  fetchApiStatus,
  fetchStartMock,
  fetchStopMock,
  fetchAttackMock,
};
