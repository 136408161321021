import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useGetFraudChart from '../../../hooks/api/insights/useGetFraudChart';
import AxisTick from '../../chart/AxisTick';
import Spinner from '../../Spinner';

export default function FraudChart() {
  const { isLoading, data } = useGetFraudChart('weeks');

  return (
    <>
      {isLoading ? (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full p-2 bg-black bg-opacity-20">
          <Spinner />
        </div>
      ) : (
        <div className="w-full h-60">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={data.data}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="period" scale="band" tick={{ fontSize: 10 }} />
              <YAxis
                tick={AxisTick}
                label={{
                  angle: -90,
                  position: 'insideLeft',
                  fontSize: 12,
                }}
              >
                <Label
                  value="Fraud attacks"
                  angle={-90}
                  position="insideLeft"
                  className="text-xs"
                  style={{ textAnchor: 'middle' }}
                />
              </YAxis>
              <YAxis
                yAxisId="breach"
                orientation="right"
                tickFormatter={(value) => `${value}%`}
                tick={AxisTick}
              >
                <Label
                  value="Breach percentage"
                  angle={90}
                  position="insideRight"
                  className="text-xs"
                  style={{ textAnchor: 'middle' }}
                />
              </YAxis>
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Bar name="Fraud attacks" dataKey="total" fill="#1E3A8A" barSize={27} />
              <Line name="Breach percentage" dataKey="breach" yAxisId="breach" stroke="#818CF8" dot={{ fill: '#818CF8' }} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
}
