import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from './Merchant.keys';

const getMerchantThreats = async (
  client,
  params,
) => api.merchants.fetchMerchantThreats(client, params);

export default function useGetMerchantThreats(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.list('threats', params),
    async () => getMerchantThreats(apiClient, params),
  );
}
