import React, { useState } from 'react';
import { CreditCardIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import Grid from '../../components/Grid';
import Title from '../../components/headings/Title';
import HomeAuthScanned from './HomeAuthScanned';
import HomeStatus from './HomeStatus';
import LiveMerchantDetails from './LiveMerchantDetails';
import LiveCardDetails from './LiveCardDetails';
import LiveMerchantTrendsContainer from './liveMerchants/LiveMerchantTrendsContainer';
import TabsContainer from '../../components/TabsContainer';
import useConstant from '../../hooks/useConstant';
import useGetMe from '../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';

function useHomeTabs(isPerformanceFeatureFlag) {
  const tabs = useConstant(() => [
    {
      name: 'Merchant',
      value: 'merchant',
      icon: ShoppingBagIcon,
    },
    {
      name: 'Card',
      value: 'card',
      icon: CreditCardIcon,
    },
  ]);

  if (isPerformanceFeatureFlag) {
    return tabs.filter((e) => e.value !== 'card');
  }
  return tabs
}
export default function Home() {
  const { data: me } = useGetMe();
  const isPerformanceFeatureFlag = (me && me.featureFlags[FeatureFlagConstants.isPerformancePOC])

  const [selectedTab, setSelectedTab] = useState('merchant');
  const tabs = useHomeTabs(isPerformanceFeatureFlag);

  return (
    <Grid>
      <div className="col-span-12 pt-5" data-cy="merchants-screen">
        <div className="flex items-center justify-between mb-3">
          <Title>
            Arden alert
            {isPerformanceFeatureFlag === false && (
              <HomeAuthScanned />
            )}
          </Title>
          <div className="flex">
            <HomeStatus />
          </div>
        </div>
        <LiveMerchantTrendsContainer />
        <TabsContainer
          className="py-0"
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectedTabChange={setSelectedTab}
        >
          {selectedTab === 'merchant' && <LiveMerchantDetails />}
          {selectedTab === 'card' && <LiveCardDetails />}
        </TabsContainer>
      </div>
    </Grid>
  );
}
