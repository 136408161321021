const fetchRoles = async (client) => client.get('api/admin/roles')
  .then((response) => response.data);
const fetchRolePermissions = async (client, params = {}) => client.get('api/admin/roles/role-permissions', {
  params,
})
  .then((response) => response.data);
const resetPermissions = async (client, params = {}) => client.put('api/admin/roles/reset-permissions',
  params)
  .then((response) => response.data);
const updatePermissions = async (client, params = {}) => client.put('api/admin/roles/update-permissions', params)
  .then((response) => response.data);

export default {
  fetchRoles,
  fetchRolePermissions,
  resetPermissions,
  updatePermissions,
};
