import React from 'react';
import PropTypes from 'prop-types';
import Button from './buttons/Button';
import { classNames } from '../utils/ui';

export default function ButtonGroup({
  className, options, onChange, selected, roundedCorners, itemClassName,
}) {
  return (
    <div className={classNames(
      'flex',
      className,
    )}
    >
      {options.map((option, index) => (
        <Button
          key={`option-${option.value}`}
          buttonSize="sm"
          buttonStyle={option.value === selected ? 'group-dark' : 'group-light'}
          className={
            classNames(
              itemClassName,
              roundedCorners && index === 0 && 'rounded-l-md',
              roundedCorners && index === options.length - 1 && 'rounded-r-md',
              option.value === selected && 'border-none'
            )
          }
          rounded={false}
          onClick={() => onChange && onChange(option.value)}
          disabled={option.disabled}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}

ButtonGroup.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool,
  })).isRequired,
  roundedCorners: PropTypes.bool,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
};

ButtonGroup.defaultProps = {
  roundedCorners: true,
  onChange: null,
  selected: null,
  className: null,
  itemClassName: null,
};
