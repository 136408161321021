import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MinusIcon, PlusIcon, ChatIcon, PauseIcon, PlayIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Disclosure } from '@headlessui/react';
import StatusConstants from '../../config/StatusConstants';
import ardenShieldOnly from '../../assets/arden-shield-bg-blue.svg';
import { toastWithPromise } from '../Toast/Toast';
import CommentModal from '../modals/CommentModal';
import useCreateCardComment from '../../hooks/api/cards/useCreateCardComment';
import CardConstants, { CardActions } from '../../config/CardConstants';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import useGetMe from '../../hooks/api/users/useGetMe';
import ActionButton from '../buttons/ActionButton';

export default function CardDetailsActions({ cardId, statusApiName, onClick, isCompromised }) {

  const { data: user } = useGetMe();
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const createCardComment = useCreateCardComment();

  if (statusApiName === StatusConstants.replacedCardApiName) return null;

  const handleCommentSubmit = (comment) => {
    setShowAddCommentModal(false);
    toastWithPromise(
      createCardComment.mutateAsync({
        id: cardId,
        comment,
      }),
      {
        loading: 'Creating card comment',
        success: `Comment added successfully`,
        error: `An error occurred while creating a comment`,
      },
      CardConstants.actions.comment
    );
  };

  const isAllowOptions = !user.featureFlags[FeatureFlagConstants.hideCardActions];
  const isAllButtonsDisabled = statusApiName === StatusConstants.closedCard;
  const isInternalStatusPfraudOrSuspended =
    statusApiName === StatusConstants.suspendCardApiName ||
    statusApiName === StatusConstants.pFraudCardApiName;

  const isInternalStatusInactive = statusApiName === StatusConstants.statusInactive;

  const isResolved = isCompromised && isInternalStatusPfraudOrSuspended;
  return (
    <>
      {isAllowOptions && (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className="w-full py-2 font-medium text-left text-blue-900 bg-white rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                data-cy="card-actions-button"
              >
                <div className="flex">
                  <div className="flow-root w-full ml-2">
                    <span className="float-left">
                      <img src={ardenShieldOnly} className="w-5 h-5 mt-2 mr-3" alt="arden shield only logo" />
                    </span>
                    <span className="float-left mt-1">Actions</span>
                  </div>

                  {open ? (
                    <MinusIcon className="w-6 h-6 mt-2 mr-2" />
                  ) : (
                    <PlusIcon className="w-6 h-6 mt-2 mr-2 bg-white" />
                  )}
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                <div className="grid grid-cols-1 gap-x-4 gap-y-2">
                  <ActionButton
                    isDisabled={
                      isInternalStatusPfraudOrSuspended || isInternalStatusInactive || isAllButtonsDisabled
                    }
                    onClick={() => onClick(CardActions.UpdateStatus, StatusConstants.suspendCardApiName)}
                    Icon={PauseIcon}
                    iconClassName="text-yellow-600"
                    testId="suspend-button"
                  >
                    Suspend
                  </ActionButton>

                  <ActionButton
                    testId="pfraud-button"
                    isDisabled={
                      isInternalStatusPfraudOrSuspended || isInternalStatusInactive || isAllButtonsDisabled
                    }
                    onClick={() => onClick(CardActions.UpdateStatus, StatusConstants.pFraudCardApiName)}
                    Icon={PauseIcon}
                    iconClassName="text-yellow-600"
                  >
                    Mark as PFraud
                  </ActionButton>

                  <ActionButton
                    isDisabled={!isInternalStatusPfraudOrSuspended || isAllButtonsDisabled}
                    onClick={() => onClick(CardActions.Reactivate)}
                    Icon={PlayIcon}
                    iconClassName="text-green-600"
                    testId="reactivate-button"
                  >
                    Reactivate
                  </ActionButton>

                  <ActionButton
                    isDisabled={!isCompromised}
                    onClick={() => onClick(CardActions.Resolve)}
                    Icon={XIcon}
                    iconClassName="text-gray-600"
                    testId="resolve-button"
                  >
                    {isResolved ? 'Resolve' : 'Not compromised'}
                  </ActionButton>

                  <ActionButton
                    Icon={ChatIcon}
                    testId="comment-button"
                    iconClassName="text-gray-700"
                    onClick={() => setShowAddCommentModal(true)}
                  >
                    Add comment
                  </ActionButton>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      <hr className="col-span-12 mt-4 mb-6" />
      <CommentModal
        show={showAddCommentModal}
        onSubmit={handleCommentSubmit}
        onClose={() => setShowAddCommentModal(false)}
      />
    </>
  );
}

CardDetailsActions.propTypes = {
  cardId: PropTypes.number.isRequired,
  statusApiName: PropTypes.string.isRequired,
  isCompromised: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

CardDetailsActions.defaultProps = {
  isCompromised: false,
};
