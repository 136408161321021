import { Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import {
  UserIcon as OutlinedUserIcon,
  LogoutIcon,
} from '@heroicons/react/outline';
import React, { Fragment } from 'react';
import { classNames } from '../../utils/ui';
import useGetMe from '../../hooks/api/users/useGetMe';

export default function UserMenu() {
  const { logout } = useAuth0();
  const { data: me } = useGetMe();
  const history = useHistory();

  const userNavigation = [
    {
      Icon: (
        <OutlinedUserIcon className="h-6 w-6 ml-4 rounded-full border-2 mt-1.5 border-gray-700 text-gray-700" />
      ),
      name: 'Profile',
      href: '/account',
      type: 'route',
    },
    {
      Icon: (
        <LogoutIcon className="h-6 w-6 ml-4 rounded-full border-2 mt-2 border-gray-700 text-gray-700" />
      ),
      name: 'Log out',
      href: '#',
      type: 'action',
      onClick: () => logout({ returnTo: window.location.origin }),
    },
  ];

  return (
    <Menu
      as="div"
      className="relative flex mr-2 sm:mr-0"
      data-cy="user-nav"
    >
      {({ open: openMenu }) => (
        <>
          <Menu.Button className="max-w-xs px-2 items-center text-gray-600 text-sm flex focus:outline-none focus:bg-white hover:bg-white focus:bg-opacity-10 hover:bg-opacity-10 active:bg-opacity-20">
            <span className="sr-only">Open user menu</span>
            <div className="bg-gray-100 rounded-full mr-2  h-6 w-6 relative">
              {me && me.picture && (
                <img
                  className="rounded-full h-6 w-6 object-cover"
                  src={me.picture}
                  alt="Avatar"
                />
              )}
              {!(me && me.picture) && (
                <UserIcon
                  className="h-6 w-6  text-gray-300"
                  aria-hidden="true"
                />
              )}
            </div>
            <span className="hidden md:inline">Account</span>
          </Menu.Button>
          <Transition
            show={openMenu}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right z-20 absolute right-0 mt-14 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <div key={item.name} className="flex w-48">
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active
                            ? 'flex bg-gray-100'
                            : 'flex text-gray-700 hover:bg-gray-50',
                          'w-48'
                        )}
                      >
                        {item.Icon}
                        {item.type === 'route' && (
                          <button
                            type="button"
                            href={item.href}
                            onClick={() => {
                              history.push(item.href);
                            }}
                            aria-current={active ? 'page' : undefined}
                            className={classNames(
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            {item.name}
                          </button>
                        )}

                        {item.type !== 'route' && (
                          <a
                            href={item.href}
                            onClick={item.onClick}
                            className={classNames(
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </div>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
