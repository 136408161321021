import React from 'react';
import PropTypes from 'prop-types';
import numberUtils from '../../utils/numberUtils';

export default function PaginationInfo({ totalItems, currentPage, itemsPerPage }) {
  if (totalItems <= 0) {
    return (<div />);
  }

  const firstItemIdx = (currentPage - 1) * itemsPerPage + 1;

  return (
    <div className="text-gray-500 text-sm my-2">
      Showing &nbsp;
      {firstItemIdx}
      -
      {Math.min(firstItemIdx + itemsPerPage - 1, totalItems)}
      &nbsp;of&nbsp;
      {numberUtils.numberFormat(totalItems)}
    </div>
  );
}

PaginationInfo.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};
