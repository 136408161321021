const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const currencyFormat = (number) => currencyFormatter.format(Number.isNaN(number) ? 0 : number);

const numberFormat = (number) => {
  if (!number) {
    return 0;
  }
  return ((number != null) ? (`${number}.`).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.', '') : '');
};
export default {
  numberFormat,
  currencyFormat,
};
