import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import AdminRuleKeys from './AdminRules.keys';

export default function useGetRules(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    AdminRuleKeys.list('all', params),
    async () => api.admin.rules.fetchAdminRules(apiClient, params),
  );
}
