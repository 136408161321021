import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';
import Badge from '../../Badge';
import StatusConstants from '../../../config/StatusConstants';
import { getColumnValue } from '../DataTableUtils';

const getBadgeType = (statusApiName, changedByType) => {
  switch (statusApiName) {
    case StatusConstants.pendingReviewApiName:
      return 'danger';
    case StatusConstants.trustedApiName:
    case StatusConstants.reviewedApiName:
      return 'success';
    case StatusConstants.approvedApiName:
      return 'teal';
    case StatusConstants.blockedApiName:
      return changedByType === 'rule' ? 'orange' : 'danger';
    default:
      return 'default';
  }
};

const getBadgeText = (statusApiName) => {
  switch (statusApiName) {
    case StatusConstants.pendingReviewApiName:
      return 'Suspicious';
    case StatusConstants.trustedApiName:
      return 'Trusted';
    case StatusConstants.approvedApiName:
      return 'Approved';
    case 'known-merchant':
      return 'Known';
    case StatusConstants.blockedApiName:
      return 'Blocked';
    case StatusConstants.reviewedApiName:
      return 'Reviewed';
    case StatusConstants.activeCardApiName:
      return 'Active';
    default:
      return '';
  }
};

export function StatusBadge({ statusApiName, changedByType, testId }) {
  return (
    <div className="flex flex-row items-center">
      <Badge testId={testId} type={getBadgeType(statusApiName, changedByType)}>
        {getBadgeText(statusApiName)}
      </Badge>
    </div>
  );
}

StatusBadge.propTypes = {
  statusApiName: PropTypes.string.isRequired,
  changedByType: PropTypes.string,
  testId: PropTypes.string,
};

StatusBadge.defaultProps = {
  changedByType: '',
  testId:''
};
export default function MerchantStatusColumn({ column, row }) {
  const { id } = row;
  const value = getColumnValue(row, column);
  const value2 = column.valueGetter2
    ? column.valueGetter2(row[column.field2], row)
    : row[column.field2];
  return (
    <td key={`cel-${id}-status-{${column.field}}`} className={`${TABLE_CELL_CLASS}`}>
      {value && (
        <StatusBadge statusApiName={value} changedByType={value2} />
      )}
    </td>
  );
}

MerchantStatusColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
    },
  ).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
    field2: PropTypes.string,
    valueGetter2: PropTypes.func,
  }).isRequired,
};
