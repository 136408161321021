import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import useApiClient from '../../useApiClient';
import UserKeys from '../users/User.keys';
import CardKeys from './Card.keys';

const getCardNumber = async (
  client,
  cardId,
  params,
) => {
  const response = await api.cards.fetchCardNumber(client, cardId, params);
  return { response, cardId, params };
};

export default function useGetCardNumber() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      cardId,
      hideCardNumberWarning,
    }) => getCardNumber(apiClient, cardId, { hideCardNumberWarning }),
    {
      onSuccess: ({ cardId, params }) => {
        queryClient.invalidateQueries(CardKeys.log(cardId));
        if (params.hideCardNumberWarning) {
          queryClient.invalidateQueries(UserKeys.me());
        }
      },
    },
  );
}
