import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircleIcon,
} from '@heroicons/react/outline';
import { formatDate } from '../utils/date';
import getReason from '../utils/reasons';
import Spinner from './Spinner';
import { getName } from '../utils/userUtil';
import useGetTenantLogs from '../hooks/admin/tenants/useGetTenantLogs';

function getActivityName(item) {
  if (!item || !item.changedBy) {
    return 'ARDEN';
  }
  return getName(item.changedBy);
}

function getActivityDescription(item) {
  if (item.action) {
    return (
      <>
        {item.action === 'Update' && 'updated the Tenant'}
      </>
    );
  }

  return '';
}

function getActivityIcon() {
  return <CheckCircleIcon />;
}

function ActivityLogItem({ item }) {
  return (
    <div className="relative flex mb-6 -ml-px last:border-l last:border-white">
      <div className="absolute -mt-1.5 -left-4">
        <div className="w-8 h-8 p-1 text-blue-900 rounded-full bg-blue-50">{getActivityIcon(item)}</div>
      </div>
      <div className="flex-1 pb-6 ml-6 border-b border-b-gray-200">
        <div className="flex flex-col">
          <div className="flow-root">
            <div className="text-xs text-gray-500">{formatDate(item.createdAt)}</div>
            <div className="float-left text-sm text-gray-900">
              <span className="font-semibold">{getActivityName(item)}</span>
              &nbsp;
              {getActivityDescription(item)}
            </div>
          </div>
          {item.reason && (
            <div className="mt-1">
              <span className="text-sm">Reason: </span>
              <span className="text-sm text-gray-600">{getReason(item.reason)}</span>
            </div>
          )}
          {item.comment && (
            <div className="mt-1">
              <span className="text-sm">Comment: </span>
              <span className="text-sm text-gray-600">{item.comment}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ActivityLogItem.propTypes = {
  item: PropTypes.shape({
    changedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    comment: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
};

export default function TenantActivityLogs({ tenantId }) {
  const { data, isLoading } = useGetTenantLogs(tenantId);
  const tenantLogsItem = data ? data.rows : [];

  return (
    <div className="ml-2 border-l border-gray-200">
      {isLoading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
          <Spinner />
        </div>
      )}

      {!isLoading && tenantLogsItem.map((item) => (
        <ActivityLogItem key={`log-${item.id}`} item={item} />
      ))}
    </div>
  );
}

TenantActivityLogs.propTypes = {
  tenantId: PropTypes.number.isRequired,
};
