import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Grid from '../components/Grid';
import TextButton from '../components/buttons/TextButton';
import PaginationConfig from '../config/PaginationConfig';
import RefreshTable from '../components/RefreshTable';
import FiltersContainer from '../components/filters/FiltersContainer';
import FMCombobox from '../components/forms/FMCombobox';
import CardsTable, {
  CARD_ASSIGN_BULK_ACTION,
  CARD_BULK_ACTION
} from '../components/table/CardsTable';
import cardTableUtils from '../utils/cardTableUtils';
import { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../components/table/DataTable';
import { getName } from '../utils/userUtil';
import useGetCards from '../hooks/api/cards/useGetCards';
import useAssignCard from '../hooks/api/cards/useAssignCard';
import useBulkUpdateCardStatus from '../hooks/api/cards/useBulkUpdateCardStatus'
import useGetTenantUsers from '../hooks/api/users/useGetTenantUsers';
import { toastWithPromise, toastBulkAction } from '../components/Toast/Toast';
import CardExternalStatusFilter from '../components/filters/CardExternalStatusFilter';

export default function CardChildren({ currentView }) {
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [statusFilter, setStatusFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [order, setOrder] = useState('updatedAt');
  const [orderDir, setOrderDir] = useState(TABLE_SORT_DESC);
  const [disabledClearFilter, setDisabledClearFilter] = useState(true);
  const [userFilter, setUserFilter] = useState();
  const [filtersDescription, setFiltersDescription] = useState([]);
  const assignCard = useAssignCard();
  const bulkUpdateCardStatus = useBulkUpdateCardStatus();

  const getFilters = () => {
    const filters = {
      search,
      page,
      itemsPerPage,
      order,
      orderDir,
    };
    if (userFilter) {
      filters.userId = userFilter.value;
    }

    filters.externalStatusCode = statusFilter ? statusFilter.code : '';
    return filters;
  };

  const { data, isLoading, refetch } = useGetCards(getFilters());
  const cards = data ? data.rows : [];

  const { data: tenantUsersData } = useGetTenantUsers();
  const tenantUsers = tenantUsersData ? tenantUsersData.rows : [];
  const userFilterOptions = [
    { value: '', label: 'All' },
    ...tenantUsers.map((userTenant) => ({
      value: userTenant.id,
      label: getName(userTenant),
    })),
  ];
  const lastCardUpdateOutSide = useSelector((state) => state.card.lastCardUpdated);
  const columnsToUse = cardTableUtils.getCardTableColumns(currentView);
  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const updateClearAll = (status, otherFilter1) => {
    if (!status && !searchValue && !otherFilter1) {
      setDisabledClearFilter(true);
    } else {
      setDisabledClearFilter(false);
    }
  };
  const clearFilters = () => {
    setSearch('');
    setSearchValue('');
    setStatusFilter(null);
    setUserFilter(null);
    resetPage();
    setDisabledClearFilter(true);
  };

  // @TODO Create a route on backend to handle multiple user assign
  const updateCardAssignBulkUserAction = (card, user) => {
    toastWithPromise(
      Promise.all(
        card.ids.map((e) =>
          assignCard.mutateAsync({
            id: e,
            data: {
              oldUserId: null,
              userId: user.value,
            },
          })
        )
      ),
      {
        loading: 'Updating card details...',
        success: 'Card details updated successfully',
        error: 'An error occurred while updating card details',
      }
    );
  };

  const updateCardStatusBulkUserAction = (card) => {
    const { ids, status, reason, comment } = card;
    toastBulkAction(
      bulkUpdateCardStatus.mutateAsync({
        ids,
        statusApiName: status,
        reason,
        comment
      }),
      {
        loading: 'Updating cards...',
      }
    );
  };

  const changeSort = (field) => {
    if (field !== order) {
      setOrder(field);
      setOrderDir(TABLE_SORT_DESC);
      return;
    }

    setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onCardAction = (action, args, rest) => {
    switch (action) {
      case TABLE_SORT_ACTION:
        changeSort(args.field);
        break;
      case CARD_ASSIGN_BULK_ACTION:
        updateCardAssignBulkUserAction(args, rest);
        break;
      case CARD_BULK_ACTION:
        updateCardStatusBulkUserAction(args);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (cards.find((item) => item.id === lastCardUpdateOutSide.id)) {
      refetch();
    }
  }, [lastCardUpdateOutSide]);

  useEffect(() => {
    const newFilters = [];

    if (statusFilter) {
      newFilters.push({
        name: 'Status:',
        value: statusFilter.label,
      });
    }

    if (userFilter) {
      newFilters.push({
        name: 'User:',
        value: userFilter.label,
      });
    }

    setFiltersDescription(newFilters);
  }, [statusFilter, userFilter, setFiltersDescription]);

  const renderFilterTooltip = () => {
    if (filtersDescription.length === 0) {
      return null;
    }
    return <FiltersContainer.Tooltip filters={filtersDescription} />;
  };

  return (
    <Grid>
      <div className="col-span-12 pt-5">
        {/* <InsightItems fetcher={insightsFetcher} /> */}
        <div className="items-start justify-between lg:flex">
          <FiltersContainer search={search} onSearchChange={setSearch} tooltip={renderFilterTooltip()}>
            <CardExternalStatusFilter
              onChange={(e) => {
                resetPage();
                setStatusFilter(e);
                updateClearAll(e, userFilter);
              }}
              selected={statusFilter}
              placeholder="Select Status"
            />
            <FMCombobox
              placeholder="User"
              data={userFilterOptions}
              selected={userFilter}
              onChange={(e) => {
                resetPage();
                setUserFilter(e);
                updateClearAll(e, userFilter);
              }}
            />

            {(search.length > 0 || filtersDescription.length > 0) && (
              <TextButton onClick={clearFilters} disabled={disabledClearFilter}>
                Clear all
              </TextButton>
            )}
          </FiltersContainer>
          <RefreshTable setRefreshTable={() => refetch()} />
        </div>
        <CardsTable
          columns={columnsToUse}
          data={cards}
          loading={isLoading}
          paginationProps={{
            totalItems: data ? data.count : 0,
            currentPage: page,
            itemsPerPage,
            onPageClick,
            onItemsPerPageChange: (e) => {
              resetPage();
              setItemsPerPage(e);
            },
          }}
          onAction={onCardAction}
          sort={order}
          sortDirection={orderDir}
        />
      </div>
    </Grid>
  );
}
CardChildren.propTypes = {
  currentView: PropTypes.string.isRequired,
};
