export default [
  {
    label: 'Merchant',
    value: 'MerchantTenant',
  },
  {
    label: 'Card',
    value: 'Card',
  },
];
