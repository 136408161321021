import React from 'react';
import PropTypes from 'prop-types';

export default function ActivityLogInfo(props) {
  const { message, description } = props;

  return (
    <>
      {message}&nbsp;{description && <span className="font-bold">{description}</span>}
    </>
  );
}

ActivityLogInfo.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
};

ActivityLogInfo.defaultProps = {
  description: '',
};
