import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/outline';
import { UserIcon } from '@heroicons/react/solid';
import Grid from './Grid';
import TabsContainer from './TabsContainer';
import { formatDateOnly, formatTimeOnly } from '../utils/date';
import Toggle from './Toggle';
import StatusConfirmationModal from './modals/StatusConfirmationModal';
import PermissionConstants from '../config/PermissionConstants';
import useGetAdminRoles from '../hooks/admin/roles/useGetAdminRoles';
import ChangeRoleConfirmationModel from './modals/ChangeRoleConfirmationModel';
import UserActivityLog from './UserActivityLog';
import useGetMe from '../hooks/api/users/useGetMe';
import useGetMyPermissions from '../hooks/api/users/useGetMyPermissions';
import TaskTable from './taskSummaryTable/TaskTable';
import useGetUser from '../hooks/api/users/useGetUser';
import Spinner from './Spinner';
import useGetTenantsFilter from '../hooks/admin/tenants/useGetTenantsFilter';
import useUpdateUser from '../hooks/api/users/useUpdateUser';
import useUpdateUserRole from '../hooks/api/users/useUpdateUserRole';
import { checkPermission, getName } from '../utils/userUtil';

const labelClass = 'block text-xs mb-1 text-gray-500';
const valueClass = 'block text-gray-800 font-semibold';
const valueClassTime = 'text-xs mb-1';

const tabs = [
  {
    name: 'Permissions',
    value: 'permission',
  },
  {
    name: 'Activity',
    value: 'activity',
  },
  {
    name: 'Task Summary',
    value: 'taskSummary',
  },
];

export default function UserDetails({ userId }) {
  const { data: tenants } = useGetTenantsFilter();
  const { data: me } = useGetMe();
  const { data: permissions } = useGetMyPermissions();
  const { data: userDetails, isLoading } = useGetUser(userId);
  const updateUser = useUpdateUser();
  const updateUserRole = useUpdateUserRole();
  const [selectedTab, setSelectedTab] = useState('permission');

  const [showActiveStatusModal, setShowActiveStatusModal] = useState(false);
  const [modal, setModal] = useState({ showModal: false });

  // redux
  const rolesRequest = useGetAdminRoles();
  const roles = rolesRequest.data;

  useEffect(() => {
    setModal({
      ...modal,
      user: userDetails,
      showModal: false,
    });
  }, [userDetails]);

  const getStatusModalTitle = () => {
    if (userDetails.isActive) {
      return 'Deactivate User';
    }
    return 'Activate User';
  };

  const getStatusModalContent = () => {
    if (userDetails.isActive) {
      return 'This user will no longer be able to access the platform';
    }
    return 'This user will be activated, allowing him/her to access the platform as per their role assignment.';
  };

  function updateUserStatus(row) {
    setShowActiveStatusModal(false);
    const payload = {};
    payload.isActive = !userDetails.isActive;
    toast.promise(
      updateUser.mutateAsync({
        id: row.id,
        data: payload,
      }),
      {
        loading: 'Updating user...',
        success: 'User updated successfully',
        error: 'An error occurred while updating the user',
      },
    );
  }

  const onStatusToggle = () => {
    setShowActiveStatusModal(true);
  };

  const getPermission = (item) => (
    <div key={item.id}>
      <li className="p-0 mr-1">
        <span className="text-sm text-gray-500">Can </span>
        <span className="text-sm text-gray-600 form-medium">{item.name}</span>
      </li>
    </div>
  );

  const renderPermissionTab = () => (
    <div className="relative flex flex-col">
      <div className="flex-1 p-6 pb-0 overflow-auto border border-gray-300 rounded">
        <span className="text-sm text-gray-600">Permissions</span>
        <div>
          <ul className="mt-4 list-disc">
            {permissions && permissions.map((item) => getPermission(item))}
          </ul>
        </div>
      </div>
    </div>
  );

  const renderActivityTab = () => (
    <div className="relative flex flex-col">
      <div className="flex-1 p-6 pb-0 overflow-auto border border-gray-300 rounded">
        <span className="text-sm text-gray-600">Activity</span>
        <div>
          <ul className="mt-4 list-disc">
            <UserActivityLog userId={userDetails.id} className="p-8 mt-4 bg-white border-2 border-gray-100 border-solid" />
          </ul>
        </div>
      </div>
    </div>
  );
  const renderTaskSummary = () => (
    <div className="relative flex flex-col">
      <div className="flex-1 p-6 pb-0 overflow-auto border border-gray-300 rounded">
        <span className="text-sm text-gray-600">Task Summary</span>
        <div>
          <ul className="mt-4 list-disc">
            <TaskTable userId={userDetails.id} />
          </ul>
        </div>
      </div>
    </div>
  );

  const tabsContent = {
    permission: renderPermissionTab,
    activity: renderActivityTab,
    taskSummary: renderTaskSummary,
  };

  const findTenants = (idTenant) => {
    if (!idTenant) return '';
    const tenantInteractive = tenants.find((tenant) => tenant.id === idTenant);
    return tenantInteractive.name;
  };

  function handlerChangeUserRoleOpen() {
    if ((userDetails.id !== me.id)
      && checkPermission(permissions, PermissionConstants.ADD_MODIFIY_USERS)) {
      setModal({ ...modal, showModal: true });
    }
  }

  function onChangeUserRole(args, allOptions) {
    const from = allOptions.find((e) => e.value === modal.user.roleId).label;
    const to = allOptions.find((e) => e.value === args.value).label;

    setModal({
      ...modal,
      showModal: false,
    });
    if (!args || modal.user.roleId === args.value) {
      return;
    }
    const params = {
      userId: modal.user.id,
      roleId: args.value,
      from,
      to,
    };
    toast.promise(
      updateUserRole.mutateAsync(
        params,
      ),
      {
        loading: 'Updating Role...',
        success: 'Role updated successfully',
        error: 'An error occurred while updating the Role',
      },
    );
  }

  function defaultRole() {
    const role = modal.user;
    if (!role) return {};

    return {
      value: role.roleId,
      label: role.roleName,
    };
  }

  return (
    <div>
      <Grid className="z-50 pb-3 sm:gap-10">
        <div className="flex flex-col col-span-2 mt-4">
          {userDetails && (
            <>
              <Grid className="item-center">
                <div className="col-span-12">
                  {userDetails.picture && (
                    <img className="object-cover w-8 h-8 rounded-full" src={userDetails.picture} alt="Avatar" />
                  )}
                  {!userDetails.picture && (
                    <UserIcon className="w-8 h-8 text-gray-300" aria-hidden="true" />
                  )}

                  <div>
                    <span className={valueClass}>{userDetails.firstName}</span>
                  </div>
                  <div>
                    <div className="pr-0 mr-1 text-gray-400">
                      {userDetails.email}
                    </div>

                    <div className="col-span-12">
                      <span className={labelClass}>{userDetails.isActive ? 'Active' : 'Inactive'}</span>
                      <div>
                        <Toggle
                          checked={userDetails.isActive}
                          onChange={() => onStatusToggle()}
                          disabled={!(userDetails.id !== me.id)
                            && checkPermission(permissions, PermissionConstants.ADD_MODIFIY_USERS)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-12">
                  <span className={labelClass}>Role</span>
                  <div>
                    <div className="flex items-start justify-between">
                      <span>{modal.user && modal.user.roleApiName}</span>
                      <PencilIcon
                        className={
                          `flex h-4 w-4 ${((userDetails.id !== me.id)
                            && checkPermission(permissions, PermissionConstants.ADD_MODIFIY_USERS))
                            ? 'text-gray-600 cursor-pointer' : 'text-gray-200 cursor-default'}`
                        }
                        onClick={() => handlerChangeUserRoleOpen()}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-12">
                  <span className={labelClass}>Tenant</span>
                  <div className="flex items-start">
                    <span className={valueClass}>
                      {findTenants(userDetails.tenantId)}
                    </span>
                  </div>
                </div>
                <div className="col-span-12">
                  <span className={labelClass}>Inception Date</span>
                  <span className={valueClass}>
                    {formatDateOnly(userDetails.createdAt)}
                    {' '}
                    <span className={valueClassTime}>
                      {formatTimeOnly(userDetails.createdAt)}
                    </span>

                  </span>
                </div>
              </Grid>
            </>
          )}
          {(isLoading) && (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
              <Spinner />
            </div>
          )}
        </div>
        <div className="col-span-10 pl-6">
          <TabsContainer
            tabs={tabs}
            tabsContent={tabsContent}
            selectedTab={selectedTab}
            onSelectedTabChange={setSelectedTab}
          />

          <ChangeRoleConfirmationModel
            {...modal}
            roles={roles}
            name={getName(modal.user)}
            defaultRole={defaultRole()}
            onClose={() => setModal({ ...modal, showModal: false })}
            primaryAction={(args, allOptions) => onChangeUserRole(args, allOptions)}
          />

          {userDetails && (
            <StatusConfirmationModal
              showModal={showActiveStatusModal}
              title={getStatusModalTitle()}
              content={getStatusModalContent()}
              onClose={() => setShowActiveStatusModal(false)}
              primaryAction={() => updateUserStatus(userDetails)}
              primaryText={getStatusModalTitle()}
            />
          )}
        </div>
      </Grid>
    </div>
  );
}

UserDetails.propTypes = {
  userId: PropTypes.number.isRequired,
};
