const StatusOptions = [
  {
    label: 'Pending Review',
    value: 'pending-review-merchant',
  },
  {
    label: 'Blocked',
    value: 'blocked-merchant',
  },
  {
    label: 'Trusted',
    value: 'trusted-merchant',
  },
  {
    label: 'Reviewed',
    value: 'reviewed-merchant',
  },
  {
    label: 'Known',
    value: 'known-merchant',
  },
  {
    label: 'Approved',
    value: 'approved-merchant',
  },
];

const CardStatusOptions = [
  {
    label: 'Active',
    value: 'active-card',
  },
  {
    label: 'Suspended',
    value: 'suspended-card',
  },
  {
    label: 'Replaced',
    value: 'replaced-card',
  },
];


const AuthTypeValues = {
  attackAuth: 'attack-auth',
  approved: 'approved',
  insufficientFunds: 'insufficient-funds',
  transactionsNotPermitted: 'transactions-not-permitted',
  declinedTransactions: 'declined-transactions'
}

const AuthTypeOptions = [
  {
    value: 'attack-auth',
    label: 'Attack auth',
  },
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'insufficient-funds',
    label: 'Insufficient funds'
  },
  {
    value: 'transactions-not-permitted',
    label: 'Transaction not permitted'
  },
  {
    value: 'declined-transactions',
    label: 'Declined transactions'
  }
];

const CardAuthTypeOptions = [
  { value: 'attack-auth', label: 'Attack auths' },
  { value: 'card-present', label: 'Card present' },
  { value: 'card-not-present', label: 'Card not present' },
  { value: 'international-transaction-present', label: 'International transaction present' },
  { value: 'international-transaction-not-present', label: 'International transaction not present' },
];

const timeFilterOptions = [
  {
    label: '12h',
    value: '12h',
  },
  {
    label: '24h',
    value: '24h',
  },
  {
    label: '48h',
    value: '48h',
  },
  {
    label: '7d',
    value: '7d',
  },
];

const timeFilterWithAllOptions = [
  ...timeFilterOptions,
  {
    label: 'All',
    value: 'all',
  },
];

const timeFilterWithCustomOption = [
  {
    label: 'Custom',
    value: 'custom'
  },
  ...timeFilterOptions,
]

const getIsCardPresentFromAuthTypeFilter = (authTypeFilter) => {
  if (authTypeFilter && authTypeFilter.value === 'card-present') {
    return true;
  }

  if (authTypeFilter && authTypeFilter.value === 'card-not-present') {
    return false;
  }

  return undefined;
};

const getIsInternationalTransactionFromAuthTypeFilter = (authTypeFilter) => {
  if (authTypeFilter && authTypeFilter.value === 'international-transaction-present') {
    return true;
  }

  if (authTypeFilter && authTypeFilter.value === 'international-transaction-not-present') {
    return false;
  }

  return undefined;
};

const getRuleIdFromAuthTypeFilter = (authTypeFilter) => {
  if(!authTypeFilter  || authTypeFilter.value === 'attack-auth' || CardAuthTypeOptions.find((option) => option.value === authTypeFilter.value)) {
    return undefined;
  }

  return authTypeFilter.value;
}

const handleIsApproved = (authType) => {
  if (authType === AuthTypeValues.approved){
    return true;
  }
  if (authType === AuthTypeValues.declinedTransactions){
    return false;
  }
  return undefined;
}

const handleResponseCode = (authType) => {
  if (authType === AuthTypeValues.insufficientFunds){
    return '51';
  }
  return undefined;
}

const handleReasonCode = (authType) => {
  if(authType === AuthTypeValues.transactionsNotPermitted){
    return '23700'
  }
  return undefined;
}

export default {
  StatusOptions,
  CardStatusOptions,
  AuthTypeValues,
  AuthTypeOptions,
  CardAuthTypeOptions,
  timeFilterOptions,
  timeFilterWithCustomOption,
  timeFilterWithAllOptions,
  getIsCardPresentFromAuthTypeFilter,
  getIsInternationalTransactionFromAuthTypeFilter,
  getRuleIdFromAuthTypeFilter,
  handleIsApproved,
  handleResponseCode,
  handleReasonCode
};
