import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './forms/Checkbox';
import Tooltip from './Tooltip';

export default function TooltipCheckbox({
  isChecked,
  testId,
  onChange,
  title,
  value,
  listSize,
  merchantsAffectedLabel,
  tooltipId,
  disabled,
  affectedList,
  ...rest
}) {

  const textDisabled = disabled ? 'text-gray-300' : 'text-gray-500';

  return (
    <div className="flex items-center w-full mb-3">
      <Checkbox
        data-cy={testId}
        className="mr-3 cursor-pointer flex items-center"
        disabled={disabled}
        isChecked={isChecked}
        onChange={onChange}
        {...rest}
      >
        <span className={ `text-base leading-6 font-normal ${textDisabled}` }>
          {title}: <span className="font-bold">{value}</span>
        </span>
      </Checkbox>
      <>
        {listSize > 0 && (
          <div className="flex justify-center items-center rounded-xl bg-gray-100 py-1 px-3">
            <button
              type="button"
              className="text-gray-900 text-xs font-medium leading-4"
              data-tip
              data-for={tooltipId}
            >
              {merchantsAffectedLabel}
            </button>
            <Tooltip className="bg-gray-800" position="bottom" id={tooltipId}>
              <ul>
                {affectedList.map((item) => (
                  <li className="text-sm leading-5 font-normal text-white" key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            </Tooltip>
          </div>
        )}
      </>
    </div>
  );
}

TooltipCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  affectedList: PropTypes.arrayOf(PropTypes.string),
  listSize: PropTypes.number,
  merchantsAffectedLabel: PropTypes.string,
  tooltipId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TooltipCheckbox.defaultProps = {
  listSize: 0,
  merchantsAffectedLabel: '',
  testId: '',
  disabled: false,
  affectedList: [],
};
