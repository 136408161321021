import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from '../merchants/Merchant.keys';

const getPDSMerchants = async (client, params) => api.pds.fetchPDSMerchants(client, params);

export default function useGetPDSMerchants(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.pds(params),
    async () => getPDSMerchants(apiClient, params),
  );
}
