import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetStages() {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['filters', 'admin', 'stages'],
    async () => api.admin.tenants.fetchStageFilter(apiClient),
  );
}
