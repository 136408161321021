import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import Reasons from '../Reasons';
import Comment from '../Comment';
import BulkEditMerchantModalTitle from './BulkEditMerchantModalTitle'
import { getReasons, getContent } from './BulkEditMerchantModal.helper';

const otherValue = 'Other';


export default function BulkEditMerchantModal({ toStatus, onClose, action, primaryAction, ...rest }) {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [comment, setComment] = useState('');
  const [showComment, setShowComment] = useState(false);

  const validate = () => {
    if (!reason) {
      return false;
    }

    if (reason === otherValue && !otherReason) {
      return false;
    }

    return !(showComment && !comment);
  };

  const isValid = validate();

  const resetFields = () => {
    setReason('');
    setComment('');
    setOtherReason('');
    setShowComment(false);
  };

  const onCloseAction = () => {
    if (onClose) {
      onClose();
      resetFields();
    }
  };

  const onPrimaryAction = () => {
    const initialObject = {
      reason: reason === otherValue ? otherReason : reason,
      comment,
      action,
    };

    if (primaryAction) {
      primaryAction(initialObject);
      resetFields();
    }
  };

  return (
    <Modal
      primaryAction={onPrimaryAction}
      onClose={onCloseAction}
      primaryEnabled={isValid}
      size="md"
      primaryText="Confirm"
      title={<BulkEditMerchantModalTitle status={toStatus}/>}
      {...rest}
    >
      <div className="mt-2">
        <p className="text-sm text-gray-500 mb-5">{getContent(toStatus)}</p>
        <Reasons
          reasons={getReasons(toStatus, otherValue)}
          reason={reason}
          setReason={setReason}
          otherReason={otherReason}
          setOtherReason={setOtherReason}
          otherValue={otherValue}
        />
        <Comment
          comment={comment}
          showComment={showComment}
          setComment={setComment}
          setShowComment={setShowComment}
        />
      </div>
    </Modal>
  );
}

BulkEditMerchantModal.propTypes = {
  toStatus: PropTypes.string.isRequired,
  primaryAction: PropTypes.func,
  onClose: PropTypes.func,
  action: PropTypes.string,
};

BulkEditMerchantModal.defaultProps = {
  primaryAction: null,
  onClose: null,
  action: null,
};
