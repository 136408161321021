import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ size, fillColor, pathColor }) => (
  <svg
    className={`animate-spin mx-auto h-${size} w-${size} mr-${size} text-blue-600`}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.5 69.1152C55.2019 71.0194 51.561 72.2553 47.7853 72.7524C44.0095 73.2495 40.1728 72.998 36.4943 72.0123C32.8157 71.0267 29.3673 69.3261 26.3459 67.0077C23.3246 64.6894 20.7894 61.7986 18.8853 58.5005C16.9811 55.2024 15.7452 51.5615 15.2481 47.7857C14.751 44.01 15.0025 40.1733 15.9882 36.4947C16.9738 32.8162 18.6744 29.3677 20.9928 26.3464C23.3111 23.325 26.2019 20.7899 29.5 18.8857L31.0943 21.6471C28.1588 23.3419 25.5859 25.5983 23.5224 28.2875C21.459 30.9766 19.9454 34.0459 19.0681 37.32C18.1908 40.5941 17.967 44.0089 18.4094 47.3695C18.8518 50.7301 19.9519 53.9707 21.6467 56.9062C23.3415 59.8417 25.5979 62.4146 28.287 64.4781C30.9762 66.5415 34.0454 68.0551 37.3195 68.9324C40.5936 69.8097 44.0085 70.0335 47.3691 69.5911C50.7297 69.1487 53.9702 68.0486 56.9057 66.3538L58.5 69.1152Z"
      fill={pathColor}
    />
    <path
      d="M22.2843 66.8526C26.6917 71.26 32.3071 74.2615 38.4203 75.4775C44.5336 76.6935 50.8701 76.0694 56.6287 73.6842C62.3873 71.2989 67.3092 67.2596 70.7721 62.077C74.235 56.8944 76.0833 50.8014 76.0833 44.5684C76.0833 38.3353 74.235 32.2423 70.7721 27.0597C67.3092 21.8772 62.3873 17.8378 56.6287 15.4526C50.8701 13.0673 44.5336 12.4432 38.4203 13.6592C32.3071 14.8752 26.6917 17.8767 22.2843 22.2841L27.1875 27.1873C30.6251 23.7497 35.005 21.4086 39.7731 20.4602C44.5413 19.5117 49.4836 19.9985 53.9751 21.8589C58.4666 23.7194 62.3055 26.8699 65.0065 30.9122C67.7074 34.9544 69.1491 39.7068 69.1491 44.5684C69.1491 49.4299 67.7074 54.1823 65.0065 58.2246C62.3055 62.2668 58.4666 65.4173 53.9751 67.2778C49.4836 69.1382 44.5413 69.625 39.7731 68.6766C35.005 67.7281 30.6251 65.3871 27.1875 61.9494L22.2843 66.8526Z"
      fill={fillColor}
    />
  </svg>
);

Spinner.propTypes = {
  size: PropTypes.string,
  fillColor: PropTypes.string,
  pathColor: PropTypes.string,
};

Spinner.defaultProps = {
  size: '20',
  fillColor: '#2563EB',
  pathColor: '#DBEAFE',
};

export default Spinner;
