import React, { useEffect, useState } from 'react';
import FMSelect from '../../components/forms/FMSelect';
import Grid from '../../components/Grid';
import Subtitle from '../../components/headings/Subtitle';
import TabsContainer from '../../components/TabsContainer';
import PermissionConstants from '../../config/PermissionConstants';
import StatusConstants from '../../config/StatusConstants';
import useGetTenantsFilter from '../../hooks/admin/tenants/useGetTenantsFilter';
import useGetMe from '../../hooks/api/users/useGetMe';
import useGetMyPermissions from '../../hooks/api/users/useGetMyPermissions';
import { checkPermission } from '../../utils/userUtil';
import Invitations from './users/Invitations';
import Roles from './users/Roles';
import Tenants from './users/Tenants';
import UsersList from './users/UsersList';

export default function Users() {
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState('');

  const { data: tenants } = useGetTenantsFilter();
  const { data: permissions } = useGetMyPermissions();
  const { data: me } = useGetMe();

  const [selectedTenant, setSelectedTenant] = useState(null);

  const tenantId = selectedTenant?.id || me?.tenantId // if the logged user is a superUser, consider the selectedTenant

  const tabsContent = {
    tenants: () => <Tenants />,
    users: () => <UsersList tenantId={tenantId} />,
    roles: () => <Roles tenantId={tenantId} />,
    invitations: () => <Invitations tenantId={tenantId} />,
  };

  const updateTabs = () => {
    const tabsList = [];
    if (me && me.roleApiName === StatusConstants.adminApiName
      && checkPermission(permissions, PermissionConstants.SUPER_ADMIN)) {
      tabsList.push({
        name: 'Tenants',
        value: 'tenants',
      });
    }
    if (checkPermission(permissions, PermissionConstants.VIEW_USERS)) {
      tabsList.push({
        name: 'Users',
        value: 'users',
      });
    }
    if (checkPermission(permissions, PermissionConstants.VIEW_ROLES)) {
      tabsList.push({
        name: 'Roles',
        value: 'roles',
      });
    }
    // set invitation at last
    tabsList.push({
      name: 'Invitations',
      value: 'invitations',
    });
    setTabs(tabsList);
  };

  useEffect(() => {
    if (!selectedTab && tabs && tabs.length > 0) {
      setSelectedTab(tabs[0].value);
    }
  }, [tabs]);

  useEffect(() => {
    updateTabs();
  }, [permissions]);

  useEffect(() => {
    if(tenants?.length) {
      setSelectedTenant(tenants.find((item) => item.id === tenantId));
    } else {
      setSelectedTenant({});
    }
  }, [tenants]);

  return (
    <Grid>
      <div className="col-span-12">
        <div className="flex justify-between mb-6">
          <Subtitle data-cy="user-management-screen">User Management</Subtitle>
          {(selectedTab !== 'tenants' && checkPermission(permissions, PermissionConstants.SUPER_ADMIN))
            && (
              <div className="flex items-center">
                <span className="mr-2 text-sm text-gray-600">Showing data for:</span>
                <FMSelect
                  className="w-48"
                  size="small"
                  getOptionLabel={(tenant) => tenant.name}
                  getOptionValue={(tenant) => tenant.id}
                  options={tenants}
                  value={selectedTenant}
                  onChange={setSelectedTenant}
                  isSearchable={false}
                />
              </div>
            )}
        </div>
        <TabsContainer
          tabs={tabs}
          tabsContent={tabsContent}
          selectedTab={selectedTab}
          onSelectedTabChange={setSelectedTab}
        />
      </div>
    </Grid>
  );
}
