import React from 'react';

import PropTypes from 'prop-types';
import {
  BanIcon,
  ClockIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  PauseIcon,
  XIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/outline';
import { getName } from '../utils/userUtil';
import { formatDate } from '../utils/date';
import getReason from '../utils/reasons';
import Spinner from './Spinner';
import useUserLogs from '../hooks/admin/UserActivityLog/useUserLogs';
import ActionConstants from '../config/ActionConstants';

const modules = {
  USER_PROFILE: 'user-profile',
  CARD_UPDATE: 'card-update',
  MERCHANT_UPDATE: 'merchant-update',
  USER_INVITATION: 'user-invitation',
};

const createObjectDescription = (prefixLabel, actionLabel) => ({ prefixLabel, actionLabel });

const getMerchantUpdateDescription = (item) => {
  const { relatedObjectName, action } = item;

  const messageBlockedPrefix = `marked the merchant ${relatedObjectName} as `;
  const messageUnblockedPrefix = `unblocked ${relatedObjectName} `;

  const actionMapper = {
    [ActionConstants.UnblockedById]: createObjectDescription(messageUnblockedPrefix, 'by ID'),
    [ActionConstants.UnblockedByName]: createObjectDescription(messageUnblockedPrefix, 'by Name'),
    [ActionConstants.UnblockedByIdAndName]: createObjectDescription(messageUnblockedPrefix, 'by ID and Name'),
    [ActionConstants.BlockedById]: createObjectDescription(messageBlockedPrefix, 'Blocked by ID'),
    [ActionConstants.BlockedByName]: createObjectDescription(messageBlockedPrefix, 'Blocked by Name'),
    [ActionConstants.BlockedByIdAndName]: createObjectDescription(messageBlockedPrefix, 'Blocked by ID and Name'),
    [ActionConstants.Trusted]: createObjectDescription(messageBlockedPrefix, 'Trusted'),
    [ActionConstants.Approved]: createObjectDescription(messageBlockedPrefix, 'Approved'),
    [ActionConstants.Reviewed]: createObjectDescription(messageBlockedPrefix, 'Reviewed'),
  };

  return actionMapper[action] || createObjectDescription('', action);
};

// I just moved all of these outside of the render function
// but all this stuff needs to be refactored
// to use well defined react components
// not functions that act as renderers :(
const getActivityIcon = (item) => {
  const { action } = item;

  const mapper = {
    Resolved: <CheckCircleIcon />,
    Suspended: <PauseIcon />,
    Replaced: <SwitchHorizontalIcon />,
    Remove: <ClockIcon />,
    Active: <CheckCircleIcon />,
    Blocked: <BanIcon />,
    Trusted: <CheckCircleIcon />,
    'Pending review': <ExclamationCircleIcon />,
    Reviewed: <XIcon />,
  }
  return mapper[action] || <CheckCircleIcon />
}

const getActivityName = (item) => getName(item);

const getCardActionName = (item) => {
  const { action } = item;

  const mapper = {
    Remove: 'Removed',
    Suspended: 'Suspended',
    Replaced: 'Replaced',
    Active: 'Re-activated',
  }
  return mapper[action] || action;
}

const isRoleUpdated = (item) => {
  const { action, from, to } = item;
  return !!from && !!to && action === ActionConstants.RoleUpdate;
};

const getRoleUpdatedInfoSection = (item) => {
  const { from, to, relatedObjectName } = item;
  return (
    <>
      updated the role of <span className="font-bold">{relatedObjectName}</span> from{' '}
      <span className="font-bold">{from}</span>
      <span> to </span>
      <span className="font-bold">{to}</span>
    </>
  );
};

const getUserProfileActionData = (item) => {
  if (isRoleUpdated(item)) return getRoleUpdatedInfoSection(item);

  return (
    <>
      performed&nbsp;
      <span className="font-bold">{item.action}</span>
    </>
  );
};

const getCardUpdateData = (item) => (
  <>
    {item.action === ActionConstants.AssignUser ? 'updated the' : 'marked the Card as'}
    &nbsp;
    <span className="font-bold">{getCardActionName(item)}</span>
  </>
);

const getMerchantUpdateData = (item) => {
  const { prefixLabel, actionLabel } = getMerchantUpdateDescription(item);
  return (
    <>
      {prefixLabel}
      <span className="font-bold">{actionLabel}</span>
    </>
  );
};

const getUserInvitationData = (item) => (
  <>
    invited&nbsp;
    {item.metadata ? item.metadata.email : 'someone'}
  </>
)

const getCustomData = (label, value) => (
  <div data-cy="activity-reason" className="mt-1">
    <span className="text-sm">{label}</span>
    <span className="text-sm text-gray-600">{value}</span>
  </div>
);

function getActivityDescription(item) {
  if (item.action) {
    return (
      <>
        {item.module === modules.USER_PROFILE && getUserProfileActionData(item)}
        {item.module === modules.CARD_UPDATE && getCardUpdateData(item)}
        {item.module === modules.MERCHANT_UPDATE && getMerchantUpdateData(item)}
        {item.module === modules.USER_INVITATION && getUserInvitationData(item)}
      </>
    );
  }
  return '';
}

function ActivityLogItem({ item }) {
  return (
    <div data-cy="activity-item" className="relative flex mb-6 -ml-px last:border-l last:border-white">
      <div className="absolute -mt-1.5 -left-4">
        <div className="w-8 h-8 p-1 text-blue-900 rounded-full bg-blue-50">{getActivityIcon(item)}</div>
      </div>
      <div className="flex-1 pb-6 ml-6 border-b border-b-gray-200">
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div data-cy="activity-description" className="text-sm text-gray-900">
              <span data-cy="activity-user-name" className="font-semibold">{getActivityName(item)}</span>
              &nbsp;
              {getActivityDescription(item)}
            </div>
            <span data-cy="activity-time" className="flex-none mb-1 text-xs text-gray-500">{formatDate(item.createdAt)}</span>
          </div>
          {item.reason && getCustomData('Reason: ', getReason(item.reason))}
          {item.note && getCustomData('Comment: ', item.note)}
        </div>
      </div>
    </div>
  );
}

ActivityLogItem.propTypes = {
  item: PropTypes.shape({
    changedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    comment: PropTypes.string,
    reason: PropTypes.string,
    note: PropTypes.string,
    id: PropTypes.number,
    userId: PropTypes.number,
    module: PropTypes.string,
  }).isRequired,
};

export default function UserActivityLog({ userId, className }) {
  const { isLoading, data: logItems } = useUserLogs(userId);

  return (
    <div>
      {
        isLoading
          ? <div className="pl-2 mt-10"><Spinner /></div>
          : <div className={`${className}`}>
            {logItems?.map((item) => (
              <ActivityLogItem item={item} key={item.id} />
            ))}
          </div>
      }
    </div>
  );
}

UserActivityLog.propTypes = {
  userId: PropTypes.number.isRequired,
  className: PropTypes.string,
};

UserActivityLog.defaultProps = {
  className: '',
};
