import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BottomSheet from '../../components/bottomSheet/BottomSheet';
import MinimizedTabs from '../../components/MinimizedTabs';
import { BottomSheetContext } from './useBottomSheet';
import useGroupViewContext from '../groupViewContext/useGroupViewContext';

export default function BottomSheetContextProvider({ children }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const tabsState = useGroupViewContext();

  const handleRemoveTab = (toRemove) => {
    const newTabs = tabs.filter(
      (tab) => toRemove.id !== tab.id || toRemove.type !== tab.type
    );
    setTabs(newTabs);
    tabsState.removeState(`${toRemove.type}-${toRemove.id}`);
    if (selectedTabIndex >= newTabs.length) {
      setSelectedTabIndex(Math.max(0, newTabs.length - 1));
    }
    if (newTabs.length === 0) {
      setIsOpen(false);
    }
  };

  const handleCloseAll = () => {
    setSelectedTabIndex(0);
    setTabs([]);
    setIsOpen(false);
  };

  const handleMinimizeTab = () => {
    setIsOpen(false);
  };

  const handleShowTab = () => {
    setIsOpen(true);
  };

  const addTab = (tab) => {
    const tabExistIndex = tabs.findIndex(
      (item) => item.id === tab.id && item.type === tab.type
    );
    setIsOpen(true);

    if (tabExistIndex >= 0) {
      setSelectedTabIndex(tabExistIndex);
      return;
    }
    const newTabs = [...tabs, tab];
    setTabs(newTabs);
    setSelectedTabIndex(newTabs.length - 1);
  };

  const updateTab = (tab) => {
    const toUpdate = tabs.find(tabItem=> tabItem.id === tab.id && tabItem.type === tab.type)
    if(toUpdate){
      setTabs(tabs.map((tabItem) => tabItem.id !== tab.id || tabItem.type !== tab.type ? tabItem : tab));
    }
    
  };

  return (
    <BottomSheetContext.Provider
      value={{
        tabs,
        addTab,
        updateTab,
        isOpen,
        setIsOpen
      }}
    >
      {children}
      <BottomSheet
        isOpen={isOpen}
        tabs={tabs}
        onRemoveTab={handleRemoveTab}
        onCloseAll={handleCloseAll}
        onMinimize={handleMinimizeTab}
        selectedIndex={selectedTabIndex}
        onChangeSelectedIndex={setSelectedTabIndex}
      />
      <MinimizedTabs tabs={tabs} handleShowTab={handleShowTab} isOpen={isOpen}/>
    </BottomSheetContext.Provider>
  );
}

BottomSheetContextProvider.propTypes = {
  children: PropTypes.node,
};

BottomSheetContextProvider.defaultProps = {
  children: null,
};
