import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getFraudChart = async (client, params) => api.insights.fetchFraudReportChart(client, params);

export default function useGetFraudChart(period) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'insights/fraudChart',
      period,
    ],
    async () => getFraudChart(apiClient, { period }),
  );
}
