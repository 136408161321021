import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowNarrowRightIcon,
  ArrowNarrowLeftIcon,
} from '@heroicons/react/solid';
import FMSelect from '../forms/FMSelect';
import PaginationInfo from './PaginationInfo';
import { classNames } from '../../utils/ui';
import { addFirstLabelIfNeeded, addLastLabelIfNeeded } from './paginationHelper'


const useGetPaginationData = (numPages, currentPage, exhibitionLimit = 10) => {

  const min = Math.max(1, currentPage - exhibitionLimit);
  const max = Math.min(numPages, currentPage + exhibitionLimit);

  let allPages = [];

  for(let i = min; i <= max; i++) {
    allPages.push({
      label: i,
      value: i,
    });
  }

  allPages = addFirstLabelIfNeeded(allPages, currentPage, exhibitionLimit);
  allPages = addLastLabelIfNeeded(allPages, currentPage, exhibitionLimit, numPages);

  return allPages;
};

export default function Pagination({
  totalItems, itemsPerPage, currentPage, onPageClick, className,
}) {
  const numPages = Math.ceil(totalItems / itemsPerPage);
  const allPages = useGetPaginationData(numPages, currentPage);
  return (
    <div className={classNames(
      'flex justify-between items-center mt-2',
      className,
    )}
    >
      <PaginationInfo
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      {numPages > 1
        && (
          <div className="flex justify-end items-center">
            {currentPage > 1
              && (
                <button type="button" className="focus:outline-none text-sm text-gray-500 px-4 inline-flex border-transparent" onClick={() => onPageClick && onPageClick(currentPage - 1)}>
                  <ArrowNarrowLeftIcon className="h-5 w-5" />
                  &nbsp;Prev
                </button>
              )}
            <div className="min-w-3xs max-w-xs">
              <FMSelect
                menuPlacement="top"
                size="small"
                isSearchable={false}
                isClearable={false}
                options={allPages}
                value={allPages.find((item) => item.value === currentPage)}
                onChange={(e) => e && onPageClick(e.value)}
                testSelector="pagination-select"
              />
            </div>
            {currentPage < numPages
              && (
                <button type="button" className="focus:outline-none text-sm text-gray-500 pl-4 inline-flex hover:text-primary-600 border-transparent" onClick={() => onPageClick && onPageClick(currentPage + 1)}>
                  Next&nbsp;
                  <ArrowNarrowRightIcon className="h-5 w-5" />
                </button>
              )}
          </div>
        )}
    </div>
  );
}

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  className: null
};
