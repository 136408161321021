import React from 'react';
import PropTypes from 'prop-types';
import Subtitle from '../headings/Subtitle';
import Modal from '../Modal';
import Spinner from '../Spinner';
import useGetMyPermissions from '../../hooks/api/users/useGetMyPermissions';

export default function ViewUserPermissionsModal({
  param,
  ...rest
}) {
  const title = false;
  const buttonStyle = 'w-full';
  // @TODO I dont think this works
  // the backend endpoint doesn't read these parameters
  const { data: permission, isLoading, isSuccess } = useGetMyPermissions(param, {
    enabled: param && param.roleId && param.tenantId,
  });

  const getPermission = (item) => {
    if (!item.id) return null;
    return (
      <div key={item.id}>
        <span className="p-0 mr-1">
          &bull;
          &nbsp;
          <span className="text-sm text-gray-500">Can </span>
          <span className="text-sm text-gray-600 form-medium">
            {item.name}
          </span>
        </span>
      </div>
    );
  };

  const getContent = () => (
    <div>
      <div className="flex justify-center mt-4 mb-2">
        <Subtitle>Assigned permission(s)</Subtitle>
      </div>
      <span className="text-sm text-gray-600">The following permissions are associated with your role.</span>
      {isLoading && (
        <div className="mt-10">
          <Spinner />
        </div>
      )}
      {isSuccess && (
        <div className="mt-4">
          {permission.map((item) => getPermission(item))}
        </div>
      )}
    </div>
  );
  return (
    <Modal
      {...rest}
      showTitle={title}
      primaryShow={false}
      size="md"
      divider={false}
      secondaryText="Close"
      primaryClassName={buttonStyle}
    >
      {getContent()}
    </Modal>
  );
}

ViewUserPermissionsModal.propTypes = {
  param: PropTypes.shape({
    roleId: PropTypes.number,
    tenantId: PropTypes.number,
  }),
};
ViewUserPermissionsModal.defaultProps = {
  param: {
    roleId: null,
    tenantId: null,
  },
};
