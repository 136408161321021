import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import Button from '../../components/buttons/Button';
import Grid from '../../components/Grid';
import Title from '../../components/headings/Title';
import Panel from '../../components/Panel';
import Spinner from '../../components/Spinner';
import StatusConstants from '../../config/StatusConstants';
import useGetInvitation from '../../hooks/admin/invitations/useGetInvitation';
import useUpdateInvitationPublic from '../../hooks/admin/invitations/useUpdateInvitationPublic';
import { classNames } from '../../utils/ui';

export default function OpenInvitation() {
  const { id } = useParams();
  const getInvitation = useGetInvitation(id);
  const [isDeclined, setIsDeclined] = useState(false);
  const updateInvitation = useUpdateInvitationPublic();

  const handleDeclinePress = () => {
    updateInvitation.mutateAsync({
      id,
      data: {
        statusApiName: StatusConstants.userInvitation.declined,
      },
    }).then(() => setIsDeclined(true));
  };

  const handleNewRequest = () => {
    updateInvitation.mutateAsync({
      id,
      data: {
        isNewRequested: true,
      },
    }).then(() => getInvitation.refetch());
  };

  const isExpired = getInvitation.data && getInvitation.data.isExpired;
  const isNewRequested = getInvitation.data && getInvitation.data.isNewRequested;
  const isValidInvitation = getInvitation.data && !isDeclined && !isNewRequested && !isExpired;

  const renderNewRequested = () => (
    <div className="flex flex-col items-center">
      <CheckCircleIcon className="w-6 mb-2 text-green-600" />
      <h1 className="mb-2 text-2xl font-bold text-gray-900">New invitation requested</h1>
      <p className="mb-5 text-sm text-gray-500">
        The person inviting you has been notified about your request.
      </p>
      <p className="mb-5 text-sm italic text-gray-500">
        (Look out for a new invitation email)
      </p>
    </div>
  );

  const renderValidInvitation = () => (
    <>
      <p className="mb-4 text-sm text-center text-gray-500">
        You have been invited to join
        the Fraud Prevention Squad
        powered by Arden!
      </p>
      <Button buttonStyle="success" className="mb-4" onClick={() => { window.location.href = getInvitation.data.link; }} rounded>Accept</Button>
      <Button buttonStyle="light" onClick={() => handleDeclinePress()} rounded>Decline</Button>
    </>
  );

  const renderExpired = () => (
    <div className="flex flex-col items-center">
      <ExclamationCircleIcon className="w-6 mb-2 text-gray-500" />
      <h1 className="mb-2 text-2xl font-bold text-gray-900">Invitation expired</h1>
      <p className="mb-5 text-sm text-gray-500">(Invitations expire after 48 hours)</p>
      <Button disabled={getInvitation.isLoading} onClick={() => handleNewRequest()} className="mb-3" rounded>Request new invitation</Button>
      <p className="mb-5 text-xs text-center text-gray-500">Person inviting you will receive a notificaiton regaridng your request.</p>
    </div>
  );

  return (
    <Grid className="min-h-full">
      {getInvitation.isLoading && (
        <div className="flex items-center justify-center col-span-12">
          <Spinner />
        </div>
      )}
      {!getInvitation.isLoading && (
        <div className={classNames('col-span-12', (isExpired || getInvitation.isError) && 'flex items-center justify-center')}>
          <div className="flex justify-center">
            <div className="max-w-full w-96">
              {getInvitation.data && !getInvitation.data.isExpired && (
                <div className="col-span-12 pt-5 mb-10">
                  <div className="flex justify-center">
                    <Title>
                      <div>
                        Welcome to Arden
                      </div>
                    </Title>
                  </div>
                </div>
              )}
              <Panel>
                <div className="flex flex-col">
                  {isDeclined && (
                    <p className="text-center">This invitation has been declined</p>
                  )}
                  {isNewRequested && renderNewRequested()}
                  {isValidInvitation && renderValidInvitation()}
                  {!isNewRequested && isExpired && renderExpired()}
                  {getInvitation.isError && (
                    <div className="flex flex-col items-center">
                      <p className="text-center">This invitation is not valid anymore.</p>
                    </div>
                  )}
                </div>
              </Panel>
            </div>
          </div>
        </div>
      )}
    </Grid>
  );
}
