import DateColumn from '../../../components/table/columns/DateColumn';
import DebugColumn from '../../../components/table/columns/DebugColumn';
import MerchantStatusColumn from '../../../components/table/columns/MerchantStatusColumn';
import TwoRowsColumn from '../../../components/table/columns/TwoRowsColumn';
import getUserNameColumn from '../../../components/table/columns/UserNameColumn';
import { MERCHANT_OPEN_MERCHANT } from '../../../components/table/MerchantsTable';
import numberUtils from '../../../utils/numberUtils';

const addedOnColumn = DateColumn('createdAt', 'Added On', 'text-left', false);

const ruleColumn = {
  field: 'rule',
  headerName: 'Rule Name',
  valueGetter: (field) => field.name,
};

const mccColumn = {
  field: 'mcc',
  headerName: 'MCC',
};

const authsColumn = {
  field: 'auths',
  headerName: 'Total Auths',
  headerClassName: 'text-right',
  className: 'text-right',
  valueGetter: (field) => numberUtils.numberFormat(field),
};

const ruleStatusColumn = {
  render: MerchantStatusColumn,
  field: 'onCreateMerchantStatus',
  headerName: 'Rule Status',
  valueGetter: (field) => field && field.apiName,
};

const reviewedByColumn = getUserNameColumn('Added/Last modified By');

const reviewedStatusColumn = {
  render: MerchantStatusColumn,
  valueGetter2: () => 'user',
  field: 'onReviewMerchantStatus',
  headerName: 'Reviewed Status',
  valueGetter: (field) => field && field.apiName,
};

const merchantTenantColumns = [
  addedOnColumn,
  ruleColumn,
  {
    field: 'merchantTenant',
    field2: 'merchantTenant',
    headerName: 'Merchant Details',
    valueGetter: (valueField) => valueField.merchant.name,
    valueGetter2: (valueField) => `ID: ${valueField.merchant.number}`,
    render: TwoRowsColumn,
    actionName: MERCHANT_OPEN_MERCHANT,
  },
  {
    key: 'mcc',
    headerName: 'MCC',
    field: 'merchantTenant',
    valueGetter: (valueField) => valueField.merchant.mcc,
  },
  authsColumn,
  ruleStatusColumn,
  reviewedByColumn,
  reviewedStatusColumn,
];

const cardColumns = [
  addedOnColumn,
  ruleColumn,
  {
    field: 'lastFour',
    headerName: 'Card Number',
    valueGetter: (valueField) => `* ${valueField}`,
  },
  mccColumn,
  authsColumn,
  {
    field: 'addedUpdatedFields',
    headerName: 'Rule Updated Fields',
    render: DebugColumn,
  },
  reviewedByColumn,
  {
    field: 'reviewedUpdatedFields',
    headerName: 'Reviewed Updated Fields',
    render: DebugColumn,
  },
];

export default {
  merchantTenantColumns,
  cardColumns,
};
