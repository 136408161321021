import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../utils/ui';
import { InformationCircleIcon } from '@heroicons/react/outline';

const icons = {
  info: InformationCircleIcon,
};

export default function LabelCustom({ icon, message, className, testId }) {
  const Icon = icons[icon];

  return (
    <div data-cy={testId} className={classNames('flex-1 h-6 px-2 py-1 rounded-xl max-w-full', className)}>
      <span className="text-sm leading-4 text-gray-500 flex max-w-full w-auto">
        <Icon className="h-4 w-6 font-medium" aria-hidden="true" />
        &nbsp;<span data-cy="label-text" className="truncate">{message}</span>
      </span>
    </div>
  );
}

LabelCustom.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.oneOf(['info']),
  testId: PropTypes.string,
};

LabelCustom.defaultProps = {
  className: null,
  icon: 'info',
  testId: '',
};
