import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { PlayIcon, XIcon, PauseIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import DataTable, { TABLE_SORT_ACTION } from './DataTable';
import CardConfirmationModal from '../modals/CardConfirmationModal';
import {
  selectCard, setAuthType, setShowSelectedCardTransactions,
} from '../../store/features/card/cardSlice';
import CheckboxColumn from './columns/CheckboxColumn';
import Button from '../buttons/Button';
import StatusConstants from '../../config/StatusConstants';
import BulkEditCardModal from '../modals/BulkEditCardModal';
import FMSelect from '../forms/FMSelect';
import useGetTenantUsers from '../../hooks/api/users/useGetTenantUsers';
import { getName } from '../../utils/userUtil';
import useBottomSheetContext from '../../hooks/bottomSheet/useBottomSheet';
import numberUtils from '../../utils/numberUtils';
import useAssignCard from '../../hooks/api/cards/useAssignCard';
import useCardActions from '../../hooks/actions/useCardActions';
import useBulkUpdateCardStatus from '../../hooks/api/cards/useBulkUpdateCardStatus';
import useBulkResolveCard from '../../hooks/api/cards/useBulkResolveCard';
import useGetMe from '../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import CheckboxHeader from './CheckboxHeader';
import BulkActionsButton from './BulkActionsButton';
import { classNames } from '../../utils/ui';
import BulkActions from './BulkActions';
import { handlePlural } from './DataTableUtils';
import { toastBulkAction} from "../Toast/Toast";
import useTrackEvent from '../appInsights/useTrackEvent';
import useBulkReactivateCard from '../../hooks/api/cards/useBulkReactivateCard';
import { CardActions } from 'src/config/CardConstants';


export const CARD_UPDATE_STATUS_ACTION = 'card-update-status';
export const CARD_ASSIGN_BULK_ACTION = 'card-assign-bulk-action';
export const CARD_OPEN_TRANSACTIONS = 'card-open-transactions';
export const CARD_OPEN_COMPROMISED_TRANSACTIONS =
  'card-open-compromised-transactions';
export const CARD_ASSIGN_USER = 'card-assign-user';
export const CARD_OPEN_DETAILS = 'card-open-details';
export const CARD_CHECK_ACTION = 'card-check-action';
export const CARD_BULK_ACTION = 'card-bulk-action';
export const CARD_RESOLVE_BULK_ACTION = 'card-resolve-bulk-action';
export const COMPROMISED_AUTH = 'compromised';
export const TOTAL_AUTH = 'total';

export default function CardsTable({ data, onAction, columns, ...rest }) {
  const { onActionSuccess } = rest;
  const [modal, setModal] = useState({ show: false, card: {} });
  const [checked, setChecked] = useState([]);
  const [showBulkModal, setShowBulkModal] = useState({
    showModal: false,
    toStatus: StatusConstants.replacedCardApiName,
    ids: [],
    action: ''
  });
  const [userAssign, setUserAssign] = useState(null);

  const { data: userData } = useGetTenantUsers({
    itemsPerPage: Number.MAX_SAFE_INTEGER,
  });
  const tenantUsers = userData ? userData.rows : [];
  const dispatch = useDispatch();
  const assignCard = useAssignCard();
  const bottomSheet = useBottomSheetContext();
  const deepLink = useParams();

  const { data: me } = useGetMe();
  const isCardBulkActionsEnable = me && me.featureFlags[FeatureFlagConstants.useCardBulkActions];
  const trackCardAction = useTrackEvent('Card Table Action');

  const allChecked = checked.length > 0 && checked.length === data.length;
  const isCheckboxChecked = checked.length > 0;
  const checkColumnClassName = !allChecked && isCheckboxChecked ? 'partial' : '';

  const bulkUpdateCardStatus = useBulkUpdateCardStatus();
  const bulkResolveCard = useBulkResolveCard();
  const bulkReactivateCard = useBulkReactivateCard();

  const cardActions = useCardActions();

  const activeCards = data
    .filter(
      (card) =>
        (checked.find((id) => id === card.id) &&
        card.statusApiName === StatusConstants.activeCardApiName)
    )
    .map((card) => card.id);

  const toReactivate = data
    .filter(
      (card) =>
        checked.find((id) => id === card.id) &&
        (card.statusApiName === StatusConstants.suspendCardApiName ||
        card.statusApiName === StatusConstants.pFraudCardApiName)
    )
    .map((card) => card.id);

    const toResolve = data
    .filter((card) => checked.find((id) => id === card.id) &&
      card.statusApiName === StatusConstants.activeCardApiName && card.isCompromised)
    .map((card) => card.id);

  const userOptions = [
    ...tenantUsers.map((userTenant) => ({
      value: userTenant.id,
      label: getName(userTenant),
    })),
  ];

  const closeConfirmationModal = () => {
    if (modal.show) {
      setModal({ ...modal, show: false });
    }
  };

  const showConfirmationModal = (args) => {
    const [action, type, card] = args;
    if (card && !modal.show) {
      setModal({
        show: true,
        action,
        card,
        oldStatus: card.statusApiName,
        newStatus: type,
      });
    }
  };

  const updateCardAssignAction = (userId, card) => {
    toast.promise(
      assignCard.mutateAsync({
        id: card.id,
        data: {
          oldUserId: card.userId,
          userId,
        },
      }),
      {
        loading: `Updating card ${card.lastFour}`,
        success: `Card ${card.lastFour} updated successfully`,
        error: `An error occurred while updating Card * ${card.lastFour}`,
      }
    );
  };

  useEffect(() => {
    setChecked([]);
  }, [data]);

  const unsuspendCardsBulkStatus = (args) => {
    const {ids, status, comment, reason, action} = args;
    toastBulkAction(
      bulkReactivateCard.mutateAsync({
        ids,
        statusApiName: status,
        reason,
        comment,
        action
      },{
        onSuccess: () => {
          if(onActionSuccess)
            onActionSuccess()
        }
      }),
      {
        loading: 'Updating cards...',
      },
      status
    );
  };

  const resolveCardsBulkStatus = (args) => {
    const {ids, status, comment, reason, action} = args;

    toastBulkAction(
      bulkResolveCard.mutateAsync({
        ids,
        statusApiName: status,
        reason,
        comment,
        action
      },{
        onSuccess: () => {
          if(onActionSuccess)
            onActionSuccess()
        }
      }),
      {
        loading: 'Updating cards...',
      },
      status
    );
  };

  const updateCardsBulkStatus = (card) => {
    const { ids, status, reason, comment } = card;

    toastBulkAction(
      bulkUpdateCardStatus.mutateAsync({
        ids,
        statusApiName: status,
        reason,
        comment
      },{
        onSuccess: () => {
          if(onActionSuccess)
            onActionSuccess()
        }
      }),
      {
        loading: 'Updating cards...'
      },
      status
    );
  };

  const onTableAction = (action, args) => {
    if (action !== CARD_UPDATE_STATUS_ACTION) {
      trackCardAction({
        action,
        ...args
      });
    }
    switch (action) {
      case CARD_CHECK_ACTION:
        if (checked.includes(args.id)) {
          setChecked(checked.filter((id) => id !== args.id));
        } else {
          setChecked([...checked, args.id]);
        }
        break;
      case CARD_UPDATE_STATUS_ACTION:
        showConfirmationModal(args);
        break;
      case TABLE_SORT_ACTION:
        onAction(action, args);
        break;
      case CARD_ASSIGN_USER:
        if (args && args.card) {
          updateCardAssignAction(args.userId, args.card);
        }
        break;
      case CARD_OPEN_COMPROMISED_TRANSACTIONS:
        dispatch(setAuthType(COMPROMISED_AUTH));
        dispatch(selectCard(args));
        dispatch(setShowSelectedCardTransactions(true));
        break;
      case CARD_OPEN_TRANSACTIONS:
        dispatch(setAuthType(TOTAL_AUTH));
        dispatch(selectCard(args));
        dispatch(setShowSelectedCardTransactions(true));
        break;
      case CARD_OPEN_DETAILS:
        bottomSheet.addTab({
          id: args.id,
          title: `* ${args.lastFour}`,
          subtitle: `Balance: ${numberUtils.currencyFormat(args.authBalance)}`,
          type: 'card',
        });
        break;
      case CARD_BULK_ACTION:
        switch (args.action) {
          case CardActions.Reactivate:
            unsuspendCardsBulkStatus(args);
            break;
          case CardActions.Resolve:
            resolveCardsBulkStatus(args);
            break;
          default:
            updateCardsBulkStatus(args);
            break;
        }
        setShowBulkModal({ ...showBulkModal, showModal: false });
        setChecked([]);
        setUserAssign(null);
        break;
      default:
        break;
    }
  };

  const onModalPrimaryAction = (args) => {
    const { action, card, newStatus } = modal;
    closeConfirmationModal();
    const newCard = {
      ...args,
      statusApiName: newStatus,
    }

    cardActions(action, card.id, card.lastFour, newCard, onActionSuccess);

    trackCardAction({
      action: CARD_UPDATE_STATUS_ACTION,
      ...modal.card,
      status: newStatus
    });
  };
  const toggleAll = () => {
    if (allChecked) {
      setChecked([]);
    } else {
      setChecked(data.map((row) => row.id));
    }
  };
  const checkColumn = {
    field: 'id',
    actionName: CARD_CHECK_ACTION,
    headerRender: (column, onHeaderAction) => (
      <CheckboxHeader
        className={classNames(checkColumnClassName, 'rounded-sm')}
        checked={allChecked || isCheckboxChecked}
        onChange={() => onHeaderAction(CARD_CHECK_ACTION, column)}
      />
    ),
    render: (props) =>
      CheckboxColumn({
        ...props,
        // eslint-disable-next-line react/prop-types
        checked: !!checked.find((checkedId) => props.row.id === checkedId),
        checkBoxClassName: 'rounded-sm'
      }),
  };
  const onTableHeaderAction = (action) => {
    switch (action) {
      case CARD_CHECK_ACTION:
        toggleAll();
        break;
      default:
        break;
    }
  };

  const getTableColumn = () => {
    if(isCardBulkActionsEnable) {
      return [checkColumn, ...columns];
    }
    return [...columns];
  };

  useEffect(() => {
    if(!deepLink.id) return
    bottomSheet.addTab({
      id: deepLink.id,
      title: `* ${deepLink.lastFour}`,
      subtitle: `Balance: ${numberUtils.currencyFormat(deepLink.balance)}`,
      type: 'card',
    });
  }, []);

  return (
    <>
      <DataTable
        data={data}
        columns={getTableColumn()}
        {...rest}
        onAction={onTableAction}
        onHeaderAction={onTableHeaderAction}
      />
      <CardConfirmationModal
        showModal={modal.show}
        action={modal.action}
        newStatus={modal.newStatus}
        isCompromised={modal.isCompromised}
        oldStatus={modal.oldStatus}
        onClose={closeConfirmationModal}
        primaryAction={onModalPrimaryAction}
      />
      {isCardBulkActionsEnable && (
        <>
          <BulkActions isCheckboxChecked={isCheckboxChecked}>
            {activeCards.length > 0 && (
              <>
              <BulkActionsButton
                className='w-44'
                bulkActionTitle="Suspend"
                  bulkSize={activeCards.length}
                onClick={() =>
                  setShowBulkModal({ showModal: true, action: CardActions.UpdateStatus, toStatus: StatusConstants.suspendCardApiName, ids: activeCards })
                }
                icon={<PauseIcon className="w-4 h-4 mr-3 text-yellow-600" aria-hidden="true" />}
                  bulkType={`${handlePlural(activeCards.length, 'card')}`}
                />
              <BulkActionsButton
                className="w-56"
                bulkActionTitle="Mark"
                  bulkSize={activeCards.length}
                  onClick={() => setShowBulkModal({ showModal: true, action: CardActions.UpdateStatus, toStatus: StatusConstants.pFraudCardApiName, ids: activeCards })}
                icon={<PauseIcon className="w-4 h-4 mr-3 text-yellow-600" aria-hidden="true" />}
                  bulkType={`${handlePlural(activeCards.length, 'card')} as PFRAUD`}
              />
              </>
            )}
            {toReactivate.length > 0 && (
              <BulkActionsButton
                bulkActionTitle="Reactivate"
                bulkSize={toReactivate.length}
                onClick={() =>
                  setShowBulkModal({ showModal: true, action: CardActions.Reactivate, ids: toReactivate })
                }
                className="w-48"
                icon={<PlayIcon className="w-4 h-4 mr-3 text-green-600" aria-hidden="true" />}
                bulkType={`${handlePlural(toReactivate.length, 'card')}`}
                testId="reactivate-bulk-button"
              />
            )}
            {toResolve.length > 0 && (
              <BulkActionsButton
                className="w-72"
                bulkActionTitle="Mark"
                bulkSize={toResolve.length}
                onClick={() => setShowBulkModal({ showModal: true, action: CardActions.Resolve, toStatus: StatusConstants.resolveCard, ids: toResolve })}
                icon={<XIcon className="w-4 h-4 mr-3 text-gray-600" aria-hidden="true" />}
                bulkType={`${handlePlural(toResolve.length, 'card')} as Not compromised`}
              />
            )}
            <div className="flex justify-center items-center gap-3">
              <span>|</span>
              <FMSelect
                isSearchable={false}
                isClearable={false}
                size="small"
                placeholder="Select user to assign"
                value={userAssign}
                options={userOptions}
                menuPlacement="top"
                onChange={(e) => {
                  setUserAssign(e);
                }}
                className="w-52"
              />
              <Button
                buttonSize="sm"
                buttonStyle="light"
                onClick={() =>
                  setShowBulkModal({
                    userAssign,
                    showModal: true,
                    toStatus: 'card-assign-bulk-action',
                  })
                }
              >
                Assign
              </Button>
            </div>
          </BulkActions>

          <BulkEditCardModal
            {...showBulkModal}
            onClose={() => setShowBulkModal({ ...showBulkModal, showModal: false })}
            primaryAction={(args) => 
              onTableAction(CARD_BULK_ACTION, {
                ids: showBulkModal.ids ?? checked,
                status: showBulkModal.toStatus,
                action: showBulkModal.action,
                ...args
              })
            }
          />
        </>
      )}
  </>
  );
}

CardsTable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  data: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  onAction: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

CardsTable.defaultProps = {
  children: null,
  onAction: null,
  columns: [],
};
