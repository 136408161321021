/* eslint-disable no-unused-vars */
import React from 'react';

export const BottomSheetContext = React.createContext({
  tabs: [],
  addTab: () => { },
  updateTab: () => { },
  isOpen: false,
  setIsOpen: () => { },
  state: () => { },
  setState: () => { },

});

export default function useBottomSheetContext() {
  return React.useContext(BottomSheetContext);
}
