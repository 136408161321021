/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'admin',
  initialState: {
    selectedTenant: null,
    showSelectedTenant: false,
    tenantUpdatedOutside: false,
    selectedUser: null,
    showSelectedUser: false,
  },
  reducers: {
    setShowSelectedTenant: (state, action) => {
      state.showSelectedTenant = action.payload;
    },
    setTenantUpdatedOutside: (state, action) => {
      state.tenantUpdatedOutside = action.payload;
    },
    selectTenant: (state, action) => {
      state.selectedTenant = action.payload;
    },
    setShowSelectedUser: (state, action) => {
      state.showSelectedUser = action.payload;
    },
    selectUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  selectCard,
  setShowSelectedCardTransactions,
  setShowSelectedCard,
  setShowSelectedTenant,
  selectTenant,
  setShowSelectedUser,
  selectUser,
  setTenantUpdatedOutside,
} = slice.actions;

export default slice.reducer;
