import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

// Create root level element for react-tooltips
const domNode = document.createElement('div');
document.body.appendChild(domNode);

// Wrapper component to portal react-tooltips
function BodyPortal({ children }) {
  return ReactDOM.createPortal(
    children,
    domNode,
  );
}

export default function Tooltip({ id, children, className,position }) {
  return (
    <BodyPortal>
      <ReactTooltip
        id={id}
        place={position}
        effect="solid"
        backgroundColor="#1F2937" // bg-gray/800
        textColor="#FFFFFF" // text-white
        className={className}
      >
        {children}
      </ReactTooltip>
    </BodyPortal>
  );
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  position: PropTypes.string
};

Tooltip.defaultProps = {
  className: null,
  position: 'top'
};
