const fetchMe = async (client, params) => client.get('api/users/me', {
  params,
})
  .then((response) => response.data);

// @TODO move to react hook, also this is a bit confussing
// with no params it should return the current user permissions
// and it should also accept a userId param? instead of tenantId roleId
// I couldn't figure this out
const fetchMyPermissions = async (client, params) => client.get('api/users/permissions', {
  params,
})
  .then((response) => response.data);

const logSuccessfulLogin = async (client) => client.post('api/users/login');

export default {
  fetchMe,
  fetchMyPermissions,
  logSuccessfulLogin,
};
