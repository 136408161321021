import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../components/Tooltip';
import stringUtils from '../../utils/stringUtils';
import { ReactComponent as QuestionCircle } from '../../assets/icons/question-circle.svg';
import numberUtils from '../../utils/numberUtils';

function renderMetricLabel(metric) {
  switch (metric.id) {
    case 'suspiciousThreats':
      return 'Suspicious';
    case 'autoBlockedThreats':
      return 'Auto-blocked';
    case 'cardsBalanceLowRisk':
      return 'Low risk';
    case 'cardsBalanceMediumRisk':
      return 'Medium risk';
    case 'cardsBalanceHighRisk':
      return 'High risk';
    default:
      return '';
  }
}

function renderMetricTooltip(metric) {
  switch (metric.id) {
    case 'suspiciousThreats':
      return 'Merchant threats which are not blocked but are required to review';
    case 'autoBlockedThreats':
      return 'Auto-Blocked Merchant threats which are required to review';
    default:
      return '';
  }
}

export default function HomeMetric({
  metric,
  firstLabel,
  secondLabel,
}) {
  return (
    <div
      key={metric.id}
      className="items-start relative bg-gray-50 py-4 px-5 border-gray-200 border rounded-sm grid grid-cols-3 font-semibold"
    >
      <div className="flex items-start">
        <p className="text-gray-900">{renderMetricLabel(metric)}</p>
        <button data-tip data-for={`metric-tooltip-${metric.id}`} type="button">
          <QuestionCircle className="inline-block w-5 ml-0.5 text-gray-500" />
        </button>
        <Tooltip className="max-w-2xs" id={`metric-tooltip-${metric.id}`}>{renderMetricTooltip(metric)}</Tooltip>
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-medium text-gray-600 mb-1">{firstLabel}</span>
        <span className="text-sm font-medium text-gray-600">{secondLabel}</span>
      </div>
      <div className="flex flex-col items-end">
        <span className="font-bold text-gray-900">{numberUtils.numberFormat(metric.value.count)}</span>
        <span className="font-bold text-gray-900">{stringUtils.currencyFormatNormal(metric.value.amount)}</span>
      </div>
    </div>
  );
}

HomeMetric.propTypes = {
  firstLabel: PropTypes.string.isRequired,
  secondLabel: PropTypes.string.isRequired,
  metric: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({
      count: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
