import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import MerchantsTable, {
  MERCHANT_OPEN_CARDS,
  MERCHANT_OPEN_MERCHANT,
  MERCHANT_UPDATE_STATUS_ACTION,
  MERCHANT_BULK_ACTION_DONE,
} from '../../components/table/MerchantsTable';
import useGetPDSMerchants from '../../hooks/api/pds/useGetPDSMerchants';
import PaginationConfig from '../../config/PaginationConfig';
import TwoRowsColumn from '../../components/table/columns/TwoRowsColumn';
import numberUtils from '../../utils/numberUtils';
import MerchantStatusColumn from '../../components/table/columns/MerchantStatusColumn';
import MerchantActionsColumn from '../../components/table/columns/MerchantActionsColumn';
import { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../../components/table/DataTable';
import { setMerchantCardFilters } from '../../store/features/merchant/merchantSlice';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import useGetMe from '../../hooks/api/users/useGetMe';
import MccDescriptionColumn from '../../components/table/columns/MccDescriptionColumn';
import MerchantKeys from '../../hooks/api/merchants/Merchant.keys';

const columns = [
  {
    field: 'name',
    field2: 'number',
    headerName: 'Merchant Details',
    valueGetter2: (valueField) => `ID: ${valueField}`,
    linkGetter: (row) => `merchants/${row.id}`,
    render: TwoRowsColumn,
    actionName: MERCHANT_OPEN_MERCHANT,
    useLink: true,
  },
  {
    field: 'mcc',
    headerName: 'MCC',
    sortable: true,
    render: (props) => (<MccDescriptionColumn row={props.row}/>),
  },
  {
    field: 'amount',
    headerName: 'Total Amount',
    headerClassName: 'justify-end',
    className: 'text-right',
    valueGetter: (field) => numberUtils.currencyFormat(field),
    sortable: true,
  },
  {
    field: 'auths',
    headerName: 'Total Auths',
    headerClassName: 'justify-end',
    className: 'text-right',
    valueGetter: (field) => numberUtils.numberFormat(field),
    sortable: true,
  },
  {
    field: 'cards',
    headerName: 'Total Cards',
    headerClassName: 'justify-end',
    className: 'text-right pr-10',
    valueGetter: (field) => numberUtils.numberFormat(field),
    sortable: true,
  },
  {
    field: 'statusApiName',
    field2: 'changedByType',
    headerName: 'Status',
    render: MerchantStatusColumn,
  },
  {
    key: 'actions',
    render: MerchantActionsColumn,
  },
];

export default function PDSMerchants({
  mcc,
  status,
  timeSelected,
  excludeMcc,
  search,
  authCount,
  refreshTick,
  reasonCode,
  startDate,
  endDate
}) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [order, setOrder] = useState('id');
  const [orderDir, setOrderDir] = useState(TABLE_SORT_DESC);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data: me } = useGetMe();
  const showPaging = me && !me.featureFlags[FeatureFlagConstants.isPerformancePOC];
  const selectedPeriod = timeSelected !== 'custom' ? timeSelected : '30min'
  const request = {
    mcc,
    excludeMcc,
    statusApiName: status,
    page,
    itemsPerPage,
    timeSelected: startDate || selectedPeriod,
    endDate: endDate || undefined,
    search,
    authCount,
    order,
    orderDir,
    reasonCode,
  }

  const { isLoading, data } = useGetPDSMerchants(request);
  const onActionSuccess = () => queryClient.invalidateQueries(MerchantKeys.pds(request));

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const changeSort = (field) => {
    if (field !== order) {
      setOrder(field);
      setOrderDir(TABLE_SORT_DESC);
      return;
    }

    setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onTableAction = (action, args) => {
    switch (action) {
      case TABLE_SORT_ACTION:
        changeSort(args.field);
        break;
      case MERCHANT_UPDATE_STATUS_ACTION:
      case MERCHANT_BULK_ACTION_DONE:
        onActionSuccess();
        break;
      case MERCHANT_OPEN_CARDS:
        dispatch(setMerchantCardFilters({ timeSelected }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (refreshTick > 0) {
      onActionSuccess();
    }
  }, [refreshTick]);

  return (
    <>
      <MerchantsTable
        columns={columns}
        data={data ? data.rows : []}
        loading={isLoading}
        paginationProps={{
          totalItems: data && data.count ? data.count : 0,
          currentPage: page,
          itemsPerPage,
          onPageClick,
          onItemsPerPageChange: (e) => {
            resetPage();
            setItemsPerPage(e);
          },
          optionsAdd: !showPaging ? [{ value: 1000, label: 1000 }] : [],
          hideInfo: !showPaging,
          className: !reasonCode && 'px-5',
        }}
        onAction={onTableAction}
        sort={order}
        sortDirection={orderDir}
        onActionSuccess={onActionSuccess}
      />
    </>
  );
}

PDSMerchants.propTypes = {
  mcc: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  timeSelected: PropTypes.string.isRequired,
  excludeMcc: PropTypes.bool,
  authCount: PropTypes.string,
  search: PropTypes.string,
  refreshTick: PropTypes.number,
  reasonCode: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

PDSMerchants.defaultProps = {
  mcc: [],
  status: null,
  excludeMcc: false,
  authCount: null,
  search: null,
  refreshTick: 0,
  reasonCode: null,
  startDate:null,
  endDate:null
};
