import OptionDto from 'src/api/dto/OptionDto';
import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetActiveCardAttacksFilter(cardId: number) {
  const apiClient = useApiClient();
  return useLoggedInQuery<OptionDto[]>(['cards', 'active-card-attacks-filter', cardId], () =>
    api.transactions.fetchActiveCardAttacksFilter(apiClient, cardId)
  );
}
