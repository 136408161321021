import { ExclamationCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

export const ROLE_ACTION_CANCEL = 'role-action-cancel';
export const ROLE_ACTION_SAVE = 'role-action-save';
export const ROLE_ACTION_RESTORE = 'role-action-reset';

export default function RoleConfirmationModel({
  action, ...rest
}) {
  const props = {
    size: 'md',
  };
  let content = '';
  switch (action) {
    case ROLE_ACTION_RESTORE:
      props.title = (
        <div className="flex items-center">
          <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
            <ExclamationCircleIcon className="w-5 text-blue-900" />
          </div>
          Role Permissions Restore
        </div>
      );
      content = 'Theh {Role} access will be updated to default permissions.';
      break;
    case ROLE_ACTION_SAVE:
      props.title = (
        <div className="flex items-center">
          <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
            <ExclamationCircleIcon className="w-5 text-blue-900" />
          </div>
          Role Permissions Update
        </div>
      );
      content = 'The {Role} access will be updated based on the below selection.';
      break;
    default:
      props.title = (
        <div className="flex items-center">
          <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
            <ExclamationCircleIcon className="w-5 text-blue-900" />
          </div>
          Role Permissions Cancel
        </div>
      );
      content = 'Cancel the all selected permissions';
      break;
  }
  return (
    <Modal
      {...rest}
      {...props}
      primaryText="Confirm"
      divider={false}
    >
      <p className="text-left text-gray-500 ml-10 pr-1">{content}</p>
    </Modal>
  );
}

RoleConfirmationModel.propTypes = {
  action: PropTypes.string,
};
RoleConfirmationModel.defaultProps = {
  action: '',
};
