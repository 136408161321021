import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowSmDownIcon, ArrowSmUpIcon, BanIcon, ClockIcon, CreditCardIcon,
  CurrencyDollarIcon, ExclamationCircleIcon, ShieldCheckIcon,
} from '@heroicons/react/outline';
import StatusConstants from '../../config/StatusConstants';
import numberUtils from '../../utils/numberUtils';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import { ReactComponent as QuestionCircle } from '../../assets/icons/question-circle.svg';
import Tooltip from '../Tooltip';
import TextButton from '../buttons/TextButton';

const getTitle = (item) => {
  switch (item.id) {
    case 'merchantCount':
      switch (item.statusApiName) {
        case StatusConstants.pendingReviewApiName:
          return 'Suspicious merchants';
        case StatusConstants.blockedApiName:
          return 'Merchants blocked';
        default:
          return '';
      }
    case 'transactionsAmount':
      switch (item.statusApiName) {
        case StatusConstants.blockedApiName:
          return 'Auth Protected';
        default:
          return '';
      }
    case 'compromisedCards':
      return 'Cards compromised';
    case 'balanceAtRisk':
      return 'Balance at risk';
    case 'balanceProtected':
      return 'Balance protected';
    case 'blockedTransactions':
      return 'Auth Protected';
    case 'averageApproved':
      return 'Avg: # auths to get 1 approval';
    case 'transactionsTotal':
      return 'Total auths';
    case 'threatsIdentified':
      return 'Threats Identified';
    case 'threatsBlocked':
      return 'Threats Blocked';
    case 'falseThreats':
      return 'False Threats';
    case 'manualBlocked':
      return 'Manual Blocked';
    default:
      return 'Title not set';
  }
};

const getIcon = (item) => {
  switch (item.id) {
    case 'merchantCount':
      switch (item.statusApiName) {
        case StatusConstants.pendingReviewApiName:
          return ExclamationCircleIcon;
        case StatusConstants.blockedApiName:
          return BanIcon;
        default:
          return ExclamationCircleIcon;
      }
    case 'compromisedCards':
      return CreditCardIcon;
    case 'balanceAtRisk':
    case 'transactionsAmount':
    case 'balanceProtected':
      return CurrencyDollarIcon;
    case 'blockedTransactions':
      return ShieldCheckIcon;
    case 'averageApproved':
      return ClockIcon;
    case 'transactionsTotal':
      return ShieldIcon;
    default:
      return null;
  }
};

const getTooltip = (item) => {
  switch (item.id) {
    case 'transactionsTotal':
      return 'Total # of auths processed by ARDEN.';
    case 'merchantCount':
      return 'Total # of merchants presently blocked in ARDEN.';
    case 'blockedTransactions':
      return 'Total Auth dollar protected by ARDEN.';
    case 'balanceProtected':
      return 'Total Card Balance protected by ARDEN.';
    case 'transactionsAmount':
      return 'Total Auth dollar protected by ARDEN.';
    default:
      return null;
  }
};

const getPercentageColor = (item) => {
  let percentColor = 'text-indigo-600';
  let moreIsBetter = 1;

  switch (item.id) {
    case 'balanceAtRisk':
      moreIsBetter = -1;
      break;
    default:
      break;
  }

  if (moreIsBetter * item.percentage < 0) {
    percentColor = 'text-yellow-600';
  }
  if (moreIsBetter * item.percentage > 0) {
    percentColor = 'text-green-600';
  }

  return percentColor;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatPercentage = (percentage) => {
  const value = percentage > 0
    ? percentage.toFixed(2) : percentage;

  return `${value}%`;
};

const getValueFormatted = (item) => {
  switch (item.format) {
    case 'currency':
      return currencyFormatter.format(item.value);
    case 'percentage':
      return formatPercentage(item.value);
    default:
      return numberUtils.numberFormat(item.value);
  }
};

export default function InsightsCard({ item, onViewClicked }) {
  const percentColor = getPercentageColor(item);
  let ChangeIcon = null;
  if (item.percentage < 0) {
    ChangeIcon = ArrowSmDownIcon;
  }
  if (item.percentage > 0) {
    ChangeIcon = ArrowSmUpIcon;
  }
  const Icon = getIcon(item);
  const tooltip = getTooltip(item);
  const tooltipId = `tooltip-insight-${item.id}`;
  return (
    <div className="relative bg-white px-4 overflow-hidden">
      <dt>
        <div className="absolute rounded-md">
          {Icon && <Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />}
        </div>
        <div className="ml-8 text-sm text-left font-normal text-gray-700 truncate">
          {getTitle(item)}
          {tooltip && (
            <>
              <button data-tip data-for={tooltipId} type="button">
                <QuestionCircle className="text-gray-500 inline-block ml-1 h-4 w-4" />
              </button>
              <Tooltip id={tooltipId}>{tooltip}</Tooltip>
            </>
          )}
        </div>

      </dt>
      <dd className="ml-8 flex items-baseline">
        <p className="text-2xl font-semibold text-gray-900">
          {getValueFormatted(item)}
          {item.showView && (
            <TextButton
              onClick={() => onViewClicked && onViewClicked(item.id)}
              className="ml-1"
            >
              View
            </TextButton>
          )}
        </p>
        {item.percentage !== undefined && (
          <p className={`ml-2 flex items-baseline text-sm font-semibold ${percentColor}`}>
            {ChangeIcon
              && <ChangeIcon className={`self-center flex-shrink-0 h-5 w-5 ${percentColor}`} aria-hidden="true" />}
            {formatPercentage(item.percentage)}
          </p>
        )}

        {item.onClickElement && (
          <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 text-left">
            <div className="text-sm">
              {item.onClickElement}
            </div>
          </div>
        )}

      </dd>
    </div>
  );
}

InsightsCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percentage: PropTypes.number,
    onClickElement: PropTypes.func,
    changeIcon: PropTypes.node,
    showView: PropTypes.bool,
  }).isRequired,
  onViewClicked: PropTypes.func,
};

InsightsCard.defaultProps = {
  onViewClicked: null,
};
