export default {
  timeFormat: 'h:mm aa',
  dayMonthFormat: 'MM/dd',
  dateFormat: 'M/dd/yyyy h:mm aaa',
  dateOnlyFormat: 'M/dd/yyyy',
  idleTimeout: process.env.REACT_APP_IDLE_TIMEOUT || 900000,
  auth0TokenParams: {
    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
    scope: 'openid profile email refresh_token offline_access',
  },
};
