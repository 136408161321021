import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { Disclosure, Transition } from '@headlessui/react';
import { ReactComponent as QuestionCircle } from '../../../assets/icons/question-circle.svg';
import StatusConstants from '../../../config/StatusConstants';
import RoleConfirmationModel, { ROLE_ACTION_CANCEL, ROLE_ACTION_RESTORE } from '../../../components/modals/RoleConfirmationModel';
import Spinner from '../../../components/Spinner';
import Toggle from '../../../components/Toggle';
import Grid from '../../../components/Grid';
import Tooltip from '../../../components/Tooltip';
import { toastWithPromise } from '../../../components/Toast/Toast';
import useGetAdminRoles from '../../../hooks/admin/roles/useGetAdminRoles';
import useGetRolePermissions from '../../../hooks/admin/roles/useGetRolePermissions';
import useUpdateRolePermissions from '../../../hooks/admin/roles/useUpdateRolePermissions';
import useResetRolePermissions from '../../../hooks/admin/roles/useResetRolePermissions';

// const buttonCustomRole = 'mr-2 col-span-4 flex bg-blue-600
// border border-gray-300 rounded px-3 items-center py-2 text-white text-sm cursor-pointer';
const buttonClass = 'col-span-4 flex border border-gray-300 rounded px-3 items-center py-1 text-red-500 text-xs cursor-pointer hover:bg-gray-100';
/* const tabOptions = [
  {
    label: 'Default Role',
    value: 'default',
  },
  {
    label: 'Custom Role',
    value: 'custom',
  },
]; */
const priomaryPermissions = [
  'view-threats',
  'view-merchants',
  'view-cards',
  'view-insights',
  'view-users',
  'view-roles',
  'access-activity',
  'override-decisions',
  'select-block-level',
];

const getTitle = (item) => {
  if (item.apiName === StatusConstants.adminApiName) {
    return 'Owner (Admin)';
  }
  return item.name;
};
const filterViewPermission = (array) => array.filter(
  (item) => priomaryPermissions.includes(item.permissionApi),
);

const filterActionPermission = (array, name, category) => {
  if (!array) {
    return [];
  }
  const arryayPermision = array.filter(
    (item) => item.permissionName !== name && item.permissionCategory === category,
  );
  return arryayPermision;
};
const getTooptipMessage = (name, subName) => `Only users allowed to ${name} can ${subName.substr(0, subName.indexOf(' '))} them`;

const Roles = ({ tenantId }) => {
  const { data: permissions, isLoading } = useGetRolePermissions({
    tenantId,
  });
  const [model, setModel] = useState({ showModal: false });
  // const [type, setTypeSelected] = useState(tabOptions[0].value);
  const rolesRequest = useGetAdminRoles();
  const updateRolePermissions = useUpdateRolePermissions();
  const resetRolePermissions = useResetRolePermissions();
  const roles = rolesRequest.data;

  const onChangePermission = (role, permission, value) => {
    if (role) {
      const params = {
        roleId: role.id,
        tenantId,
        permissionId: permission.permissionId,
        isActive: value,
      };
      toastWithPromise(
        updateRolePermissions.mutateAsync(params),
        {
          loading: 'Updating permission...',
          success: 'Permision updated successfully',
          error: 'An error occurred while updating the permission',
        },
      );
    }
  };
  const onKeyPress = () => { };
  const restoreDefaultPermission = (e, role) => {
    e.stopPropagation();
    const params = {
      roleId: role.id,
      tenantId,
    };
    toastWithPromise(
      resetRolePermissions.mutateAsync(params),
      {
        loading: 'Restoring permission...',
        success: 'Permision restore successfully',
        error: 'An error occurred while restoring the permission',
      },
    );
  };

  const onConfirmAction = () => {
    setModel({
      ...model,
      showModal: false,
    });
    switch (model.action) {
      case ROLE_ACTION_CANCEL:
        // cancelPermission(model.role);
        break;
      case ROLE_ACTION_RESTORE:
        restoreDefaultPermission(model.role);
        break;
      default:
        break;
    }
  };
  const getPermissionName = (name) => (
    <>
      <span className="text-sm text-gray-500">Can </span>
      <span className="text-sm text-gray-600 form-medium">{name}</span>
    </>
  );
  const renderPermissionRow = (index, role, permission) => (
    <div className="mt-2">
      {index !== 0
        && (
          <hr />
        )}
      <Grid className="mt-4 mb-6 item-center">
        <div className="col-span-6">
          <div className="flex justify-between pr-6 mt-4">
            <span className="p-0 mr-1">
              {index + 1}
              .&nbsp;
              {getPermissionName(permission.permissionName)}
            </span>
            <div className="flex">
              <Toggle
                disabled
                checked={!!permission.isActive}
                onChange={(value) => onChangePermission(role, permission, value)}
              />
              {permission.permissionApi === StatusConstants.permissionOverrideDecisions
                && (
                  <button data-tip data-for={`title${index}`} type="button">
                    <QuestionCircle className="w-5 h-5 pr-0 ml-2 mr-1 text-gray-400" />
                    <Tooltip id={`title${index}`}>
                      {`User ${permission.permissionName}`}
                    </Tooltip>
                  </button>
                )}
              {permission.permissionApi !== StatusConstants.permissionOverrideDecisions
                && (
                  <div className="mr-8"> </div>
                )}
            </div>
          </div>
        </div>
        <div className="col-span-6 pr-6 pr-8">
          {(filterActionPermission(permissions[role.apiName],
            permission.permissionName,
            permission.permissionCategory).map((subPermission, subIndex) => (
              <div className="flex justify-between mt-4" key={`${role.apiName}${subPermission.permissionId}`}>
                <span className="p-0 mr-1">
                  {index + 1}
                  .
                  {subIndex + 1}
                  .
                  &nbsp;
                  {getPermissionName(subPermission.permissionName)}
                </span>
                <div className="flex">
                  <Toggle
                    disabled
                    checked={!!subPermission.isActive}
                    onChange={(value) => onChangePermission(role, subPermission, value)}
                  />
                  <button data-tip data-for={`title${index}${subIndex}`} type="button">
                    <QuestionCircle className="w-5 h-5 pr-0 ml-2 mr-1 text-gray-400" />
                    <Tooltip id={`title${index}${subIndex}`}>
                      {getTooptipMessage(permission.permissionName, subPermission.permissionName)}
                    </Tooltip>
                  </button>
                </div>
              </div>
          )))}

        </div>
      </Grid>
    </div>
  );
  const renderPermissions = (role) => (
    <div>
      <span className="font-semibold text-black">
        Permissions
      </span>
      {(permissions && permissions[role.apiName])
        && filterViewPermission(permissions[role.apiName]).map((permission, index) => (
          <div key={`${role.apiName}${permission.permissionId}`}>
            {renderPermissionRow(index, role, permission)}
          </div>
        ))}
    </div>
  );

  const getTileAndDescription = () => (
    <div className="mt-4 mb-4 text-gray-600">
      Every member gets basic permissions and functionality by default.
      You can customize settings for all Members, or make local
      adjustments for individual users in their profiles.
    </div>

  );
  const getDefaultRoleComponent = () => (
    <div className="px-3 pt-8 pb-8 pl-4 border border-gray-300 rounded">
      <span data-cy="roles-permissions-content" className="pl-4 font-bold text-medium">Roles &#38; Permissions</span>
      <div className="m-4">
        {roles?.map((item) => (
          <div key={item.id}>
            <Disclosure key={item.id}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="w-full py-2 font-medium text-left text-black rounded-lg hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <div className="flex">
                      <ChevronRightIcon
                        className={`${open ? 'transform rotate-90' : ''} w-5 h-5 text-gray-500`}
                      />
                      <div className="flow-root w-full">
                        <span className="float-left" data-cy={`role-${item.apiName}`}>{getTitle(item)}</span>
                        {open
                          && (
                            <div className="hidden float-right">
                              <div
                                className={buttonClass}
                                onClick={(e) => restoreDefaultPermission(e, item)}
                                onKeyPress={onKeyPress}
                                role="button"
                                tabIndex="0"
                              >
                                Restore default
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    enter="transition duration-200 ease-out"
                    enterFrom="transform scale-y-50 opacity-0"
                    enterTo="transform scale-y-100 opacity-100"
                    leave="transition duration-200 ease-out"
                    leaveFrom="transform scale-y-100 opacity-100"
                    leaveTo="transform scale-y-50 opacity-0"
                  >
                    <Disclosure.Panel className="px-8 pb-2 text-sm text-gray-500">
                      {getTileAndDescription(item)}
                      {renderPermissions(item)}
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
            <hr className="col-span-12 mt-4 mb-4" />
          </div>
        ))}
      </div>
    </div>
  );

  /* const getCustomRoleComponent = () => (
    <div className="flex justify-between w-full mt-12">
      <span className="font-bold text-medium">Custom roles</span>
      <button className={`${buttonCustomRole} whitespace-nowrap`}
      data-tip data-for="unsuspendcard" onClick={() => addCustomRole()} type="button">
        <PlusIcon className="w-5 h-5 pr-0 mr-1 text-white" />
        Add Custom Role
      </button>
    </div>
  ); */
  /* const getComponent = () => {
    switch (type) {
      case 'default':
        return getDefaultRoleComponent();
      case 'custom':
        return getCustomRoleComponent();
      default:
        return getDefaultRoleComponent();
    }
  }; */
  return (
    <div>
      {/* <div className="pb-4">
        <div>
          <ButtonGroup
            options={tabOptions}
            selected={type}
            onChange={setTypeSelected}
          />
        </div>
      </div>
      {getComponent()} */}
      {isLoading && (
        <div className="top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
          <Spinner />
        </div>
      )}
      {!isLoading && getDefaultRoleComponent()}
      <RoleConfirmationModel
        {...model}
        onClose={() => setModel({ ...model, showModal: false })}
        primaryAction={() => onConfirmAction()}
      />
    </div>
  );
};
Roles.propTypes = {
  tenantId: PropTypes.number,
};

Roles.defaultProps = {
  tenantId: null,
};

export default Roles;
