import { format, parseISO, add } from 'date-fns';
import config from '../config';

function formatBase(date, formatString) {
  // @TODO remove string from here
  // we should receive a date object always
  return date ? format(typeof date === 'string' ? parseISO(date) : date, formatString) : '';
}

export const formatTimeOnly = (date) => formatBase(date, config.timeFormat);
export const formatDayMonth = (date) => formatBase(date, config.dayMonthFormat);
export const formatDate = (date) => (date ? format(parseISO(date), config.dateFormat) : '');
export const formatDateOnly = (date) => (date ? format(parseISO(date), config.dateOnlyFormat) : '');
export const formatDayMonthTimeOnly = (date) => (date ? format(parseISO(date), config.dayMonthTimeFormat) : '');
export const formatDateTime = (date, time) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
export const handleCurrentDay = (date, setMaxEndDateLimit, dateLimit) => {
  const today = new Date();
  if (date.getDate() === today.getDate())
    setMaxEndDateLimit(today);
  else
    setMaxEndDateLimit(add(date, { days: dateLimit }));
}
