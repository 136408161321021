import React from 'react';
import PropTypes from 'prop-types';
import CustomTimePeriod from './CustomTimePeriod/CustomTimePeriod';
import FMCombobox from './FMCombobox';

const CustomTimePeriodWrapper = ({
  dateFilter,
  data,
  timeFilter,
  maxEndTimeLimit,
  minEndTimeLimit,
  maxEndDateLimit,
  handleDateLimit,
  handleTimeLimit,
  setDateFilter,
  handleComboBoxChange,
  isClearable,
  handleResetDateFilter
}) => (
  <>
    {!dateFilter && (
      <FMCombobox
        placeholder="Time Period"
        data={data}
        selected={timeFilter}
        onChange={(e) => {
          handleComboBoxChange(e);
        }}
        isClearable={isClearable}
      />
    )}
    <CustomTimePeriod
      maxEndTimeLimit={maxEndTimeLimit}
      maxEndDateLimit={maxEndDateLimit}
      minEndTimeLimit={minEndTimeLimit}
      handleDateLimit={handleDateLimit}
      handleTimeLimit={handleTimeLimit}
      dateFilter={dateFilter}
      setDateFilter={setDateFilter}
      selectedTimePeriod={timeFilter?.value}
      handleResetDateFilter={handleResetDateFilter}
    />
  </>
);

export default CustomTimePeriodWrapper;

CustomTimePeriodWrapper.propTypes = {
  dateFilter: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  handleComboBoxChange: PropTypes.func.isRequired,
  timeFilter: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  isClearable: PropTypes.bool,
  maxEndDateLimit: PropTypes.instanceOf(Date),
  minEndTimeLimit: PropTypes.instanceOf(Date) || PropTypes.number,
  maxEndTimeLimit: PropTypes.instanceOf(Date) || PropTypes.number,
  handleDateLimit: PropTypes.func,
  handleTimeLimit: PropTypes.func,
  setDateFilter: PropTypes.func.isRequired,
  handleResetDateFilter: PropTypes.func,
};

CustomTimePeriodWrapper.defaultProps = {
  dateFilter: null,
  timeFilter: null,
  isClearable: true,
  data: [],
  maxEndDateLimit: null,
  maxEndTimeLimit: null,
  minEndTimeLimit: null,
  handleDateLimit: null,
  handleTimeLimit: null,
  handleResetDateFilter: null
};
