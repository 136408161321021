import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../Badge';
import Tooltip from '../../Tooltip';
import { getColumnValue } from '../DataTableUtils';
import { TABLE_CELL_CLASS } from '../Table';

export default function StringListWithBadgeColumn({ column, row }) {
  const { id } = row;
  const value = getColumnValue(row, column) || [];

  return (
    <td
      key={`cel-${id}-string-list-{${column.field}}`}
      className={`${TABLE_CELL_CLASS}`}
    >
      <div style={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
        <div style={{ display: 'table-cell' }}>
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
              wordWrap: 'break-word',
            }}
          >
            <h2 style={{ display: 'inline' }}>{value.join(', ')}</h2>
          </div>
        </div>

        {value.length > 1 && (
          <div style={{ display: 'table-cell', width: 90 }}>
            <span className="pl-2.5" data-tip data-for={`string-list-${id}`}>
              <Badge>See all {value.length}</Badge>
            </span>
            <Tooltip id={`string-list-${id}`}>
              {value.map((name, index) => (
                <p key={`list-p-${index}`}>
                  {index + 1}. {name}
                </p>
              ))}
            </Tooltip>
          </div>
        )}
      </div>
    </td>
  );
}

StringListWithBadgeColumn.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};
