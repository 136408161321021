import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Textarea from '../forms/Textarea';
import Modal from '../Modal';

export default function CommentModal({onSubmit, onClose, show}) {
  const [comment, setComment] = useState('');
  const handleAddCommentConfirm = () => {
    onSubmit(comment);
    setComment('');
  };

  const  handleClose = () => {
    setComment('');
    onClose();
  }
  return (
    <Modal
      showModal={show}
      title="Add comment"
      primaryAction={handleAddCommentConfirm}
      onClose={handleClose}
      primaryText="Add Comment"
      secondaryText="Cancel"
      primaryEnabled={comment.trim().length > 0}
    >
      <Textarea value={comment} onChange={setComment} rows="4" />
    </Modal>
  );
}

CommentModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}
