import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Modal from '../Modal';
import Filters from '../Filters';
import SelectFilter from '../filters/SelectFilter';

const getTitle = () => (
  <div className="flex items-center">
    <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
      <ExclamationCircleIcon className="w-5 text-blue-900" />
    </div>
    Change  Role
  </div>
);
export default function ChangeRoleConfirmationModel({
  primaryAction,
  roles,
  onClose,
  name,
  defaultRole,
  ...rest
}) {
  const roleOptions = [
    { value: null, label: 'Select' },
    ...roles.map((role) => ({ value: role.id, label: role.name })),
  ];
  const [roleFilter, setRoleFilter] = useState(defaultRole);
  const content = `You are changing role for ${name}. The change will be effective immediately and ${name} will be notified via email.`;

  useEffect(() => {
    setRoleFilter(defaultRole);
  }, [defaultRole]);

  const resetFields = () => {
    setRoleFilter(null);
  };
  const onPrimaryAction = () => {
    primaryAction(roleFilter, roleOptions);
    resetFields();
  };
  const onCloseAction = () => {
    if (onClose) {
      onClose();
      resetFields();
    }
  };
  return (
    <Modal
      {...rest}
      primaryText="Change Role"
      onClose={onCloseAction}
      primaryAction={onPrimaryAction}
      title={getTitle()}
      divider={false}
    >
      <div className="ml-10 ">
        <p className="text-left text-gray-500 pr-1 mb-4">{content}</p>
        <Filters>
          <SelectFilter
            id="role-filter"
            isSearchable={false}
            placeholder="Select Role"
            options={roleOptions}
            value={roleOptions.filter(({ value }) => roleFilter && value === roleFilter.value)}
            menuPlacement="top"
            onChange={(e) => { setRoleFilter(e); }}
          />
        </Filters>
      </div>
    </Modal>
  );
}

ChangeRoleConfirmationModel.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func,
  primaryAction: PropTypes.func.isRequired,
  name: PropTypes.string,
  defaultRole: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};
ChangeRoleConfirmationModel.defaultProps = {
  roles: [],
  onClose: null,
  name: '',
  defaultRole: {
    value: -1,
    label: '',
  },
};
