export function getStatusModelTitle(isActive) {
  if (isActive) {
    return 'Deactivate Tenant';
  }
  return 'Activate Tenant';
}
export function getStatusModelContent(isActive) {
  if (isActive) {
    return 'All of the tenant\'s users, including the owner, will no longer be able to access the ARDEN platform';
  }
  return 'The tenant account will be activated, allowing the tenant\'s owner and associated users to access the ARDEN platform based on the role assigned.';
}
export default {
  owner: {
    title: 'Select Owner',
    description: 'Select an owner of the tenant from the list of existing admins.',
    type: 'select',
    label: 'Set owner',
    array: [],
  },
  stage: {
    title: 'Select Stage',
    description: 'Select the stage of the teanant lifecycle',
    type: 'select',
    label: 'Select tenant stage',
  },
  cardNumber: {
    title: 'Card number displayed',
    description: 'Select the last number of digits which are required to be visible for the tenant’s users.',
    type: 'select',
    label: 'Last',
    array: [
      {
        value: 1,
        label: '4 Digit',
      },
      {
        value: 2,
        label: '6 digit',
      },
    ],
  },
  revenue: {
    title: 'Revenue',
    description: 'Enter the total revenue earned from the tenant.',
    type: 'input',
    label: 'Total revenue',
  },
  referrals: {
    title: 'Referrals',
    description: 'Enter the total referrals provided by the tenant.',
    type: 'input',
    label: 'Total referrals',
  },
  type: {
    CARD_NUMBER: 'cardNumer',
    REFERRALS: 'referrals',
    STAGE: 'stage',
    OWNER: 'owner',
    REVENUE: 'revenue',
  },
};
