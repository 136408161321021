import useLoggedInQuery from '../../useLoggedInQuery';
import useApiClient from '../../useApiClient';
import api from '../../../api';

const fetchActivityLog = async (client, params) => api.account.fetchActivityLog(client, params);

export default function useFetchActivityLog(clientApi, userId) {
  const apiClient = useApiClient();

  return useLoggedInQuery(
    ['fetchActivityLog', userId],
    async () => fetchActivityLog(apiClient, { userId }),
  );
}
