import { differenceInHours, parseISO } from 'date-fns';
import DateColumn from '../components/table/columns/DateColumn';
import MccDescriptionColumn from '../components/table/columns/MccDescriptionColumn';
import MerchantActionsColumn from '../components/table/columns/MerchantActionsColumn';
import MerchantStatusColumn from '../components/table/columns/MerchantStatusColumn';
import TwoRowsColumn from '../components/table/columns/TwoRowsColumn';
import { MERCHANT_OPEN_MERCHANT } from '../components/table/MerchantsTable';
import numberUtils from './numberUtils';
import { getName } from './userUtil';

export function formatLastLog(lastLog) {
  return lastLog && lastLog.changedByType && lastLog.changedByType === 'user'
    ? getName(lastLog.changedBy)
    : 'ARDEN';
}

const changedByColumn = (headerName = 'Added By') => ({
  field: 'changedBy',
  headerName,
});

const merchantDetailsColumn = {
  field: 'name',
  field2: 'number',
  headerName: 'Merchant Details',
  valueGetter2: (valueField) => `ID: ${valueField}`,
  linkGetter: (value) => `merchants/${value.id}`,
  useLink: true,
  render: TwoRowsColumn,
  actionName: MERCHANT_OPEN_MERCHANT,
};

const mccColumn = {
  field: 'mcc',
  headerName: 'MCC',
  sortable: true,
  render: MccDescriptionColumn,
};

const activeAlertColumn = {
  field: 'activeAlerts',
  headerName: 'Active Alerts',
  sortable: true,
};

const amountColumn = {
  field: 'amount',
  headerName: 'Amount',
  headerClassName: 'text-right',
  valueGetter: numberUtils.currencyFormat,
  className: 'text-right',
  sortable: true,
};

// const attackAmountColumn = {
//   field: 'attackAmount',
//   headerName: 'Attack Amount',
//   headerClassName: 'justify-end',
//   className: 'text-right pr-10',
//   sortable: true,
// };

const authsColumn = {
  field: 'auths',
  headerName: 'Total Auths',
  headerClassName: 'justify-end',
  className: 'text-right pr-10',
  valueGetter: numberUtils.numberFormat,
  sortable: true,
};

const cardsColumn = {
  field: 'cards',
  headerName: 'Total Cards',
  headerClassName: 'justify-end',
  className: 'text-right pr-10',
  valueGetter: numberUtils.numberFormat,
  sortable: true,
};


const actionsColumn = {
  field: '',
  headerName: 'Actions',
  render: MerchantActionsColumn,
};

const approveExpireColumn = {
  field: 'lastLogAt',
  key: 'approve-expiry',
  headerName: 'Temp Trust Expiry',
  valueGetter: (field) => {
    if (!field) {
      return '';
    }
    const diff = 24 - differenceInHours(new Date(), parseISO(field));
    return `in ${Math.max(diff, 0)}h`;
  },
};

const statusColumn = {
  field: 'statusApiName',
  field2: 'changedByType',
  headerName: 'Status',
  render: MerchantStatusColumn,
  sortable: true,
};

const defaultDateColumn = DateColumn();

const columns = [
  defaultDateColumn,
  merchantDetailsColumn,
  mccColumn,
  authsColumn,
  amountColumn,
  {
    field: 'approvedAuths',
    headerName: 'Approved Auths',
    headerClassName: 'text-right',
    className: 'text-right',
    valueGetter: (field) => numberUtils.numberFormat(field),
    sortable: true,
  },
  {
    field: 'approvedAmount',
    headerName: 'Approved Amount',
    headerClassName: 'text-right',
    className: 'text-right',
    sortable: true,
  },
  cardsColumn,
];

export default {
  getMerchantTableColumns: (view) => {
    switch (view) {
      case 'blocked-merchant':
        return [
          DateColumn(),
          merchantDetailsColumn,
          mccColumn,
          changedByColumn(),
          actionsColumn,
        ];
      case 'trusted-merchant':
        return [
          defaultDateColumn,
          merchantDetailsColumn,
          mccColumn,
          changedByColumn(),
          actionsColumn,
        ];
      case 'approved-merchant':
        return [
          defaultDateColumn,
          merchantDetailsColumn,
          mccColumn,
          changedByColumn(),
          approveExpireColumn,
          actionsColumn,
        ];
      case 'all':
        return [
          DateColumn('createdAt'),
          merchantDetailsColumn,
          mccColumn,
          DateColumn('updatedAt', 'Status Change'),
          changedByColumn('Last Modified By'),
          statusColumn,
          actionsColumn,
        ];
      case 'threats':
        return [
          DateColumn('updatedAt', 'Threat Identified'),
          merchantDetailsColumn,
          mccColumn,
          activeAlertColumn,
          statusColumn,
          actionsColumn,
        ];
      case 'merchantsByCard':
        return [
          merchantDetailsColumn,
          mccColumn,
          authsColumn,
          amountColumn,
          statusColumn,
        ];
      default:
        return columns;
    }
  },
  formatLastLog,
};
