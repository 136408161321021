import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import CardKeys from './Card.keys';

export const assignCard = (
  client,
  id,
  data,
) => {
  if (!id) {
    throw new Error('id is required');
  }
  return api.cards.assignCard(client, id, data);
};

export default function useAssignCard(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => assignCard(client, id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(CardKeys.all());
      queryClient.invalidateQueries(CardKeys.details());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
