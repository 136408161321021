import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import CardTransactions from '../table/CardTransactions';
import { setShowSelectedTenant } from '../../store/features/admin/adminSlice';
import TenantDetails from '../TenantDetails';
import SlideOverBottom from './SlideOverBottom';

export default function SlideOverSelectedTenant() {
  const showSelectedTenant = useSelector(
    (state) => state.admin.showSelectedTenant,
  );
  const selectedTenant = useSelector((state) => state.admin.selectedTenant);
  const dispatch = useDispatch();
  return (
    <SlideOverBottom
      isOpen={showSelectedTenant}
      setOpen={(value) => dispatch(setShowSelectedTenant(value))}
      title="Tenant Details"
    >
      {selectedTenant && (
        <TenantDetails tenantId={selectedTenant.id} />
      )}
    </SlideOverBottom>
  );
}
