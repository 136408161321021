import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import CardKeys from './Card.keys';

export const bulkReactivateCard = (
  client,
  cardIds,
  statusApiName,
  reason,
  comment,
  action
) => {
  if (!cardIds) {
    throw new Error('cardIds is required');
  }
  return api.cards.reactivateCardsBulkStatus(client, cardIds, statusApiName, reason, comment, action)
    .then((results) => results.map(result => ({
      ...result,
      message: result.success
        ? `Card: ${result.value.lastFour} updated successfully`
        : `An error occurred while updating card ${result.value?.lastFour}`
    })));
};

export default function useBulkReactivateCard(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({ ids, statusApiName, reason, comment, action }) => bulkReactivateCard(client, ids, statusApiName, reason, comment, action), {
    onSuccess: () => {
      queryClient.invalidateQueries(CardKeys.all());
      queryClient.invalidateQueries(CardKeys.details());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
