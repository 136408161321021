import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterIcon } from '@heroicons/react/outline';
import SearchFilter from './SearchFilter';
import IconButton from '../buttons/IconButton';
import Tooltip from '../Tooltip';

const FiltersContainer = ({
  children, expandable, search, onSearchChange, tooltip, defaultToExpand
}) => {
  const [expanded, setExpanded] = useState(defaultToExpand);

  const handleExpandedFilters = () => {
    setExpanded(!expanded)
  }

  return (
    <div className="flex flex-col space-y-4 mb-2">
      <div className="flex space-x-2">
        <SearchFilter
          onChange={onSearchChange}
          value={search}
        />
        {expandable && (
          <div className="flex relative">
            <IconButton data-tip data-for="filter-container" onClick={handleExpandedFilters} buttonSize="sm" buttonStyle="light" icon={<FilterIcon className="text-gray-400" />} />
            {tooltip && (
              <>
                <span className="rounded-full w-2 h-2 bg-blue-600 absolute top-1/4 left-1/2" />
                <Tooltip id="filter-container">{tooltip}</Tooltip>
              </>
            )}
          </div>
        )}
      </div>
      {expandable && expanded && (
        <div className="flex flex-wrap gap-3">
          {children}
        </div>
      )}
    </div>
  );
};

FiltersContainer.propTypes = {
  search: PropTypes.string,
  onSearchChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  expandable: PropTypes.bool,
  defaultToExpand: PropTypes.bool,
  tooltip: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FiltersContainer.defaultProps = {
  search: '',
  onSearchChange: null,
  children: null,
  expandable: true,
  tooltip: null,
  defaultToExpand: true,
};

function FiltersContainerTooltip({ filters }) {
  return (
    <>
      {filters.map((filter) => (
        <p key={filter.name} className="text-xs block">
          {filter.name}
          {' '}
          <span className="font-bold">
            {filter.value}
          </span>
        </p>
      ))}
    </>
  );
}

FiltersContainerTooltip.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })),
};

FiltersContainerTooltip.defaultProps = {
  filters: [],
};

FiltersContainer.Tooltip = FiltersContainerTooltip;

export { FiltersContainer as default };
