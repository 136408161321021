import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import { classNames } from '../../utils/ui';
import IconButton from '../buttons/IconButton';
import RadioGroup from '../RadioGroup';
import HighlightText from '../HighlightText';

const delimiters = ['Enter'];

function TagItem({
  tag, removeButtonText, onDelete, classNames: compClassNames,
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button type="button" title={`${removeButtonText}: ${tag.name}`} onClick={onDelete} className={compClassNames.selectedTag}>
      {tag.name}
      <XIcon className="w-3" />
    </button>
  );
}

TagItem.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  removeButtonText: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  classNames: PropTypes.shape({
    selectedTag: PropTypes.string,
  }).isRequired,
};

const MAX_ITEMS = 20;

export default function FMMultipleCombobox({
  data, placeholder, selected, onChange, onCancel, preInput, maxItemsPreview,
}) {
  const [query, setQuery] = useState('');
  const [selectedInner, setSelectedInner] = useState(selected);
  const [inputFocused, setInputFocused] = useState(false);
  const tagsRef = useRef(null);
  const buttonRef = useRef(null);
  const cleanQuery = query.toLowerCase();
  const filtered = (query === ''
    ? data
    : data.filter((item) => item.label.toLowerCase().includes(cleanQuery))).slice(0, MAX_ITEMS);

  const isEmpty = !selected || selected.length === 0;
  const getLabel = () => {
    if (selected.length > maxItemsPreview) {
      const previewItems = selected.slice(0, maxItemsPreview).map((item) => data.find((option) => option.value === item).label).join(', ');
      return `${previewItems}, +${selected.length - maxItemsPreview}`;
    }
    return selected.map((item) => data.find((option) => option.value === item).label).join(', ');
  };

  const renderInput = () => (
    <div className={classNames(
      'border text-sm rounded focus:outline-none focus:ring-0 relative mb-3',
      inputFocused ? 'border-blue-600 bg-blue-100 bg-opacity-50' : 'border-gray-200',
    )}
    >
      <ReactTags
        ref={tagsRef}
        inputFieldPosition="inline"
        tags={selectedInner.map((item) => ({ id: item, name: item }))}
        suggestions={filtered.map((item) => ({ id: item.value, name: item.label }))}
        delimiters={delimiters}
        onInput={(value) => setQuery(value)}
        onDelete={(i) => setSelectedInner(
          selectedInner.filter((_, index) => index !== i),
        )}
        onAddition={(tag) => {
          if (!selectedInner.some((item) => item === tag.id)) {
            setSelectedInner([...selectedInner, tag.id]);
          }
          setQuery('');
        }}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        classNames={{
          root: 'flex flex-wrap gap-2 p-1.5',
          selected: 'flex gap-2 max-w-full flex-wrap',
          selectedTag: 'flex bg-gray-200 px-1.5 py-0.5 items-center gap-1',
          search: 'flex-1',
          searchInput: 'py-0.5 focus:outline-none bg-transparent',
        }}
        tagComponent={TagItem}
        placeholderText=""
        suggestionComponent={() => (<span />)}
      />
    </div>
  );

  const renderOptions = () => filtered.map((item) => (
    <Combobox.Option key={item.value} value={item.value} className="text-sm text-gray-500 hover:text-black cursor-pointer">
      {({ active, selected: isSelected }) => (
        <div className={classNames('flex justify-between items-center py-1.5 px-3', active && 'bg-gray-50')}>
          <HighlightText text={item.label} highlight={query} highlightClassName="bg-transparent text-black" />
          {isSelected && <CheckIcon className="w-5 text-black" />}
        </div>
      )}
    </Combobox.Option>
  ));

  const renderFooter = () => (
    <div className="flex justify-between">
      <button
        type="button"
        className="border font-normal shadow-sm px-4 py-2 text-sm text-gray-700"
        onClick={() => {
          buttonRef.current?.click();
          setSelectedInner(selected);
          if (onCancel) {
            onCancel();
          }
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        className="border border-transparent font-normal text-sm shadow-sm px-4 py-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white"
        onClick={() => {
          buttonRef.current?.click();
          onChange(selectedInner);
        }}
      >
        Apply
      </button>
    </div>
  );

  const handleOnChange = (value) => {
    tagsRef.current.clearInput();
    tagsRef.current.focusInput();
    setSelectedInner(value);
  };

  useEffect(() => {
    setSelectedInner(selected);
  }, [selected]);

  return (
    <Combobox multiple value={selectedInner} onChange={handleOnChange}>
      <div className="relative">
        <div className="flex">
          <Combobox.Button
            ref={buttonRef}
            type="button"
            className={classNames(
              'rounded-sm pl-3 pr-2 py-1.5 text-sm flex items-center focus:outline-none focus:ring-0',
              !isEmpty ? 'bg-blue-50 text-black' : 'bg-gray-100 text-gray-500',
            )}
          >
            <span>{!isEmpty ? getLabel() : placeholder}</span>
            {isEmpty && <ChevronDownIcon className="ml-2 w-5" />}
          </Combobox.Button>
          {!isEmpty && (
            <IconButton onClick={() => onChange && onChange([])} buttonStyle="custom" buttonSize="custom" className="bg-blue-50 border-l border-blue-700 px-1.5" icon={<XIcon className="px-1" />} />
          )}
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <div className="absolute top-full mt-1 rounded bg-white z-50 overflow-hidden">
            <div className="p-3 border border-gray-200">
              <Combobox.Input
                as={Fragment}
                placeholder="type to search"
                displayValue={(item) => (item ? item.value : '')}

              >
                <div className="border-0 p-0">
                  {preInput}
                  {renderInput()}
                </div>
              </Combobox.Input>
              <Combobox.Options as="div" className="w-80">
                {filtered && filtered.length ? (
                  <ul className="h-40 overflow-auto border border-gray-200 rounded-b mb-3">
                    {renderOptions()}
                  </ul>
                ) : (<div className="flex h-40 text-xs items-center justify-center text-gray-500 border border-gray-200 rounded-b mb-3">no matches found</div>)}
                {renderFooter()}
              </Combobox.Options>
            </div>
          </div>
        </Transition>
      </div>
    </Combobox>
  );
}

FMMultipleCombobox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  selected: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  preInput: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  maxItemsPreview: PropTypes.number,
};

FMMultipleCombobox.defaultProps = {
  data: [],
  selected: [],
  placeholder: 'Select...',
  onChange: null,
  onCancel: null,
  preInput: null,
  maxItemsPreview: 4,
};

export function FMMultipleComboboxWithExclude({
  excludeMcc, onChange, onCancel, ...rest
}) {
  const [excludeMccInner, setExcludeMccInner] = useState(excludeMcc);

  useEffect(() => setExcludeMccInner(excludeMcc), [excludeMcc]);

  return (
    <FMMultipleCombobox
      {...rest}
      onChange={(mccs) => {
        onChange({ mccs, exclude: excludeMccInner });
      }}
      onCancel={() => {
        setExcludeMccInner(excludeMcc);
        if (onCancel) {
          onCancel();
        }
      }}
      preInput={(
        <div className="flex gap-8">
          <RadioGroup
            options={[
              {
                label: 'Show only',
                value: false,
              },
              {
                label: 'Show all except',
                value: true,
              },
            ]}
            selected={excludeMccInner}
            onChange={setExcludeMccInner}
            labelClassName="text-sm"
          />
        </div>
      )}
    />
  );
}

FMMultipleComboboxWithExclude.propTypes = {
  excludeMcc: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};

FMMultipleComboboxWithExclude.defaultProps = {
  onChange: null,
  onCancel: null,
};
