import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import AdminTenantKeys from './AdminTenant.keys';

export default function useGetTenants(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    AdminTenantKeys.list('all', params),
    async () => api.admin.tenants.fetchTenants(apiClient, params),
  );
}
