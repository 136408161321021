import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getApiClient } from '../api/base';
import ErrorMessages from './api/enum/error';

export default function useApiClient() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const history = useHistory();
  const client = useMemo(() => {
    const c = getApiClient();

    c.interceptors.request.use(async (requestConfig) => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          return {
            ...requestConfig,
            headers: {
              ...requestConfig.headers,
              'Access-Control-Allow-Credentials': true,
              Authorization: `Bearer ${token}`,
            },
          };
        }
        return requestConfig;
      } catch (error) {
        // redirect to login if any auth0 error occurrs
        // error.error = invalid_grant | login_required
        loginWithRedirect();

        return Promise.reject(error);
      }
    });
    c.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response && error.message === ErrorMessages.NetworkError) {
          history.push('/network-error');
        }

        if (error.response && error.response.status === 401) {
          loginWithRedirect();
        }

        return Promise.reject(error);
      }
    );
    return c;
  }, [getAccessTokenSilently, loginWithRedirect, history]);
  return client;
}
