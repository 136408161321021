import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import AdminRolesKeys from './AdminRoles.keys';

export default function useResetRolePermissions(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation((data) => api.admin.roles.resetPermissions(client, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(AdminRolesKeys.list('permissions'));
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
