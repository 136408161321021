import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getTop5Merchants = async (
  client,
  params,
) => api.merchants.fetchTop5Merchants(client, params);

export default function useGetTop5Merchants(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'merchant/top5',
      ...Object.values(params),
    ],
    async () => getTop5Merchants(apiClient, params)
      .then((results) => results.map((result, i) => ({
        ...result,
        rank: i + 1,
      }))),
  );
}
