import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Spinner from '../Spinner';

export default function BlockLoadingModal({ show }) {
  return (
    <Modal
      showModal={show}
      title={
        <div className="flex items-center">
          <div className="p-1 mr-3 rounded-full w-7">
            <Spinner size="7" />
          </div>
          <div className="p-1 mr-3 rounded-full">Just a moment...</div>
        </div>
      }
      primaryShow={false}
      secondaryShow={false}
      divider={false}
    >
      <div className="p-1 mr-3 text-sm">
        Please wait while we scan the cards associated with the merchant
        blocked. You will find the cards identified on the cards tab on the
        Arden alert page.
      </div>
    </Modal>
  );
}

BlockLoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
};
