import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getActiveCardAttacks = async (
  client,
  cardId,
 params
) => api.cards.fetchActiveCardAttacks(client, cardId, params);

export default function useGetActiveCardAttacks(cardId, params) {
  const apiClient = useApiClient();

  return useLoggedInQuery(
    [
      'cards',
      'active-attacks',
      params,
    ],
    async () => getActiveCardAttacks(apiClient,cardId, params),
  );
}
