import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import CardKeys from './Card.keys';

export const bulkResolveCard = (
  client,
  cardIds,
  statusApiName,
  reason,
  comment,
  action
) => {
  if (!cardIds) {
    throw new Error('cardIds is required');
  }
  return api.cards.resolveCardsBulkStatus(client, cardIds, statusApiName, reason, comment, action);
};

export default function useBulkResolveCard(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({ ids, statusApiName, reason, comment, action }) => bulkResolveCard(client, ids, statusApiName, reason, comment, action), {
    onSuccess: () => {
      queryClient.invalidateQueries(CardKeys.all());
      queryClient.invalidateQueries(CardKeys.details());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
