/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  BanIcon,
  ClockIcon,
  XIcon,
  DotsHorizontalIcon,
} from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import ToolTip from '../Tooltip';
import strings from '../../config/strings';
import StatusConstants from '../../config/StatusConstants';
import { classNames } from '../../utils/ui';

const CLASS_ICON = 'h-5 w-5 ml-2 mt-2 border-gray-700 stroke-current w-5 h-5';

function ApproveButton({ merchant, onClick }) {
  const isApproved = merchant.statusApiName === StatusConstants.approvedApiName
  const isAttackActive = merchant.statusApiName === StatusConstants.trustedApiName && merchant.hasActiveAttack;
  const isBlocked = merchant?.isBlockedById || merchant?.isBlockedByName;
  const disabled = isApproved || isBlocked || isAttackActive;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          disabled={disabled}
          onClick={() => onClick(
            StatusConstants.approvedApiName,
            merchant,
          )}
          data-tip
          data-for="approve"
          data-cy="approve-button"
        >
          <ClockIcon className={classNames(CLASS_ICON, disabled ? 'opacity-30 text-blue-400' : 'text-blue-600')} />
          <ToolTip id="approve" className="max-w-2xs">
            {strings.approveTooltip}
          </ToolTip>
          <span
            className={classNames('flex items-left px-4 py-2 text-gray-700 text-xs font-bold', disabled ? 'opacity-30 text-gray-400' : '')}
          >
            Trust for 24h only
          </span>

        </button>
      )}
    </Menu.Item>
  );
}

ApproveButton.propTypes = {
  merchant: PropTypes.shape({
    statusApiName: PropTypes.string,
    hasActiveAttack: PropTypes.bool,
    isBlockedById: PropTypes.bool,
    isBlockedByName: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

function TrustButton({ merchant, onClick }) {
  const isTrust = merchant.statusApiName === StatusConstants.trustedApiName
  const isBlocked = merchant.isBlockedById || merchant.isBlockedByName;
  const disabled = isTrust || isBlocked;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          disabled={disabled}
          onClick={() => onClick(
            StatusConstants.trustedApiName,
            merchant,
          )}
          data-tip
          data-for="trust"
          data-cy="trust-button"
        >
          <ClockIcon className={classNames(CLASS_ICON, disabled ? 'opacity-30 text-blue-400' : 'text-green-600')} />
          <ToolTip id="trust" className="max-w-2xs">
            {strings.trustTooltip}
          </ToolTip>
          <span
            className={classNames('flex items-left px-4 py-2 text-gray-700 text-xs font-bold', disabled ? 'opacity-30 text-gray-400' : '')}
          >
            Trust
          </span>

        </button>
      )}
    </Menu.Item>
  );
}

TrustButton.propTypes = {
  merchant: PropTypes.shape({
    statusApiName: PropTypes.string,
    isBlockedById: PropTypes.bool,
    isBlockedByName: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

function BlockButton({ merchant, onClick }) {
  const isBlockConfirmed = merchant.statusApiName === StatusConstants.blockedApiName && merchant?.lastLog?.changedByType === "rule"
  const disabled = merchant.isBlockedById && merchant.isBlockedByName;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          data-tip
          data-for="block"
          type="button"
          data-cy="block-button"
          onClick={() => onClick(
            StatusConstants.blockedApiName,
            merchant,
          )}
          disabled={disabled}
        >
          <BanIcon className={classNames(CLASS_ICON, 'text-red-600', disabled ? 'opacity-30 text-gray-400' : '')} />
          <ToolTip id="block" className="max-w-2xs">
            {strings.blockToolTip}
          </ToolTip>
          <span
            className={classNames('flex items-left px-4 py-2 text-gray-700 text-xs font-bold ', disabled ? 'opacity-30 text-gray-400' : '')}
          >
            {isBlockConfirmed ? 'Confirm Block' : 'Block'}
          </span>
        </button>
      )}
    </Menu.Item>
  );
}

BlockButton.propTypes = {
  merchant: PropTypes.shape({
    statusApiName: PropTypes.string,
    isBlockedById: PropTypes.bool,
    isBlockedByName: PropTypes.bool,
    lastLog: PropTypes.shape({
      changedByType: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

function ThreatButton({ merchant, onClick }) {
  const isThreat = merchant.statusApiName === StatusConstants.pendingReviewApiName;
  const isTrustedOrApproved = merchant.statusApiName === StatusConstants.trustedApiName || merchant.statusApiName === StatusConstants.approvedApiName;
  const disabled = !isTrustedOrApproved && !isThreat && !merchant.isBlockedById && !merchant.isBlockedByName;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          data-tip
          data-for="remove"
          type="button"
          data-cy="remove-button"
          disabled={disabled}
          onClick={() => onClick(StatusConstants.reviewedApiName, merchant, false)}
        >
          <XIcon className={classNames(CLASS_ICON, disabled ? 'opacity-30 text-gray-400' : 'text-gray-600', ' w-5 h-5')} />
          <ToolTip id="remove" className="max-w-2xs">
            {strings.removeToolTip}
          </ToolTip>
          <span className={classNames('flex items-left px-4 py-2 text-gray-700 text-xs font-bold', disabled ? 'opacity-30 text-gray-400' : '')}>
            Not a threat
          </span>

        </button>
      )}
    </Menu.Item>
  );
}

ThreatButton.propTypes = {
  merchant: PropTypes.shape({
    statusApiName: PropTypes.string,
    isBlockedById: PropTypes.bool,
    isBlockedByName: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

function RemoveButton({ merchant, onClick }) {
  const isThreat = merchant.statusApiName === StatusConstants.pendingReviewApiName;
  const isTrustedOrApproved = merchant.statusApiName === StatusConstants.trustedApiName || merchant.statusApiName === StatusConstants.approvedApiName;
  const disabled = !isTrustedOrApproved && !isThreat && !merchant.isBlockedById && !merchant.isBlockedByName;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          data-tip
          data-for="remove"
          type="button"
          data-cy="remove-button"
          disabled={disabled}
          onClick={() => onClick(StatusConstants.reviewedApiName, merchant, merchant.statusApiName === StatusConstants.blockedApiName)}
        >
          <XIcon className={classNames(CLASS_ICON, disabled ? 'opacity-30 text-gray-400' : 'text-gray-600', ' w-5 h-5')} />
          <ToolTip id="remove" className="max-w-2xs">
            {strings.removeToolTip}
          </ToolTip>
          <span className={classNames('flex items-left px-4 py-2 text-gray-700 text-xs font-bold', disabled ? 'opacity-30 text-gray-400' : '')}>
            Remove from list
          </span>

        </button>
      )}
    </Menu.Item>
  );
}

RemoveButton.propTypes = {
  merchant: PropTypes.shape({
    statusApiName: PropTypes.string,
    isBlockedById: PropTypes.bool,
    isBlockedByName: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

function MerchantActions(props) {
  function getActions() {
    const {
      merchant, onClick, currentView,
    } = props;
    const isThreat = merchant?.statusApiName === StatusConstants.pendingReviewApiName
    const isNotThreat = isThreat  && (!merchant.isBlockedById || !merchant.isBlockedByName);
    const isAttackActive = (isThreat || merchant?.statusApiName === StatusConstants.trustedApiName) && merchant?.hasActiveAttack;

    return (
      <>
        <Menu as="div" className="border mx-2 p-0.5 focus:outline-none">
          {({ open: openMenu }) => (
            <>
              <Menu.Button data-cy={`${merchant.id}-action`} className="w-6 px-1 items-center text-gray-600 text-sm flex focus:outline-none focus:bg-white hover:bg-white focus:bg-opacity-10 hover:bg-opacity-10 active:bg-opacity-20">
                <span className="hidden md:inline">
                  <DotsHorizontalIcon
                    className={classNames(
                      'text-gray-400 group-hover:text-gray-500',
                      '-ml-0.5 h-5 w-5',
                    )}
                  />
                </span>
              </Menu.Button>
              <Transition
                show={openMenu}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right z-20 absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {currentView !== StatusConstants.trustedApiName
                  && <TrustButton merchant={merchant} onClick={onClick} />}

                  {currentView !== StatusConstants.approvedApiName
                  && <ApproveButton merchant={merchant} onClick={onClick} />}

                  {currentView !== StatusConstants.blockedApiName
                  && <BlockButton merchant={merchant} onClick={onClick} />}

                  {(isNotThreat || isAttackActive) && (<ThreatButton merchant={merchant} onClick={onClick} />)}

                  {(!isNotThreat && !isAttackActive) && (<RemoveButton merchant={merchant} onClick={onClick} />)}

                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </>
    );
  }

  return (
    <>
      <div className="flex justify-center align-middle space-x-3">
        {getActions(props)}
      </div>
    </>
  );
}

MerchantActions.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    statusApiName: PropTypes.string,
    isCompromised: PropTypes.bool,
    isBlockedById: PropTypes.bool,
    isBlockedByName: PropTypes.bool,
    hasActiveAttack: PropTypes.bool,
  }).isRequired,
  currentView: PropTypes.string,
  onClick: PropTypes.func,
};

MerchantActions.defaultProps = {
  currentView: 'all',
  onClick: () => { },
};

export default MerchantActions;
