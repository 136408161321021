import api from "../../../api";
import useApiClient from "../../useApiClient";
import useLoggedInQuery from "../../useLoggedInQuery";

export default function useGetActiveMerchantAttacksFilter(merchantTenantId) {
  const apiClient = useApiClient();

  return useLoggedInQuery(
    ['merchants', 'active-merchant-attacks-filter', merchantTenantId],
    () => api.transactions.fetchActiveMerchantAttacksFilter(apiClient, merchantTenantId)
  )
}