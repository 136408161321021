import { AxiosInstance } from 'axios';
import OptionDto from './dto/OptionDto';

const fetchMerchantTransactions = async (client: AxiosInstance, merchantId: number, params = {}) =>
  client
    .get(`api/merchants/${merchantId}/transactions`, {
      params,
    })
    .then((response) => response.data);

const fetchCardTransactions = async (client: AxiosInstance, cardId: number, params = {}) =>
  client
    .get(`api/cards/${cardId}/transactions`, {
      params,
    })
    .then((response) => response.data);

const fetchReasonCodes = async (client: AxiosInstance, params: unknown) =>
  client
    .get('api/transactions/reason-codes', {
      params,
    })
    .then((response) => response.data);

const fetchResponseCodes = async (client: AxiosInstance, params: unknown) =>
  client
    .get('api/transactions/response-codes', {
      params,
    })
    .then((response) => response.data);

const fetchPaymentMethods = async (client: AxiosInstance, params: unknown) =>
  client
    .get('api/transactions/payment-methods', {
      params,
    })
    .then((response) => response.data);
const fetchActiveMerchantAttacksFilter = async (client: AxiosInstance, merchantTenantId: number) =>
  client.get(`api/merchants/${merchantTenantId}/active-attacks-filter`).then((response) => response.data);
const fetchActiveCardAttacksFilter = async (client: AxiosInstance, cardId: number) =>
  client.get<OptionDto[]>(`api/cards/${cardId}/active-attacks-filter`).then((response) => response.data);
const fetchAllTransactionsCount = async (client: AxiosInstance) =>
  client.get('api/transactions/all').then((response) => response.data);

export default {
  fetchMerchantTransactions,
  fetchCardTransactions,
  fetchReasonCodes,
  fetchResponseCodes,
  fetchPaymentMethods,
  fetchActiveMerchantAttacksFilter,
  fetchActiveCardAttacksFilter,
  fetchAllTransactionsCount,
};
