import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';
import FMSelect from '../../forms/FMSelect';
import { CARD_ASSIGN_USER } from '../CardsTable';
import Spinner from '../../Spinner';
import { getName,checkPermission } from '../../../utils/userUtil';
import PermissionConstants from '../../../config/PermissionConstants';
import useGetMyPermissions from '../../../hooks/api/users/useGetMyPermissions';
import useGetTenantUsers from '../../../hooks/api/users/useGetTenantUsers';

export default function CardAssignToColumn({ row: card, onAction }) {
  const { data } = useGetTenantUsers({
    itemsPerPage: Number.MAX_SAFE_INTEGER,
  });
  const { data: permissions } = useGetMyPermissions();
  const tenantUsers = data ? data.rows : [];
  const userOptions = [
    { value: null, label: 'unassigned' },
    ...tenantUsers.map((userTenant) => ({ value: userTenant.id, label: getName(userTenant) })),
  ];
  return (
    <td className={TABLE_CELL_CLASS} key="cardAssign">
      {card.loading
        ? (<Spinner size="6" />)
        : (
          <FMSelect
            className="w-40"
            size="small"
            menuShouldBlockScroll
            isSearchable={false}
            disabled={!checkPermission(permissions, PermissionConstants.REASSIGN_COMPROMISED_CARDS)
              && !!card.userId}
            value={userOptions.find(
              (userOption) => userOption.value === card.userId,
            )}
            menuPortalTarget={document.getElementById('scroll-container')}
            options={userOptions}
            maxMenuHeight={200}
            menuPlacement="bottom"
            onChange={(e) => onAction(CARD_ASSIGN_USER, { userId: e.value, card })}
          />
        )}
    </td>
  );
}
CardAssignToColumn.propTypes = {
  row: PropTypes.shape(
    {
      userId: PropTypes.number,
      loading: PropTypes.bool,
    },
  ).isRequired,
  onAction: PropTypes.func.isRequired,
};
