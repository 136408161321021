import React from 'react';
import PropTypes from 'prop-types';
import TextButton from '../../../buttons/TextButton';
import { TABLE_CELL_CLASS } from '../../Table';
import StatusConstants from '../../../../config/StatusConstants';
import { getColumnValue } from '../../DataTableUtils';

export const INVITATION_ACTIONS = {
  resend: 'invitation-actions-resend',
  cancel: 'invitation-actions-cancel',
};

function renderActions(id, statusApiName, isExpired, onAction) {
  switch (statusApiName) {
    case StatusConstants.userInvitation.declined:
      return (
        <TextButton
          onClick={() => onAction(INVITATION_ACTIONS.resend, id)}
        >
          Resend
        </TextButton>
      );
    case StatusConstants.userInvitation.pending:
      return (
        <>
          {isExpired && (
            <>
              <TextButton
                onClick={() => onAction(INVITATION_ACTIONS.resend, id)}
              >
                Resend
              </TextButton>
              <span className="border border-gray-500 mx-2" />
            </>
          )}
          <TextButton
            onClick={() => onAction(INVITATION_ACTIONS.cancel, id)}
          >
            Cancel
          </TextButton>
        </>
      );
    default:
      return null;
  }
}

export default function InvitationActionsColumn({ column, row, onAction }) {
  const { id, isExpired } = row;
  const value = getColumnValue(row, column);
  return (
    <td className={`${TABLE_CELL_CLASS}`}>
      {value && value.apiName && renderActions(id, value.apiName, isExpired, onAction)}
    </td>
  );
}

InvitationActionsColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      isExpired: PropTypes.bool,
    },
  ).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
};
