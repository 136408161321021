import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';

const listObjectFields = (object) => (
  <ul>
    {Object.keys(object)
      .map((key) => (
        <li key={`debug-${key}`}>
          {`${key}: ${object[key]}`}
        </li>
      ))}
  </ul>
);

export default function DebugColumn({ row, column }) {
  const { id } = row;
  const { field, valueGetter } = column;
  const value = valueGetter ? valueGetter(row[field]) : row[field];
  return (
    <td key={`cel-debug-${id}-${field}`} className={TABLE_CELL_CLASS}>
      {value && listObjectFields(value)}
    </td>
  );
}

DebugColumn.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
    valueGetter: PropTypes.func,
  }).isRequired,
};
