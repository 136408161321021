import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

const colors = {
  success: 'green',
  danger: 'red',
};

const icons = {
  success: CheckCircleIcon,
  danger: ExclamationCircleIcon,
};

export default function Alert(props) {
  const {
    show, title, message, onClose, type,
  } = props;

  const color = colors[type];
  const Icon = icons[type];

  if (show) {
    setTimeout(() => {
      onClose(false);
    }, 5000);
  }

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
      >
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`max-w-xl w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden bg-${color}-100`}>
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <Icon className={`h-6 w-6 text-${color}-500`} aria-hidden="true" />
                </div>
                <div className={`ml-3 w-0 flex-1 pt-0.5 text-left text-sm text-${color}-800`}>
                  <p className="font-medium">{title}</p>
                  <p className={`mt-1 text-${color}-600`}>
                    {message}
                  </p>
                  <div className="mt-3 flex space-x-7">
                    <button
                      type="button"
                      className={`font-medium hover:text-${color}-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={() => onClose(false)}
                    >
                      Dismiss
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'danger']),
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  type: 'success',
  title: '',
  message: '',
  onClose: () => {},
};
