import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';
import ExternalStatusConstants from '../../../config/ExternalStatusConstants';

export const getBadgeTypeFromCardExternalStatus = (externalStatusName) => {
  switch (externalStatusName) {
    case ExternalStatusConstants.Closed:
    case ExternalStatusConstants.Lost:
    case ExternalStatusConstants.Replaced:
    case ExternalStatusConstants.Expired:
    case ExternalStatusConstants.Revoked:
    case ExternalStatusConstants.CClosed:
    case ExternalStatusConstants.MBClosed:
    case ExternalStatusConstants.Fraud:
    case ExternalStatusConstants.LastStmt:
    case ExternalStatusConstants.Chargeoff:
    case ExternalStatusConstants.Deceased:
    case ExternalStatusConstants.Warning:
    case ExternalStatusConstants.MUClosed:
    case ExternalStatusConstants.Void:
    case ExternalStatusConstants.Nonrenewal:
    case ExternalStatusConstants.Destroyed:
      return 'success';
    case ExternalStatusConstants.Dormant:
    case ExternalStatusConstants.Ready:
    case ExternalStatusConstants.Suspended:
    case ExternalStatusConstants.PFraud:
      return 'warning';
    case ExternalStatusConstants.Active:
    case ExternalStatusConstants.SActive:
      return 'danger';
    default:
      return 'default';
  }
};

export const getBadgeColorsForExternalStatus = (apiName) => {
  switch (getBadgeTypeFromCardExternalStatus(apiName)) {
    case 'success':
      return 'green-600';
    case 'teal':
      return 'teal-600';
    case 'danger':
      return 'red-600';
    case 'warning':
      return 'yellow-400';
    case 'orange':
      return 'orange-600';
    default:
      return 'gray-600';
  }
};


const getRenderForExternalStatus = (children, externalStatusName) => {
  if (children) {
    return children;
  }
  if(!externalStatusName){
    return 'unknown'
  }
  return externalStatusName;
};

const style = 'items-center px-2.5 py-0.5 pt-0.5 pb-0.5 rounded-md text-xs font-medium bg-gray-50 border';


export function CardBorderExternalStatusBadge({ externalStatusName, children }) {
  return (
    <span className={`text-black ${style} border-${getBadgeColorsForExternalStatus(externalStatusName)}`}>
      {getRenderForExternalStatus(children, externalStatusName)}
    </span>
  );
}

export function CardTextExternalStatusBadge({ externalStatusName, children }) {
  return (
    <span className={` border-gray-200 rounded-full ${style} text-${getBadgeColorsForExternalStatus(externalStatusName)}`}>
      {getRenderForExternalStatus(children, externalStatusName)}
    </span>
  );
}

export function CardRowExternalStatusBadge({
  row: { id, externalStatusName },
  children
}) {
  return (<td key={`cel-${id}-status`} className={TABLE_CELL_CLASS}>
    {externalStatusName && (
      <span className={`text-black ${style} 
    border-${getBadgeColorsForExternalStatus(externalStatusName)}`}
      >
        {getRenderForExternalStatus(children, externalStatusName)}
      </span>
    )}
  </td>)
}

CardRowExternalStatusBadge.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      externalStatusName: PropTypes.string,
    },
  ).isRequired,
  children: PropTypes.node,
}
CardRowExternalStatusBadge.defaultProps = {
  children: null,
};

CardTextExternalStatusBadge.propTypes = {
  externalStatusName: PropTypes.string,
  children: PropTypes.node,
};
CardTextExternalStatusBadge.defaultProps = {
  children: null,
};
CardBorderExternalStatusBadge.propTypes = CardTextExternalStatusBadge.propTypes;
CardBorderExternalStatusBadge.defaultProps = CardTextExternalStatusBadge.defaultProps;
