import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetTenantsFilter(options) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['filters', 'admin', 'tenants'],
    async () => api.admin.tenants.fetchTenantsFilter(apiClient),
    { enabled: true, ...options }
  );
}
