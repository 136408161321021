import api from 'src/api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getMyPermissions = async (client) => api.users.fetchMyPermissions(client);

export default function useGetMyPermissions(options) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['me', 'permissions'],
    async () => getMyPermissions(apiClient),
    options,
  );
}
