const uploadPicture = async (client, userId, params) => client.post(`api/users/${userId}/picture`, params, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})
  .then((response) => response.data);

const removePicture = async (client, userId, params = {}) => client.put(`api/users/${userId}/picture/remove`, params)
  .then((response) => response.data);

const fetchActivityLog = async (client, params = {}) => client.get(`api/users/${params.userId}/log`)
  .then((response) => response.data);

export default {
  uploadPicture,
  removePicture,
  fetchActivityLog,
};
