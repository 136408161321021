import React, { useState, useEffect, useMemo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { InformationCircleIcon, CalendarIcon, ClockIcon, XIcon } from '@heroicons/react/outline';
import { isAfter } from 'date-fns';
import PropTypes from 'prop-types';
import Button from '../../buttons/Button';
import CustomDatePickerWrapper from '../CustomDatePickerWrapper';
import { formatDateRange, formatDefaultDate } from './customTimePeriodHelper';
import useOnClickOutsideRef from '../../../hooks/ref/useOnClickOutsideRef';
import { formatDateTime } from '../../../utils/date';

const CustomTimePeriod = ({
  selectedTimePeriod,
  maxEndDateLimit,
  minEndTimeLimit,
  maxEndTimeLimit,
  handleDateLimit,
  handleTimeLimit,
  dateFilter,
  setDateFilter,
  startDefaultTime,
  timeIntervals,
  handleResetDateFilter
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(startDefaultTime);
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isOpenedCustomTimePeriodMenu, setOpenCustomTimePeriodMenu] = useState(false);
  const initialDateFormat = formatDefaultDate(dateFilter?.startDate, dateFilter?.endDate);
  const [formattedDate, setFormattedDate] = useState(initialDateFormat);
  const [minEndDateLimit, setMinEndDateLimit] = useState(new Date());
  const timePeriodRef = useRef();
  const today = new Date();

  const handleInitialDateChange = (date) => {
    setStartDate(date);
    setEndDate(null);
    setMinEndDateLimit(date);
    if (handleDateLimit) handleDateLimit(date);
  };

  const handleInitialTimeChange = (date) => {
    setStartTime(date);
    setEndTime(null);
    if (handleTimeLimit) {
      handleTimeLimit(date);
    }
  };

  const handleClearDate = () => {
    setFormattedDate(null);
    setStartDate(new Date());
    setStartTime(startDefaultTime);
    setEndDate(new Date());
    setEndTime(new Date());
    setDateFilter(null);
  };

  const handleCustomDate = () => {
    setOpenCustomTimePeriodMenu(true);
  };

  const handleCancel = () => {
    if(!dateFilter && handleResetDateFilter) {
      handleResetDateFilter()
    }
    setOpenCustomTimePeriodMenu(false);
  };

  useEffect(() => {
    if (!dateFilter) {
      handleClearDate();
    }
  }, [dateFilter]);

  useEffect(() => {
    if (selectedTimePeriod && selectedTimePeriod === 'custom') {
      setOpenCustomTimePeriodMenu(true);
    }
  }, [selectedTimePeriod]);

  const formatFullDate = () => {
    const startDateTime = formatDateTime(startDate, new Date(startTime));
    const endDateTime = formatDateTime(endDate, new Date(endTime));

    return { startDateTime, endDateTime };
  };

  const isDateValid = useMemo(() => {
    if (!startDate || !endDate || !startTime || !endTime) {
      return false;
    }
    const { startDateTime, endDateTime } = formatFullDate();

    const isDateCorrect = isAfter(endDateTime, startDateTime);
    const isEndDateBigger = endDateTime.getTime() > startDateTime.getTime();
    return isDateCorrect || isEndDateBigger;
  }, [startDate, endDate, startTime, endTime]);

  const handleApply = () => {
    if (!startDate || !endDate || !startTime || !endTime) {
      setOpenCustomTimePeriodMenu(true);
    }
    setOpenCustomTimePeriodMenu(false);

    const formattedDateRange = formatDateRange(startDate, startTime, endDate, endTime);
    const { startDateTime, endDateTime } = formatFullDate();

    setFormattedDate(formattedDateRange);
    return setDateFilter({
      startDate: startDateTime,
      endDate: endDateTime,
    });
  };

  const position = isOpenedCustomTimePeriodMenu ? 'absolute z-50' : 'inherit';
  const defaultFullDate = startDate && endDate && startTime && endTime;
  const isCustomTimePeriodMenuClosed = defaultFullDate && formattedDate && !isOpenedCustomTimePeriodMenu;

  useEffect(() => {
    if (selectedTimePeriod && selectedTimePeriod === 'custom' && !initialDateFormat) {
      setOpenCustomTimePeriodMenu(true);
    } else { setOpenCustomTimePeriodMenu(false) };
  }, [selectedTimePeriod, initialDateFormat]);

  useOnClickOutsideRef(timePeriodRef, () => {
    if (!formattedDate || !isDateValid) {
      setOpenCustomTimePeriodMenu(false);
    } else { setOpenCustomTimePeriodMenu(false) };
  });

  return (
    <>
      {isCustomTimePeriodMenuClosed ? (
        <div className="gap-x-2.5 inline-flex justify-center items-center bg-blue-50 rounded-sm">
          <span
            aria-hidden
            onClick={handleCustomDate}
            className="border-r border-blue-700 text-sm text-gray-900 px-3 cursor-pointer"
          >
            {formattedDate}
          </span>
          <div className="pr-2.5">
            <XIcon className="w-3.5 text-gray-900 cursor-pointer" onClick={handleClearDate} />
          </div>
        </div>
      ) : (
        isOpenedCustomTimePeriodMenu && (
          <div className="relative" id="time-period" ref={timePeriodRef}>
            <div className={`${position}`}>
              <div className=" bg-white pt-3.5 pb-6 border">
                <div className="h-32 w-max">
                  <div className="flex gap-x-2.5 pl-2.5 pr-5">
                    <InformationCircleIcon className="w-3 text-blue-600" />
                    <span className="text-xs text-gray-800">
                      Any dates can be selected, but the maximum range is (3 days).
                    </span>
                  </div>
                  <div className="flex items-center mt-3 pl-3.5">
                    <span className="text-sm text-gray-500">From</span>&nbsp;
                    <div className="flex justify-evenly w-full ml-2">
                      <div>
                        <DatePicker
                          placeholder="Select a date"
                          selected={startDate}
                          maxDate={today}
                          onChange={handleInitialDateChange}
                          popperModifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [50, 1],
                              },
                            },
                          ]}
                          customInput={
                            <CustomDatePickerWrapper
                              size="medium"
                              placeholderText="MM/DD/YYY"
                              icon={<CalendarIcon className="w-3 text-gray-500 cursor-pointer" />}
                            />
                          }
                        />
                      </div>
                      <div>
                        <DatePicker
                          placeholder="Select a time"
                          selected={startTime}
                          onChange={handleInitialTimeChange}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={timeIntervals}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          popperModifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [30, 1],
                              },
                            },
                          ]}
                          customInput={
                            <CustomDatePickerWrapper
                              placeholderText="HH:MM"
                              icon={<ClockIcon className="w-3 text-gray-500 cursor-pointer" />}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-3 items-center pl-3.5">
                    <span className="text-sm text-gray-500">To</span>&nbsp;
                    <div className="flex justify-evenly w-full ml-6">
                      <div>
                        <DatePicker
                          placeholder="Select a date"
                          selected={endDate}
                          minDate={minEndDateLimit}
                          maxDate={maxEndDateLimit}
                          onChange={(date) => setEndDate(date)}
                          popperModifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [50, 1],
                              },
                            },
                          ]}
                          customInput={
                            <CustomDatePickerWrapper
                              size="medium"
                              placeholderText="MM/DD/YYY"
                              icon={<CalendarIcon className="w-3 text-gray-500 cursor-pointer" />}
                            />
                          }
                        />
                      </div>
                      <div>
                        <DatePicker
                          placeholder="Select a time"
                          selected={endTime}
                          onChange={(date) => setEndTime(date)}
                          minTime={minEndTimeLimit}
                          maxTime={maxEndTimeLimit}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={timeIntervals}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          popperModifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [30, 1],
                              },
                            },
                          ]}
                          customInput={
                            <CustomDatePickerWrapper
                              placeholderText="HH:MM"
                              icon={<ClockIcon className="w-3 text-gray-500 cursor-pointer" />}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pl-3.5 bg-white pt-8 border-t mt-3">
                  {!isDateValid && (
                    <div className="pb-4">
                      <span className="text-sm text-red-500">Incorrect date&time field selection</span>
                    </div>
                  )}
                  <div className="flex gap-x-4">
                    <Button buttonStyle="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      className="rounded-sm"
                      onClick={handleApply}
                      disabled={!isDateValid}
                      buttonStyle="primary"
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default CustomTimePeriod;

CustomTimePeriod.propTypes = {
  selectedTimePeriod: PropTypes.string,
  maxEndDateLimit: PropTypes.instanceOf(Date),
  maxEndTimeLimit: PropTypes.instanceOf(Date),
  minEndTimeLimit: PropTypes.instanceOf(Date),
  startDefaultTime: PropTypes.number,
  timeIntervals: PropTypes.number,
  handleDateLimit: PropTypes.func,
  handleTimeLimit: PropTypes.func,
  setDateFilter: PropTypes.func.isRequired,
  dateFilter: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  handleResetDateFilter: PropTypes.func
};

CustomTimePeriod.defaultProps = {
  startDefaultTime: new Date().setHours(new Date().getHours() - 2),
  timeIntervals: 30,
  selectedTimePeriod: null,
  handleDateLimit: null,
  handleTimeLimit: null,
  maxEndDateLimit: null,
  maxEndTimeLimit: null,
  minEndTimeLimit: null,
  handleResetDateFilter: null,
  dateFilter: null,
};
