import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';

export default function CheckboxColumn({
  row, onAction, column, checked, disabled, checkBoxClassName
}) {
  const { id } = row;
  return (
    <td key={`cel-${id}-checkbox`} className={TABLE_CELL_CLASS}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        className={checkBoxClassName}
        onChange={() => onAction && onAction(column.actionName, row)}
        data-cy="cb-column"
      />
    </td>
  );
}

CheckboxColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      statusApiName: PropTypes.string,
    },
  ).isRequired,
  column: PropTypes.shape({
    actionName: PropTypes.string,
  }).isRequired,
  checked: PropTypes.bool,
  onAction: PropTypes.func,
  disabled: PropTypes.bool,
  checkBoxClassName: PropTypes.string,
};

CheckboxColumn.defaultProps = {
  onAction: null,
  checked: false,
  disabled: false,
  checkBoxClassName: ''
};
