import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Filters from '../../../components/Filters';
import SearchFilter from '../../../components/filters/SearchFilter';
import SelectFilter from '../../../components/filters/SelectFilter';
import TextButton from '../../../components/buttons/TextButton';
import ToggleColumn from '../../../components/table/columns/ToggleColumn';
import DataTable from '../../../components/table/DataTable';
import { selectTenant, setShowSelectedTenant } from '../../../store/features/admin/adminSlice';
import PaginationConfig from '../../../config/PaginationConfig';
import StatusConfirmationModal from '../../../components/modals/StatusConfirmationModal';
import { getStatusModelContent, getStatusModelTitle } from '../../../utils/tenantUtils';
import { getName } from '../../../utils/userUtil';
import { toastWithPromise } from '../../../components/Toast/Toast';
import { formatDateOnly } from '../../../utils/date';
import TextButtonColumn from '../../../components/table/columns/TextButtonColumn';
import TenantUsersDetails from '../../../components/table/columns/TenantUsersDetails';
import useGetTenants from '../../../hooks/admin/tenants/useGetTenants';
import useUpdateTenant from '../../../hooks/admin/tenants/useUpdateTenant';

const OPEN_TENANT_DETAILS_ACTION = 'open_tenant_details';
const CHANGE_ACTIVE_ACTION = 'change-active-action';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'usersCount',
    headerName: 'Users',
    headerClassName: 'justify-center',
    className: 'text-center',
    render: TenantUsersDetails,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Modified On',
    headerClassName: 'justify-center',
    className: 'text-center',
    valueGetter: (field) => formatDateOnly(field),
  },
  {
    field: 'changedBy',
    headerName: 'Last Modified By',
    headerClassName: 'justify-center',
    className: 'text-center',
    valueGetter: (field) => getName(field),
  },
  {
    field: 'sources',
    headerName: 'Source',
    headerClassName: 'justify-center',
    className: 'text-center',
    valueGetter: (field) => (field ? field.name : ''),
  },
  {
    field: 'isActive',
    headerName: 'Status',
    render: ToggleColumn,
    actionName: CHANGE_ACTIVE_ACTION,
  },
  {
    field: 'id',
    headerName: 'Profile',
    valueGetter: () => 'View',
    className: 'text-blue-300',
    render: TextButtonColumn,
    actionName: OPEN_TENANT_DETAILS_ACTION,
  },
];

export default function Tenants() {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [searchValue, setSearchValue] = useState('');
  const [disabledClearFilter, setDisabledClearFilter] = useState(true);
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [activeStatusModel, setActiveStatusModel] = useState({ showModal: false });
  const updateTenant = useUpdateTenant();
  const { data, isLoading } = useGetTenants({
    search,
    itemsPerPage,
    page,
    isActive: statusFilter ? statusFilter.value : undefined,
  });
  const tenants = data ? data.rows : [];
  const count = data ? data.count : 0;
  const dispatch = useDispatch();

  function updateTenantStatus(comment, row) {
    setActiveStatusModel({ ...activeStatusModel, showModal: false });
    const payload = {};
    if (row.isActive) {
      payload.isActive = false;
    } else {
      payload.isActive = true;
    }
    if (comment) {
      payload.comment = comment;
    }
    toastWithPromise(
      updateTenant.mutateAsync({
        id: row.id,
        data: payload,
      }),
      {
        loading: 'Updating tenant...',
        success: 'Tenant updated successfully',
        error: 'An error occurred while updating the tenant',
      },
    );
  }
  function onTableAction(action, row) {
    switch (action) {
      case OPEN_TENANT_DETAILS_ACTION:
        dispatch(selectTenant(row));
        dispatch(setShowSelectedTenant(true));
        break;
      case CHANGE_ACTIVE_ACTION:
        setActiveStatusModel({
          ...activeStatusModel,
          row,
          showModal: true,
        });
        break;
      default:
        break;
    }
  }
  const updateClearAll = (status) => {
    if (!status && !searchValue) {
      setDisabledClearFilter(true);
    } else {
      setDisabledClearFilter(false);
    }
  };
  const onSearchChange = (value) => {
    setSearchValue(value);
    if (value) {
      setDisabledClearFilter(false);
    }
    if (value && value.trim().length > 2) {
      setSearch(value.trim());
    } else {
      setSearch('');
    }
  };
  const clearFilters = () => {
    setSearch('');
    setSearchValue('');
    setStatusFilter('');
    setDisabledClearFilter(true);
  };
  const onPageClick = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <div>
        <div className="items-start justify-between lg:flex">
          <Filters>
            <SearchFilter
              onChange={onSearchChange}
              value={searchValue}
            />
            <SelectFilter
              id="status-filter"
              isSearchable={false}
              placeholder="Select Status"
              options={[
                { value: '', label: 'All' },
                { value: true, label: 'Active' },
                { value: false, label: 'Inactive' },
              ]}
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e); updateClearAll(e);
              }}
            />
            <TextButton
              disabled={disabledClearFilter}
              onClick={clearFilters}
            >
              Clear all
            </TextButton>
          </Filters>
        </div>
        <DataTable
          columns={columns}
          showPagination
          data={tenants}
          loading={isLoading}
          paginationProps={{
            totalItems: count,
            currentPage: page,
            itemsPerPage,
            onPageClick,
            onItemsPerPageChange: (e) => { setItemsPerPage(e); },
          }}
          onAction={(action, row, value) => onTableAction(action, row, value)}
        />
      </div>
      <StatusConfirmationModal
        {...activeStatusModel}
        title={getStatusModelTitle(activeStatusModel && activeStatusModel.row
          && activeStatusModel.row.isActive)}
        content={getStatusModelContent(activeStatusModel && activeStatusModel.row
          && activeStatusModel.row.isActive)}
        onClose={() => setActiveStatusModel({ ...activeStatusModel, showModal: false })}
        primaryAction={(comment) => updateTenantStatus(comment, activeStatusModel.row)}
        primaryText={getStatusModelTitle(activeStatusModel && activeStatusModel.row
          && activeStatusModel.row.isActive)}
      />
    </>
  );
}
