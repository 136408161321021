import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from '@heroicons/react/outline';
import useGetMccByMccId from '../../hooks/api/mccs/useGetMccByMccId';
import Tooltip from '../Tooltip';
import Spinner from '../Spinner';
import { classNames } from '../../utils/ui';

export default function MccDetails({ value, id, wrapperClassName }) {
  const MCC_CLASS =
    'font-medium focus:outline-none text-left text-blue-600 hover:text-blue-500 cursor-pointer flex';
  const [enableMccData, setEnableMccData] = useState(false);
  const { isLoading, data, isError } = useGetMccByMccId(value, { enabled: enableMccData, retry: false });

  const handleMccClick = async () => {
    setEnableMccData(true);
  };

  const MCC_DESCRIPTION_ID = `mcc-${id}`;

  return (
    <div key={id} className={wrapperClassName} id={MCC_DESCRIPTION_ID}>
      <button
        id={MCC_DESCRIPTION_ID}
        type="button"
        onClick={() => handleMccClick()}
        className={classNames(MCC_CLASS, 'text-sm')}
      >
        <span>{value}</span> <SearchIcon className="w-2 h-2" />
      </button>

      {isLoading && <Spinner size="8" />}

      {isError ? (
        <span
          id={MCC_DESCRIPTION_ID}
          className={classNames('text-xs w-48 truncate', 'text-gray-500 block cursor-pointer capitalize')}
        >
          MCC not found
        </span>
      ) : (
        <>
          <span
            data-tip=""
            data-for={MCC_DESCRIPTION_ID}
            className={classNames('text-gray-500 block cursor-pointer capitalize', 'text-xs w-48 truncate')}
          >
            {data?.description}
          </span>
          {data && (
            <Tooltip position="bottom" id={MCC_DESCRIPTION_ID}>
              {data.description}
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
}

MccDetails.propTypes = {
  value: PropTypes.number.isRequired,
  wrapperClassName: PropTypes.string,
  id: PropTypes.number.isRequired,
};

MccDetails.defaultProps = {
  wrapperClassName: '',
};
