/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export default function Label({
  children, className, isRequired, ...attrs
}) {
  return (
    <label className={`inline-block font-medium ${className}`} {...attrs}>
      {isRequired && (
        <span className="text-red-600">*</span>
      )}
      {children}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
};

Label.defaultProps = {
  isRequired: false,
  className: '',
};
