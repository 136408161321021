import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from './Merchant.keys';

const getSimilarMerchantsById = async (
  client, id, isBlockedById,
) => api.merchants.fetchSimilarMerchantsById(client, id, isBlockedById);

export default function useGetSimilarMerchantsById(id, isBlockedById, options={}) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.blockedIdList(id, isBlockedById),
    async () => getSimilarMerchantsById(apiClient, id, isBlockedById),
    options
  );
}
