import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';
import { classNames } from '../../utils/ui';

const ActionButton = ({ testId, children, isDisabled, onClick, iconClassName, Icon }) => (
  <IconButton
    rounded
    buttonStyle="light"
    data-cy={testId}
    icon={
      <Icon
        className={classNames(
          isDisabled ? 'text-gray-500' : iconClassName
        )}
      />
    }
    onClick={onClick}
    disabled={isDisabled}
  >
    {children}
  </IconButton>
);

ActionButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  iconClassName: PropTypes.string,
  testId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  Icon: PropTypes.elementType.isRequired,
}

ActionButton.defaultProps = {
  isDisabled: false,
  iconClassName: '',
  children: null,
  testId: ''
}

export default ActionButton;
