const MerchantKeys = {
  all: ['merchants'],
  lists: () => [...MerchantKeys.all, 'list'],
  list: (listName, params) => (params
    ? [...MerchantKeys.lists(), listName, params]
    : [...MerchantKeys.lists(), listName]),
  details: () => [...MerchantKeys.all, 'detail'],
  detail: (id) => [...MerchantKeys.details(), id],
  log: (id) => [...MerchantKeys.detail(id), 'log'],
  cards: (id, filters) => [...MerchantKeys.detail(id), 'cards', filters],
  pds: (filters) => [...MerchantKeys.all, 'pds', filters],
  blockedIdList: (id, isBlockedById) => [...MerchantKeys.detail(id), 'blockedIdList', isBlockedById],
  blockedNameList: (id, isBlockedByName) => [...MerchantKeys.detail(id), 'blockedNameList', isBlockedByName],
  activeMerchantAttacks: (id) => [...MerchantKeys.detail(id), "activeMerchantAttacks"]
};

export default MerchantKeys;
