import React from 'react';
import Grid from '../components/Grid';
import Title from '../components/headings/Title';
import InsightSummary from '../components/insights/InsightSummary';
import FraudInsights from '../components/insights/fraud/FraudInsights';
import CardInsights from '../components/insights/card/CardInsights';

export default function Insights() {
  return (
    <Grid>
      <div className="col-span-12 pt-5">
        <div className=" mb-6">
          <Title>Insights</Title>
        </div>
        <hr className="mb-6" />
        <InsightSummary />
        <hr className="my-8" />
        <FraudInsights />
        <CardInsights />
      </div>
    </Grid>
  );
}
