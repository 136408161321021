import { useQuery } from 'react-query';
import api from '../../../api';
import useApiClient from '../../useApiClient';
import InvitationKeys from './Invitation.keys';

const getInvitations = async (client, params) => api.admin.invitations.getInvitations(
  client, params,
);

export default function useGetInvitations(params) {
  const {
    tenantId,
    search
  } = params;
  const apiClient = useApiClient();
  return useQuery(
    InvitationKeys.list('all', params),
    async () => getInvitations(apiClient, params),
    {
      enabled: !!tenantId,
      search,
    },
  );
}
