import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import AdminRuleKeys from './AdminRules.keys';

export default function useUpdateTenantRule(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation((data) => api.admin.rules.updateTenantRule(client, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(AdminRuleKeys.all());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
