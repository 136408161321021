import React from 'react';
import PropTypes from 'prop-types';
import { CreditCardIcon } from '@heroicons/react/solid';
import { GlobeAltIcon } from '@heroicons/react/outline';
import StatusConstants from '../../../config/StatusConstants';
import Tooltip from '../../Tooltip';
import { TABLE_CELL_CLASS } from '../Table';

const formatResponse = (isApproved) =>
  isApproved ? StatusConstants.approvedTransactionApiName : StatusConstants.declinedTransactionApiName;

const presentStyle = 'w-5 h-5 text-gray-200 bg-gray-800 rounded-full py-0.5 px-0.5 cursor-pointer';
const notPresentStyle = 'w-5 h-5 text-gray-300 border border-gray-200 bg-white rounded-full py-0.5 px-0.5 cursor-pointer';

const CardPresentIconComponent = ({ id, isCardPresent }) =>
  isCardPresent !== null &&
  isCardPresent !== undefined && (
    <div className="ml-2.5">
      <div data-tip data-for={`transaction-is-card-present-${id}`}>
        {isCardPresent ? (
          <CreditCardIcon className={presentStyle} />
        ) : (
          <CreditCardIcon className={notPresentStyle} />
        )}
      </div>

      <Tooltip className="max-w-xs" id={`transaction-is-card-present-${id}`}>
        {isCardPresent ? 'Card present' : 'Card not present'}
      </Tooltip>
    </div>
  );

CardPresentIconComponent.propTypes = {
  id: PropTypes.number.isRequired,
  isCardPresent: PropTypes.bool,
};

CardPresentIconComponent.defaultProps = {
  isCardPresent: false,
};

const InternationalTransactionIconComponent = ({ id, isInternationalTransaction }) =>
  isInternationalTransaction !== null &&
  isInternationalTransaction !== undefined && (
    <div className="ml-2.5">
      <div
        data-tip
        data-for={`${
          isInternationalTransaction
            ? `international-transaction-present-${id}`
            : `international-transaction-not-present-${id}`
        } `}
      >
        {isInternationalTransaction ? (
          <GlobeAltIcon className={presentStyle} />
        ) : (
          <GlobeAltIcon className={notPresentStyle} />
        )}
      </div>

      <Tooltip
        className="max-w-xs"
        id={`${
          isInternationalTransaction
            ? `international-transaction-present-${id}`
            : `international-transaction-not-present-${id}`
        } `}
      >
        {isInternationalTransaction
          ? 'International Transaction present'
          : 'International Transaction not present'}
      </Tooltip>
    </div>
  );

InternationalTransactionIconComponent.propTypes = {
  id: PropTypes.number.isRequired,
  isInternationalTransaction: PropTypes.bool,
};

InternationalTransactionIconComponent.defaultProps = {
  isInternationalTransaction: false,
};

export default function ResponseColumn({
  row: {
    id,
    responseCodeDescription,
    reasonCodeDescription,
    isApproved,
    isCardPresent,
    isInternationalTransaction,
  },
}) {
  return (
    <td className={TABLE_CELL_CLASS}>
      <div className="flex">
        <span
          className={`items-center px-2.5 py-0.5 pt-0.5 pb-0.5 rounded-md text-xs font-medium bg-gray-50 border border-gray-200 rounded-full ${
            isApproved ? 'text-green-600' : 'text-red-600'
          }`}
        >
          {formatResponse(isApproved)}
        </span>

        <CardPresentIconComponent id={id} isCardPresent={isCardPresent} />
        <InternationalTransactionIconComponent
          id={id}
          isInternationalTransaction={isInternationalTransaction}
        />
      </div>

      {responseCodeDescription && (
        <div className="flex flex-col w-40 mt-2 space-y-1 text-xs text-gray-500 truncate">
          {reasonCodeDescription && (
            <>
              <span data-tip data-for={`transaction-reason-${id}`} className="truncate focus:outline-none">
                Reason: {reasonCodeDescription}
              </span>
              <Tooltip id={`transaction-reason-${id}`}>{reasonCodeDescription}</Tooltip>
            </>
          )}
          <span data-tip data-for={`transaction-response-${id}`} className="truncate focus:outline-none">
            Response: {responseCodeDescription}
          </span>
          <Tooltip id={`transaction-response-${id}`}>{responseCodeDescription}</Tooltip>
        </div>
      )}
    </td>
  );
}

ResponseColumn.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isApproved: PropTypes.bool.isRequired,
    isCardPresent: PropTypes.bool,
    isInternationalTransaction: PropTypes.bool,
    responseCodeDescription: PropTypes.string,
    reasonCodeDescription: PropTypes.string,
  }).isRequired,
};
