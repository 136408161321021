const fetchAdminRules = async (client, params) => client.get('api/admin/rules', {
  params,
})
  .then((response) => response.data);

const fetchAdminRulesInsights = async (client, params) => client.get('api/admin/rules/insights', {
  params,
})
  .then((response) => response.data);

const fetchAdminRulesPerformanceSummary = async (client, params) => client.get('api/admin/rules/performance/summary', {
  params,
})
  .then((response) => response.data);

const fetchAdminRulesPerformanceList = async (client, params) => client.get('api/admin/rules/performance/list', {
  params,
})
  .then((response) => response.data);

const updateTenantRule = async (client, params) => client.post('api/admin/rules', params)
  .then((response) => response.data);

const fetchRulesFilter = async (client, data) => client.get('api/admin/rules/filter', {
  params: data,
})
  .then((response) => response.data);

export default {
  fetchAdminRules,
  updateTenantRule,
  fetchAdminRulesInsights,
  fetchAdminRulesPerformanceSummary,
  fetchAdminRulesPerformanceList,
  fetchRulesFilter,
};
