import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import UserKeys from './User.keys';

const getUsers = async (
  client,
  params,
) => api.admin.users.fetchUsers(client, params);

export default function useGetUsers(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    UserKeys.list('all', params),
    async () => getUsers(apiClient, params),
  );
}
