export default {
  hideRuleValidationTab: 'hide-rule-validation-tab',
  hideFalsePositiveRiskLevel: 'hide-false-positive-risk-level',
  hidePaymentMethod: 'hide-payment-method',
  isPerformancePOC: 'use-performance-poc',
  hideTask: 'hide-task',
  hideCardActions: 'hide-card-actions',
  useTransactionDetails: 'use-transaction-details',
  useCardBulkActions: 'use-card-bulk-actions',
  useMerchantBulkActions: 'use-merchant-bulk-actions'
};
