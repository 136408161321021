import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import CardKeys from './Card.keys';

export default function useGetCardTransactions(
  cardId,
  params,
) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      CardKeys.transactions(cardId),
      params,
    ],
    () => api.transactions.fetchCardTransactions(apiClient, cardId, params),
  );
}
