import React from 'react';
import PropTypes from 'prop-types';
import FMSelect from '../forms/FMSelect';

export default function SelectFilter({ className, ...props }) {
  return (
    <div className={className}>
      <FMSelect
        isClearable
        {...props}
      />
    </div>
  );
}

SelectFilter.propTypes = {
  className: PropTypes.string,
};

SelectFilter.defaultProps = {
  className: 'w-52',
};
