import React from 'react';
import PropTypes from 'prop-types';

export default function CheckboxHeader(props) {
  const { disabled, className, checked, onChange } = props;
  return (
    <input type="checkbox" disabled={disabled} className={className} checked={checked} onChange={onChange} />
  );
}

CheckboxHeader.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  column: PropTypes.shape({
    field: PropTypes.string,
  }),
};

CheckboxHeader.defaultProps = {
  onChange: null,
  checked: false,
  disabled: false,
  className: '',
  column: null
};
