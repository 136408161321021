import React from 'react';
import PropTypes from 'prop-types';
import FMSelect from '../forms/FMSelect';
import PaginationInfo from './PaginationInfo';
import { classNames } from '../../utils/ui';

export default function PaginationHeader({
  totalItems,
  itemsPerPage,
  currentPage,
  onItemsPerPageChange,
  className,
  optionsAdd,
  hideInfo,
}) {
  let options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
  ];

  if (optionsAdd) {
    options = [...options, ...optionsAdd];
  }
  return (
    <div
      className={classNames(
        'flex justify-between items-center mb-2',
        className
      )}
    >
      {!hideInfo ? (
        <PaginationInfo
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
        />
      ) : (
        <div className="my-2" />
      )}

      <div className="flex items-center">
        <span className="text-sm mr-2 text-gray-500">Results per page:</span>
        <div className="w-18">
          <FMSelect
            size="small"
            isSearchable={false}
            isClearable={false}
            options={options}
            value={options.filter((option) => option.value === itemsPerPage)}
            defaultValue={itemsPerPage}
            onChange={(e) => e && onItemsPerPageChange(e.value)}
          />
        </div>
      </div>
    </div>
  );
}

PaginationHeader.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
  hideInfo: PropTypes.bool,
  optionsAdd: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

PaginationHeader.defaultProps = {
  className: null,
  optionsAdd: null,
  hideInfo: false,
};
