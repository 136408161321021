import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useGetCardNumber from '../../hooks/api/cards/useGetCardNumber';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import Modal from '../Modal';
import Checkbox from '../forms/Checkbox';
import { toastWithPromise } from '../Toast/Toast';

export default function CardNumber({ id, lastFour }) {
  const { isLoading, mutateAsync } = useGetCardNumber();
  const [hideCardNumberWarning, setHideCardNumberWarning] = useState(false);
  const [showCardNumberModal, setShowCardNumberModal] = useState(false);
  const [fullCardNumber, setFullCardNumber] = useState(null);

  const handleGetCardNumber = () => {
    setShowCardNumberModal(false);
    mutateAsync({ cardId: id, hideCardNumberWarning })
      .then((({ response }) => {
        setFullCardNumber(response.result);
      }));
  };

  const handleCopyToClipboard = () => {
    toastWithPromise(
      navigator.clipboard.writeText(fullCardNumber),
      {
        loading: 'Copying to clipboard...',
        success: 'Card number copied to clipboard',
      },
    );
  };


  return (
    <>
      <div className="flex items-center space-x-2">
        <span className="block text-xs text-gray-500">Card details</span>
        {isLoading && <Spinner size="6" />}
      </div>
      <div>
        <span className="block mt-1 font-semibold text-gray-800 text-normal">
          {fullCardNumber ? (
            <>
              <button
                onClick={handleCopyToClipboard}
                className="block mt-1 font-semibold text-gray-800 text-normal"
                type="button"
                data-tip
                data-for="full-card-number"
              >
                {fullCardNumber}
              </button>
              <Tooltip id="full-card-number">Click to copy</Tooltip>
            </>
          ) : (
            `* ${lastFour}`
          )}
        </span>
      </div>
      <Modal
        showModal={showCardNumberModal}
        primaryAction={handleGetCardNumber}
        onClose={() => setShowCardNumberModal(false)}
        primaryText="I understand"
        title={
          <div>
            <span className="text-xl font-semibold leading-7 text-black">
              Card number
            </span>
          </div>
        }
      >
        <>
          <p className="mb-6 text-sm font-normal leading-5 text-gray-600">
            Viewing card number is being recorded for security reasons.
          </p>
          <Checkbox
            isChecked={hideCardNumberWarning}
            onChange={setHideCardNumberWarning}
          >
            <span className="text-sm font-medium leading-5 text-gray-500">
              Don&apos;t show again
            </span>
          </Checkbox>
        </>
      </Modal>
    </>
  );
}

CardNumber.propTypes = {
  id: PropTypes.number.isRequired,
  lastFour: PropTypes.string.isRequired,
};
