import { useMutation } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';

export default function useUploadPicture(
  options,
) {
  const client = useApiClient();

  return useMutation(({ id, data }) => api.account.uploadPicture(client, id, data), {
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
