import React from 'react';
import PropTypes from 'prop-types';
import { ChatIcon } from '@heroicons/react/solid';
import { TABLE_CELL_CLASS } from '../../Table';
import Badge from '../../../Badge';
import StatusConstants from '../../../../config/StatusConstants';
import Tooltip from '../../../Tooltip';
import { getColumnValue, getColumnValue2 } from '../../DataTableUtils';

function getBadgeType(statusApiName, isExpired) {
  switch (statusApiName) {
    case StatusConstants.userInvitation.accepted:
      return 'success';
    case StatusConstants.userInvitation.declined:
    case StatusConstants.userInvitation.cancelled:
      return 'danger';
    default:
      if (isExpired) {
        return 'warning';
      }
      return 'default';
  }
}

function getBadgeText(statusApiName, isExpired) {
  switch (statusApiName) {
    case StatusConstants.userInvitation.accepted:
      return 'Accepted';
    case StatusConstants.userInvitation.declined:
      return 'Declined';
    case StatusConstants.userInvitation.cancelled:
      return 'Cancelled';
    default:
      if (isExpired) {
        return 'Expired';
      }
      return 'Pending';
  }
}

export default function InvitationStatusColumn({ column, row }) {
  const { id } = row;
  const value = getColumnValue(row, column);
  const isNewRequested = getColumnValue2(row, column);
  const elementId = `invitation-status-${id}`;
  return (
    <td className={`${TABLE_CELL_CLASS}`}>
      {value && value.apiName && (
        <Badge type={getBadgeType(value.apiName, row.isExpired)}>
          {getBadgeText(value.apiName, row.isExpired)}
        </Badge>
      )}
      {isNewRequested && (
        <>
          <ChatIcon className="w-4 text-gray-600 inline-block ml-2" data-tip data-for={elementId} />
          <Tooltip id={elementId}>
            Resend request raised by User
          </Tooltip>
        </>
      )}
    </td>
  );
}

InvitationStatusColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      isExpired: PropTypes.bool,
    },
  ).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};
