import { Disclosure } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import {
  CreditCardIcon,
  MenuIcon,
  XIcon,
  ChartBarIcon,
  ShoppingBagIcon,
  StatusOnlineIcon,
  CogIcon,
} from '@heroicons/react/outline';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import UserMenu from './header/UserMenu';
import HeaderNav from './header/HeaderNav';
import ardenLogo from '../assets/arden-logo.png';
import StatusConstants from '../config/StatusConstants';
import PermissionConstants from '../config/PermissionConstants';
import { checkPermission } from '../utils/userUtil';
import useGetMe from '../hooks/api/users/useGetMe';
import useGetMyPermissions from '../hooks/api/users/useGetMyPermissions';
import { ReactComponent as ManualScanIcon } from '../assets/icons/manual-scan.svg';
import FeatureFlagConstants from '../config/FeatureFlagConstants';

const secondaryNavigation = [
  // {
  //   name: 'Guide',
  //   href: '/guide',
  //   icon: BookOpenIcon,
  // },
  // {
  //   name: 'Notifications',
  //   href: '/notifications',
  //   icon: BellIcon,
  // },
];

const defaultNavigationItems = [
  {
    name: 'Arden alert',
    href: '/home',
    icon: StatusOnlineIcon,
  },
  {
    name: 'Client alert',
    href: '/client-alert',
    icon: StatusOnlineIcon,
  },
  {
    name: 'Manual scan',
    href: '/pds',
    icon: ManualScanIcon,
  },
];

function Header() {
  const [navigation, setNavigation] = useState(defaultNavigationItems);
  const { isAuthenticated } = useAuth0();
  const { data: me } = useGetMe();
  const { data: permissions } = useGetMyPermissions();
  const location = useLocation();
  const updatePermission = () => {
    let array = [...defaultNavigationItems];
    if (permissions && permissions.length > 0) {
      if (checkPermission(permissions, PermissionConstants.VIEW_MERCHANTS_LIST)) {
        array.push({
          name: 'Merchants',
          href: '/merchants',
          icon: ShoppingBagIcon,
        });
      }
      if (checkPermission(permissions, PermissionConstants.VIEW_CARDS) && !me.featureFlags[FeatureFlagConstants.isPerformancePOC]) {
        array.push({
          name: 'Cards',
          href: '/cards',
          icon: CreditCardIcon,
        });
      }
      if (checkPermission(permissions, PermissionConstants.VIEW_INSIGHTS)) {
        array.push({
          name: 'Insights',
          href: '/insights',
          icon: ChartBarIcon,
        });
      }
    }

    if (me && me.roleApiName === StatusConstants.adminApiName) {
      array.push({
        name: 'Admin',
        href: '/admin',
        icon: CogIcon,
      });
    }
    if (me && me.featureFlags[FeatureFlagConstants.isPerformancePOC]) {
      array = array.filter(e=> e.name !== 'Client alert');
    }
    setNavigation(array);
  };
  useEffect(() => {
    if (me) {
      updatePermission();
    }
  }, [me, permissions]);
  return (
    <header className="bg-white border-b">
      <Disclosure>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-screen-2xl sm:px-3 lg:px-12 ">
              <div className="flex justify-between h-14">
                <div className="flex">
                  <div className="flex items-center mr-2 lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-3 text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block w-6 h-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex items-center flex-shrink-0">
                    <img src={ardenLogo} alt="arden logo" className="w-28" />
                  </div>
                  <nav aria-label="primary" className="hidden sm:-my-px sm:ml-6 lg:flex sm:space-x-8">
                    {isAuthenticated
                      && <HeaderNav navigationItems={navigation} location={location.pathname} />}
                  </nav>
                </div>
                <div className="flex sm:ml-6">
                  {isAuthenticated && (
                    <HeaderNav
                      navigationItems={secondaryNavigation}
                      location={location.pathname}
                      containerClasses="flex"
                      iconClasses="w-6 h-6 text-gray-600"
                      textClasses="hidden md:inline ml-2 text-gray-600"
                      baseClasses="inline-flex items-center text-sm font-normal px-4 focus:outline-none"
                    />
                  )}
                  {/* Profile dropdown */}
                  {isAuthenticated ? <UserMenu location={location.pathname} href="/account" /> : <LoginButton />}
                </div>
              </div>
            </div>
            <Disclosure.Panel className="lg:hidden">
              {isAuthenticated
                && <HeaderNav navigationItems={navigation} location={location.pathname} textClasses="py-4" />}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
}

export default Header;
