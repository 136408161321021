import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { classNames } from '../utils/ui';

const itemClasses = 'text-gray-500 group flex items-center pl-3 pr-5 py-5 text-sm font-medium';
export default function VerticalNav({ items, iconClasses, location }) {
  return (
    <nav
      className="bg-white border border-gray-200 rounded-md overflow-hidden"
      aria-label="Sidebar"
    >
      {items.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          className={classNames(
            itemClasses,
            location === item.href
              ? 'bg-gray-50 text-blue-900 border-blue-900 border-l-4'
              : 'bg-gray-50 border-transparent border-l-4',
            item.disabled ? 'pointer-events-none' : '',
          )}
        >
          {item.icon && <item.icon className={iconClasses} />}
          <span className="truncate">
            {item.name}
          </span>
        </Link>
      ))}
    </nav>
  );
}

VerticalNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  iconClasses: PropTypes.string,
  location: PropTypes.string,
};

VerticalNav.defaultProps = {
  location: '',
  iconClasses: 'w-6 h-6 mr-2',
};
