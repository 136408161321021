import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getPDSReasons = async (client, params) => api.pds.fetchPDSReasons(client, params);

export default function useGetPDSReasons(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'pds/reasons',
      ...Object.values(params),
    ],
    async () => getPDSReasons(apiClient, params),
  );
}

const getPDSReason = async (client, params) => api.pds.fetchPDSReason(client, params);

export function useGetPDSReason(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'pds/reason',
      ...Object.values(params),
    ],
    async () => getPDSReason(apiClient, params),
  );
}
