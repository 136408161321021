export default {
  fetchUserValidationReports: async (client, params) => client.get('api/admin/validation-rules-report', {
    params,
  })
    .then((response) => response.data),
  fetchUserValidationReportDownloadUrl: async (client, id) => client.get(`api/admin/validation-rules-report/${id}/download`)
    .then((response) => response.data),
  createValidationReport: async (client, params) => client.post('api/admin/validation-rules-report', params)
    .then((response) => response.data),
};
