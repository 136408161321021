import ReactTooltip from 'react-tooltip';
import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import Button from './buttons/Button';

function Modal(props) {
  const cancelButtonRef = useRef();
  const {
    showModal, title, primaryText, secondaryText, onClose, primaryAction,
    children, primaryEnabled, primaryEnabledFunc, size, primaryStyle,
    primaryShow, secondaryShow, divider, testId
  } = props;

  const primaryEnabledFuncResult = primaryEnabledFunc();

  return (
    <Transition.Root show={showModal} as={Fragment} afterEnter={() => ReactTooltip.rebuild()}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={showModal}
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div data-cy={`dialog-${testId}`} className={`inline-block align-bottom bg-white rounded-lg pb-6 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-${size} sm:w-full`}>
              {title
                && (
                <Dialog.Title as="h3" className="font-semibold block py-3 text-left px-6" data-cy={`dialog-title-${testId}`}>
                    {title}
                  </Dialog.Title>
                )}

              {divider
                && <hr className="mb-4" />}
              <div className="px-6 mb-8">
                {children}
              </div>
              {divider
                && <hr className="mb-4" />}
              <div className="mt-5 sm:mt-6 flex flex-row justify-end px-6">
                {secondaryShow && (
                  <Button
                    type="button"
                    buttonStyle="light"
                    rounded
                    onClick={() => onClose()}
                    ref={cancelButtonRef}
                    data-cy={`dialog-cancel-${testId}`}
                  >
                    {secondaryText}
                  </Button>
                )}
                {primaryShow && (
                  <Button
                    data-cy={`dialog-primary-action-${testId}`}
                    buttonStyle={primaryStyle}
                    className="ml-3"
                    rounded
                    onClick={() => primaryAction()}
                    disabled={!primaryEnabled || !primaryEnabledFuncResult}
                  >
                    {primaryText}
                  </Button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  primaryText: PropTypes.string,
  primaryEnabled: PropTypes.bool,
  primaryShow: PropTypes.bool,
  secondaryShow: PropTypes.bool,
  primaryStyle: PropTypes.string,
  secondaryText: PropTypes.string,
  primaryAction: PropTypes.func,
  onClose: PropTypes.func,
  primaryEnabledFunc: PropTypes.func,
  testId: PropTypes.string,
  children: PropTypes.element,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '3xl']),
  divider: PropTypes.bool,
};

Modal.defaultProps = {
  title: '',
  primaryText: 'Ok',
  primaryEnabled: true,
  primaryShow: true,
  secondaryShow: true,
  primaryStyle: 'primary',
  secondaryText: 'Cancel',
  primaryAction: () => { },
  onClose: () => { },
  primaryEnabledFunc: () => true,
  children: null,
  size: 'lg',
  divider: true,
};

export default Modal;
