/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export default function Textarea({ onChange, ...attrs }) {
  return (
    <textarea
      {...attrs}
      onChange={(e) => onChange && onChange(e.currentTarget.value)}
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
    />
  );
}

Textarea.propTypes = {
  onChange: PropTypes.func,
};

Textarea.defaultProps = {
  onChange: null,
};
