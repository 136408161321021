import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import { XIcon } from '@heroicons/react/outline';
import Modal from '../../../../components/Modal';
import Label from '../../../../components/forms/Label';
import FMSelect from '../../../../components/forms/FMSelect';
import useGetAdminRoles from '../../../../hooks/admin/roles/useGetAdminRoles';

const rolePlaceholder = { label: '- select -', value: null };

function Chip({ tag, removeButtonText, onDelete }) {
  return (
    <div className="rounded-lg bg-gray-100 inline-block pl-2.5 py-0.5 inline-flex items-center mr-2 mb-1">
      <span className="text-sm text-gray-800">
        {tag.name}
      </span>
      <button type="button" className="w-3 mx-1 text-gray-400" title={`${removeButtonText}: ${tag.name}`} onClick={onDelete}>
        <XIcon />
      </button>
    </div>
  );
}

Chip.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  removeButtonText: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default function InviteModal({ primaryAction, onClose, ...props }) {
  const schema = yup.object({
    roleId: yup.string().required(),
    emails: yup.array().min(1).required(),
  }).required();
  const {
    control,
    formState: { isValid },
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roleId: null,
      emails: [],
    },
    resolver: yupResolver(schema),
  });

  const adminRoles = useGetAdminRoles();
  const roleOptions = [
    rolePlaceholder,
    ...(adminRoles.data || []).map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  ];

  const onValidate = (tag) => tag.name.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i);
  const handlePrimaryAction = () => {
    const values = getValues();
    primaryAction({
      ...values,
      emails: values.emails.map((i) => i.name),
    });
    reset();
  };

  return (
    <Modal
      {...props}
      primaryEnabled={isValid}
      primaryAction={() => handlePrimaryAction()}
      onClose={() => { reset(); onClose(); }}
    >
      <div className="mt-2">
        <p className="mb-5 text-sm text-gray-500">
          Invitiation will be sent out to provided email and expire in 3 days.
        </p>
        <div className="mb-5">
          <Label isRequired htmlFor="email" className="mb-1 text-sm text-gray-700">
            Select role
          </Label>
          <div className="w-64">
            <Controller
              control={control}
              name="roleId"
              render={({ field }) => (
                <FMSelect
                  value={roleOptions.find((role) => role.value === field.value)}
                  onChange={(option) => field.onChange(option.value)}
                  isSearchable={false}
                  options={roleOptions}
                />
              )}
            />
          </div>
        </div>
        <Label isRequired htmlFor="email" className="mb-1 text-sm text-gray-700">
          Type emails to invite&nbsp;
          <span className="font-normal text-gray-500">
            (separate by a comma)
          </span>
        </Label>
        <Controller
          control={control}
          name="emails"
          render={({ field }) => (
            <ReactTags
              tags={field.value}
              delimiters={['Enter', 'Tab', ',']}
              onDelete={(tagIndex) => field.onChange(field.value.filter((_, i) => i !== tagIndex))}
              onAddition={(newEmail) => field.onChange([...field.value, newEmail])}
              onValidate={onValidate}
              autoresize={false}
              allowNew
              addOnBlur
              tagComponent={Chip}
              classNames={{
                root: 'border rounded-lg border-gray-300 bg-white p-3 h-24',
                selected: 'inline',
                search: 'inline-block text-sm',
                searchInput: 'outline-none',
              }}
              placeholderText="Add email"
            />
          )}
        />
      </div>
    </Modal>
  );
}

InviteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  primaryAction: PropTypes.func.isRequired,
};
