import React from 'react';
import PropTypes from 'prop-types';
import CardDetails from '../cardDetails/CardDetails';
import MerchantDetails from '../merchantDetails/MerchantDetails';

export default function BottomSheetTabContent({ type, id }) {
  switch (type) {
    case 'card':
      return (
          <CardDetails id={id} />
      );
    case 'merchant':
      return (
          <MerchantDetails id={id} />
      );
    default:
      return (
        <div>
          {type}
          {id}
        </div>
      );
  }
}

BottomSheetTabContent.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};
