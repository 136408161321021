import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftIcon, CreditCardIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import PDSMerchants from '../PDSMerchants';
import TextButton from '../../../components/buttons/TextButton';
import Panel from '../../../components/Panel';
import numberUtils from '../../../utils/numberUtils';
import TabsContainer from '../../../components/TabsContainer';
import useConstant from '../../../hooks/useConstant';
import PDSCards from '../PDSCards';

export default function PDSTasksDetail({
    task,
    onBack,
    status,
    timeSelected,
    startDate,
    endDate,
    ...rest
}) {
    const tabs = useConstant(() => [
        {
            name: 'Merchants',
            value: 'merchants',
            icon: ShoppingBagIcon,
        },
        {
            name: 'Cards',
            value: 'cards',
            icon: CreditCardIcon,
        },
    ]);
    const [selectedTab, setSelectedTab] = useState(tabs[0].value);
    const tabsContent = useMemo(() => {
        const renderMerchantsTab = () => (
            <PDSMerchants
                // @TODO: Place MCC parameter here: mcc={[1,2,3]}
                mcc={task.mccs}
                startDate={startDate}
                endDate={endDate}
                status={status}
                timeSelected={timeSelected}
                {...rest}
            />
        );
        const renderCardsTab = () => (
            <PDSCards
                // @TODO: Place MCC parameter here: mcc={[1,2,3]}
                mcc={task.mccs}
                startDate={startDate}
                endDate={endDate}
                timeSelected={timeSelected}
                status={status}
                {...rest}
            />
        );

        return {
            merchants: renderMerchantsTab,
            cards: renderCardsTab,
        };
    }, [task.name, status, timeSelected, rest.search, rest.authCount, rest.refreshTick, startDate, endDate]);

    return (
        <>
            <div className="px-5 mt-2">
                <TextButton className="mb-4" onClick={() => onBack && onBack()}>
                    <span className="flex">
                        <ArrowLeftIcon className="w-5 mr-2" />
                        Back
                    </span>
                </TextButton>
                <Panel className="mb-6">
                    <div className="flex space-x-10">
                        <div className="flex flex-col flex-1">
                            <span className="mb-1 text-xs text-gray-500">Task</span>
                            <span className="font-semibold">{task?.name}</span>
                        </div>
                        <div className="flex flex-col">
                            <span className="mb-1 text-xs text-right text-gray-500">Auths</span>
                            <span className="font-semibold text-right">{task?.auths ?? 0}</span>
                        </div>
                        <div className="flex flex-col">
                            <span className="mb-1 text-xs text-right text-gray-500">Amount</span>
                            <span className="font-semibold text-right">{numberUtils.currencyFormat(task?.amount ?? 0)}</span>
                        </div>
                        <div className="flex flex-col">
                            <span className="mb-1 text-xs text-right text-gray-500">Merchants</span>
                            <span className="font-semibold text-right">{task?.merchants ?? 0}</span>
                        </div>
                        <div className="flex flex-col">
                            <span className="mb-1 text-xs text-right text-gray-500">Cards</span>
                            <span className="font-semibold text-right">{task?.cards ?? 0}</span>
                        </div>
                    </div>
                </Panel>
            </div>
            <div className="px-5 mt-2">
                <TabsContainer
                    tabs={tabs}
                    tabsContent={tabsContent}
                    selectedTab={selectedTab}
                    onSelectedTabChange={setSelectedTab}
                />
            </div>
        </>
    );
}

PDSTasksDetail.propTypes = {
    task: PropTypes.shape({
        name: PropTypes.string,
        mccs: PropTypes.arrayOf(PropTypes.number),
        auths: PropTypes.string,
        amount: PropTypes.number,
        merchants: PropTypes.string,
        cards: PropTypes.string,
    }).isRequired,
    onBack: PropTypes.func,
    status: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    timeSelected: PropTypes.string,
};

PDSTasksDetail.defaultProps = {
    onBack: null,
    status: null,
    startDate: null,
    endDate: null,
    timeSelected: null
};
