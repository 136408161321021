import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import useApiClient from '../../useApiClient';
import CardKeys from './Card.keys';

export const reactivateCard = (client, id, data) => {
  if (!id) {
    throw new Error('id is required');
  }
  return api.cards.reactivateCard(client, id, data);
};

export default function useReactivateCard(options) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => reactivateCard(client, id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(CardKeys.all());
      queryClient.invalidateQueries(CardKeys.details());
    },
    ...options,
  });
}
