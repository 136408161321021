import React, { useEffect, useMemo, useState } from 'react';
import ArdenRounded from '../../components/ArdenRounded';
import TextButton from '../../components/buttons/TextButton';
import FiltersContainer from '../../components/filters/FiltersContainer';
import FMCombobox from '../../components/forms/FMCombobox';
import Grid from '../../components/Grid';
import Panel from '../../components/Panel';
import RefreshTable from '../../components/RefreshTable';
import { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../../components/table/DataTable';
import MerchantsTable from '../../components/table/MerchantsTable';
import PaginationConfig from '../../config/PaginationConfig';
import PermissionConstants from '../../config/PermissionConstants';
import useGetMerchantThreats from '../../hooks/api/merchants/useGetMerchantThreats';
import useGetMerchantThreatsActiveAlertFilter from '../../hooks/api/merchants/useGetMerchantThreatsActiveAlertFilter';
import useGetMyPermissions from '../../hooks/api/users/useGetMyPermissions';
import merchantTableUtils from '../../utils/merchantTableUtils';
import { checkPermission } from '../../utils/userUtil';
import HomeMetrics from './HomeMetrics';

const statusOptions = [
  { value: 'blocked-merchant', label: 'Blocked' },
  { value: 'pending-review-merchant', label: 'Suspicious' },
];

export default function LiveMerchantDetails() {
  const [, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('updatedAt');
  const [sortDir, setSortDir] = useState(TABLE_SORT_DESC);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [canUpdate, setCanUpdate] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [activeAlertFilter, setActiveAlertFilter] = useState(null);
  const { data: merchantThretsResponse, refetch } = useGetMerchantThreats({
    search,
    sort,
    sortDir,
    page,
    itemsPerPage,
    statusApiName: statusFilter ? statusFilter.value : null,
    activeAlertId: activeAlertFilter ? activeAlertFilter.value : null,
  });
  const { data: activeAlertOptions } = useGetMerchantThreatsActiveAlertFilter();
  const [filtersDescription, setFiltersDescription] = useState([]);

  const data = merchantThretsResponse ? merchantThretsResponse.rows : [];
  const count = merchantThretsResponse ? merchantThretsResponse.count : 0;
  const { data: permissions } = useGetMyPermissions();

  const ACTIVE_ALERT_OPTIONS = useMemo(
    () =>
      (activeAlertOptions?.map((item) => ({
          value: item.id,
          label: item.name,
        }))) ||
      [],
    [activeAlertOptions]
  );

  const renderFilterTooltip = () => {
    if (filtersDescription.length === 0) {
      return null;
    }
    return <FiltersContainer.Tooltip filters={filtersDescription} />;
  };

  useEffect(() => {
    const newFilters = [];

    if (activeAlertFilter) {
      newFilters.push({
        name: 'Active Alert:',
        value: activeAlertFilter.label,
      });
    }

    if (statusFilter) {
      newFilters.push({
        name: 'Status:',
        value: statusFilter.label,
      });
    }

    setFiltersDescription(newFilters);
  }, [statusFilter, activeAlertFilter, setFiltersDescription]);

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const clearFilters = () => {
    setSearch('');
    setSearchValue('');
    setStatusFilter(null);
    setActiveAlertFilter(null);
    resetPage();
  };

  const changeSort = (field) => {
    if (field !== sort) {
      setSort(field);
      setSortDir(TABLE_SORT_DESC);
      return;
    }

    setSortDir(sortDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
  };

  const onMerchantAction = (action, args) => {
    switch (action) {
      case TABLE_SORT_ACTION:
        changeSort(args.field);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setCanUpdate(checkPermission(permissions, PermissionConstants.UPDATE_MERCHANTS_LIST));
  }, [permissions]);

  const columnsToUse = merchantTableUtils.getMerchantTableColumns('threats');

  return (
    <>
      <Grid>
        <div className="col-span-12" data-cy="merchants-screen">
          <HomeMetrics />
          <Panel containerClassName="py-4">
            <div className="items-start justify-between px-4 lg:flex">
              <FiltersContainer search={search} onSearchChange={setSearch} tooltip={renderFilterTooltip()}>
                <FMCombobox
                  placeholder="Select Active Alert"
                  data={ACTIVE_ALERT_OPTIONS}
                  selected={activeAlertFilter}
                  onChange={(e) => {
                    resetPage();
                    setActiveAlertFilter(e);
                  }}
                />
                <FMCombobox
                  placeholder="Select Status"
                  data={statusOptions}
                  selected={statusFilter}
                  onChange={(e) => {
                    resetPage();
                    setStatusFilter(e);
                  }}
                />
                {(search.length > 0 || filtersDescription.length > 0) && (
                  <TextButton onClick={() => clearFilters()}>Clear all</TextButton>
                )}
              </FiltersContainer>

              <div className="flex space-x-6">
                <RefreshTable setRefreshTable={() => refetch()} />
              </div>
            </div>

            {data && <MerchantsTable
              sort={sort}
              sortDirection={sortDir}
              canUpdate={canUpdate}
              columns={columnsToUse}
              data={data}
              paginationProps={{
                className: 'px-4',
                totalItems: count,
                currentPage: page,
                itemsPerPage,
                onItemsPerPageChange: (e) => {
                  resetPage();
                  setItemsPerPage(e);
                },
                onPageClick: (e) => setPage(e),
              }}
              onAction={onMerchantAction}
              emptyStateContent={<ArdenRounded />}
            />}
          </Panel>
        </div>
      </Grid>
    </>
  );
}
