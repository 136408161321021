import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import CardKeys from './Card.keys';

const getCardLog = async (
  client,
  cardId,
) => api.cards.fetchCardLog(client, cardId);

export default function useGetCardLog(cardId) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    CardKeys.log(cardId),
    async () => getCardLog(apiClient, cardId),
  );
}
