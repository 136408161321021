import { PlusIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import IconButton from '../../../components/buttons/IconButton';
import Filters from '../../../components/Filters';
import SearchFilter from '../../../components/filters/SearchFilter';
import SelectFilter from '../../../components/filters/SelectFilter';
import DataTable from '../../../components/table/DataTable';
import PaginationConfig from '../../../config/PaginationConfig';
import useGetInvitations from '../../../hooks/admin/invitations/useGetInvitations';
import InvitationStatusColumn from '../../../components/table/columns/invitations/InvitationStatusColumn';
import DateColumn from '../../../components/table/columns/DateColumn';
import getUserNameColumn from '../../../components/table/columns/UserNameColumn';
import InvitationActionsColumn, { INVITATION_ACTIONS } from '../../../components/table/columns/invitations/InvitationActionsColumn';
import InviteModal from './components/InviteModal';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import StatusConstants from '../../../config/StatusConstants';
import useCreateInvitation from '../../../hooks/admin/invitations/useCreateInvitation';
import useResendInvitation from '../../../hooks/admin/invitations/useResendInvitation';
import useUpdateInvitation from '../../../hooks/admin/invitations/useUpdateInvitation';

const columns = [
  DateColumn('createdAt', 'Date'),
  {
    field: 'email',
    headerName: 'Email',
  },
  getUserNameColumn('Invited By'),
  {
    field: 'status',
    field2: 'isNewRequested',
    headerName: 'Status',
    render: InvitationStatusColumn,
  },
  {
    key: 'actions',
    field: 'status',
    headerName: 'Actions',
    render: InvitationActionsColumn,
  },
];

export default function Invitations({ tenantId }) {
  const [search, setSearch] = useState('');
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const { data, isLoading, refetch } = useGetInvitations({
    page, itemsPerPage, tenantId, search
  });
  const createInvitation = useCreateInvitation();
  const resendInvitation = useResendInvitation();
  const updateInvitation = useUpdateInvitation();

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const onSearchChange = (value) => {
    setSearchValue(value);
    if (value && value.trim().length > 2) {
      resetPage();
      setSearch(value.trim());
    } else {
      setSearch('');
    }
  };

  const submit = (formData) => {
    setShowModal(false);
    toast.promise(
      createInvitation.mutateAsync({
        ...formData,
        tenantId,
      }),
      {
        loading: 'Sending invitations...',
        success: (response) => {
          // eslint-disable-next-line react/destructuring-assignment
          const successResponses = response
            .filter((item) => item.success);
          // eslint-disable-next-line react/destructuring-assignment
          const failedResponses = response
            .filter((item) => !item.success);
          refetch();

          return (
            <div>
              {successResponses.length > 0 && (
                <div className="text-sm">
                  <span className="font-medium">Users invited:</span>
                  <ul>
                    {successResponses.map((item) => (
                      <li key={item.email}>
                        Invitation sent to:&nbsp;
                        {item.email}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {failedResponses.length > 0 && (
                <div className="text-sm">
                  <span className="font-medium">Users not invited:</span>
                  <ul>
                    {failedResponses.map((item) => (
                      <li key={item.email}>
                        {item.email}
                        &nbsp;-&nbsp;
                        {item.error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );
        },
        error: 'An error occurred while sending invitations, please try again later',
      },
      {
        duration: 5000,
      },
    );
  };


  const handleResend = () => {
    setShowResendModal(false);
    toast.promise(
      resendInvitation.mutateAsync(selectedInvitation),
      {
        loading: 'Resending invitation...',
        success: 'Invitation successfully resent',
        error: 'An error occurred while resending the invitation, please try again later',
      },
    );
  };

  const handleCancel = () => {
    setShowCancelModal(false);
    toast.promise(
      updateInvitation.mutateAsync({
        id: selectedInvitation,
        data: {
          statusApiName: StatusConstants.userInvitation.cancelled,
        },
      }),
      {
        loading: 'Canceling invitation...',
        success: 'Invitation successfully cancelled',
        error: 'An error occurred while cancelling the invitation, please try again later',
      },
    );
  };

  const onTableAction = (action, args) => {
    setSelectedInvitation(args);
    switch (action) {
      case INVITATION_ACTIONS.resend:
        setShowResendModal(true);
        break;
      case INVITATION_ACTIONS.cancel:
        setShowCancelModal(true);
        break;
      default:
      // action not found
    }
  };

  return (
    <div>
      <div className="items-start justify-between lg:flex">
        <Filters>
          <SearchFilter
            onChange={onSearchChange}
            value={searchValue}
          />
          <SelectFilter
            id="status-filter"
            isSearchable={false}
            placeholder="Status: All"
            options={[
              { value: 'accepted', label: 'Accepted' },
              { value: 'cancelled', label: 'Cancelled' },
              { value: 'expired', label: 'Expired' },
              { value: 'pending', label: 'Pending' },
            ]}
            value={statusFilter}
            onChange={(e) => { resetPage(); setStatusFilter(e); }}
          />
        </Filters>
        <IconButton
          testId="invite-user-button"
          icon={<PlusIcon />}
          rounded
          onClick={() => setShowModal(true)}
        >
          Invite user(s)
        </IconButton>
      </div>
      <DataTable
        onAction={onTableAction}
        columns={columns}
        data={data ? data.rows : []}
        loading={isLoading}
        paginationProps={{
          totalItems: data ? data.count : 0,
          currentPage: page,
          itemsPerPage,
          onPageClick,
          onItemsPerPageChange: (e) => { resetPage(); setItemsPerPage(e); },
        }}
      />
      <InviteModal
        showModal={showModal}
        title="Invite user(s)"
        onClose={() => setShowModal(false)}
        divider={false}
        primaryText="Invite user(s)"
        primaryAction={(formData) => submit(formData)}
      />
      <ConfirmModal
        showModal={showResendModal}
        title="Resend Invitation"
        onCancel={() => setShowResendModal(false)}
        onConfirm={() => handleResend()}
      >
        <ConfirmModal.Body>
          An invitation link will be sent again to the user via email.
        </ConfirmModal.Body>
      </ConfirmModal>
      <ConfirmModal
        showModal={showCancelModal}
        title="Cancel Invitation"
        onCancel={() => setShowCancelModal(false)}
        onConfirm={() => handleCancel()}
      >
        <ConfirmModal.Body>
          The invitation link sent to the user will be deactivated.
        </ConfirmModal.Body>
      </ConfirmModal>
    </div>
  );
}

Invitations.propTypes = {
  tenantId: PropTypes.number,
};

Invitations.defaultProps = {
  tenantId: null,
};
