import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { PencilIcon } from '@heroicons/react/outline';
import Filters from '../../../components/Filters';
import SearchFilter from '../../../components/filters/SearchFilter';
import SelectFilter from '../../../components/filters/SelectFilter';
import TextButton from '../../../components/buttons/TextButton';
import ToggleColumn from '../../../components/table/columns/ToggleColumn';
import DataTable from '../../../components/table/DataTable';
import PaginationConfig from '../../../config/PaginationConfig';
import { TABLE_CELL_CLASS } from '../../../components/table/Table';
import ChangeRoleConfirmationModel from '../../../components/modals/ChangeRoleConfirmationModel';
import StatusConfirmationModel from '../../../components/modals/StatusConfirmationModal';
import { checkPermission, getName } from '../../../utils/userUtil';
import useGetAdminRoles from '../../../hooks/admin/roles/useGetAdminRoles';
import PermissionConstants from '../../../config/PermissionConstants';
import TextButtonColumn from '../../../components/table/columns/TextButtonColumn';
import { setShowSelectedUser, selectUser } from '../../../store/features/admin/adminSlice';
import useGetMe from '../../../hooks/api/users/useGetMe';
import useGetMyPermissions from '../../../hooks/api/users/useGetMyPermissions';
import useGetUsers from '../../../hooks/api/users/useGetUsers';
import useUpdateUser from '../../../hooks/api/users/useUpdateUser';
import useUpdateUserRole from '../../../hooks/api/users/useUpdateUserRole';

// const OPEN_USER_DETAILS_ACTION = 'open_user_details';
const CHANGE_USER_ROLE_ACTION = 'change_role_action';
const CHANGE_ACTIVE_ACTION = 'change-active-action';

const getStatusModelTitle = (activeStatusModal) => {
  if (activeStatusModal && activeStatusModal.row && activeStatusModal.row.isActive) {
    return 'Deactivate User';
  }
  return 'Activate User';
};
const getStatusModelContent = (activeStatusModal) => {
  if (activeStatusModal && activeStatusModal.row && activeStatusModal.row.isActive) {
    return 'This user will no longer be able to access the platform';
  }
  return 'This user will be activated, allowing him/her to access the platform as per their role assignment.';
};

function RenderToggleColumn({ row, onAction, column }) {
  // @TODO send as column prop
  const { data: me } = useGetMe();
  const { data: permissions } = useGetMyPermissions();
  return (
    <ToggleColumn
      key={`cel-${row.id}-active`}
      row={row}
      column={column}
      className={(row.id !== me.id)
        && checkPermission(permissions, PermissionConstants.ADD_MODIFIY_USERS)
        ? 'opacity-100' : 'opacity-40'}
      onAction={(row.id !== me.id)
        && checkPermission(permissions, PermissionConstants.ADD_MODIFIY_USERS)
        ? onAction : null}
    />
  );
}
RenderToggleColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      statusApiName: PropTypes.string,
    },
  ).isRequired,
  column: PropTypes.shape({
    actionName: PropTypes.string,
  }).isRequired,
  onAction: PropTypes.func,
};
RenderToggleColumn.defaultProps = {
  onAction: null,
};
function RoleRender(
  {
    row: {
      id, roleName, roleId, roleApiName,
    },
    onAction,
  },
) {
  const { data: me } = useGetMe();
  const { data: permissions } = useGetMyPermissions();
  return (
    <td className={TABLE_CELL_CLASS}>
      <div className="flex items-start justify-between">
        <span>{roleName}</span>
        <PencilIcon
          className={`flex h-4 w-4 ${((id !== me.id) && checkPermission(permissions, PermissionConstants.ADD_MODIFIY_USERS)) ? 'text-gray-600 cursor-pointer' : 'text-gray-200 cursor-default'}`}
          onClick={(e) => ((id !== me.id)
            && checkPermission(permissions, PermissionConstants.ADD_MODIFIY_USERS)
            ? onAction(CHANGE_USER_ROLE_ACTION, { id, role: { roleId, roleName, roleApiName }, e })
            : '')}
        />
      </div>
    </td>
  );
}
RoleRender.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    roleName: PropTypes.string.isRequired,
    roleId: PropTypes.number.isRequired,
    roleApiName: PropTypes.string.isRequired,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
};

const OPEN_USER_DETAILS_ACTION = 'open_tenant_details';

const columns = [
  {
    field: 'firstName',
    headerName: 'Name',
    valueGetter: (field, row) => getName(row),
  },
  {
    field: 'email',
    headerName: 'Email',
  },
  {
    field: 'roleName',
    headerName: 'Role',
    render: RoleRender,
  },
  {
    field: 'isActive',
    headerName: 'Active',
    render: RenderToggleColumn,
    actionName: CHANGE_ACTIVE_ACTION,
  },
  {
    field: 'is',
    headerName: 'Profile',
    valueGetter: () => 'View',
    render: TextButtonColumn,
    actionName: OPEN_USER_DETAILS_ACTION,
  },
];

export default function UsersList({ tenantId }) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [searchValue, setSearchValue] = useState('');
  const [disabledClearFilter, setDisabledClearFilter] = useState(true);
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [modal, setModal] = useState({ showModal: false, user: {} });
  const [activeStatusModal, setActiveStatusModal] = useState({ showModal: false });
  const updateUser = useUpdateUser();
  const updateUserRole = useUpdateUserRole();
  const { data, isLoading } = useGetUsers({
    search,
    itemsPerPage,
    page,
    roleId: roleFilter ? roleFilter.value : null,
    isActive: statusFilter ? statusFilter.value : null,
    tenantId,
  });
  const users = data ? data.rows : [];
  const count = data ? data.count : 0;
  const dispatch = useDispatch();

  // redux
  const rolesRequest = useGetAdminRoles();
  const roles = rolesRequest.data;
  const rolesOptions = [
    { value: '', label: 'All' },
    ...(roles || []).map((role) => ({ value: role.id, label: role.name })),
  ];

  function updateUserStatus(row) {
    setActiveStatusModal({ ...activeStatusModal, showModal: false });
    const payload = {};
    payload.isActive = !row.isActive;
    toast.promise(
      updateUser.mutateAsync({
        id: row.id,
        data: payload,
      }),
      {
        loading: 'Updating user...',
        success: 'User updated successfully',
        error: 'An error occurred while updating the user',
      },
    );
  }
  function onChangeUserRole(args, allOptions) {
    const from = allOptions.find((e) => e.value === modal.user.roleId).label;
    const to = allOptions.find((e) => e.value === args.value).label;

    setModal({
      ...modal,
      showModal: false,
    });
    if (!args || modal.user.roleId === args.value) {
      return;
    }
    const params = {
      userId: modal.user.id,
      roleId: args.value,
      from,
      to,
    };
    toast.promise(
      updateUserRole.mutateAsync(
        params,
      ),
      {
        loading: 'Updating Role...',
        success: 'Role updated successfully',
        error: 'An error occurred while updating the Role',
      },
    );
  }
  const findUser = (id) => users.find((user) => user.id === id);
  function onTableAction(action, row) {
    switch (action) {
      case OPEN_USER_DETAILS_ACTION:
        dispatch(setShowSelectedUser(true));
        dispatch(selectUser(row.id));

        break;
      case CHANGE_USER_ROLE_ACTION:
        setModal({
          ...modal,
          user: findUser(row.id),
          showModal: true,
        });
        break;
      case CHANGE_ACTIVE_ACTION:
        setActiveStatusModal({
          ...activeStatusModal,
          row,
          showModal: true,
        });
        break;
      default:
        break;
    }
  }
  const updateClearAll = (status, otherFilter) => {
    if (!status && !searchValue && !otherFilter) {
      setDisabledClearFilter(true);
    } else {
      setDisabledClearFilter(false);
    }
  };
  const onSearchChange = (value) => {
    setSearchValue(value);
    if (value) {
      setDisabledClearFilter(false);
    }
    if (value && value.trim().length > 2) {
      setSearch(value.trim());
    } else {
      setSearch('');
    }
  };
  const clearFilters = () => {
    setSearch('');
    setSearchValue('');
    setStatusFilter('');
    setRoleFilter('');
    setDisabledClearFilter(true);
  };
  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div>
        <div className="items-start justify-between lg:flex">
          <Filters>
            <SearchFilter
              onChange={onSearchChange}
              value={searchValue}
            />
            <SelectFilter
              id="role-filter"
              isSearchable={false}
              placeholder="Select Role"
              testSelector="role-select"
              options={rolesOptions}
              value={roleFilter}
              onChange={(e) => {
                setRoleFilter(e); updateClearAll(e, statusFilter);
              }}
            />
            <SelectFilter
              id="status-filter"
              isSearchable={false}
              placeholder="Select Status"
              testSelector="status-select"
              options={[
                { value: '', label: 'All' },
                { value: true, label: 'Active' },
                { value: false, label: 'Inactive' },
              ]}
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e); updateClearAll(e, roleFilter);
              }}
            />
            <TextButton
              disabled={disabledClearFilter}
              onClick={clearFilters}
            >
              Clear all
            </TextButton>
          </Filters>
        </div>
        <DataTable
          columns={columns}
          showPagination
          data={users}
          loading={isLoading}
          paginationProps={{
            totalItems: count,
            currentPage: page,
            itemsPerPage,
            onPageClick,
            onItemsPerPageChange: (e) => { setItemsPerPage(e); },
          }}
          onAction={(action, row, value) => onTableAction(action, row, value)}
        />
      </div>
      <ChangeRoleConfirmationModel
        {...modal}
        roles={roles}
        name={getName(modal.user)}
        defaultRole={{
          value: modal.user.roleId,
          label: modal.user.roleName,
        }}
        onClose={() => setModal({ ...modal, showModal: false })}
        primaryAction={(args, allOptions) => onChangeUserRole(args, allOptions)}
      />
      <StatusConfirmationModel
        {...activeStatusModal}
        title={getStatusModelTitle(activeStatusModal)}
        content={getStatusModelContent(activeStatusModal)}
        onClose={() => setActiveStatusModal({ ...activeStatusModal, showModal: false })}
        primaryAction={() => updateUserStatus(activeStatusModal.row)}
        primaryText={getStatusModelTitle(activeStatusModal)}
      />
    </>
  );
}
UsersList.propTypes = {
  tenantId: PropTypes.number,
};

UsersList.defaultProps = {
  tenantId: null,
};
