/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../../config';

// @TODO remove, use auth0 or better useLoggedInQuery
export const getToken = createAsyncThunk(
  'me/getToken',
  async (getAccessTokenSilently) => getAccessTokenSilently(config.auth0TokenParams),
);

const meSlice = createSlice({
  name: 'me',
  initialState: {
    user: null,
    token: null,
    permissions: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.token = action.payload;
    });
  },
});

export default meSlice.reducer;
