import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSelectedUser } from '../../store/features/admin/adminSlice';
import SlideOverBottom from './SlideOverBottom';
import UserDetails from '../UserDetails';

export default function SlideOverSelectedUser() {
  const [showSelectedUser, selectedUser] = useSelector(
    (state) => [state.admin.showSelectedUser, state.admin.selectedUser],
  );
  const dispatch = useDispatch();
  return (
    <SlideOverBottom
      title="User Details"
      isOpen={showSelectedUser}
      setOpen={(value) => dispatch(setShowSelectedUser(value))}
    >
      {selectedUser && <UserDetails userId={selectedUser} />}
    </SlideOverBottom>
  );
}
