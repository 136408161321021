import React, { useState } from 'react';
import Panel from '../../Panel';
import TabsContainer from '../../TabsContainer';
import CardChart from './CardChart';

const tabs = [
  {
    name: 'Report',
    value: 'report',
  },
  {
    name: 'Balance Risk vs Protected Trend',
    value: 'chart',
  },
];

export default function CardInsights() {
  const [selectedTab, setSelectedTab] = useState('chart');

  const renderReportTab = () => (
    <div>Card Report</div>
  );

  const renderChartTab = () => (
    <CardChart />
  );

  const tabsContent = {
    report: renderReportTab,
    chart: renderChartTab,
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-2">
        Cards
      </h2>
      <Panel containerClassName="px-6" className="mb-12">
        <TabsContainer
          className="py-4"
          tabs={tabs}
          tabsContent={tabsContent}
          selectedTab={selectedTab}
          onSelectedTabChange={setSelectedTab}
        />
      </Panel>
    </>
  );
}
