import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetAllTransactionsCount() {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['transactions/all'],
    () => api.transactions.fetchAllTransactionsCount(apiClient),
  );
}
