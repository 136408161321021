import { DownloadIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import IconButton from '../../../components/buttons/IconButton';
import Filters from '../../../components/Filters';
import FMCombobox from '../../../components/forms/FMCombobox';
import RefreshTable from '../../../components/RefreshTable';
import DataTable from '../../../components/table/DataTable';
import PaginationConfig from '../../../config/PaginationConfig';
import RulesPerformanceListColumns from './RulesPerformanceListColumns';
import {
  selectMerchant,
  setShowSelectedMerchant,
} from '../../../store/features/merchant/merchantSlice';
import { MERCHANT_OPEN_MERCHANT } from '../../../components/table/MerchantsTable';
import useGetRulesPerformanceList from '../../../hooks/admin/rules/useGetRulesPerformanceList';
import useGetRulesFilter from '../../../hooks/admin/rules/useGetRulesFilter';

const summaryOptions = [
  { value: '', label: 'Summary: All' },
  { value: 'blockedThreats', label: 'Summary: Threats Blocked' },
  { value: 'falseThreats', label: 'Summary: False Threats' },
];

export default function RulesPerformanceList({
  tenantId,
  timeSelected,
  relatedModel,
}) {
  const [ruleFilter, setRuleFilter] = useState(null);
  const [summaryFilter, setSummaryFilter] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(
    PaginationConfig.itemsPerPage
  );
  const [page, setPage] = useState(1);
  const { data: rulesList } = useGetRulesFilter({
    relatedModel,
  });
  const { data, refetch } = useGetRulesPerformanceList({
    tenantId,
    timeSelected,
    relatedModel,
    page,
    itemsPerPage,
    ruleId: ruleFilter ? ruleFilter.value : null,
    filter: summaryFilter ? summaryFilter.value : null,
  });

  const { refetch: download } = useGetRulesPerformanceList(
    {
      tenantId,
      timeSelected,
      relatedModel,
      ruleId: ruleFilter ? ruleFilter.value : null,
      format: 'csv',
    },
    {
      onSuccess: (result) => window.open(result.url, '_blank'),
    }
  );
  const listData = data ? data.rows : [];
  const count = data ? data.count : 0;

  const rulesListOptions = (rulesList || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    setRuleFilter(null);
  }, [relatedModel]);

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const onSummaryFilterChange = (value) => {
    resetPage();
    setSummaryFilter(value);
  };

  const onRuleFilterChange = (value) => {
    resetPage();
    setRuleFilter(value);
  };

  const onDownloadClick = () => {
    download();
  };

  const onTableAction = (action, args) => {
    switch (action) {
      case MERCHANT_OPEN_MERCHANT:
        dispatch(selectMerchant(args.merchantTenant));
        dispatch(setShowSelectedMerchant(true));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="items-start justify-between lg:flex">
        <Filters>
          <FMCombobox
            placeholder="Rules: All"
            data={rulesListOptions}
            selected={ruleFilter}
            onChange={onRuleFilterChange}
          />
          <FMCombobox
            placeholder="Summary: All"
            data={summaryOptions}
            selected={summaryFilter}
            onChange={onSummaryFilterChange}
          />
        </Filters>
        <div className="flex mb-5 space-x-6">
          <IconButton
            buttonStyle="light"
            icon={<DownloadIcon />}
            onClick={() => onDownloadClick()}
          >
            Download (.csv)
          </IconButton>
          <RefreshTable setRefreshTable={refetch} />
        </div>
      </div>
      <DataTable
        onAction={onTableAction}
        data={listData}
        columns={
          relatedModel === 'MerchantTenant'
            ? RulesPerformanceListColumns.merchantTenantColumns
            : RulesPerformanceListColumns.cardColumns
        }
        paginationProps={{
          itemsPerPage,
          totalItems: count,
          currentPage: page,
          onPageClick,
          onItemsPerPageChange: (e) => {
            setItemsPerPage(e);
          },
        }}
      />
    </div>
  );
}

RulesPerformanceList.propTypes = {
  tenantId: PropTypes.number,
  relatedModel: PropTypes.string.isRequired,
  timeSelected: PropTypes.string.isRequired,
};

RulesPerformanceList.defaultProps = {
  tenantId: null,
};
