import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetInsightsSummary() {
  const apiClient = useApiClient();
  return useLoggedInQuery([
    'insights', 'summary',
  ],
  async () => api.insights.fetchInsightsSummary(apiClient));
}
