import CardActionsColumn from '../components/table/columns/CardActionsColumn';
import { CARD_OPEN_DETAILS } from '../components/table/CardsTable';
import CardAssignToColumn from '../components/table/columns/CardAssignToColumn';
import DateColumn from '../components/table/columns/DateColumn';
import StringListWithBadgeColumn from '../components/table/columns/StringListWithBadgeColumn';
import CardDetailsColumn from '../components/table/columns/CardDetailsColumn';
import { CardRowExternalStatusBadge } from '../components/table/columns/CardExternalStatusBadge';
import numberUtils from './numberUtils';

const statusColumn = {
  field: 'externalStatusName',
  headerName: 'Status',
  render: CardRowExternalStatusBadge,
  sortable: true,
};




const cardDetailsColumn = (actionName) => ({
  key: 'card-details',
  field: 'auths',
  headerName: 'Card',
  headerClassName: 'text-left',
  className: 'text-left pr-6',
  sortable: true,
  render: CardDetailsColumn,
  valueGetter: (row) => `cards/${row.id}`,
  useLink: true,
  actionName,
});
const cardBalanceColumn = {
  field: 'authBalance',
  headerName: 'Balance',
  headerClassName: 'justify-end',
  className: 'text-right pr-10',
  valueGetter: (value) => numberUtils.currencyFormat(value),
  sortable: true,
};

const totalAuthsColumn = {
  field: 'auths',
  headerName: 'Auths',
  headerClassName: 'justify-end',
  className: 'text-right',
  sortable: true,
};


const cardAssignToColumn = {
  field: 'assignTo',
  headerName: 'Assigned To',
  headerClassName: 'text-left',
  className: 'text-left',
  render: CardAssignToColumn,
};



const cardActionColumn = {
  field: 'card-action',
  headerName: 'Actions',
  headerClassName: 'justify-center items-center',
  className: 'text-left',
  render: CardActionsColumn,
};

const amountColumn = {
  field: 'amount',
  headerName: 'Amount',
  headerClassName: 'justify-end',
  className: 'text-right',
  sortable: true,
  valueGetter: (field) => numberUtils.currencyFormat(field),
};

const merchantsColumn = {
  field: 'merchantNames',
  headerName: 'Confirmed Merchant Threat',
  headerClassName: 'text-left',
  className: 'text-left',
  render: StringListWithBadgeColumn,
};

const rulesColumn = {
  field: 'ruleNames',
  headerName: 'Alerts',
  headerClassName: 'text-left',
  className: 'text-left',
  render: StringListWithBadgeColumn,
};

export default {
  getBadgeTypeFromCardStatus: (statusApiName) => {
    switch (statusApiName) {
      case 'reissued-card':
      case 'replaced-card':
        return 'success';
      case 'suspended-card':
        return 'danger';
      default:
        return 'default';
    }
  },
  getCardTableColumns: (view, isFromRules = null) => {
    switch (view) {
      case 'merchantCards':
        return [
          cardDetailsColumn(CARD_OPEN_DETAILS),
          cardBalanceColumn,
          totalAuthsColumn,
          amountColumn,
          statusColumn,
          cardActionColumn,
        ];
      case 'compromised':
        return [
          DateColumn('lastCompromisedAt', 'Last Compromised', 'text-left'),
          cardDetailsColumn(CARD_OPEN_DETAILS),
          cardBalanceColumn,
          ...(isFromRules ? [rulesColumn] : [merchantsColumn]),
          cardAssignToColumn,
          statusColumn,
          cardActionColumn,
        ];
      case 'all':
        return [
          DateColumn('updatedAt', 'Last Modified'),
          cardDetailsColumn(CARD_OPEN_DETAILS),
          cardBalanceColumn,
          DateColumn('lastCompromisedAt', 'Last Compromised'),
          {
            field: 'lastModifiedBy',
            headerName: 'Last Modified By',
          },
          statusColumn,
        ];

      case 'pds':
        return [
          cardDetailsColumn(CARD_OPEN_DETAILS),
          cardBalanceColumn,
          amountColumn,
          totalAuthsColumn,
          {
            field: 'merchants',
            headerName: 'Total Merchants',
            sortable: true,
            headerClassName: 'text-right',
            className: 'text-right',
            valueGetter: (field) => numberUtils.numberFormat(field),
          },
          DateColumn('updatedAt', 'Last modified'),
          {
            field: 'updatedBy',
            headerName: 'Last Modified By',
            sortable: true,
          },
          statusColumn,
          cardActionColumn,
        ];
      default:
        return [];
    }
  },
};
