import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DataTable, { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../../../components/table/DataTable';
import TextButtonColumn from '../../../components/table/columns/TextButtonColumn';
import PaginationConfig from '../../../config/PaginationConfig';
import useGetPDSTasks from '../../../hooks/api/pds/useGetPDSTasks';
import numberUtils from '../../../utils/numberUtils';


const TASK_VIEW_ACTION = 'task-view-action';

export default function PDSTasksMain({
    refreshTick,
    timeSelected,
    search,
    onTaskSelected,
    startDate,
    endDate
}) {

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
    const [order, setOrder] = useState('updatedAt');
    const [orderDir, setOrderDir] = useState(TABLE_SORT_DESC);
    const selectedPeriod = timeSelected !== 'custom' ? timeSelected : '30min'

    const { isLoading, data, refetch } = useGetPDSTasks({
        tenantId: undefined,
        timeSelected: startDate || selectedPeriod,
        endDate: endDate || undefined,
        search,
        page,
        itemsPerPage,
        order,
        orderDir
    });

    const onPageClick = (newPage) => {
        setPage(newPage);
    };

    const resetPage = () => {
        if (page > 1) {
            setPage(1);
        }
    };

    const changeSort = (field) => {
        if (field !== order) {
            setOrder(field);
            setOrderDir(TABLE_SORT_DESC);
            return;
        }

        setOrderDir(orderDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
    };

    const onTableAction = (action, args) => {
        switch (action) {
            case TASK_VIEW_ACTION:
                onTaskSelected(args);
                break;
            case TABLE_SORT_ACTION:
                changeSort(args.field);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (refreshTick > 0) {
            refetch();
        }
    }, [refreshTick]);

    const columns = [
        {
            field: 'name',
            headerName: 'Task',
            sortable: true,
        },
        {
            field: 'auths',
            headerName: 'Auths',
            sortable: true,
            className: 'text-right',
            headerClassName: 'justify-end',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            sortable: true,
            className: 'text-right',
            headerClassName: 'justify-end',
            valueGetter: numberUtils.currencyFormat,
        },
        {
            field: 'merchants',
            headerName: 'Merchants',
            sortable: true,
            className: 'text-right',
            headerClassName: 'justify-end',
        },
        {
            field: 'cards',
            headerName: 'Cards',
            sortable: true,
            className: 'text-right',
            headerClassName: 'justify-end',
        },
        {
            field: 'details',
            headerName: 'Details',
            valueGetter: () => 'View',
            className: 'text-blue-300',
            render: TextButtonColumn,
            actionName: TASK_VIEW_ACTION,
        },
    ]

    return (
        <DataTable
            showPagination
            data={data?.rows || []}
            columns={columns}
            loading={isLoading}
            onAction={onTableAction}
            paginationProps={{
                totalItems: data?.count ?? 0,
                currentPage: page,
                itemsPerPage,
                onPageClick,
                onItemsPerPageChange: (e) => { resetPage(); setItemsPerPage(e); },
                className: 'px-5',
            }}
        />
    );

}

PDSTasksMain.propTypes = {
    timeSelected: PropTypes.string.isRequired,
    onTaskSelected: PropTypes.func.isRequired,
    search: PropTypes.string,
    refreshTick: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
};

PDSTasksMain.defaultProps = {
    search: null,
    refreshTick: 0,
    startDate: null,
    endDate: null
};
