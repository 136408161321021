import React from 'react';
import PropTypes from 'prop-types';
import {
  BanIcon,
  ChatIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  PauseIcon,
  XIcon,
} from '@heroicons/react/outline';
import { formatDate } from '../../utils/date';
import StatusConstants from '../../config/StatusConstants';
import getReason from '../../utils/reasons';
import useGetCardLog from '../../hooks/api/cards/useGetCardLog';
import Spinner from '../Spinner';
import CardConstants from '../../config/CardConstants';
import { getActivityChangedBy } from './helper'
import { ActivityDescription } from './ActivityDescription';

function getActivityReason(item) {
  if (
    item.changedByType === 'rule' &&
    item.changedBy &&
    item.changedBy.description
  ) {
    return item.changedBy.description;
  }

  return getReason(item.reason);
}

function ActivityAlert({ item }) {
  if (
    item.changedByType !== 'rule' ||
    !item.changedBy ||
    !item.changedBy.name
  ) {
    return null;
  }
  return (
    <div>
      <span className="text-sm font-medium">Alert: </span>
      <span className="text-sm text-gray-600">{item.changedBy.name}</span>
    </div>
  );
}

ActivityAlert.propTypes = {
  item: PropTypes.shape({
    changedByType: PropTypes.string,
    changedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

function getActivityIconFromAction(item) {
  if(item.action === CardConstants.actions.cardInMerchantAttack){
    return <ExclamationCircleIcon />;
  } else {
    return <CheckCircleIcon />;
  }
}

function getActivityIcon(item) {
  if (item.newStatusApiName) {
    switch (item.newStatusApiName) {
      case StatusConstants.resolveCard:
        return <CheckCircleIcon />;
      case StatusConstants.suspendCardApiName:
        return <PauseIcon />;
      case StatusConstants.pendingReviewApiName:
        return <BanIcon />;
      case StatusConstants.approvedApiName:
        return <ClockIcon />;
      case StatusConstants.reviewedApiName:
        return <XIcon />;
      default:
        return getActivityIconFromAction(item);
    }
  } else {
    if(item.action === CardConstants.actions.comment) {
      return <ChatIcon />;
    }
    return <CheckCircleIcon />;
  }
}

function ItemComment({ action, note }) {
  return (
    <div>
      <span className="text-sm font-medium">
        {action === CardConstants.actions.cardInMerchantAttack
          ? 'Confirmed merchant threat: '
          : 'Comment: '}
      </span>
      <span className="text-sm text-gray-600">{note}</span>
    </div>
  );
}

ItemComment.propTypes = {
  action: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
};

function ActivityLogItem({ item }) {
  const reason = getActivityReason(item);
  return (
    <div className="relative flex mb-6 -ml-px last:border-l last:border-white">
      <div className="absolute -mt-1.5 -left-4">
        <div className="w-8 h-8 p-1 text-blue-900 rounded-full bg-blue-50">{getActivityIcon(item)}</div>
      </div>
      <div className="flex-1 pb-6 ml-6 border-b border-b-gray-200">
        <div className="flex flex-col">
          <div className="flow-root">
            <div className="float-left text-sm text-gray-900">
              <span className="font-semibold">{getActivityChangedBy(item.changedBy, item.changedByType)}</span>
              &nbsp;
              <ActivityDescription
                action={item.action}
                lastFour={item.lastFour}
                changedByType={item.changedByType}
                newStatusApiName={item.newStatusApiName}
                isExternalStatusSynced={item.isExternalStatusSynced}
              />
            </div>
            <span className="float-right mb-1 text-xs text-gray-500">{formatDate(item.createdAt)}</span>
          </div>
          <ActivityAlert item={item} />
          {reason && (
            <div>
              <span className="text-sm font-medium">Reason: </span>
              <span className="text-sm text-gray-600">{reason}</span>
            </div>
          )}
          {item.note && item.changedByType !== 'rule' && <ItemComment action={item.action} note={item.note} />}
        </div>
      </div>
    </div>
  );
}

ActivityLogItem.propTypes = {
  item: PropTypes.shape({
    changedBy: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    changedByType: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    note: PropTypes.string,
    reason: PropTypes.string,
    action: PropTypes.string,
    lastFour: PropTypes.string,
    newStatusApiName: PropTypes.string,
    isExternalStatusSynced: PropTypes.bool
  }).isRequired,
};

export default function CardActivityLog({ id }) {
  const { data: cardLogsItem, isLoading } = useGetCardLog(id);

  return isLoading ? (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
      <Spinner />
    </div>
  ) : (
    <div className="ml-2 border-l border-gray-200">
      {cardLogsItem.map((item) => (
        <ActivityLogItem key={`log-${item.id}`} item={item} />
      ))}
    </div>
  );
}

CardActivityLog.propTypes = {
  id: PropTypes.number.isRequired,
};
