import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';
import LinkTextButton from '../../buttons/LinkTextButton';

export default function CardDetailsColumn({ row, column, onAction }) {
  const { className, valueGetter } = column;

  return (
    <td className={`${TABLE_CELL_CLASS} ${className}`}>
      <div>
        <LinkTextButton
          className="flex flex-col space-y-2"
          onClick={() => onAction && onAction(column.actionName, row)}
          link={valueGetter(row)}
          row={row}
        >
          * {row.lastFour}
        </LinkTextButton>
        <span className="text-xs text-gray-500 truncate focus:outline-none">
          
          B: {row.bin} <br />
          
          P: {row.proxy} <br />
        </span>
      </div>
    </td>
  );
}

CardDetailsColumn.propTypes = {
  row: PropTypes.shape({
    bin: PropTypes.number,
    lastFour: PropTypes.string,
    proxy: PropTypes.string,
  }).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string,
    headerName: PropTypes.string,
    valueGetter: PropTypes.func,
    render: PropTypes.func,
    className: PropTypes.string,
    actionName: PropTypes.string,
    childClassName: PropTypes.string,
  }).isRequired,
  onAction: PropTypes.func,
};

CardDetailsColumn.defaultProps = {
  onAction: null,
};
