import CardConstants from "src/config/CardConstants";


interface ChangedByProps {
  name: string;
}

export function getActionName(action: string) {
  switch (action) {
    case CardConstants.actions.viewFullCardNumber:
      return 'viewed the full card number';
    case CardConstants.actions.hideFullCardNumber:
      return 'hide the full card number';
    case CardConstants.actions.cardInMerchantAttack:
      return 'compromised';
    case 'Remove':
      return 'Removed';
    case 'Suspend':
      return 'Suspended';
    case 'Replace':
      return 'Replaced';
    case 'Active':
      return 'ACTIVE';
    case 'Assign User':
      return 'Assigned User';
    default:
      return action;
  }
}

export function getActivityChangedBy(changedBy: ChangedByProps, changedByType: string) {
  if (!changedBy || changedByType === CardConstants.changedByType.rule || changedByType === CardConstants.changedByType.system) {
    return 'ARDEN';
  }

  return changedBy.name || '';
}

export const validateSyncAction = (action: string) => {
  return action !== 'card-in-merchant-attack' &&
  action !== 'RESOLVED' &&
  action !== 'NOT COMPROMISED'
}
