import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getMerchantTrendsChartData = async (
  client,
  params,
) => api.merchants.fetchMerchantTrendsChartData(client, params);

export default function useGetMerchantTrendsChartData(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'merchant/trends-chart-data',
      ...Object.values(params),
    ],
    async () => getMerchantTrendsChartData(apiClient, params),
  );
}
