import { useMutation } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';

export default function useRemovePicture(
  options,
) {
  const client = useApiClient();

  return useMutation((id) => api.account.removePicture(client, id), {
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
