/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const cardSlice = createSlice({
  name: 'card',
  initialState: {
    authType: null,
    selectedCard: null,
    removeCard: null,
    showSelectedCardTransactions: false,
    showSelectedCard: false,
    lastCardUpdated: {},
  },
  reducers: {
    selectCard: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selectedCard = action.payload;
    },
    setAuthType: (state, action) => {
      state.authType = action.payload;
    },
    setRemoveCard: (state, action) => {
      state.removeCard = action.payload;
    },
    setShowSelectedCardTransactions: (state, action) => {
      state.showSelectedCardTransactions = action.payload;
    },
    setShowSelectedCard: (state, action) => {
      state.showSelectedCard = action.payload;
    },
    setLastCardUpdated: (state, action) => {
      state.lastCardUpdated = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAuthType,
  selectCard,
  setRemoveCard,
  setShowSelectedCardTransactions,
  setShowSelectedCard,
  setLastCardUpdated,
} = cardSlice.actions;

export default cardSlice.reducer;
