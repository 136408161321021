import React from 'react';
import { toast } from 'react-hot-toast';
import {
  BanIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  XIcon,
  SwitchHorizontalIcon,
  PauseIcon,
  PlayIcon,
  ChatIcon,
} from '@heroicons/react/outline';
import dismissToast from './Toast.helper';
import Spinner from '../Spinner';
import MerchantConstants from '../../config/MerchantConstants';
import StatusConstants from '../../config/StatusConstants';

export const LOADING = 'loading';
export const SUCCESS = 'success';
export const FAILED = 'failed';

export const getIcon = (type) => {
  switch (type) {
    case LOADING:
      return (
        <Spinner fillColor="#2563EB" pathColor="#FFFFFF" size="6" />
      );
    case FAILED:
      return <ExclamationCircleIcon />;
    case SUCCESS:
      return <CheckCircleIcon />;
    case StatusConstants.blockedApiName:
      return <BanIcon />;
    case StatusConstants.pendingReviewApiName:
      return <ExclamationCircleIcon />;
    case StatusConstants.approvedApiName:
      return <ClockIcon />;
    case StatusConstants.reviewedApiName:
      return <XIcon />;
    case StatusConstants.replacedCardApiName:
      return <SwitchHorizontalIcon />;
    case StatusConstants.resolveCard:
      return <XIcon />;
    case StatusConstants.suspendCardApiName:
    case StatusConstants.pFraudCardApiName:
      return <PauseIcon />;
    case StatusConstants.activeCardApiName:
      return <PlayIcon />;
    case MerchantConstants.actions.comment:
      return <ChatIcon />;
    default:
      return <CheckCircleIcon />;
  }
};

export const getIconColor = (type) => {
  switch (type) {
    case LOADING:
      return 'gray';
    case FAILED:
      return 'red';
    case SUCCESS:
      return 'green';
    case StatusConstants.blockedApiName:
      return 'yellow';
    case StatusConstants.pendingReviewApiName:
      return 'blue';
    case StatusConstants.approvedApiName:
      return 'blue';
    case StatusConstants.reviewedApiName:
      return 'gray';
    case StatusConstants.replacedCardApiName:
      return 'blue';
    case StatusConstants.resolveCard:
      return 'gray';
    case StatusConstants.suspendCardApiName:
      return 'purple';
    case StatusConstants.activeCardApiName:
    case MerchantConstants.actions.comment:
      return 'green';
    default:
      return 'green';
  }
};

export function getMessage2(type) {
  switch (type) {
    case StatusConstants.blockedApiName:
      return 'Blocked';
    case StatusConstants.trustedApiName:
      return 'Trusted';
    case StatusConstants.pendingReviewApiName:
      return 'Pending Review';
    case StatusConstants.approvedApiName:
      return 'Approved';
    case StatusConstants.reviewedApiName:
      return 'Removed from list';
    case StatusConstants.knownApiName:
      return 'Known';
    case StatusConstants.replacedCardApiName:
      return 'Replaced';
    case StatusConstants.resolveCard:
      return 'Resolved';
    case StatusConstants.suspendCardApiName:
      return 'Suspended';
    case StatusConstants.pFraudCardApiName:
      return 'Marked as Pfraud';
    case StatusConstants.activeCardApiName:
      return 'Re-activated';
    default:
      return '';
  }
}

export function showToast(type, message1, message2) {

  const useCustomSpinnerIcon = type === 'loading';
  const widthClass = useCustomSpinnerIcon ? '' : 'w-7';

  return toast.custom((t) => (
    <div
      className={`${t.visible ? 'animate-enter' : 'animate-leave'}
        max-w-md w-96 bg-white rounded pointer-events-auto flex p-2 border-solid border border-gray-200`}
      data-cy="toast-container"
    >
      <div className="flex-1 w-0 flex-shrink-0 pt-0.5 pl-2 align-middle text-sm">
        <div className="flex items-center">
          <div
            className={`bg-${getIconColor(type)}-100 p-1 ${widthClass} mr-3 rounded-full text-${getIconColor(
              type
            )}-700`}
          >
            {getIcon(type)}
          </div>
          <span className="text-sm font-normal text-gray-900">{message1}</span>
          &nbsp;
          <span className="text-sm font-medium text-gray-900">{message2}</span>
        </div>
      </div>
      <div className="flex items-center ml-3 h-7">
        <button
          data-cy="slide-over-close-button"
          type="button"
          className="text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            toast.dismiss(t.id);
          }}
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  ));
}

export function toastWithPromise(promise, {
  error, success, loading,
}, type) {
  if (promise.isFulfilled) return promise;
  const toastId = showToast(LOADING, loading.message1 ? loading.message1 : loading, loading.message2 ? loading.message2 : '');
  const result = promise.then((data) => {
    dismissToast(toastId);
    showToast(type, success.message1 ? success.message1 : success, success.message2 ? success.message2 : '');
    return data;
  }, (e) => {
    console.error(e)
    dismissToast(toastId);
    showToast(FAILED, error);
  });
  return result;
}

export function toastBulkAction(promise, {
  loading,
}, type) {
  if (promise.isFulfilled) return promise;
  const toastId = showToast(LOADING, loading || '');
  const result = promise.then((bulkAction) => {
    const errorMessages = bulkAction.filter((action) => !action.success);
    const successMessages = bulkAction.filter((action) => action.success);
    if(errorMessages.length > 0) {
      errorMessages.forEach((error) =>
      {
        showToast(FAILED, error.message);
      })
    }

    if(successMessages.length > 0) {
      successMessages.forEach((success) =>
      {
        showToast(type, success.message);
      })
    }
    dismissToast(toastId);
    return bulkAction;
  });
  return result;
}
