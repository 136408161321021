import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/ui';
import useApiClient from '../../hooks/useApiClient';
import useFetchActivity from '../../hooks/api/activity/useFetchActivity';

const globalTypeOfData = {
  merchants: [
    { type: 'merchant-update', value: '# Merchants Reviewed' },
    { type: 'merchant-update - Blocked', value: '# Merchants Blocked' },
    { type: 'merchant-update - Trusted', value: '# Merchants Trusted' },
    { type: 'merchant-update - Reviewed', value: '# Merchants Removed from List' },
    { type: 'merchant-update - Hour', value: '# Avg. Merchant Reviwed/hr' }],
  cards: [
    { type: 'card-update', value: '# Card Assigned' },
    { type: 'card-update - Active - Replaced', value: '# Card Resolved' },
    { type: 'card-update - Suspended', value: '# Card Suspended' },
    { type: 'card-update - Active', value: '# Card Re-activated' },
    { type: 'card-update - 1226', value: '# Card marked Not Compromised' },
    { type: 'card-update - Replaced', value: '# Card Replaced' },
    { type: 'averageTurnaroundTime', value: 'Avg. Turnaround Time/card' },
    { type: 'card-update - Hour', value: 'Avg. Card Reviewed/hr' },
    { type: 'card-update', value: '# of Unresolved Cards' }],
};
const ProcessData = (type, data) => {
  const parsedType = type.split(' - ');
  if (parsedType.length === 1 && data) {
    return data.filter((field) => (field.module === type)).length;
  }
  if (parsedType.length === 2 && data) {
    if (parsedType[1] === '1226') {
      return data.filter((field) => (field.reason === parsedType[1])).length;
    }
    if (parsedType[1] === 'Hour') {
      let hours = data.filter((field) => (field.module === parsedType[0])).map((d) => d.createdAt.split(':')[0]);
      hours = hours.filter((hour, index) => hours.indexOf(hour) === index);
      return (data.filter((field) => (field.module === parsedType[0])).length / hours.length)
        .toFixed(2);
    }
    return data.filter((field) => (field.action === parsedType[1])).length;
  }
  if (parsedType.length === 3 && data) {
    return data.filter((field) => (field.action === parsedType[1])).length
      + data.filter((field) => (field.action === parsedType[2])).length;
  }
  return 0;
};
const RenderTaskTableValues = (typeOfData, data) => {
  const typeOfDataSelected = globalTypeOfData[typeOfData];
  return (
    <div className="mt-6">
      <table className="p-0">
        <tbody>
          <tr>
            <th className="items-center h-8 pl-2 pr-2 text-xs not-italic font-normal leading-4 text-right text-black bg-white border-2">Description</th>
            <th className="items-center h-8 pl-2 pr-2 m-0 text-xs not-italic font-bold leading-4 text-center text-black uppercase bg-gray-100 border-2">Interval Summary</th>
          </tr>
          {typeOfDataSelected && typeOfDataSelected.map((row) => (
            <tr key={`tasktable-row-${row.value}`}>
              <td className="items-center h-8 pl-2 pr-2 text-xs not-italic font-normal leading-4 text-right text-black bg-white border-2">{row.value}</td>
              <td className="items-center h-8 pl-2 pr-2 m-0 text-xs not-italic font-bold leading-4 text-center text-black uppercase bg-gray-100 border-2">{ProcessData(row.type, data)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
    </div>
  );
};

export default function TaskTable({ userId }) {
  const [hours, setHours] = useState(24);
  const [typeOfData, setTypeofData] = useState('merchant');
  const clientApi = useApiClient();
  const { data: activityData } = useFetchActivity(
    clientApi, userId,
  );

  return (
    <>
      <div className="flex mt-6">
        <div className="flex w-full">
          <button type="button" onClick={() => { setTypeofData('merchants'); }} className={classNames('w-24 h-8  rounded-sm not-italic font-normal text-sm leading-5 text-center border-2', typeOfData === 'merchants' && 'bg-blue-800 text-white')}> Merchant </button>
          <button type="button" onClick={() => { setTypeofData('cards'); }} className={classNames('w-24 h-8  rounded-sm not-italic font-normal text-sm leading-5 text-center border-2', typeOfData === 'cards' && 'bg-blue-800 text-white')}> Card </button>
        </div>
        <div className="flex">
          <button onClick={() => { setHours(12); }} type="button" className={classNames('w-16 h-6 not-italic font-medium text-xs leading-4 text-center  bg-white border-2', hours === 12 && 'bg-blue-800 text-white not-italic font-medium text-xs leading-4 text-center')}>12 hours</button>
          <button onClick={() => { setHours(24); }} type="button" className={classNames('w-16 h-6 not-italic font-medium text-xs leading-4 text-center  bg-white border-2', hours === 24 && 'bg-blue-800 text-white')}>24 hours</button>
          <button onClick={() => { setHours(48); }} type="button" className={classNames('w-16 h-6 not-italic font-medium text-xs leading-4 text-center  bg-white border-2', hours === 48 && 'bg-blue-800 text-white')}>48 hours</button>
          <button onClick={() => { setHours(null); }} type="button" className={classNames('w-16 h-6 not-italic font-medium text-xs leading-4 text-center  bg-white border-2', hours === null && 'bg-blue-800 text-white')}>All</button>
        </div>
      </div>
      {RenderTaskTableValues(typeOfData, activityData)}
    </>
  );
}

TaskTable.propTypes = {
  userId: PropTypes.number.isRequired,
};
