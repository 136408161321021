import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '../../../components/ButtonGroup';
import ButtonGroupColumn from '../../../components/table/columns/ButtonGroupColumn';
import ToggleColumn from '../../../components/table/columns/ToggleColumn';
import DataTable from '../../../components/table/DataTable';
import ruleOptions from './ruleOptions';
import { toastWithPromise } from '../../../components/Toast/Toast';
import useGetRules from '../../../hooks/admin/rules/useGetRules';
import useUpdateTenantRule from '../../../hooks/admin/rules/useUpdateTenantRule';
import useConstant from '../../../hooks/useConstant';
import useGetMe from '../../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../../config/FeatureFlagConstants';

const CHANGE_ACTIVE_ACTION = 'change-active-action';
const CHANGE_RISK_LEVEL_ACTION = 'change-risk-level';

function useRulesManagementColumns() {
  const { data: me } = useGetMe();
  const riskOptions = useConstant(() => (
    [
      {
        label: 'Low',
        value: 1,
      },
      {
        label: 'Medium',
        value: 2,
      },
      {
        label: 'High',
        value: 3,
      },
      {
        label: 'Critical',
        value: 4,
      },
    ]
  ));
  const columns = useConstant(() => ([
    {
      field: 'name',
      headerName: 'Rule',
    },
    {
      field: 'description',
      headerName: 'Description',
    },
    {
      field: 'isActive',
      headerName: 'Status',
      render: ToggleColumn,
      actionName: CHANGE_ACTIVE_ACTION,
    },
    {
      field: 'riskLevel',
      headerName: 'False positive risk level',
      render: ButtonGroupColumn,
      options: riskOptions,
      actionName: CHANGE_RISK_LEVEL_ACTION,
    },
    {
      field: 'classification',
      headerName: 'Fraud Type',
    },
    {
      field: 'category',
      headerName: 'Category',
    },
  ]));

  return useMemo(() => {
    if (!me) {
      return [];
    }
    if (me && me.featureFlags[FeatureFlagConstants.hideFalsePositiveRiskLevel]) {
      return columns.filter((column) => column.field !== 'riskLevel');
    }
    return columns;
  }, [me]);
}

export default function RulesManagement({ tenantId }) {
  const columns = useRulesManagementColumns();
  const [type, setTypeSelected] = useState(ruleOptions[0].value);
  const { data } = useGetRules({
    tenantId,
    relatedModel: type,
  });
  const updateTenantRule = useUpdateTenantRule();

  function onTableAction(action, rule, value) {
    const payload = {};
    switch (action) {
      case CHANGE_ACTIVE_ACTION:
      case CHANGE_RISK_LEVEL_ACTION:
        if (action === CHANGE_ACTIVE_ACTION) {
          payload.isActive = !rule.isActive;
        } else if (action === CHANGE_RISK_LEVEL_ACTION) {
          payload.riskLevel = value;
        }

        toastWithPromise(
          updateTenantRule.mutateAsync({
            tenantId,
            ruleId: rule.id,
            ...payload,
          }),
          {
            loading: 'Updating rule...',
            success: 'Rule updated successfully',
            error: 'An error occurred while updating the rule',
          },
        );
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <div className="mb-8">
        <ButtonGroup
          roundedCorners={false}
          itemClassName="text-sm"
          options={ruleOptions}
          selected={type}
          onChange={setTypeSelected}
        />
      </div>
      <DataTable
        columns={columns}
        showPagination={false}
        data={data}
        onAction={(action, row, value) => onTableAction(action, row, value)}
      />
    </div>
  );
}

RulesManagement.propTypes = {
  tenantId: PropTypes.number.isRequired,
};
