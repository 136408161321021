const fetchPDSMerchants = async (client, params) => client.get('api/pds/merchants',
  {
    params,
  })
  .then((response) => response.data);

const fetchPDSReasons = async (client, params) => client.get('api/pds/reasons',
  {
    params,
  })
  .then((response) => response.data);

const fetchPDSReason = async (client, params) => client.get('api/pds/reason',
  {
    params,
  })
  .then((response) => response.data);

const fetchPDSCards = async (client, params) => client.get('api/pds/cards', {
  params,
})
  .then((response) => response.data);

const fetchPDSTasks = async (client, params) => client.get('api/pds/tasks', {
  params,
})
  .then((response) => response.data);

export default {
  fetchPDSMerchants,
  fetchPDSReasons,
  fetchPDSReason,
  fetchPDSCards,
  fetchPDSTasks
};
