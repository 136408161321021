import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';
import Toggle from '../../Toggle';
import { getColumnValue } from '../DataTableUtils';

export default function ToggleColumn({
  row, onAction, column, className,
}) {
  const { id } = row;
  return (
    <td key={`cel-${id}-toggle`} className={`${TABLE_CELL_CLASS} ${className}`}>
      <Toggle
        checked={getColumnValue(row, column)}
        onChange={() => onAction && onAction(column.actionName, row)}
      />
    </td>
  );
}

ToggleColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      statusApiName: PropTypes.string,
    },
  ).isRequired,
  column: PropTypes.shape({
    actionName: PropTypes.string,
  }).isRequired,
  onAction: PropTypes.func,
  className: PropTypes.string,
};

ToggleColumn.defaultProps = {
  onAction: null,
  className: '',
};
