import { parseISO } from 'date-fns';
import { useQuery } from 'react-query';
import api from '../../../api';
import useApiClient from '../../useApiClient';

const getUserValidationReports = async (
  client,
  params,
) => api.admin.validationRules.fetchUserValidationReports(
  client,
  params,
).then(({ rows, count }) => ({
  rows: rows.map((row) => ({
    ...row,
    startDate: parseISO(row.startDate),
    endDate: parseISO(row.endDate),
  })),
  count,
}));

export default function useGetUserValidationReports(params) {
  const apiClient = useApiClient();
  return useQuery(
    [
      'validationReports', params,
    ],
    async () => getUserValidationReports(apiClient, params),
  );
}
