import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getRules = async (client) =>
  api.cards.fetchCompromisedCardsRulesFilter(client);

export default function useGetCompromisedCardsRulesFilter(options) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['cards', 'compromised', 'rules'],
    async () => getRules(apiClient),
    options
  );
}
