import { useRef } from 'react';

export default function useConstant(fn) {
  const ref = useRef();

  if (!ref.current) {
    ref.current = { value: fn() };
  }

  return ref.current.value;
}
