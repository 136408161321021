import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetRulesPerformanceSummary(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery([
    'rulesAdmin', 'summary', params,
  ],
  async () => api.admin.rules.fetchAdminRulesPerformanceSummary(apiClient, params));
}
