import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getMerchants = async (client) =>
  api.cards.fetchCompromisedCardsMerchantsFilter(client);

export default function useGetCompromisedCardsMerchantsFilter(options) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['cards', 'compromised', 'merchants'],
    async () => getMerchants(apiClient),
    options
  );
}
