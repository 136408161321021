import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Modal from '../Modal';
import Filters from '../Filters';
import SelectFilter from '../filters/SelectFilter';
import Label from '../forms/Label';
import Input from '../forms/Input';

const getTitle = (title) => (
  <div className="flex items-center mt-2">
    <div className="bg-blue-50 p-1 w-7 mr-3 rounded-full">
      <ExclamationCircleIcon className="w-5 text-blue-900" />
    </div>
    <span className="text-xl font-semibold">
      {title}
    </span>
  </div>
);
export default function TenantConfirmationModal({
  primaryAction,
  onClose,
  constantValue,
  defaultSelector,
  defaultNumber,
  category,
  ...rest
}) {
  const [selectData, setSelectData] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState(defaultNumber);

  useEffect(() => {
    setInputFieldValue(defaultNumber);
    setSelectData(defaultSelector || null);
    setSelectOptions(constantValue ? constantValue.array : []);
  }, [constantValue, defaultNumber, defaultSelector]);

  const resetFields = () => {
    setInputFieldValue('');
    setSelectOptions([]);
  };
  const onPrimaryAction = () => {
    primaryAction(category, (constantValue.type === 'select' && selectData) ? selectData.value : inputFieldValue);
    resetFields();
  };
  const onCloseAction = () => {
    if (onClose) {
      onClose();
      resetFields();
    }
  };
  const checkValue = (value) => {
    if (!Number(value)) {
      setInputFieldValue('');
    } else {
      setInputFieldValue(value);
    }
  };
  return (
    <Modal
      {...rest}
      primaryText="Confirm"
      secondaryText="Cancel"
      onClose={onCloseAction}
      primaryAction={onPrimaryAction}
      title={getTitle(constantValue ? constantValue.title : '')}
      divider={false}
    >
      <div className="ml-10 ">
        <p className="text-left text-gray-500 pr-1 mb-4">{constantValue ? constantValue.description : ''}</p>
        {constantValue && constantValue.type === 'select' && (
          <div className="flex flex-col">
            <Label className="mb-1" htmlFor="inputValue">
              {constantValue.label}
            </Label>
            <Filters>
              <SelectFilter
                id="filter"
                isSearchable={false}
                placeholder="Select"
                options={selectOptions}
                value={
                  selectOptions.filter(({ value }) => selectData && value === selectData.value)
                }
                menuPlacement="top"
                onChange={(e) => { setSelectData(e); }}
              />
            </Filters>
          </div>
        )}
        {constantValue && constantValue.type === 'input' && (
          <div className="flex flex-col">
            <Label className="mb-1" htmlFor="inputValue">
              {constantValue.label}
            </Label>
            <Input
              className="w-1/2"
              type="text"
              id="inputValue"
              name="inputValue"
              rows={3}
              maxLength="250"
              value={inputFieldValue}
              onChange={({ target }) => checkValue(target.value)}
            />
          </div>
        )}

      </div>
    </Modal>
  );
}

TenantConfirmationModal.propTypes = {
  constantValue: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    array: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  defaultSelector: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  category: PropTypes.string,
  defaultNumber: PropTypes.number,
  onClose: PropTypes.func,
  primaryAction: PropTypes.func.isRequired,
};
TenantConfirmationModal.defaultProps = {
  constantValue: {
    title: '',
    description: '',
    type: '',
    label: '',
    array: [],
  },
  category: '',
  defaultSelector: {},
  defaultNumber: null,
  onClose: null,
};
