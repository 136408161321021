export default {
  SUPER_ADMIN: 'super-admin',
  VIEW_THREADS: 'view-threats',
  RESOLVE_THREADS: 'resolve-threats',
  VIEW_MERCHANTS_LIST: 'view-merchants',
  UPDATE_MERCHANTS_LIST: 'update-merchant',
  VIEW_CARDS: 'view-cards',
  RESOLVE_CARDS: 'resolve-cards',
  REASSIGN_COMPROMISED_CARDS: 'reassign-compromised-cards',
  VIEW_INSIGHTS: 'view-insights',
  OVERRIDE_DESICTIONS: 'override-decisions',
  SELECT_AUTO_BLOCK_LEVEL: 'select-block-level',
  VIEW_USERS: 'view-users',
  ADD_MODIFIY_USERS: 'add-users',
  VIEW_ROLES: 'view-roles',
  ADD_MODIFY_ROLES: 'add-roles',
  ACCESS_ACTIVITY_LOGS: 'access-activity',
};
