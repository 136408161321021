import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PauseIcon, PlayIcon, XIcon, DotsHorizontalIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import StatusConstants from '../../config/StatusConstants';
import { checkPermission } from '../../utils/userUtil';
import PermissionConstants from '../../config/PermissionConstants';
import useGetMe from '../../hooks/api/users/useGetMe';
import useGetMyPermissions from '../../hooks/api/users/useGetMyPermissions';
import { classNames } from '../../utils/ui';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import { CardActions as CardActionConstants } from '../../config/CardConstants';

const CLASS_ICON = 'h-5 w-5 ml-2 mt-1.5 border-gray-700';

const NotCompromisedResolvedButton = ({ card, onClick, permissions }) => {
  if (!checkPermission(permissions, PermissionConstants.RESOLVE_CARDS)) {
    return null;
  }
  const isCardSuspendedOrPfraud = card.statusApiName === StatusConstants.suspendCardApiName || card.statusApiName === StatusConstants.pFraudCardApiName;
  const isCompromised = card.isCompromised;
  const isResolved = isCompromised && isCardSuspendedOrPfraud;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          disabled={!isCompromised}
          onClick={() => onClick(CardActionConstants.Resolve, StatusConstants.resolveCard, card)}
          data-cy="card-actions-column-resolve"
        >
          <XIcon className={classNames(CLASS_ICON, !isCompromised ? 'opacity-30 text-gray-400' : 'text-gray-600', '')} />
          <span className={classNames('flex items-left px-2 py-2 text-xs font-bold', !isCompromised ? 'opacity-30 text-gray-400' : 'text-gray-700')}>
            {isResolved ? 'Resolved' : 'Not compromised'}
          </span>
        </button>
      )}
    </Menu.Item>
  );
};

NotCompromisedResolvedButton.propTypes = {
  card: PropTypes.shape({
    statusApiName: PropTypes.string,
    isCompromised: PropTypes.bool,
  }),
  onClick: PropTypes.func,
  permissions: PropTypes.oneOfType([PropTypes.array]),
};
NotCompromisedResolvedButton.defaultProps = {
  onClick: () => { },
  card: null,
  permissions: null,
};

const UnsuspendButton = ({ card, onClick, permissions }) => {
  if (!checkPermission(permissions, PermissionConstants.RESOLVE_CARDS)) {
    return null;
  }

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          onClick={() =>
            onClick(CardActionConstants.Reactivate, StatusConstants.unsuspendCardApiName, card)
          }
          data-cy="card-actions-column-reactivate"
        >
          <PlayIcon className={classNames(CLASS_ICON, 'text-green-600')} />
          <span className="flex items-left px-2 py-2 text-gray-700 text-xs font-bold">Reactivate</span>
        </button>
      )}
    </Menu.Item>
  );
};

UnsuspendButton.propTypes = {
  card: PropTypes.shape({
    statusApiName: PropTypes.string,
  }),
  onClick: PropTypes.func,
  permissions: PropTypes.oneOfType([PropTypes.array]),
};
UnsuspendButton.defaultProps = {
  onClick: () => { },
  card: null,
  permissions: null,
};

const SuspendButton = ({ card, onClick, permissions }) => {
  if (!checkPermission(permissions, PermissionConstants.RESOLVE_CARDS)) {
    return null;
  }
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(active ? 'flex bg-gray-100' : 'flex text-gray-700 hover:bg-gray-50', 'w-48')}
          onClick={() =>
            onClick(CardActionConstants.UpdateStatus, StatusConstants.suspendCardApiName, card)
          }
        >
          <PauseIcon className={classNames(CLASS_ICON, 'text-yellow-600')} />
          <span className="flex items-left px-2 py-2 text-gray-700 text-xs font-bold">Suspend</span>
        </button>
      )}
    </Menu.Item>
  );
};

SuspendButton.propTypes = {
  card: PropTypes.shape({
    statusApiName: PropTypes.string,
    isCompromised: PropTypes.bool,
  }),
  onClick: PropTypes.func,
  permissions: PropTypes.oneOfType([PropTypes.array]),
};

SuspendButton.defaultProps = {
  onClick: () => { },
  card: null,
  permissions: null,
};

function CardActions(props) {
  const { card, onClick } = props;
  const { data: user } = useGetMe();
  const { data: permissions } = useGetMyPermissions();

  const { statusApiName } = card;
  const isInternalStatusInactive = statusApiName === StatusConstants.statusInactive;
  const hasOptionsAllowed =
    !user.featureFlags[FeatureFlagConstants.hideCardActions] && !isInternalStatusInactive;

  const isInternalStatusPfraud = statusApiName === StatusConstants.pFraudCardApiName;
  const toUnsuspend = statusApiName === StatusConstants.suspendCardApiName || isInternalStatusPfraud;
  const toSuspend = statusApiName === StatusConstants.activeCardApiName;
  return (
    <>
      <div className="flex justify-center align-middle space-x-3">
        {hasOptionsAllowed && (
          <Menu as="div" className="border mx-2 p-0.5 focus:outline-none">
            {({ open: openMenu }) => (
              <>
                <Menu.Button
                  className="w-6 px-1 items-center text-gray-600 text-sm flex focus:outline-none focus:bg-white hover:bg-white focus:bg-opacity-10 hover:bg-opacity-10 active:bg-opacity-20"
                  data-cy="card-actions-column-button"
                >
                  <span className="hidden md:inline">
                    <DotsHorizontalIcon
                      className={classNames('text-gray-400 group-hover:text-gray-500', '-ml-0.5 h-5 w-5')}
                    />
                  </span>
                </Menu.Button>
                <Transition
                  show={openMenu}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right z-20 absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {toSuspend && <SuspendButton permissions={permissions} card={card} onClick={onClick} />}
                    {toUnsuspend && (
                      <UnsuspendButton permissions={permissions} card={card} onClick={onClick} />
                    )}
                    <NotCompromisedResolvedButton card={card} permissions={permissions} onClick={onClick} />
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        )}
      </div>
    </>
  );
}

CardActions.propTypes = {
  card: PropTypes.shape({
    statusApiName: PropTypes.string,
    isAttacksCompromised: PropTypes.bool,
    isCompromised: PropTypes.bool,
  }),
  onClick: PropTypes.func,
};

CardActions.defaultProps = {
  onClick: () => { },
  card: null,
};

export default CardActions;
