const fetchManualBlockedMerchants = async (client, params) => client.get(
  'api/merchants/manual-blocked',
  { params },
).then((response) => response.data);
const fetchMerchantSummary = async (client, id, params) => client.get(
  `api/merchants/${id}/summary`,
  { params },
).then((response) => response.data);

const fetchMccs = async (client) => client.get('api/merchants/mcc')
  .then((response) => response.data);

const fetchMerchantThreats = async (client, params = {}) => client.get('api/merchants/threats', {
  params,
})
  .then((response) => response.data);
const fetchMerchantThreatsActiveAlertFilter = async (client, params = {}) => client.get('api/merchants/threats/active-alerts', {
  params,
})
  .then((response) => response.data);

const fetchMerchants = async (client, params = {}) => client.get('api/merchants', {
  params,
})
  .then((response) => response.data);

const fetchMerchantTrendsChartData = async (client, params = {}) => client.get('api/merchants/trends-chart-data', {
  params,
})
  .then((response) => response.data);
const fetchTop5Merchants = async (client, params = {}) => client.get('api/merchants/top5', {
  params,
})
  .then((response) => response.data);

const fetchCardsByMerchant = async (client, id, params) => client.get(`api/merchants/${id}/cards`, {
  params,
})
  .then((response) => response.data);

const fetchMerchantById = async (client, id, params = {}) => client.get(`api/merchants/${id}`, {
  params,
})

const fetchActiveMerchantAttacks = async (client, merchantTenantId) => client.get(`api/merchants/${merchantTenantId}/active-attacks`)
  .then((response) => response.data);

const fetchMerchant = async (client, merchantId) => client.get(`api/merchants/${merchantId}`)
  .then((response) => response.data);

const fetchMerchantLog = async (client, merchantId) => client.get(`api/merchants/${merchantId}/log`)
  .then((response) => response.data);

const updateMerchantStatus = async (client, merchantId, data) => {
  const { actionById, actionByName, comment, reason, statusApiName } = data;

  return client
    .put(`api/merchants/${merchantId}`, { actionById, actionByName, comment, reason, statusApiName })
    .then((response) => response.data);
};

const updateMerchantTenantBulkStatus = async (client, merchantTenantIds, statusApiName, reason, comment) => client.put('api/merchants/bulk/status',
  {
    statusApiName,
    merchantTenantIds,
    reason,
    comment
  })
  .then((response) => response.data);
const createMerchantComment = async (client, merchantTenantId, data) => client.post(`api/merchants/${merchantTenantId}/comment`,
  data);

const fetchSimilarMerchantsById = async (client, id, isBlockedById) => client.get(`api/merchants/${id}/similar-by-id/${isBlockedById}`)
  .then((response) => response.data);

const fetchSimilarMerchantsByName = async (client, id, isBlockedByName) => client.get(`api/merchants/${id}/similar-by-name/${isBlockedByName}`)
  .then((response) => response.data);

// @TODO shouldn't this receive the status to filter by
const getBlockedMerchantsFilters = async (client) => client.get('api/merchants/merchant-filter')
  .then((response) => response.data);

export default {
  fetchMerchant,
  fetchManualBlockedMerchants,
  fetchMccs,
  fetchMerchants,
  fetchMerchantSummary,
  fetchMerchantThreats,
  fetchMerchantThreatsActiveAlertFilter,
  fetchMerchantTrendsChartData,
  fetchMerchantLog,
  fetchTop5Merchants,
  fetchCardsByMerchant,
  fetchMerchantById,
  updateMerchantStatus,
  updateMerchantTenantBulkStatus,
  getBlockedMerchantsFilters,
  createMerchantComment,
  fetchActiveMerchantAttacks,
  fetchSimilarMerchantsById,
  fetchSimilarMerchantsByName
};
