export default {
  Dormant: 'DORMANT',
  Ready: 'READY',
  Active: 'ACTIVE',
  Closed: 'CLOSED',
  Lost: 'LOST',
  Replaced: 'REPLACED',
  Suspended: 'SUSPENDED',
  Expired: 'EXPIRED',
  SActive: 'SACTIVE',
  Revoked: 'REVOKED',
  CClosed: 'CCLOSED',
  MBClosed: 'MBCLOSED',
  Fraud: 'FRAUD',
  PFraud: 'PFRAUD',
  LastStmt: 'LAST STMT',
  Chargeoff: 'CHARGEOFF',
  Deceased: 'DECEASED',
  Warning: 'WARNING',
  MUClosed: 'MUCLOSED',
  Void: 'VOID',
  Nonrenewal: 'NONRENEWAL',
  Destroyed: 'DESTROYED',
};
