import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import AdminRuleKeys from './AdminRules.keys';

export default function useGetRulesPerformanceList(params, options) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    AdminRuleKeys.list('performance', params),
    async () => api.admin.rules.fetchAdminRulesPerformanceList(apiClient, params),
    options,
  );
}
