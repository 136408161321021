/* eslint-disable no-unused-vars */
import React from 'react';

export const GroupViewContext = React.createContext({
  stateContent: [],
  state: () => { },
  setState: () => { },
  removeState: () => { },
  cleanState: () => { }
});

export default function useGroupViewContext() {
  return React.useContext(GroupViewContext);
}
