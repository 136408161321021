import React from 'react';
import PropTypes from 'prop-types';
import {
  BanIcon,
  ChatIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { formatDate } from '../utils/date';
import useGetMerchantLog from '../hooks/api/merchants/useGetMerchantLog';
import Spinner from './Spinner';
import ActivityLogInfo from './activityLog/ActivityLogInfo';

import ActionConstants from '../config/ActionConstants';
import MerchantConstants from '../config/MerchantConstants';
import StatusConstants from '../config/StatusConstants';

const getActivityIcon = (item) => {
  const { action, newStatus } = item;

  const actionMapper = {
    [MerchantConstants.actions.comment]: <ChatIcon />,
    [ActionConstants.UnblockedById]: <XIcon />,
    [ActionConstants.UnblockedByName]: <XIcon />,
    [ActionConstants.UnblockedByIdAndName]: <XIcon />,
  };

  const newStatusMapper = {
    [StatusConstants.blockedApiName]: <BanIcon />,
    [StatusConstants.pendingReviewApiName]: <ExclamationCircleIcon />,
    [StatusConstants.approvedApiName]: <ClockIcon />,
    [StatusConstants.reviewedApiName]: <XIcon />,
  };
  const component = actionMapper[action] || newStatusMapper[newStatus?.apiName];
  return component || <CheckCircleIcon />;
};

const getActivityChangedBy = (item) => {
  const { changedByType, changedBy, action } = item;
  if (changedByType === 'rule' && action === 'failsafe-rule') return 'Fail-safe'
  if (!item || !changedBy || changedByType === 'rule') return 'ARDEN';
  return item.changedBy;
};

const getReason = (item) => {
  const { changedByType, description, reason } = item;
  return changedByType === 'rule' && description ? description : reason;
}

const getActionDescription = (status) => {
  const mapper = {
    [ActionConstants.UnblockedById]: 'ID',
    [ActionConstants.UnblockedByName]: 'Name',
    [ActionConstants.UnblockedByIdAndName]: 'ID and Name',
    [ActionConstants.BlockedById]: 'Blocked by ID',
    [ActionConstants.BlockedByName]: 'Blocked by Name',
    [ActionConstants.BlockedByIdAndName]: 'Blocked by ID and Name',
    [StatusConstants.reviewedApiName]: 'Reviewed',
    [StatusConstants.trustedApiName]: 'Trusted',
    [StatusConstants.approvedApiName]: 'Approved',
  };
  return mapper[status] || status;
}

const getActivityNewStatusName = (status) => {
  const statusMapper = {
    [StatusConstants.pendingReviewApiName]: 'Suspicious',
    [StatusConstants.blockedApiName]: 'Blocked',
  };

  return statusMapper[status.apiName] || status.name;
};

const isActionBlockByIdOrNameOrBoth = (action) =>
  action === ActionConstants.UnblockedById ||
  action === ActionConstants.UnblockedByName ||
  action === ActionConstants.UnblockedByIdAndName;

const hasChangedStatus = (item) => {
  const { prevStatusId, newStatusId } = item;
  return newStatusId && prevStatusId !== newStatusId;
}

const getActivityDescription = (item) => {
  const { action, changedByType, newStatus } = item;

  if (isActionBlockByIdOrNameOrBoth(action)) {
    return <ActivityLogInfo message="unblocked the merchant by" description={getActionDescription(action)} />;
  }

  if (MerchantConstants.actions.comment === action) {
    return <ActivityLogInfo message="added a" description="comment" />;
  }

  if(changedByType === 'rule' && action === 'failsafe-rule'){
    return <ActivityLogInfo message=" rule triggered on this merchant" />;
  }

  if(action === 'rule' && changedByType === 'rule') {
    const actionMessage = getActivityNewStatusName(newStatus);
    return <ActivityLogInfo message="marked this merchant as" description={actionMessage} />;
  }

  if (
    hasChangedStatus(item) ||
    changedByType === 'rule' ||
    action === 'rule' ||
    newStatus?.apiName === StatusConstants.blockedApiName
  ) {
    const actionMessage = action ? getActionDescription(action) : getActivityNewStatusName(newStatus);
    return <ActivityLogInfo message="marked this merchant as" description={actionMessage} />;
  }

  if (!hasChangedStatus(item) && newStatus?.apiName === StatusConstants.pendingReviewApiName) {
    const description = action || getActivityNewStatusName(newStatus);
    return <ActivityLogInfo message="confirmed the merchant as" description={description} />;
  }

  if (action === ActionConstants.NotThreat) {
    return <ActivityLogInfo message="marked this merchant as" description={action} />;
  }

  return <>{''}</>;
};

const ActivityAlert = ({ item }) => {
  if (!item.changedBy || item.changedByType !== 'rule') return null;

  return (
    <div>
      <span className="text-sm font-medium">Alert: </span>
      <span className="text-sm text-gray-600">{item.changedBy}</span>
    </div>
  );
};

ActivityAlert.propTypes = {
  item: PropTypes.shape({
    changedByType: PropTypes.string,
    changedBy: PropTypes.string,
  }).isRequired,
};

const ActivityLogItem = ({ item }) => {
  const reason = getReason(item);

  return (
    <div className="relative flex mb-6 -ml-px last:border-l last:border-white">
      <>
        <div className="absolute -top-1 -left-4">
          <div className="w-8 h-8 p-1 text-blue-900 rounded-full bg-blue-50">{getActivityIcon(item)}</div>
        </div>
        <div className="flex-1 pb-6 ml-6 border-b border-b-gray-200">
          <div className="flex flex-col">
            <div className="flow-root">
              <div className="float-left text-sm text-gray-900">
                <span className="font-semibold">{getActivityChangedBy(item)}</span>
                &nbsp;
                {getActivityDescription(item)}
              </div>
              <span className="float-right mb-1 text-xs text-gray-500">{formatDate(item.createdAt)}</span>
            </div>
            <ActivityAlert item={item} />
            {reason && (
              <div>
                <span className="text-sm font-medium">Reason: </span>
                <span className="text-sm text-gray-600">{reason}</span>
              </div>
            )}
            {item.note && (
              <div>
                <span className="text-sm font-medium">Comments: </span>
                <span className="text-sm text-gray-600">{item.note}</span>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

ActivityLogItem.propTypes = {
  item: PropTypes.shape({
    changedBy: PropTypes.string,
    reason: PropTypes.string,
    note: PropTypes.string,
    merchantId: PropTypes.string,
    merchantName: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default function ActivityLog({ merchantTenantId }) {
  const { data: items, isLoading } = useGetMerchantLog(merchantTenantId);

  return isLoading ? (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
      <Spinner />
    </div>
  ) : (
    <div className="ml-2 border-l border-gray-200">
      {items.map((item) => <ActivityLogItem key={`log-${item.id}`} item={item} />)}
    </div>
  );
}

ActivityLog.propTypes = {
  merchantTenantId: PropTypes.number.isRequired,
};
