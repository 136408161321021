import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

export default function ActiveAlerts({ title, quantity, alerts }) {
  return (
    <>
      <span className="text-xs text-gray-500">
        {title} ({quantity})
      </span>
      {alerts?.length === 0 && (
        <div>
          <span className="text-base text-gray-600 text-justify">none</span>
        </div>
      )}
      {!!alerts?.length && (
        <div className="max-h-28 overflow-auto border border-gray-200 rounded pb-2 px-1.5">
          {alerts.map((alert) => (
            <div
              key={`${alert.id} - ${alert.name}`}
              className="flex w-fit border border-gray-200 items-center rounded mt-2 bg-gray-50"
            >
              <span className="font-medium text-sm text-gray-700 pt-0.5 pl-1.5 pb-0.5 pr-1.5 flex-1">
                {alert.name}
              </span>
              <InformationCircleIcon
                className="h-3 w-3 text-gray-500 cursor-pointer mr-2"
                data-tip=""
                data-for={String(alert.id)}
              />
              {alert.description ? (
                <Tooltip position="right" id={String(alert.id)}>
                  {alert.name} : {alert.description}
                </Tooltip>
              ) : (
                <Tooltip position="right" id={String(alert.id)}>
                  {alert.merchantNumber} , {alert.name}
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

ActiveAlerts.propTypes = {
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      merchantNumber: PropTypes.string,
    })
  ),
};

ActiveAlerts.defaultProps = {
  alerts: [],
};
