import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { SpeakerphoneIcon } from '@heroicons/react/outline';

import { classNames } from '../../utils/ui';

export default function BulkActions(props) {
  const { isCheckboxChecked, children, className } = props;

  return (
    <Transition
      enter="transition ease-in-out duration-300 transform"
      enterFrom="translate-y-full opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in-out duration-300 transform"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="translate-y-full opacity-0"
      show={isCheckboxChecked}
      className={classNames(
        className,
        'fixed h-16 bottom-0 left-0 right-0 px-6 py-3.5 bg-blue-600 border-gray-200'
      )}
    >
      <div className="flex flex-wrap items-center justify-between" data-cy="bulk-actions-bar">
        <div className="flex items-center gap-5">
          <SpeakerphoneIcon className="w-6 h-6 text-white" aria-hidden="true" />
          <p className="font-semibold text-sm text-white truncate">Bulk action:</p>
          <div className="flex items-center flex-1 w-0 gap-3">{children}</div>
        </div>
      </div>
    </Transition>
  );
}

BulkActions.propTypes = {
  children: PropTypes.node.isRequired,
  isCheckboxChecked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

BulkActions.defaultProps = {
  className: '',
};
