import React from 'react';
import PropTypes from 'prop-types';

export default function SidebarLabel({
  title,
  label,
  value,
  titleClassName,
  labelClassName,
  valueClassName,
  element,
  elementClassName,
  testId
}) {
  return (
    <div>
      {title && <span className={titleClassName}>{title}</span>}
      {(label || value) && (
        <div className="max-w-full w-auto">
          {label && <span className={labelClassName}>{label}</span>}
          {value && <span className={valueClassName}>{value}</span>}
        </div>
      )}
      {element && <div data-cy={testId} className={elementClassName}>{element}</div>}
    </div>
  );
}

SidebarLabel.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  titleClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  testId: PropTypes.string,
  element: PropTypes.node,
  elementClassName: PropTypes.string,
};

SidebarLabel.defaultProps = {
  title: '',
  label: '',
  value: '',
  titleClassName: '',
  labelClassName: '',
  valueClassName: '',
  testId: '',
  element: null,
  elementClassName: '',
};
