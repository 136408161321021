import React from 'react';
import PropTypes from 'prop-types';
import InsightsCard from './InsightsCard';

export default function InsightItems({ items, onViewClicked }) {
  return (
    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      {items.map((item) => (
        <InsightsCard
          key={item.id}
          item={item}
          onViewClicked={onViewClicked}
        />
      ))}
    </dl>
  );
}

InsightItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onViewClicked: PropTypes.func,
};

InsightItems.defaultProps = {
  onViewClicked: null,
};
