import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlideOver from './SlideOver';
import { setShowSelectedMerchantTransactions } from '../../store/features/merchant/merchantSlice';
import MerchantTransactions from '../table/MerchantTransactions';
import StatusConstants from '../../config/StatusConstants';

export default function SlideOverSelectedMerchantTransactions() {
  const showSelectedMerchantTransactions = useSelector(
    (state) => state.merchant.showSelectedMerchantTransactions,
  );
  const selectedMerchant = useSelector((state) => state.merchant.selectedMerchant);
  const dispatch = useDispatch();
  return (
    <SlideOver
      isOpen={showSelectedMerchantTransactions}
      setOpen={(value) => dispatch(setShowSelectedMerchantTransactions(value))}
      title={StatusConstants.authTitle}
    >
      {selectedMerchant && <MerchantTransactions id={selectedMerchant.id} />}
    </SlideOver>
  );
}
