import React from 'react';
import PropTypes from 'prop-types';
import useGetCardsMetrics from '../../hooks/api/insights/useGetCardsMetrics';
import HomeStatusMetric from './HomeStatusMetric';
import useInterval from '../../hooks/useInterval';
import CardConstants from '../../config/CardConstants';

const INTERVAL_LIMIT = process.env.REACT_APP_HOME_METRICS_INTERVAL || 60000;

export default function CardHomeMetrics({ isFromRules }) {
  const { data: cardMetrics, refetch } = useGetCardsMetrics({
    cardAttackType: isFromRules
      ? CardConstants.cardAttackType.rule
      : CardConstants.cardAttackType.merchantAttack,
  });
  const cardThreatsFoundCount = cardMetrics
    ? cardMetrics.find((metric) => metric.id === 'compromisedCards').value
    : 0;

  useInterval(refetch, INTERVAL_LIMIT);

  if (!cardMetrics) {
    return null;
  }
  let foundText = 'Compromised cards';
  let notFoundText = 'No threats detected';
  if (isFromRules) {
    foundText += ' via rules';
    notFoundText += ' via rules';
  }
  return (
    <dl className="grid grid-cols-1 gap-5 my-5 sm:grid-cols-2 lg:grid-cols-3">
      <HomeStatusMetric
        className="col-span-3"
        count={cardThreatsFoundCount}
        foundText={foundText}
        notFoundText={notFoundText}
        tooltip="Arden has identified the below merchant(s) as a potential
        threat for fraud due to suspicious card activity.
        Review the details of the merchant(s) to determine what
        action should be taken (trust, approve, block, remove)."
      />
    </dl>
  );
}

CardHomeMetrics.propTypes = {
  isFromRules: PropTypes.bool,
};

CardHomeMetrics.defaultProps = {
  isFromRules: false,
};
