export default {
  fetchInsightsSummary: async (client) =>
    client.get('api/insights/summary').then((response) => response.data),
  fetchFraudReport: async (client) =>
    client.get('api/insights/fraud-report').then((response) => response.data),
  fetchCardReportChart: async (client, params) =>
    client
      .get('api/insights/card-report-chart', {
        params,
      })
      .then((response) => response.data),
  fetchFraudReportChart: async (client, params) =>
    client
      .get('api/insights/fraud-report-chart', {
        params,
      })
      .then((response) => response.data),
  fetchMerchantsMetrics: async (client) =>
    client.get('api/insights/home-metrics').then((response) => response.data),
  fetchCardMetrics: async (client, params) =>
    client
      .get('api/insights/card-metrics', {
        params,
      })
      .then((response) => response.data),
};
