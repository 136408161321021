import React, { useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import Tabs from './Tabs';
import { classNames } from '../utils/ui';
import TextButton from './buttons/TextButton';
import GroupViewContextProvider from '../hooks/groupViewContext/GroupViewContextProvider';

type TabsContainerProps = {
  tabs: {
    name: string;
    value: string;
    icon: React.ComponentClass;
  }[];
  tabsContent?: {
    [key: string]: () => React.ReactNode;
  };
  selectedTab: string;
  onSelectedTabChange?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onStateChange?: (state: unknown) => void;
  initialState?: object;
  className?: string;
  rightTabContent?: React.ReactNode;
  isCollapsible?: boolean;
  inPanel?: boolean;
  children?: React.ReactNode;
};

export default function TabsContainer({
  className,
  tabs,
  selectedTab,
  onSelectedTabChange,
  tabsContent,
  rightTabContent,
  isCollapsible,
  inPanel,
  onStateChange,
  initialState,
  children,
}: TabsContainerProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderCollapsible = () => {
    if (!isCollapsible) {
      return null;
    }

    return (
      <TextButton className="px-4" onClick={() => setIsCollapsed(!isCollapsed)}>
        <div className="flex">
          {isCollapsed ? 'Expand' : 'Collapse'}
          <ChevronUpIcon className={classNames('w-5 h-5', isCollapsed && 'rotate-180 transform')} />
        </div>
      </TextButton>
    );
  };

  return (
    <div className={classNames((isCollapsible || inPanel) && 'bg-white border border-gray-200 rounded')}>
      <GroupViewContextProvider onStateChange={onStateChange} initialState={initialState}>
        <Tabs
          buttonClassName={isCollapsible || inPanel ? 'px-6' : null}
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={(e) => onSelectedTabChange && onSelectedTabChange(e)}
          rightTabContent={
            <>
              {rightTabContent}
              {isCollapsible && renderCollapsible()}
            </>
          }
        />

        <div
          className={classNames(
            (!isCollapsible || !isCollapsed) && className,
            isCollapsible && !isCollapsed && 'p-6'
          )}
        >
          {selectedTab && (!isCollapsible || !isCollapsed) && children}
          {selectedTab &&
            tabsContent &&
            (!isCollapsible || !isCollapsed) &&
            tabsContent &&
            tabsContent[selectedTab]()}
        </div>
      </GroupViewContextProvider>
    </div>
  );
}
