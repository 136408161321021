import React, { useState } from 'react';
import PDSTasksDetail from './tasks/PDSTasksDetail';
import PDSTasksMain from './tasks/PDSTasksMain';

export default function PDSTasks(props) {
  const [taskSelected, setTaskSelected] = useState();

  return (
    <>
      {!taskSelected && (
        <PDSTasksMain
          {...props}
          onTaskSelected={(reason) => setTaskSelected(reason)}
        />
      )}
      {taskSelected && (
        <PDSTasksDetail
          {...props}
          task={taskSelected}
          onBack={() => setTaskSelected(null)}
        />
      )}
    </>
  );
}
