import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { subMinutes } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonGroup from '../../../components/ButtonGroup';
import Grid from '../../../components/Grid';
import Panel from '../../../components/Panel';
import Checkbox, { CHECKBOX_TYPES } from '../../../components/forms/Checkbox';
import Button from '../../../components/buttons/Button';
import RadioGroup from '../../../components/RadioGroup';
import FMDatePicker from '../../../components/forms/FMDatePicker';
import Subtitle from '../../../components/headings/Subtitle';
import DataTable from '../../../components/table/DataTable';
import PaginationConfig from '../../../config/PaginationConfig';
import RulesValidationColumns, { VALIDATION_REPORT_DOWNLOAD_ACTION } from './RulesValidationColumns';
import useGetUserValidationReports from '../../../hooks/admin/rules/useGetUserValidationReports';
import useGetRulesFilter from '../../../hooks/admin/rules/useGetRulesFilter';
import useCreateValidationReport from '../../../hooks/admin/rules/useCreateValidationReport';
import useGetUserValidationReportDownloadUrl from '../../../hooks/admin/rules/useGetUserValidationReportDownloadUrl';

const typeOptions = [
  {
    label: 'Merchant',
    value: 'MerchantTenant',
  },
  {
    label: 'Card',
    value: 'Card',
  },
];

const rangeOptions = [
  {
    label: (<span className="text-sm text-gray-900">Historical</span>),
    value: 'range',
  },
  {
    label: (<span className="text-sm text-gray-900">Simulated</span>),
    value: 'simulator',
  },
];

const schema = yup.object({
  ruleIds: yup.array().min(1, 'Please select at least 1 rule').required(),
  range: yup.object({
    startDate: yup.date().required(),
    endDate: yup.date().required(),
  }).required(),
}).required();

export default function RulesValidation({ tenantId }) {
  const [type, setTypeSelected] = useState(typeOptions[0].value);
  const [rangeSelected, setRangeSelected] = useState(rangeOptions[0].value);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [page, setPage] = useState(1);
  const { data } = useGetUserValidationReports({
    page, itemsPerPage,
  });
  const tableData = data ? data.rows : [];
  const count = data ? data.count : 0;
  const { data: rulesList } = useGetRulesFilter({
    relatedModel: type,
  });
  const createValidationReport = useCreateValidationReport();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ruleIds: [],
      range: { startDate: null, endDate: null },
    },
    resolver: yupResolver(schema),
  });

  const getUserValidationReportDownloadUrl = useGetUserValidationReportDownloadUrl();

  const onSubmit = (formData) => {
    const { ruleIds, range: { startDate, endDate } } = formData;
    createValidationReport.mutateAsync(
      {
        tenantId,
        ruleIds: ruleIds.join(','),
        startDate: subMinutes(startDate, startDate.getTimezoneOffset()),
        endDate: subMinutes(endDate, endDate.getTimezoneOffset()),
      },
    )
      .then(() => {
        reset({
          ruleIds: [],
          range: { startDate: null, endDate: null },
        });
        setValue('ruleIds', []);
      });
  };

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const onTableAction = (action, args) => {
    switch (action) {
      case VALIDATION_REPORT_DOWNLOAD_ACTION:
        getUserValidationReportDownloadUrl.mutateAsync(args)
          .then((result) => window.open(
            result.url,
            '_blank',
          ));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="flex justify-between mb-8">
        <div className="flex space-x-4">
          <ButtonGroup
            roundedCorners={false}
            itemClassName="text-sm"
            options={typeOptions}
            selected={type}
            onChange={setTypeSelected}
          />
        </div>
      </div>
      <div className="mb-5">
        {rulesList && (
          <Panel>
            <Grid>
              <div className="col-span-4">
                <div className="flex space-between mb-3.5">
                  <span className="font-medium text-right text-gray-900">
                    Rules to validate
                  </span>
                  <Controller
                    control={control}
                    name="ruleIds"
                    render={({ field }) => (
                      <Checkbox
                        isChecked={rulesList.length === field.value.length}
                        onChange={(checked) => field.onChange(checked
                          ? rulesList.map((rule) => rule.id)
                          : [])}
                        type={CHECKBOX_TYPES.circle}
                      >
                        <span className="text-sm text-blue-800">Select all</span>
                      </Checkbox>
                    )}
                  />
                </div>
                <div className="flex flex-col space-y-4">
                  {rulesList.map((rule) => (
                    <div key={rule.id} className="col-span-3">
                      <Controller
                        control={control}
                        name="ruleIds"
                        render={({ field }) => (
                          <Checkbox
                            className="text-sm font-medium text-gray-900"
                            isChecked={field.value.some((ruleId) => ruleId === rule.id)}
                            onChange={(checked) => field.onChange(
                              checked
                                ? [...field.value, rule.id]
                                : field.value.filter((ruleId) => ruleId !== rule.id),
                            )}
                          >
                            {rule.name}
                          </Checkbox>
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-8 pl-12 border-l border-color-gray-200">
                <div className="max-w-sm">
                  <span className="block font-medium text-gray-900 mb-3.5">
                    Data Type
                  </span>
                  <div className="flex mb-2">
                    <RadioGroup
                      options={rangeOptions}
                      selected={rangeSelected}
                      onChange={(value) => setRangeSelected(value)}
                    />
                  </div>
                  <span className="block mb-6 text-sm font-medium text-gray-900">
                    Time zone: UTC
                  </span>
                  <div>
                    <span className="inline-block mb-1 text-sm font-medium text-gray-900">From</span>
                    <Controller
                      control={control}
                      name="range"
                      render={({ field }) => (
                        <div className="flex space-x-6">
                          <FMDatePicker
                            widthClass="w-full"
                            selected={field.value.startDate}
                            onChange={(value) => field.onChange({
                              ...field.value,
                              startDate: value,
                            })}
                            selectsStart
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="H:mm aa"
                            endDate={getValues('range.endDate')}
                            placeholderText="- select time -"
                          />
                          <FMDatePicker
                            widthClass="w-full"
                            selected={field.value.startDate}
                            onChange={(value) => field.onChange({
                              ...field.value,
                              startDate: value,
                            })}
                            selectsStart
                            endDate={getValues('range.endDate')}
                            placeholderText="- select date -"
                          />
                        </div>
                      )}
                    />
                    {errors.range && (
                      <span className="text-sm text-red-500">
                        Please select a valid date range
                      </span>
                    )}
                  </div>
                  <div className="my-5 border-b border-color-gray-200" />
                  <div className="mb-5">
                    <span className="inline-block mb-1 text-sm font-medium text-gray-900">Until</span>
                    <Controller
                      control={control}
                      name="range"
                      render={({ field }) => (
                        <div className="flex space-x-6">
                          <FMDatePicker
                            widthClass="w-full"
                            selected={field.value.endDate}
                            onChange={(value) => field.onChange({
                              ...field.value,
                              endDate: value,
                            })}
                            showTimeSelectOnly
                            showTimeSelect
                            selectsEnd
                            dateFormat="H:mm aa"
                            startDate={getValues('range.startDate')}
                            minDate={getValues('range.startDate')}
                            placeholderText="- select time -"
                          />
                          <FMDatePicker
                            widthClass="w-full"
                            selected={field.value.endDate}
                            onChange={(value) => field.onChange({
                              ...field.value,
                              endDate: value,
                            })}
                            selectsEnd
                            startDate={getValues('range.startDate')}
                            minDate={getValues('range.startDate')}
                            placeholderText="- select date -"
                          />
                        </div>
                      )}
                    />
                  </div>
                  <Button onClick={handleSubmit(onSubmit)} className="block w-full">
                    Generate Report
                  </Button>
                </div>
              </div>
              <div className="col-span-12">
                {errors.ruleIds && (
                  <span className="text-sm text-red-500">
                    {errors.ruleIds.message}
                  </span>
                )}
              </div>
            </Grid>
          </Panel>
        )}
      </div>
      <Subtitle className="mb-5">Generated Validation reports</Subtitle>
      <DataTable
        data={tableData}
        columns={RulesValidationColumns}
        onAction={onTableAction}
        paginationProps={{
          itemsPerPage,
          totalItems: count,
          currentPage: page,
          onPageClick,
          onItemsPerPageChange: (e) => { setItemsPerPage(e); },
        }}
      />
    </div>
  );
}

RulesValidation.propTypes = {
  tenantId: PropTypes.number,
};

RulesValidation.defaultProps = {
  tenantId: null,
};
