export default {
  UnblockedByName: 'UnblockedByName',
  UnblockedById: 'UnblockedById',
  UnblockedByIdAndName: 'UnblockedByIdAndName',
  BlockedByName: 'BlockedByName',
  BlockedById: 'BlockedById',
  BlockedByIdAndName: 'BlockedByIdAndName',
  RoleUpdate: 'Role Update',
  AssignUser: 'Assign User',
  Reviewed: 'reviewed-merchant',
  Trusted: 'Trusted',
  Approved: 'Approved',
  NotThreat: 'Not a threat'
}
