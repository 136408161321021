import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetResponseCodes(params = {}) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['responseCodes', ...Object.values(params)],
    () => api.transactions.fetchResponseCodes(apiClient, params),
  );
}
