/* eslint react/prop-types: 0 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from '@heroicons/react/outline';
import { TABLE_CELL_CLASS } from '../Table';
import LinkTextButton from '../../buttons/LinkTextButton';
import { classNames } from '../../../utils/ui';
import TextButton from '../../buttons/TextButton';
import useGetMccByMccId from '../../../hooks/api/mccs/useGetMccByMccId';
import Tooltip from '../../Tooltip';
import Spinner from '../../Spinner';

export default function TwoRowsColumn({ row, column, onAction }) {
  const {
    field,
    field2,
    linkGetter,
    valueGetter,
    valueGetter2,
    actionName,
    className,
    headerName,
    mccField,
  } = column;
  const value = valueGetter ? valueGetter(row[field]) : row[field];
  const ButtonType = column.useLink ? LinkTextButton : TextButton;
  const mcc = row[mccField];
  const id = row?.id;
  const [enableMccData, setEnableMccData] = useState(false);
  const { isLoading, data, isError } = useGetMccByMccId(mcc ?? value, {
    enabled: enableMccData,
    retry: false,
  });

  const handleMccClick = async () => {
    setEnableMccData(true);
  };

  return (
    <td className={TABLE_CELL_CLASS}>
      <div className={classNames('flex', !className && 'flex-col space-y-2', className)}>
        {actionName ? (
          <ButtonType
            className="text-left"
            onClick={() => onAction(column.actionName, row)}
            link={linkGetter(row)}
            row={row}
            testId={id}
          >
            {value}
          </ButtonType>
        ) : (
          <span className="text-sm text-gray-900">{value}</span>
        )}
        <span className="text-xs font-medium text-gray-500">
          {valueGetter2 ? valueGetter2(row[field2]) : row[field2]}
        </span>
        {mcc && headerName === 'Merchant' && (
          <>
            <div className="flex gap-1">
              <span className="text-xs font-medium text-gray-500 h-4">MCC:</span>
              <button
                id={`mcc-${id}`}
                type="button"
                onClick={() => handleMccClick()}
                className="font-medium text-xs focus:outline-none text-left text-blue-600 hover:text-blue-500 cursor-pointer flex"
              >
                <span>{mcc}</span> <SearchIcon className="w-2 h-2" />
              </button>
            </div>
            {isLoading && <Spinner size="8" />}

            {isError ? (
              <span
                id={`mcc-${id}`}
                className="text-gray-500 capitalize text-xs block cursor-pointer w-40 truncate"
              >
                MCC not found
              </span>
            ) : (
              <>
                <span
                  data-tip=""
                  data-for={`mcc-${id}`}
                  className="text-gray-500 text-xs capitalize block cursor-pointer w-40 truncate"
                >
                  {data?.description}
                </span>
                {data && (
                  <Tooltip position="bottom" id={`mcc-${id}`}>
                    {data.description}
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
      </div>
    </td>
  );
}

TwoRowsColumn.propTypes = {
  row: PropTypes.shape({}).isRequired,
  column: PropTypes.shape({
    className: PropTypes.string,
    field: PropTypes.string.isRequired,
    field2: PropTypes.string.isRequired,
    linkGetter: PropTypes.func,
    valueGetter: PropTypes.func,
    valueGetter2: PropTypes.func,
    render: PropTypes.func,
    actionName: PropTypes.string,
    useLink: PropTypes.bool,
    mccField: PropTypes.string,
    headerName: PropTypes.string,
  }).isRequired,
  onAction: PropTypes.func,
};

TwoRowsColumn.defaultProps = {
  onAction: null,
};
