import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/ui';

const enabledStyle = 'text-blue-600 hover:text-blue-500 cursor-pointer';
const disabledStyle = 'text-blue-300 cursor-default';
export default function TextButton({
  disabled, children, onClick, className
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={
        classNames(
          'font-medium text-sm focus:outline-none',
          className,
          disabled ? disabledStyle : enabledStyle,
        )
      }
    >
      {children}
    </button>
  );
}

TextButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

TextButton.defaultProps = {
  children: '',
  onClick: () => { },
  disabled: false,
  className: null,
};
