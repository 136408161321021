import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export const getCardMetrics = async (client, params) =>
  api.insights.fetchCardMetrics(client, params);

export default function useGetCardsMetrics(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(['insights', 'card-metrics', params], async () =>
    getCardMetrics(apiClient, params)
  );
}
