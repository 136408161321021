import { format } from 'date-fns';

export const formatDateRange = (startDate, startTime, endDate, endTime, dateFormat = 'M/dd/yyyy', timeFormat = 'h:mm a')  => {
  const startDateFormatted = format(startDate, dateFormat);
  const startTimeFormatted = format(new Date(startTime), timeFormat);
  const endDateFormatted = format(endDate, dateFormat);
  const endTimeFormatted = format(new Date(endTime), timeFormat);
  const formattedDateRange = `${startDateFormatted}, ${startTimeFormatted} - ${endDateFormatted}, ${endTimeFormatted}`;
  return formattedDateRange;
}

export const formatDefaultDate = (startDate, endDate) => {
  if(startDate && endDate)
    return formatDateRange(startDate, startDate, endDate, endDate);
  return ''
}
