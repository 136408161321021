import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getCardChart = async (client, params) => api.insights.fetchCardReportChart(client, params);

export default function useGetCardChart(period) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'insights/cardChart',
      period,
    ],
    async () => getCardChart(apiClient, { period }),
  );
}
