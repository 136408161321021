/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export default function Input({ type, className, ...attrs }) {
  return (
    <input
      className={`bg-white border-gray-300 rounded-sm text-sm ${className}`}
      type={type}
      {...attrs}
    />
  );
}

Input.propTypes = {
  type: PropTypes.oneOf(['text']),
  className: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  className: '',
};
