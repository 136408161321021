import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getCards = async (client, params) => api.cards.fetchCards(client, params);


export default function useGetCards(params) {
  const apiClient = useApiClient();
  const { isResolved, ...rest } = params;
  return useLoggedInQuery(
    [
      'cards',
      ...Object.values(params),
    ],
    async () => ( getCards(apiClient, rest)),
  );
}
