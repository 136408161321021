export default {
  createInvitation: async (client, data) => client.post('api/admin/user-invitation', data)
    .then((response) => response.data),
  getInvitations: async (client, params) => client.get('api/admin/user-invitation', {
    params,
  })
    .then((response) => response.data),
  getInvitationById: async (client, id) => client.get(`api/admin/user-invitation/${id}`)
    .then((response) => response.data),
  completeInvitation: async (client, id, data) => client.post(`api/admin/user-invitation/${id}/complete`, data)
    .then((response) => response.data),
  resendInvitation: async (client, id) => client.post(`api/admin/user-invitation/${id}/resend`)
    .then((response) => response.data),
  updateInvitation: async (client, id, data) => client.put(`api/admin/user-invitation/${id}`, data)
    .then((response) => response.data),
};
