import React, { useState } from 'react';
import {
  DotsCircleHorizontalIcon,
} from '@heroicons/react/outline';
import Tabs from '../components/Tabs';
import Title from '../components/headings/Title';
import Grid from '../components/Grid';
import CardChildren from './CardChildren';

export default function Cards() {
  const [currentView, setCurrentView] = useState('all');
  const onViewChange = (view) => {
    setCurrentView(view);
  };
  return (
    <Grid>
      <div className="col-span-12 pt-5">
        <div className="flex justify-between mb-6">
          <Title>
            Cards
          </Title>
        </div>
        <hr className="mb-4" />
        {/* <InsightItems fetcher={insightsFetcher} /> */}
        <Tabs
          className="mb-6"
          tabs={[
            
            {
              name: 'All',
              value: 'all',
              icon: DotsCircleHorizontalIcon,
            },
          ]}
          selectedTab={currentView}
          onClick={onViewChange}
        />
        <CardChildren currentView={currentView} />
      </div>
    </Grid>
  );
}