import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';
import MccDetails from '../../mccDetails';

export default function MccDescriptionColumn({ row }) {

  return (
    <td id={row.id} key={row.id} className={TABLE_CELL_CLASS}>
      <MccDetails value={row.mcc} id={row.id} />
    </td>
  );
}

MccDescriptionColumn.propTypes = {
  row: PropTypes.shape({
    id:  PropTypes.number,
    mcc:  PropTypes.number
  }).isRequired
};
