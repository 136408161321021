/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    showManualBlockedMerchants: false,
    manualBlockedMerchantsContext: {
      tenantId: null,
      timeSelected: null,
    },
  },
  reducers: {
    setShowManualBlockedMerchants: (state, action) => {
      state.showManualBlockedMerchants = action.payload.show;
      if (action.payload.context) {
        state.manualBlockedMerchantsContext = action.payload.context;
      }
    },
  },
});

export const {
  setShowManualBlockedMerchants,
} = globalSlice.actions;

export default globalSlice.reducer;
