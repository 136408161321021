import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import config from '../config';

export default function useIdleTimeout() {
  const { logout, isAuthenticated } = useAuth0();
  const onIdle = () => {
    logout({ returnTo: `${window.location.origin}/inactivity` });
  };
  const { start } = useIdleTimer({ startManually: true, onIdle, timeout: config.idleTimeout });
  const [started, setStarted] = useState(false);

  useEffect(() => {
    if (!started && isAuthenticated) {
      start();
      setStarted(true);
    }
  }, [started, isAuthenticated, start]);
}
