import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getCards = async (client, params) => api.cards.fetchCompromisedCards(client, params);

export default function useGetCompromisedCards(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'cards',
      'compromised',
      params,
    ],
    async () => getCards(apiClient, params),
  );
}
