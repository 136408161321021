import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetUsersFilter(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['filters', 'admin', 'users', params],
    async () => api.admin.users.fetchUserFilter(apiClient, params),
  );
}
