/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { XIcon, MinusIcon } from '@heroicons/react/outline';
import { Tab } from '@headlessui/react';
import { classNames } from '../utils/ui';
import IconButton from './buttons/IconButton';
import Button from './buttons/Button';
import Tooltip from './Tooltip';

export default function ClosableTabsContainer({
  tabs,
  onRemoveTab,
  onCloseAll,
  onMinimize,
  selectedIndex,
  onChangeSelectedIndex,
}) {
  const buttonSize = `px-0 px-1 py-1 text-xs rounded`;

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onChangeSelectedIndex}>
      <div className="flex flex-col h-full bg-white shadow-xl rounded-t-xl">
        <div className="relative w-full bg-gray-50 h-14 z-10">
          <Tab.List as={Fragment}>
            <div className="absolute top-0 left-0 right-20">
              <div className="flex overflow-auto flex-nowrap">
                {tabs.map((tab) => (
                  <Tab as={Fragment} key={`${tab.type}-${tab.id}`}>
                    {({ selected }) => (
                      <div className={classNames('w-52 relative flex-none', selected && 'bg-white')}>
                        <Button
                          buttonStyle="custom"
                          className="flex flex-col w-full space-y-1 border-r outline-none pr-7 border-r-gray-50 h-14 focus:outline-none ring-0"
                        >
                          <span
                            className={classNames(
                              'text-sm leading-5 font-semibold text-left truncate max-w-full',
                              selected ? 'text-black' : 'text-gray-500'
                            )}
                          >
                            {tab.title}
                          </span>
                          <span
                            className={classNames(
                              'text-xs leading-4 font-medium text-left truncate max-w-full',
                              selected ? 'text-gray-600' : 'text-gray-500'
                            )}
                          >
                            {tab.subtitle}
                          </span>
                        </Button>
                        <IconButton
                          buttonSize="sm"
                          buttonStyle="custom"
                          icon={<XIcon className="w-4" />}
                          className={classNames(
                            'absolute right-0 border-0 top-1.5',
                            selected ? 'text-gray-500' : 'text-gray-400'
                          )}
                          onClick={(event) => {
                            event.stopPropagation();
                            onRemoveTab(tab);
                          }}
                        />
                      </div>
                    )}
                  </Tab>
                ))}
              </div>
            </div>
          </Tab.List>
          <div className="absolute top-5 right-12 flex items-center justify-center w-2 h-2 mr-2">
            <IconButton
              buttonSize="custom"
              buttonStyle="light"
              rounded
              className={buttonSize}
              icon={<MinusIcon />}
              onClick={() => onMinimize()}
              data-tip
            />
          </div>
          <div className="absolute top-5 right-2 flex items-center justify-center w-2 h-2 mr-4">
            <IconButton
              buttonSize="custom"
              buttonStyle="light"
              className={buttonSize}
              rounded
              icon={<XIcon />}
              onClick={() => onCloseAll()}
              data-tip
              data-for="tooltip-close-all"
            />
            <Tooltip id="tooltip-close-all">Close all</Tooltip>
          </div>
        </div>
        <div className="relative flex-1 pb-2 overflow-y-scroll">
          <div className="absolute inset-0 px-12 sm:py-6">
            <Tab.Panels>
              {tabs.map((tab, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tab.Panel key={`tab-content-${i}`}>{tab.content}</Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </div>
      </div>
    </Tab.Group>
  );
}

ClosableTabsContainer.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
  onRemoveTab: PropTypes.func.isRequired,
  onCloseAll: PropTypes.func.isRequired,
  onMinimize: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onChangeSelectedIndex: PropTypes.func.isRequired,
};
