import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import UserKeys from './User.keys';

const getTenantUsers = async (
  client,
  params,
) => api.admin.users.fetchTenantUsers(client, params);

export default function useGetTenantUsers(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    UserKeys.list('tenant', params),
    async () => getTenantUsers(apiClient, params),
  );
}
