function checkPermission(permissions, apiName) {
  return permissions && !!permissions.some(
    (permission) => permission.apiName === apiName,
  );
}

function getName(user) {
  if (!user || !user.firstName) return '';
  return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName;
}

module.exports = {
  checkPermission,
  getName,
}