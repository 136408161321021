import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/ui';

const enabledStyle = 'text-blue-600 hover:text-blue-500 cursor-pointer';
const disabledStyle = 'text-blue-300 cursor-default';
export default function LinkTextButton({
  disabled, children, onClick, className, link, testId
}) {

  const clickHandler = (e) =>{
    e.preventDefault()

    if(e.metaKey){
      window.open(link)
      return
    }
    onClick();
  }

  return (
      <a
        data-cy={`link-button-${testId}`}
        href={link}
        disabled={disabled}
        onClick={clickHandler}
        className={classNames(
          'font-medium text-sm focus:outline-none',
          className,
          disabled ? disabledStyle : enabledStyle
        )}
      >
        {children}
      </a>
  );
}

LinkTextButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  testId: PropTypes.number
};

LinkTextButton.defaultProps = {
  children: '',
  onClick: () => { },
  testId: null,
  disabled: false,
  className: null,
};
