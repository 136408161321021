import React from 'react';
import CardConstants from 'src/config/CardConstants';
import { getActionName, validateSyncAction } from './helper';
import StatusConstants from 'src/config/StatusConstants';
import { dataCyValueFormatter } from 'src/utils/stringUtils';

interface ActivityDescriptionProps {
  action: string;
  lastFour: string;
  newStatusApiName: string;
  isExternalStatusSynced: boolean;
  changedByType: string;
}

export const ActivityDescription = ({
  action,
  lastFour,
  newStatusApiName,
  isExternalStatusSynced,
  changedByType,
}: ActivityDescriptionProps) => {
  const dataCyFormatted = dataCyValueFormatter(getActionName(action));


  switch (action) {
    case CardConstants.actions.comment:
      return (
        <>
          added a&nbsp;
          <span className="font-bold">comment</span>
        </>
      );
    case CardConstants.actions.viewFullCardNumber:
    case CardConstants.actions.hideFullCardNumber:
      return <>{getActionName(action)}</>;
    default:
      return (
        <>
          {action === 'Assign User' ? 'updated the' : 'marked the Card as'}
          &nbsp;
          <span className="font-bold" data-cy={`activity-log-description-${dataCyFormatted}`}>
            {getActionName(action)}
          </span>
          {changedByType !== 'rule' && validateSyncAction(action) &&
             (
              <>
                &nbsp;
                <span
                  data-cy={`activity-log-${dataCyFormatted}-${isExternalStatusSynced ? 'sync' : 'not-sync'}`}
                >
                  {isExternalStatusSynced ? '(Synchronized)' : '(Not synchronized)'}
                </span>
              </>
            )}
          <span>
            &nbsp;
            {action === 'Assign User' ? `of Card ${lastFour}` : ''}
          </span>
          {newStatusApiName === StatusConstants.reviewedApiName && '(Removed from list)'}
        </>
      );
  }
};
