import React from 'react';
import PropTypes from 'prop-types';
import { getColumnValue } from '../DataTableUtils';
import { TABLE_CELL_CLASS } from '../Table';

export default function BaseColumn({ row, column }) {
  return (
    <td className={`${TABLE_CELL_CLASS} ${column.className}`}>
      {getColumnValue(row, column)}
    </td>
  );
}

BaseColumn.propTypes = {
  row: PropTypes.shape({}).isRequired,
  column: PropTypes.shape({
    key: PropTypes.string,
    className: PropTypes.string,
    field: PropTypes.string.isRequired,
    valueGetter: PropTypes.func,
  }).isRequired,
};
