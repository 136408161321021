import StatusConstants from '../../../config/StatusConstants';

export const getReasons = (status, otherValue) => {
  switch (status) {
    case StatusConstants.trustedApiName:
      return [
        {
          value: 'Repeated false positives/requests to remove merchants block',
          label: 'Repeated false positives/requests to remove merchants block',
        },
        {
          value: 'Minimal Risk – EMV/face to face transactions',
          label: 'Minimal Risk – EMV/face to face transactions',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case StatusConstants.blockedApiName:
      return [
        {
          value: 'Known fraud merchants',
          label: 'Known fraud merchants',
        },
        {
          value: 'Merchants used for enumeration and fraudulent testing',
          label: 'Merchants used for enumeration and fraudulent testing',
        },
        {
          value: 'Merchants commonly disputed',
          label: 'Merchants commonly disputed',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case StatusConstants.reviewedApiName:
      return [
        {
          value: 'False positive – fraud not detected',
          label: 'False positive – fraud not detected',
        },
        {
          value: 'Fraud event has ceased',
          label: 'Fraud event has ceased',
        },
        {
          value: 'Previous merchants status no longer relevant',
          label: 'Previous merchants status no longer relevant',
        },
        {
          value: 'Previous merchants status incorrectly identified',
          label: 'Previous merchants status incorrectly identified',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case StatusConstants.approvedApiName:
      return [
        {
          value:
            'Merchants Name associated with prior reports of fraud testing but has multiple merchants identification numbers',
          label:
            'Merchants Name associated with prior reports of fraud testing but has multiple merchants identification numbers',
        },
        {
          value: 'Exception needed to allow short term purchase window',
          label: 'Exception needed to allow short term purchase window',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    default:
      return null;
  }
};

export const getContent = (status) => {
  switch (status) {
    case StatusConstants.trustedApiName:
      return 'All future transactions of these merchants will not be scanned by ARDEN. If you are sure. Please provide relevant details if you want to proceed.';
    case StatusConstants.approvedApiName:
      return 'All the transactions for next 24 hours for these merchants will not be scanned by ARDEN. Please provide relevant details if you want to proceed.';
    case StatusConstants.blockedApiName:
      return 'All future transactions for these merchants will be blocked by ARDEN. If you are sure. Please provide relevant details if you want to proceed.';
    case StatusConstants.reviewedApiName:
      return 'All future transactions for these selected merchants will be allowed by ARDEN for these selected merchants on the RAN list removed.';
    default:
      return null;
  }
};
;
