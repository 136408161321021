import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import UserKeys from './User.keys';

const getUser = async (
  client,
  userId,
) => api.admin.users.fetchUserDetails(client, userId);

export default function useGetUser(userId) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    UserKeys.detail(userId),
    async () => getUser(apiClient, userId),
  );
}
