import React from 'react';
import { addMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../../../components/table/Table';
import * as DateUtils from '../../../utils/date';
import Badge from '../../../components/Badge';
import StatusConstants from '../../../config/StatusConstants';
import TextButton from '../../../components/buttons/TextButton';
import DateColumn from '../../../components/table/columns/DateColumn';
import getUserNameColumn from '../../../components/table/columns/UserNameColumn';
import { getColumnValue } from '../../../components/table/DataTableUtils';

function RangeColumn({ row, column }) {
  const { id } = row;
  const {
    field, field2, valueGetter, valueGetter2,
  } = column;
  const value = valueGetter(row[field]);
  const value2 = valueGetter2(row[field2]);
  return (
    <td key={`cel-${id}-range-${field}`} className={TABLE_CELL_CLASS}>
      <div className="flex items-center">
        <span className="block mr-2 text-sm text-gray-900">
          {DateUtils.formatDayMonth(value)}
        </span>
        <span className="block text-xs font-medium text-gray-500">
          {DateUtils.formatTimeOnly(value)}
        </span>
      </div>
      <div className="flex items-center">
        <span className="block mr-2 text-sm text-gray-900">
          {DateUtils.formatDayMonth(value2)}
        </span>
        <span className="block text-xs font-medium text-gray-500">
          {DateUtils.formatTimeOnly(value2)}
        </span>
      </div>
    </td>
  );
}

RangeColumn.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
    field2: PropTypes.string.isRequired,
    valueGetter: PropTypes.func.isRequired,
    valueGetter2: PropTypes.func.isRequired,
  }).isRequired,
};

function getBadgeType(status, retryCount) {
  switch (status) {
    case StatusConstants.rulesValidation.complete:
      return 'success';
    case StatusConstants.rulesValidation.failed:
      return retryCount < 3 ? 'default' : 'danger';
    default:
      return 'default';
  }
}

function getBadgeText(status, retryCount) {
  switch (status) {
    case StatusConstants.rulesValidation.complete:
      return 'Completed';
    case StatusConstants.rulesValidation.failed:
      return retryCount < 3 ? 'In Progress' : 'failed';
    default:
      return 'In Progress';
  }
}

export const VALIDATION_REPORT_DOWNLOAD_ACTION = 'VALIDATION_REPORT_DOWNLOAD_ACTION';

function ValidationReportStatusColumn({ row, onAction, column }) {
  const { id, retryCount } = row;
  const value = getColumnValue(row, column);
  return (
    <td key={`cel-${id}-status`} className={`${TABLE_CELL_CLASS}`}>
      <div className="flex flex-col">
        <Badge type={getBadgeType(value, retryCount)}>
          {getBadgeText(value, retryCount)}
        </Badge>
        {value === StatusConstants.rulesValidation.complete && (
          <TextButton onClick={() => onAction(VALIDATION_REPORT_DOWNLOAD_ACTION, id)} className="mt-2">
            <span className="text-xs">Download</span>
          </TextButton>

        )}
      </div>
    </td>
  );
}

ValidationReportStatusColumn.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    retryCount: PropTypes.number,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
  column: PropTypes.shape({}).isRequired,
};

export default [
  DateColumn('createdAt', 'Date of report'),
  {
    field: 'rules',
    headerName: 'Rules validated',
    valueGetter: (valueField) => valueField.join(', '),
    headerClassName: 'max-w-2xs',
    className: 'max-w-2xs',
  },
  {
    field: 'startDate',
    field2: 'endDate',
    valueGetter: (field) => addMinutes(field, field.getTimezoneOffset()),
    valueGetter2: (field) => addMinutes(field, field.getTimezoneOffset()),
    headerName: 'Date range',
    render: RangeColumn,
  },
  getUserNameColumn('Generated By'),
  {
    field: 'status',
    headerName: 'Status',
    render: ValidationReportStatusColumn,
  },
];
