import React from 'react';
import PropTypes from 'prop-types';

import Textarea from '../forms/Textarea';
import Checkbox from '../forms/Checkbox';


export default function Comment({ showComment, comment, setComment, setShowComment }) {
  const handleCommentChange = (value) => {
    setShowComment(value);
    if (!value) {
      setComment('');
    }
  };

  return (
    <>
      <Checkbox className="mb-3" isChecked={showComment} onChange={handleCommentChange}>
        Add Comment
      </Checkbox>
      {showComment && <Textarea value={comment} onChange={setComment} />}
    </>
  );
}

Comment.propTypes = {
  showComment: PropTypes.bool.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  setShowComment: PropTypes.func.isRequired
};
