import accountUser from './account/accountUser';
import adminInvitations from './admin/adminInvitations';
import adminRoles from './admin/adminRoles';
import adminRules from './admin/adminRules';
import tenants from './admin/adminTenants';
import adminUsers from './admin/adminUsers';
import validationRules from './admin/validationRules';
import cards from './cards';
import externalStatuses from './externalStatuses';
import insights from './insights';
import merchants from './merchants';
import mockProcessor from './mockProcessor';
import pds from './pds';
import transactions from './transactions';
import users from './users';
import mccs from './mccs';

export default {
  insights,
  cards,
  externalStatuses,
  merchants,
  mccs,
  pds,
  transactions,
  admin: {
    rules: adminRules,
    users: adminUsers,
    tenants,
    validationRules,
    invitations: adminInvitations,
    roles: adminRoles,
  },
  mockProcessor,
  account: accountUser,
  users,
};
