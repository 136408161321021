import React from 'react';
import PropTypes from 'prop-types';
import TextButton from '../../buttons/TextButton';
import { TABLE_CELL_CLASS } from '../Table';
import { classNames } from '../../../utils/ui';

export default function MerchantCardDetailsColumn({ row, column, onAction }) {
  const { className } = column;
  return (
    <td className={`${TABLE_CELL_CLASS} ${className}`}>
      <div
        className={classNames(
          'flex',
          !className && 'flex-col space-y-2',
          className
        )}
      >
        <TextButton
          className='text-left'
          onClick={() => onAction && onAction(column.actionName, row)}
        >
          * {row.lastFour}
        </TextButton>
        <span className="text-xs text-gray-500">
          B: {row.bin} <br/>
          P: {row.proxy} <br/>
        </span>
      </div>
    </td>
  );
}

MerchantCardDetailsColumn.propTypes = {
  row: PropTypes.shape({
    bin: PropTypes.number,
    lastFour: PropTypes.string,
    proxy: PropTypes.string,
  }).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string,
    headerName: PropTypes.string,
    valueGetter: PropTypes.func,
    render: PropTypes.func,
    className: PropTypes.string,
    actionName: PropTypes.string,
    childClassName: PropTypes.string,
  }).isRequired,
  onAction: PropTypes.func,
};

MerchantCardDetailsColumn.defaultProps = {
  onAction: null,
};
