import React from 'react';
import DatePicker from 'react-datepicker';
import { CalendarIcon, ChevronDownIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import { classNames } from '../../utils/ui';

export default function FMDatePicker(props) {
  const { showTimeSelectOnly, widthClass } = props;
  return (
    <div className="relative">
      <DatePicker
        className={classNames(
          'border-gray-300 rounded-md',
          widthClass,
          !showTimeSelectOnly && 'pl-10',
        )}
        {...props}
      />
      {!showTimeSelectOnly && <CalendarIcon className="absolute pointer-events-none w-5 top-1/2 -mt-2.5 ml-3.5 left-0 text-gray-500" />}
      {showTimeSelectOnly && <ChevronDownIcon className="absolute pointer-events-none w-5 top-1/2 -mt-2.5 mr-3.5 right-0 text-gray-500" />}
    </div>
  );
}

FMDatePicker.propTypes = {
  showTimeSelectOnly: PropTypes.bool,
  widthClass: PropTypes.string,
};

FMDatePicker.defaultProps = {
  showTimeSelectOnly: false,
  widthClass: 'w-52',
};
