import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../utils/ui';

export default function Tabs({
  tabs, selectedTab, className, onClick, rightTabContent, buttonClassName,
}) {
  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={selectedTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200 flex justify-between items-center">
          <nav className="flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                data-cy={`tab-${tab.value}`}
                type="button"
                key={tab.name}
                href={tab.value}
                className={classNames(
                  selectedTab === tab.value
                    ? 'border-gray-900 text-gray-900'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
                  buttonClassName,
                )}
                aria-current={selectedTab === tab.value ? 'page' : undefined}
                onClick={() => onClick(tab.value)}
              >
                {tab.icon && (
                  <tab.icon
                    className={classNames(
                      selectedTab === tab.value ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-500',
                      '-ml-0.5 mr-2 h-5 w-5',
                    )}
                    aria-hidden="true"
                  />
                )}
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
          {rightTabContent && (
            <div className="flex space-x-3">
              {rightTabContent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Tabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
  })).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  rightTabContent: PropTypes.node,
  buttonClassName: PropTypes.string,
};

Tabs.defaultProps = {
  buttonClassName: null,
  className: '',
  onClick: (() => { }),
  rightTabContent: null,
};
