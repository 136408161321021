import TwoRowsColumn from './TwoRowsColumn';
import * as DateUtils from '../../../utils/date';

export default (field = 'updatedAt', headerName = 'Added On', headerClassName = 'text-left', sortable = true) => ({
  field,
  field2: field,
  headerName,
  headerClassName,
  render: TwoRowsColumn,
  valueGetter: (valueField) => DateUtils.formatDateOnly(valueField),
  valueGetter2: (valueField) => DateUtils.formatTimeOnly(valueField),
  sortable,
});
