import React from 'react';
import PropTypes from 'prop-types';
import CardActions from '../CardActions';
import { TABLE_CELL_CLASS } from '../Table';
import { CARD_UPDATE_STATUS_ACTION } from '../CardsTable';

export default function CardActionsColumn({ row, onAction }) {
  const { id } = row;
  return (
    <td key={`cel-${id}-actions`} className={TABLE_CELL_CLASS}>
      <div className="flex justify-center items-center align-middle">
        <CardActions card={row} onClick={(...e) => onAction && onAction(CARD_UPDATE_STATUS_ACTION, e)} />
      </div>
    </td>
  );
}

CardActionsColumn.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    statusApiName: PropTypes.string,
  }).isRequired,
  onAction: PropTypes.func,
};

CardActionsColumn.defaultProps = {
  onAction: null,
};
