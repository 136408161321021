// @TODO Remove this, don't depend on turning off rules if there is feasible alternative
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../../api';
import Spinner from '../../../components/Spinner';

const MockComponent = () => {
  // const [type, setTypeSelected] = useState(tabOptions[0].value);
  const [loadingLog] = useState(false);
  const mockProcessors = [
    { name: 'Fis South', value: 'fissouth' },
    { name: 'Galileo', value: 'galileo' },
  ];
  const [selectedMock, setSelectedMock] = useState('fissouth');
  const [mockRunning, setMockRunning] = useState(false);
  const [mockInformation, setMockInformation] = useState({});
  const [triggerMockData, setTriggerMockData] = useState(true);
  const [attackInformation, setAttackInformation] = useState(null);
  const [bodyParams, setBodyParams] = useState({
    count: 0, forceDecline: false, mcc: 1000, merchantName: 'company (new)',
  });
  // redux

  useEffect(() => {
    // @TODO migrate to react query
    api.mockProcessor.fetchApiStatus(selectedMock).then((response) => {
      setMockRunning(response.isRunning);
      setMockInformation(response);
    });
  }, [selectedMock, triggerMockData]);
  const checkMockStatus = () => (
    <div className="flex items-center mt-8">
      <div className="relative w-5 h-5 mr-4">
        {mockRunning ? <div className="pulsating-circle" />
          : <div className="pulsating-circle-warning" />}
      </div>
      <p className="inline-block text-sm text-gray-500">
        {mockProcessors.map((mock) => {
          if (mock.value === selectedMock) {
            return mock.name;
          }
        })}
        {' '}
        Status:&nbsp;
        {mockRunning ? (
          <span className="font-medium text-gray-700">Running</span>
        ) : (
          <span className="font-medium text-gray-700">Stopped</span>
        )}
      </p>
    </div>
  );
  const parameterContainer = () => (
    <>
      {mockRunning && (
      <div className="center">
        <div className="flex justify-start">
          <div className="w-32">
            <p className="mt-1">count: </p>
          </div>
          <input
            className=" w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => {
              const params = bodyParams;
              params.count = parseInt(e.target.value, 10);
              setBodyParams(params);
            }}
            defaultValue={bodyParams.count}
          />
        </div>
        <div className="flex justify-start">
          <div className="w-32">
            <p className="mt-1">merchantName: </p>
          </div>
          <input
            className=" w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => {
              const params = bodyParams;
              params.merchantName = e.target.value;
              setBodyParams(params);
            }}
            defaultValue={bodyParams.merchantName}
          />
        </div>
        <div className="flex justify-start">
          <div className="w-32">
            <p className="mt-1">mcc: </p>
          </div>
          <input
            className=" w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => {
              const params = bodyParams;
              params.mcc = parseInt(e.target.value, 10);
              setBodyParams(params);
            }}
            defaultValue={bodyParams.mcc}
          />
        </div>
        <div className="flex justify-start">
          <div className="w-32">
            <p className="mt-1">forceDecline: </p>
          </div>
          <select
            onChange={(e) => {
              const params = bodyParams;
              params.forceDecline = e.target.value;
              setBodyParams(params);
            }}
            className=" w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            defaultValue={bodyParams.forceDecline}
          >
            <option value={true}>true</option>
            <option value={false}>false</option>
          </select>
        </div>
        <br />
        <div className="flex">
          <div
            onClick={() => {
              // @TODO migrate to react query
              api.mockProcessor.fetchAttackMock(selectedMock, bodyParams).then((attackResponse) => {
                setAttackInformation(attackResponse);
              });
            }}
            className="inline-flex justify-center items-center border border-transparent font-normal shadow-sm text-white disabled:bg-gray-400 focus:outline-none bg-blue-900 hover:ring-transparent focus:ring-transparent px-2.5 py-1 false text-sm"
          >
            Send Attack
          </div>
        </div>
      </div>
      )}

    </>
  );
  const mockCommandButtons = () => (
    <div>
      <br />
      <div className="flex">
        {mockRunning ? (
          <div
            onClick={() => {
              // @TODO migrate to react query
              api.mockProcessor.fetchStopMock(selectedMock).then(() => {
                setTriggerMockData(!triggerMockData);
              });
            }}
            className="inline-flex justify-center items-center border border-transparent font-normal shadow-sm text-white disabled:bg-gray-400 focus:outline-none bg-blue-900 hover:ring-transparent focus:ring-transparent px-2.5 py-1 false text-sm"
          >
            Stop processor
          </div>
        ) : (
          <div
            onClick={() => {
              // @TODO migrate to react query
              api.mockProcessor.fetchStartMock(selectedMock).then(() => {
                setTriggerMockData(!triggerMockData);
              });
            }}
            className="inline-flex justify-center items-center border border-transparent font-normal shadow-sm text-white disabled:bg-gray-400 focus:outline-none bg-blue-900 hover:ring-transparent focus:ring-transparent px-2.5 py-1 false text-sm"
          >
            Start processor
          </div>
        )}
      </div>
      <br />
      <div
        onClick={() => {
          setTriggerMockData(!triggerMockData);
        }}
        className="inline-flex justify-center items-center border border-transparent font-normal shadow-sm text-white disabled:bg-gray-400 focus:outline-none bg-blue-900 hover:ring-transparent focus:ring-transparent px-2.5 py-1 false text-sm"
      >
        Update status
      </div>
    </div>
  );
  const mockInformationContainer = () => (
    <div>
      <br />
      <div>
        {mockInformation.started && (
        <p>
          Started:
          {' '}
          <b>{mockInformation.started}</b>
        </p>
        )}
      </div>
      <div>
        {mockInformation.duration && (
        <p>
          Duration:
          {' '}
          <b>{mockInformation.duration}</b>
        </p>
        )}
      </div>
      <div>
        {mockInformation.count !== null && (
        <p>
          Count:
          {' '}
          <b>{mockInformation.count}</b>
        </p>
        )}
      </div>
    </div>
  );

  const getDefaultMockProcessorObject = () => (
    <>
      <div className="flex">
        {mockProcessors.map((mock) => (
          <div
            key={`mock-item-${mock.value}`}
            onClick={() => {
              setSelectedMock(mock.value);
            }}
            className={
              selectedMock === mock.value
                ? 'inline-flex justify-center items-center border border-transparent font-normal shadow-sm text-white disabled:bg-gray-400 focus:outline-none bg-blue-900 hover:ring-transparent focus:ring-transparent px-2.5 py-1 false text-sm'
                : 'inline-flex justify-center items-center border border-transparent font-normal shadow-sm disabled:bg-gray-400 focus:outline-none bg-white hover:bg-gray-50 focus:ring-transparent text-gray-700 border-gray-300 px-2.5 py-1 false text-sm'
            }
          >
            <p>{mock.name}</p>
          </div>
        ))}
      </div>
      <div className="flex justify-start">
        <div>
          {mockCommandButtons()}
          <div>

            {mockRunning && (
            <>
              <br />
              <span className="font-bold text-medium">Attack options</span>
              {attackInformation ? (
                <>
                  <br />
                  <b>{attackInformation.description}</b>
                  <br />
                  <div
                    onClick={() => {
                      setAttackInformation(null);
                    }}
                    className="inline-flex justify-center items-center border border-transparent font-normal shadow-sm text-white disabled:bg-gray-400 focus:outline-none bg-blue-900 hover:ring-transparent focus:ring-transparent px-2.5 py-1 false text-sm"
                  >
                    Send another attack
                  </div>
                  {' '}
                </>
              ) : parameterContainer()}
            </>
            )}

          </div>
          {checkMockStatus()}
          {mockInformationContainer()}

        </div>
      </div>

    </>
  );
  const getDefaultRoleComponent = () => (
    <div className="px-3 pt-8 pb-8 pl-4 border border-gray-300 rounded">
      <span className="pl-4 font-bold text-medium">Mock commands</span>
      <div className="m-4">{getDefaultMockProcessorObject()}</div>
    </div>
  );

  return (
    <div>
      {loadingLog && (
        <div className="top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
          <Spinner />
        </div>
      )}
      {!loadingLog && getDefaultRoleComponent()}
    </div>
  );
};
MockComponent.propTypes = {
  tenantId: PropTypes.number,
};

MockComponent.defaultProps = {
  tenantId: null,
};

export default MockComponent;
