import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router';
import Input from '../../components/forms/Input';
import Label from '../../components/forms/Label';
import Grid from '../../components/Grid';
import Title from '../../components/headings/Title';
import Panel from '../../components/Panel';
import Spinner from '../../components/Spinner';
import useGetInvitation from '../../hooks/admin/invitations/useGetInvitation';
import Button from '../../components/buttons/Button';
import useCompleteInvitation from '../../hooks/admin/invitations/useCompleteInvitation';

const schema = yup.object({
  firstName: yup.string().required('First name is a required field'),
  lastName: yup.string().required('Last name is a required field'),
});

export default function CreateAccount() {
  const { id } = useParams();
  const getInvitation = useGetInvitation(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
    resolver: yupResolver(schema),
  });
  const completeInvitation = useCompleteInvitation(id);
  const { loginWithRedirect } = useAuth0();

  const onSubmit = (data) => {
    setIsSubmitting(true);
    completeInvitation.mutateAsync(
      data,
    )
      .then(() => {
        setShowSuccess(true);
      })
      .finally(() => setIsSubmitting(false));
  };
  return (
    <Grid>
      {getInvitation.isLoading && (
        <div className="col-span-12 pt-5">
          <Spinner />
        </div>
      )}
      {!getInvitation.isLoading && getInvitation.data && !showSuccess && (
        <>
          <div className="col-span-12 pt-5">
            <div className="flex justify-center my-6">
              <Title>
                <div>
                  Create account
                </div>
              </Title>
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex justify-center">
              <div className="max-w-full w-96">
                <Panel>
                  <p className="mb-4 text-sm text-gray-500">
                    You&apos;ve been invited to join the Fraud
                    Prevention Squad powered by Arden!
                  </p>
                  <Label className="mb-1 text-sm text-gray-700">
                    Email
                  </Label>
                  <div className="flex mb-1 space-x-2">
                    <InformationCircleIcon className="w-5 text-blue-600" />
                    <p className="text-sm text-gray-500">This email will be your username</p>
                  </div>
                  <p className="mb-6 text-sm font-semibold">{getInvitation.data.email}</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col">
                      <div className="flex flex-col mb-6">
                        <Label className="text-sm font-medium text-gray-700">First name</Label>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field }) => (
                            <Input
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        {errors.firstName && (
                          <span className="text-sm text-red-500">
                            {errors.firstName.message}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col mb-6">
                        <Label className="text-sm font-medium text-gray-700">Last name</Label>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field }) => (
                            <Input
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        {errors.lastName && (
                          <span className="text-sm text-red-500">
                            {errors.lastName.message}
                          </span>
                        )}
                      </div>
                      <Button disabled={isSubmitting} type="submit" rounded>Finish</Button>
                    </div>
                  </form>
                </Panel>
              </div>
            </div>
          </div>
        </>
      )}
      {showSuccess && (
        <div className="col-span-12 mt-32">
          <div className="flex justify-center">
            <div className="max-w-full w-96">
              <Panel>
                <div className="flex flex-col mb-4">
                  <CheckCircleIcon className="self-center w-6 mb-4 text-green-600" />
                  <h1 className="mb-2 text-2xl font-bold text-center text-gray-900">Account created!</h1>
                  <p className="mb-4 text-sm text-center text-gray-500">
                    You&apos;re all set.You can use your email and password to login to Arden.
                  </p>
                  <Button rounded onClick={() => loginWithRedirect()}>Log in</Button>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      )}
      {getInvitation.isError && (
        <div className="col-span-12 pt-5">
          <p className="text-sm">
            Invitations may have already expired or it was already used.&nbsp;
            Please contact the administrator of your organization
          </p>
        </div>
      )}
    </Grid>
  );
}
