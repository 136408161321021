import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/ui';

function getButtonStyles(buttonStyle) {
  const styles = 'items-center border font-normal shadow-sm disabled:bg-gray-400 focus:outline-none disabled:pointer-events-none';
  const ring = 'focus:ring-offset-2 focus:ring-2';
  switch (buttonStyle) {
    case 'success':
      return `${styles} ${ring} bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white`;
    case 'danger':
      return `${styles} ${ring} bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white`;
    case 'light':
      return `${styles} ${ring} bg-white hover:bg-gray-50 focus:ring-gray-200 text-black border-gray-300 disabled:text-gray-500 disabled:bg-gray-50`;
    case 'primary':
      return `${styles}${ring}  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white`;
    case 'dark':
      return `${styles} ${ring} bg-blue-900 hover:bg-blue-700 focus:ring-blue-500 text-white`;
    case 'group-dark':
      return `${styles} bg-blue-900 hover:ring-transparent focus:ring-transparent text-white`;
    case 'group-light':
      return `${styles} bg-white hover:bg-gray-50 focus:ring-transparent text-gray-700 border-gray-300`;
    case 'secondary':
      return `border bg-white hover:bg-gray-50 focus:ring-transparent text-gray-700 border-gray-300 rounded-sm`;
    default:
      return '';
  }
}

function getButtonSizeStyles(buttonSize, rounded) {
  const styles = 'px-4';
  switch (buttonSize) {
    case 'sm':
      return `px-2.5 py-1 text-xs ${rounded && 'rounded'}`;
    case 'lg':
      return `${styles} py-3 text-lg ${rounded && 'rounded-md'}`;
    case 'md':
      return `${styles} py-2 text-sm ${rounded && 'rounded-md'}`;
    default:
      return '';
  }
}

const Button = forwardRef(
  function Button(
    { children, onClick, buttonSize, buttonStyle, className, disabled, rounded, type, fullWidth, ...rest },
    ref
  ) {
    const styles = getButtonStyles(buttonStyle);
    const sizeStyles = getButtonSizeStyles(buttonSize, rounded);

    return (
      <button
        ref={ref}
        disabled={disabled}
        className={classNames(styles, sizeStyles, className, fullWidth && 'w-full block')}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  className: PropTypes.string,
  buttonSize: PropTypes.oneOf(['sm', 'md', 'lg', 'custom']),
  buttonStyle: PropTypes.oneOf([
    'primary',
    'success',
    'danger',
    'light',
    'dark',
    'group-dark',
    'group-light',
    'secondary',
    'custom',
  ]),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
};

Button.defaultProps = {
  className: '',
  buttonSize: 'md',
  buttonStyle: 'primary',
  children: '',
  onClick: () => { },
  disabled: false,
  rounded: false,
  type: 'button',
  fullWidth: false
};

export default Button;
