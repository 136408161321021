import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import PDSKeys from './PDS.keys';

const getPDSTasks = async (client, params) => api.pds.fetchPDSTasks(client, params);

export default function useGetPDSTasks(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    PDSKeys.all(params),
    async () => getPDSTasks(apiClient, params),
  );
}
