import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_CELL_CLASS } from '../Table';
import ButtonGroup from '../../ButtonGroup';
import { getColumnValue } from '../DataTableUtils';

export default function ButtonGroupColumn({
  row, onAction, column,
}) {
  const { id } = row;
  return (
    <td key={`cel-${id}-button-group`} className={TABLE_CELL_CLASS}>
      <ButtonGroup
        size="sm"
        options={column.options}
        selected={getColumnValue(row, column)}
        onChange={(value) => onAction && onAction(column.actionName, row, value)}
      />
    </td>
  );
}

ButtonGroupColumn.propTypes = {
  row: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
    },
  ).isRequired,
  column: PropTypes.shape({
    actionName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onAction: PropTypes.func,
};

ButtonGroupColumn.defaultProps = {
  onAction: null,
};
