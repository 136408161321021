import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import { classNames } from '../../utils/ui';

export default function IconButton({ icon, children, testId, ...rest }) {
  return (
    <Button {...rest}>
      <div className="flex flex-row items-center">
        <span data-cy={testId} className={classNames('w-5', children && 'mr-2')}>{icon}</span>
        {children}
      </div>
    </Button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  testId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

IconButton.defaultProps = {
  children: null,
  testId: ''
};
