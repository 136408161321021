import { SearchIcon } from '@heroicons/react/outline';
import React from 'react';
import PropTypes from 'prop-types';
import Input from '../forms/Input';

export default function SearchFilter({ value, onChange, className }) {
  return (
    <div className={`relative ${className}`}>
      <Input
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder="search"
        className="pl-10 w-72"
        data-cy="search-input"
      />
      <SearchIcon className="absolute top-2.5 left-3.5 w-5 h-5 text-gray-400" />
    </div>
  );
}

SearchFilter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SearchFilter.defaultProps = {
  className: '',
  value: '',
  onChange: () => { },
};
