import React, { useEffect, useMemo, useState } from 'react';
import { ClipboardListIcon, CreditCardIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import { EyeIcon } from '@heroicons/react/solid';
import { addHours } from 'date-fns';
import Grid from '../../components/Grid';
import Title from '../../components/headings/Title';
import HomeStatus from '../home/HomeStatus';
import useGetMccs from '../../hooks/api/merchants/useGetMccs';
import TabsContainer from '../../components/TabsContainer';
import PDSMerchants from './PDSMerchants';
import ReasonDescription from './ReasonDescription';
import { FMMultipleComboboxWithExclude } from '../../components/forms/FMMultipleCombobox';
import FiltersContainer from '../../components/filters/FiltersContainer';
import FMCombobox from '../../components/forms/FMCombobox';
import TextButton from '../../components/buttons/TextButton';
import RefreshTable from '../../components/RefreshTable';
import filterUtils from '../../utils/filterUtils';
import useGetMe from '../../hooks/api/users/useGetMe';
import useConstant from '../../hooks/useConstant';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import PDSTasks from './PDSTasks';
import CustomTimePeriodWrapper from '../../components/forms/CustomTimePeriodWrapper';
import timeLimitUtils from '../../utils/timeLimitUtils';
import { handleCurrentDay } from '../../utils/date';

function useTimeOptions() {
  const { data: me } = useGetMe();
  const timeOptions = useConstant(() => {
    const timeOptionsToReturn = [
      {
        label: 'Custom',
        value: 'custom',
      },
      {
        label: '30min',
        value: '30min',
      },
      {
        label: '1h',
        value: '1h',
      },
      {
        label: '6h',
        value: '6h',
      },
      {
        label: '12h',
        value: '12h',
      },
      {
        label: '24h',
        value: '24h',
      },
      {
        label: '48h',
        value: '48h',
      },
      {
        label: '72h',
        value: '72h',
      },
    ];

    return timeOptionsToReturn;
  });

  return useMemo(() => {
    if (me && me.featureFlags[FeatureFlagConstants.isPerformancePOC]) {
      return timeOptions.filter(
        (tab) => tab.value !== '12h' && tab.value !== '24h' && tab.value !== '48h' && tab.value !== '72h'
      );
    }

    return timeOptions;
  }, [me]);
}

const authsOptions = [
  {
    label: 'Merchant auths: 50+',
    value: '50+',
  },
  {
    label: 'Merchant auths: 19-49',
    value: '19-49',
  },
  {
    label: 'Merchant auths: <19',
    value: '<19',
  },
];

function useLivePDSTabs() {
  const tabs = useConstant(() => [
    {
      name: 'Merchants',
      value: 'merchants',
      icon: ShoppingBagIcon,
    },
    {
      name: 'Reason description',
      value: 'reason-description',
      icon: ClipboardListIcon,
    },
    {
      name: 'Payment method',
      value: 'payment-method',
      icon: CreditCardIcon,
    },
    {
      name: 'Tasks',
      value: 'tasks',
      icon: EyeIcon,
    },
  ]);
  const { data: me } = useGetMe();

  return useMemo(() => {
    if (!me) {
      return [];
    }

    let tabsReturn = tabs;

    if (me && me.featureFlags[FeatureFlagConstants.hidePaymentMethod]) {
      tabsReturn = tabs.filter((tab) => tab.value !== 'payment-method');
    }
    if (me && me.featureFlags[FeatureFlagConstants.hideTask]) {
      tabsReturn = tabs.filter((tab) => tab.value !== 'tasks');
    }

    return tabsReturn;
  }, [me]);
}

export default function LivePDS() {
  const tabs = useLivePDSTabs();
  const timeOptions = useTimeOptions();
  const [timeSelected, setTimeSelected] = useState(timeOptions[1]);
  const [selectedTab, setSelectedTab] = useState('merchants');
  const [selectedMcc, setSelectedMcc] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [authCount, setAuthCount] = useState(null);
  const [excludeMcc, setExcludeMcc] = useState(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const { isSuccess, data: mccs } = useGetMccs();
  const [mccOptions, setMccOptions] = useState([]);
  const [refreshTick, setRefreshTick] = useState(0);
  const { data: me } = useGetMe();
  const isIncomm = me?.tenantName === 'InComm';

  const [maxEndDateLimit, setMaxEndDateLimit] = useState(new Date());
  const [minEndTimeLimit, setMinEndTimeLimit] = useState(new Date());
  const [maxEndTimeLimit, setMaxEndTimeLimit] = useState(new Date());
  const [dateFilter, setDateFilter] = useState(null);

  const handleDateLimit = (date) => {
    if(isIncomm){
      setMaxEndDateLimit(date);
    }
    else{
      handleCurrentDay(date, setMaxEndDateLimit, timeLimitUtils.TimeLimit.MANUAL_SCAN_FIS_DATE_LIMIT)
    }
  }

  const handleTimeLimit = (date) => {
    if(isIncomm){
      setMinEndTimeLimit(date);
      setMaxEndTimeLimit(addHours(date, timeLimitUtils.TimeLimit.MANUAL_SCAN_INCOMM_HOUR_LIMIT));
    }else{
      setMinEndTimeLimit(null);
      setMaxEndTimeLimit(null);
    }
  }

  useEffect(() => {
    const newFilters = [];
    if (timeSelected) {
      newFilters.push({
        name: 'Time period:',
        value: timeSelected.value,
      });
    }
    if (selectedMcc && selectedMcc.length) {
      newFilters.push({
        name: 'MCC:',
        value: selectedMcc.join(', '),
      });
    }
    if (selectedStatus) {
      newFilters.push({
        name: 'Merchant status:',
        value: selectedStatus.value,
      });
    }
    if (authCount) {
      newFilters.push({
        name: 'Auth count:',
        value: authCount.value,
      });
    }

    setFilters(newFilters);
  }, [timeSelected, selectedMcc, selectedStatus, authCount, setFilters]);

  useEffect(() => {
    const newMccs = (mccs || []).map(
      (mcc) => ({ value: mcc.mcc.toString(), label: mcc.mcc.toString() }),
    );
    setMccOptions(newMccs);
  }, [setMccOptions, mccs]);

  const handleSelectedMccWithExcludeChange = ({ mccs: newMccs, exclude }) => {
    setSelectedMcc(newMccs);
    setExcludeMcc(exclude);
  };


  useEffect(() => {
    if (!dateFilter) setTimeSelected(timeOptions[1]);
    if (!dateFilter && !timeOptions?.value) setTimeSelected(timeOptions[1]);
  }, [dateFilter, timeOptions]);

  const clearFilters = () => {
    setSearch('');
    setTimeSelected(timeOptions[1]);
    setSelectedMcc([]);
    setAuthCount(null);
    setSelectedStatus(null);
    setDateFilter(null);
  };

  const renderFilterTooltip = () => {
    if (filters.length === 0) {
      return null;
    }
    return <FiltersContainer.Tooltip filters={filters} />;
  };

  const handleResetDateFilter = () => {
    setTimeSelected(timeOptions[1])
  }

  const renderGlobalFilters = () => (
    <div className="flex items-start justify-between px-5">
      <FiltersContainer search={search} onSearchChange={setSearch} tooltip={renderFilterTooltip()}>
        <CustomTimePeriodWrapper
            isClearable={false}
            timeFilter={timeSelected}
            data={timeOptions}
            handleComboBoxChange={setTimeSelected}
            minEndTimeLimit={minEndTimeLimit}
            maxEndTimeLimit={maxEndTimeLimit}
            maxEndDateLimit={maxEndDateLimit}
            handleDateLimit={handleDateLimit}
            handleTimeLimit={handleTimeLimit}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleResetDateFilter={handleResetDateFilter}
        />

        {isSuccess && (
          <>
            <FMMultipleComboboxWithExclude
              excludeMcc={excludeMcc}
              data={mccOptions}
              selected={selectedMcc}
              placeholder="MCC"
              onChange={handleSelectedMccWithExcludeChange}
            />
          </>
        )}

        <FMCombobox
          placeholder="Merchant Status"
          data={filterUtils.StatusOptions}
          selected={selectedStatus}
          onChange={setSelectedStatus}
        />
        <FMCombobox
          placeholder="Auth count"
          data={authsOptions}
          selected={authCount}
          onChange={setAuthCount}
        />
        {(search.length > 0 || filters.length > 0) && (
          <TextButton onClick={() => clearFilters()}>
            Clear all
          </TextButton>
        )}
      </FiltersContainer>
      <RefreshTable setRefreshTable={() => setRefreshTick(refreshTick + 1)} />
    </div>
  );

  const renderTasksFilters = () => (
    <div className="flex items-start justify-between px-5">
      <FiltersContainer search={search} onSearchChange={setSearch} tooltip={renderFilterTooltip()}>
        <CustomTimePeriodWrapper
            isClearable={false}
            timeFilter={timeSelected}
            data={timeOptions}
            handleComboBoxChange={setTimeSelected}
            minEndTimeLimit={minEndTimeLimit}
            maxEndTimeLimit={maxEndTimeLimit}
            maxEndDateLimit={maxEndDateLimit}
            handleDateLimit={handleDateLimit}
            handleTimeLimit={handleTimeLimit}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleResetDateFilter={handleResetDateFilter}
        />
        {(search.length > 0 || filters.length > 0) && (
          <TextButton onClick={() => clearFilters()}>
            Clear all
          </TextButton>
        )}
      </FiltersContainer>
      <RefreshTable setRefreshTable={() => setRefreshTick(refreshTick + 1)} />
    </div>
  );

  const renderMerchantsTab = () => (
    <>
      {renderGlobalFilters()}
      <PDSMerchants
        startDate={dateFilter ? dateFilter.startDate : null}
        endDate={dateFilter ? dateFilter.endDate : null}
        timeSelected={timeSelected ? timeSelected.value : null}
        mcc={selectedMcc}
        status={selectedStatus ? selectedStatus.value : null}
        excludeMcc={excludeMcc}
        search={search && search.length > 2 ? search : ''}
        authCount={authCount ? authCount.value : null}
        refreshTick={refreshTick}
      />
    </>
  );

  const renderReasonTab = () => (
    <>
      {renderGlobalFilters()}
      <ReasonDescription
        startDate={dateFilter ? dateFilter.startDate : null}
        endDate={dateFilter ? dateFilter.endDate : null}
        timeSelected={timeSelected ? timeSelected.value : null}
        mcc={selectedMcc}
        status={selectedStatus ? selectedStatus.value : null}
        excludeMcc={excludeMcc}
        search={search && search.length > 2 ? search : ''}
        authCount={authCount ? authCount.value : null}
        refreshTick={refreshTick}
      />
    </>
  );

  const renderTasksMethodTab = () => (
    <>
      {renderTasksFilters()}
      <PDSTasks
        startDate={dateFilter ? dateFilter.startDate : null}
        endDate={dateFilter ? dateFilter.endDate : null}
        timeSelected={timeSelected ? timeSelected.value : null}
        search={search && search.length > 2 ? search : ''}
        refreshTick={refreshTick}
      />
    </>
  );
  const renderPaymentMethodTab = () => <div>Payment Methods</div>;

  const tabsContent = {
    merchants: renderMerchantsTab,
    'reason-description': renderReasonTab,
    'payment-method': renderPaymentMethodTab,
    'tasks': renderTasksMethodTab,
  };

  return (
    <Grid>
      <div className="col-span-12 pt-5" data-cy="pds-screen">
        <div className="flex items-center justify-between mb-6">
          <Title>Manual scan</Title>
          <HomeStatus />
        </div>
      </div>
      <div className="col-span-12" data-cy="pds-screen">
        <TabsContainer
          tabs={tabs}
          tabsContent={tabsContent}
          selectedTab={selectedTab}
          onSelectedTabChange={setSelectedTab}
          inPanel
          className="pt-6 pb-2"
        />
      </div>
    </Grid>
  );
}
