import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import AdminTenantKeys from './AdminTenant.keys';

export default function useGetTenant(id) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    AdminTenantKeys.detail(id),
    async () => api.admin.tenants.fetchTenantDetails(apiClient, id),
  );
}
