export default {
  // merchant confirm modal
  trustConfirmText: 'Are you sure you want to trust (merchant_name)? This will exempt this merchant from auto-block.',
  approveConfirmText: 'This action will mark the merchant as approved for the next 24 hours. Do you want to proceed?',
  blockConfirmText: 'Are you sure you want to block (merchant_name)? This will automatically decline all future authorizations via this merchant.',
  removeTrustedText: 'Are you sure you want to remove (merchant_name) from your trust-list? This will subject this merchant to auto-block.',
  removeBlockedText: 'Are you sure you want to remove (merchant_name) from your block-list? This will allow future authorizations to be approved.',
  removeSuspiciousText: 'Are you sure you want to remove (merchant_name) from your suspicious list?',
  // merchant action tooltips
  trustTooltip: 'Trust this merchant, exempting it from auto  block in the future.',
  approveTooltip: 'Approve this merchant, exempting it from auto block in the next 24h.',
  blockToolTip: 'Block this merchant, stopping all future auths from being approved.',
  removeToolTip: 'Remove this merchant from the list.',
};
