import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { showToast, FAILED } from '../components/Toast/Toast';
import useLogSuccessfulLogin from '../hooks/api/users/useLogSuccessfulLogin';

export default function Login() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const logSuccessfulLogin = useLogSuccessfulLogin();
  const history = useHistory();

  const redirectToHome = () => {
    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      scope: 'openid profile email',
    }).then(async () =>
      logSuccessfulLogin
        .mutateAsync()
        .then(() => {
          history.push('/home');
        })
        .catch((error) => {
          if (error && error.response) {
            showToast(FAILED, error.response.data.message);
          } else {
            showToast(FAILED, 'Server error');
          }
          history.push('/network-error');
        })
    );
  };
  useEffect(() => {
    if (isAuthenticated) {
      redirectToHome();
    }
  }, [isAuthenticated]);

  return (
    <div>
      {isAuthenticated && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-100">
          <Spinner />
        </div>
      )}
    </div>
  );
}
