import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

function ConfirmModalBody({ children }) {
  return (
    <p className="text-sm text-gray-600">
      {children}
    </p>
  );
}

ConfirmModalBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ConfirmModal({
  onConfirm, onCancel, children, ...rest
}) {
  return (
    <Modal primaryText="Confirm" divider={false} onClose={onCancel} primaryAction={onConfirm} {...rest}>
      {children}
    </Modal>
  );
}

ConfirmModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmModal.Body = ConfirmModalBody;

export { ConfirmModal as default };
