import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function RedirectToLogin() {
  const { loading, loginWithRedirect, isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isAuthenticated) {
      history.push('/');
    } else {
      loginWithRedirect();
    }
  }, [loading, loginWithRedirect, isAuthenticated]);

  return (
    null
  );
}
