import { useCallback } from 'react';
import useGetActiveCardAttacksFilter from 'src/hooks/api/transactions/useGetActiveCardAttacksFilter';
import useGetPaymentMethods from 'src/hooks/api/transactions/useGetPaymentMethods';
import useGetReasonCodes from 'src/hooks/api/transactions/useGetReasonCodes';
import useGetResponseCodes from 'src/hooks/api/transactions/useGetResponseCodes';
import filterUtils from 'src/utils/filterUtils';
import { MERCHANT_OPEN_MERCHANT } from '../MerchantsTable';
import { TABLE_SORT_ACTION, TABLE_SORT_ASC, TABLE_SORT_DESC } from '../DataTable';

const TransactionDateIntervalInHours = 7 * 24;

export function useFiltersForCardTransactions(cardId: number) {
  const { data: responseCodes } = useGetResponseCodes({
    cardId,
    txnDateIntervalInHours: TransactionDateIntervalInHours,
  });
  const { data: reasonCodes } = useGetReasonCodes({
    cardId,
    txnDateIntervalInHours: TransactionDateIntervalInHours,
  });
  const { data: paymentMethods } = useGetPaymentMethods({
    cardId,
    txnDateIntervalInHours: TransactionDateIntervalInHours,
  });

  const { data: activeCardAttacks } = useGetActiveCardAttacksFilter(cardId);

  const authTypeFilterOptions = activeCardAttacks
    ? [
        ...filterUtils.CardAuthTypeOptions,
        ...activeCardAttacks.map((option) => ({
          ...option,
          label: `Rule: ${option.label}`,
        })),
      ]
    : filterUtils.CardAuthTypeOptions;

  return {
    responseCodes,
    reasonCodes,
    paymentMethods,
    authTypeFilterOptions,
  };
}

export function restoreState(value: unknown, setState: React.Dispatch<React.SetStateAction<unknown>>) {
  if (value) {
    setState(value);
  }
}

export function appendFilterifHasValue(value: unknown, valueToUse: unknown, filters: unknown[]) {
  if (value) {
    filters.push(valueToUse);
  }
}

type SortActionData = {
  sortField?: string;
  field?: string;
};

type OpenMerchantData = {
  merchantTenantId: number;
  merchantName: string;
  merchantNumber: string;
};

type TabData = {
  id: number;
  title: string;
  subtitle: string;
  type: string;
};

function getOpenMerchantData(data: OpenMerchantData): TabData {
  return {
    id: data.merchantTenantId,
    title: data.merchantName,
    subtitle: `ID: ${data.merchantNumber}`,
    type: 'merchant',
  } as TabData;
}

export function useCardTransactionsAction(
  addTab: (tabData: unknown) => void,
  sort: string,
  sortDir: string,
  setSort: React.Dispatch<React.SetStateAction<string>>,
  setSortDir: React.Dispatch<React.SetStateAction<string>>
) {
  const changeSort = useCallback(
    (field: string) => {
      if (field !== sort) {
        setSort(field);
        setSortDir(TABLE_SORT_DESC);
        return;
      }
      setSortDir(sortDir === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC);
    },
    [sort, sortDir, setSort, setSortDir]
  );

  const onAction = useCallback(
    (action: string, data: unknown) => {
      switch (action) {
        case TABLE_SORT_ACTION:
          changeSort((data as SortActionData).sortField ?? (data as SortActionData).field ?? '');
          break;
        case MERCHANT_OPEN_MERCHANT:
          addTab(getOpenMerchantData(data as OpenMerchantData));
          break;
        default:
          break;
      }
    },
    [changeSort, addTab]
  );

  return onAction;
}
