import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const CustomDatePickerWrapper = forwardRef(({ icon, value, onClick, size, placeholderText }, ref) => (
  <button
    type="button"
    className={`flex items-center p-2 gap-2 rounded-sm ${size === 'small' ? 'w-28' : 'w-32'} h-10 bg-gray-100 hover:bg-gray-200 text-gray-500`}
    onClick={onClick}
    ref={ref}
  >
    {icon}
    <span className='text-sm'>{value || placeholderText}</span>
  </button>
));

export default CustomDatePickerWrapper;

CustomDatePickerWrapper.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  icon: PropTypes.node.isRequired,
  placeholderText: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
};

CustomDatePickerWrapper.defaultProps = {
  onClick: null,
  value: '',
  placeholderText: '',
  size: 'small',
};

CustomDatePickerWrapper.displayName = 'CustomDatePickerWrapper';
