/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export default function AxisTick({
  payload,
  y,
  verticalAnchor,
  visibleTicksCount,
  tickFormatter,
  ...rest
}) {
  return (
    <text {...rest} y={y + 5} className="text-xs text-black">{tickFormatter ? tickFormatter(payload.value) : payload.value}</text>
  );
}

AxisTick.propTypes = {
  value: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.string,
  }),
  y: PropTypes.number.isRequired,
  tickFormatter: PropTypes.shape({}),
  verticalAnchor: PropTypes.string,
  visibleTicksCount: PropTypes.number.isRequired,
};

AxisTick.defaultProps = {
  payload: null,
  verticalAnchor: null,
  tickFormatter: null,
};
