import React, { useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { classNames } from '../../../utils/ui';
import LiveMerchantTrendChart from './LiveMerchantTrendChart';
import Top5Merchants from './Top5Merchants';
import TextButton from '../../../components/buttons/TextButton';

export default function LiveMerchantTrendsContainer() {
  const [selectedTab, setSelectedTab] = useState('trend');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderTrendChart = () => (
    <LiveMerchantTrendChart onSelectedTabChange={setSelectedTab} />
  );

  const renderTop5 = () => (
    <Top5Merchants onSelectedTabChange={setSelectedTab} />
  );

  const tabsContent = {
    trend: renderTrendChart,
    top5: renderTop5,
  };

  return (
    <div className="bg-white border border-gray-200 rounded mb-4">
      <div className="flex space-x-3 border-b border-gray-200 justify-between items-center px-4 py-4">
        <h2 className="text-gray-900 font-semibold">Live Insights</h2>
        <TextButton className="px-4" onClick={() => setIsCollapsed(!isCollapsed)}>
          <div className="flex">
            {isCollapsed ? 'Expand' : 'Collapse'}
            <ChevronUpIcon className={classNames(
              'w-5 h-5',
              isCollapsed && 'rotate-180 transform',
            )}
            />
          </div>
        </TextButton>
      </div>

      <div className={classNames(!isCollapsed && 'p-0')}>
        {selectedTab
          && tabsContent
          && (!isCollapsed) && tabsContent[selectedTab]()}
      </div>
    </div>
  );
}
