const fetchTenantUsers = async (client, params) => client.get('api/users/tenant', {
  params,
})
  .then((response) => response.data);

const fetchUsers = async (client, params = {}) => client.get('api/users', {
  params,
})
  .then((response) => response.data);

const fetchUserFilter = async (client, params = {}) => client.get('api/users/users-filter', {
  params,
})
  .then((response) => response.data);

const updateUser = async (client, userId, params) => client.put(`api/users/${userId}`, params)
  .then((response) => response.data);

const fetchUserDetails = async (client, userId, params = {}) => client.get(`api/users/details/${userId}`, {
  params,
})
  .then((response) => response.data);

// @TODO this should be an endpoint under users
const updateUserRole = async (client, params = {}) => client.put('api/admin/roles/update', params)
  .then((response) => response.data);

export default {
  fetchTenantUsers,
  fetchUsers,
  fetchUserFilter,
  updateUser,
  fetchUserDetails,
  updateUserRole,
};
