const getPageObject = (value, label) => ({ label, value });

export const addFirstLabelIfNeeded = (items, currentPage, exhibitionLimit) =>
  currentPage - exhibitionLimit > 1 ? [getPageObject(1, 'first'), ...items.slice(1)] : items;

export const addLastLabelIfNeeded = (items, currentPage, exhibitionLimit, numPages) => {
  return currentPage + exhibitionLimit < numPages
    ? [...items.slice(0, items.length - 1), getPageObject(numPages, 'last')]
    : items;
};
