import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import ExternalStatusKeys from './ExternalStatus.keys';

const getCardExternalStatuses = async (
    client,
) => api.externalStatuses.fetchStatuses(client);

export default function useGetCardExternalStatuses() {
    const apiClient = useApiClient();
    return useLoggedInQuery(
        ExternalStatusKeys.all(),
        async () => getCardExternalStatuses(apiClient),
    );
}
