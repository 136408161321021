import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default function SlideOverBottom({
  title, isOpen, setOpen, children,
}) {
  return (
    <Transition show={isOpen} as={Fragment} afterEnter={() => ReactTooltip.rebuild()}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={isOpen} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-x-0 bottom-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <div className="w-screen h-screen">
                <div className="flex flex-col h-full pt-6 bg-white shadow-xl rounded-t-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      {title && <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>}
                      <div className="flex items-center ml-3 h-7">
                        <button
                          data-cy="slide-over-close-button"
                          type="button"
                          className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={(event) => setOpen(false, event)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-4" />
                  <div className="relative flex-1 py-4 pb-4 overflow-y-scroll sm:py-6 ">
                    <div className="absolute inset-0 px-12 py-4 sm:py-6">
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

SlideOverBottom.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.node,
};

SlideOverBottom.defaultProps = {
  title: null,
  isOpen: false,
  setOpen: () => { },
  children: null,
};
