import { useMutation } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import { getApiClient } from '../../../api/base';

export default function useCompleteInvitation(
  id,
  options,
) {
  const client = getApiClient();

  return useMutation((data) => api.admin.invitations.completeInvitation(client, id, data), {
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
