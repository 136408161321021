import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import AdminTenantKeys from './AdminTenant.keys';

export default function useGetTenantLogs(tenantId) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    AdminTenantKeys.log(tenantId),
    async () => api.admin.tenants.fetchTenantLogs(apiClient, tenantId),
  );
}
