import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetPaymentMethods(params = {}) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    ['paymentMethods', ...Object.values(params)],
    () => api.transactions.fetchPaymentMethods(apiClient, params),
  );
}
