import StatusConstants from '../config/StatusConstants';

const numberFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((i) => num >= i.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const dataCyValueFormatter = (value) =>  {
  return typeof value === 'string' ? value.replace(/\s+/g, '-').toLowerCase() : value;
}

export const formatStringLength = (fullString, limit) => {
  if(!fullString) return '';
  return fullString.length > limit ? `${fullString.substring(0, limit)}*` : fullString;
};

export const hasAsterisk = (value) => value.indexOf('*') >= 0;

export default {
  getAlertStringsByMerchantStatus: (merchantStatus, merchantName) => {
    switch (merchantStatus) {
      case StatusConstants.blockedApiName:
        return {
          title: 'Merchant blocked successfully',
          message: `${merchantName} has been added to your list of blocked merchants.`,
        };
      case StatusConstants.approvedApiName:
        return {
          title: 'Merchant approved successfully',
          message: `${merchantName} has been added to your list of approved merchants.`,
        };
      default:
        return {
          title: 'Merchant trusted successfully',
          message: `${merchantName} has been added to your list of trusted merchants.`,
        };
    }
  },
  numberFormatter,
  currencyFormat: (data) => {
    const value = numberFormatter(
      data, 1,
    );

    return `$${value}`;
  },
  currencyFormatNormal: (data) => currencyFormatter.format(data),
};
