import { format, utcToZonedTime } from 'date-fns-tz';
import React, { useMemo } from 'react';
import {
  CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import useGetCardChart from '../../../hooks/api/insights/useGetCardChart';
import numberUtils from '../../../utils/numberUtils';
import AxisTick from '../../chart/AxisTick';
import Spinner from '../../Spinner';

export default function CardChart() {
  const { isLoading, data } = useGetCardChart('weeks');

  const chartData = useMemo(() => {
    if (isLoading || !data) {
      return [];
    }
    return data.data.map((item) => ({
      ...item,
      period: format(utcToZonedTime(new Date(item.period), 'UTC'), 'yyyy-MM-dd', { timeZone: 'UTC' }),
    }));
  }, [isLoading, data]);

  return (
    <>
      {isLoading && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full p-2 bg-black bg-opacity-20">
          <Spinner />
        </div>
      )}
      {data && (
        <div className="w-full h-60">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={chartData}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis
                dataKey="period"
                scale="band"
                tick={{ fontSize: 10 }}
              />
              <YAxis
                tick={AxisTick}
                tickFormatter={(value) => numberUtils.currencyFormat(value)}
                label={{
                  value: '$ Amount',
                  angle: -90,
                  position: 'insideLeft',
                  fontSize: 12,
                }}
              />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Line name="At risk" dataKey="balanceAtRisk" stroke="#2563EB" strokeDasharray="5 5" strokeWidth={2} />
              <Line name="Protected" dataKey="balanceProtected" stroke="#2563EB" dot={{ fill: '#2563EB' }} strokeWidth={2} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
}
