import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  CartesianGrid, ComposedChart, Label,
  Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { format, parseISO } from 'date-fns';
import AxisTick from '../../../components/chart/AxisTick';
import CheckboxLegend from '../../../components/chart/CheckboxLegend';
import stringUtils from '../../../utils/stringUtils';
import numberUtils from '../../../utils/numberUtils';
import useGetMerchantTrendsChartData from '../../../hooks/api/merchants/useGetMerchantTrendsChartData';
import Spinner from '../../../components/Spinner';
import ButtonGroup from '../../../components/ButtonGroup';
import Tabs from '../../../components/Tabs';
import useGetMe from '../../../hooks/api/users/useGetMe';
import useConstant from '../../../hooks/useConstant';
import FeatureFlagConstants from '../../../config/FeatureFlagConstants';

function useTimeFilterOptions() {
  const { data: me } = useGetMe();
  const timeOptions = useConstant(() => {
    const timeFilterOptionsToReturn = [
      {
        label: 'By minute',
        value: 'minutes',
      },
      {
        label: 'Hourly',
        value: 'hours',
      },
    ];
    return timeFilterOptionsToReturn;
  });

  if (!me) {
    return timeOptions;
  }
  if (me && me.featureFlags[FeatureFlagConstants.isPerformancePOC]) {
    return timeOptions.filter((tab) => tab.value !== 'days' && tab.value !== 'weeks'&& tab.value !== 'months');
  }

  return timeOptions;
}

export default function LiveMerchantTrendChart({ onSelectedTabChange }) {
  const [active, setActive] = useState({
    approvedAuths: true,
    declinedAuths: true,
    approvedAvg: true,
    declinedAvg: true,
  });
  const timeFilterOptions = useTimeFilterOptions();
  const [timeFilter, setTimeFilter] = useState(timeFilterOptions && timeFilterOptions[0].value);
  const { data, isLoading } = useGetMerchantTrendsChartData({
    period: timeFilter,
  });


  const onLegendClick = (entry, index, event) => {
    event.preventDefault();
    setActive({
      ...active,
      [entry.payload.seriesName]: !active[entry.payload.seriesName],
    });
  };

  const dateFormatter = (value) => {
    const date = parseISO(value);
    switch (timeFilter) {
      case 'minutes':
      case 'hours':
        return format(date, 'kk:mm');
      default:
        return format(date, 'MM-dd-yyyy');
    }
  };

  const tooltipFormatter = (value, name) => {
    if (name === 'Avg. $ declined' || name === 'Avg. $ approved') {
      return numberUtils.currencyFormat(value);
    }
    return numberUtils.numberFormat(value);
  };

  return (
    <div className="relative w-full h-80">
      {isLoading ? (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full p-2 bg-black bg-opacity-20">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between border-b border-gray-200 ">
            <Tabs
              buttonClassName="px-6 ml-5"
              tabs={[
                {
                  name: 'Trend',
                  value: 'trend',
                },
                {
                  name: 'Top 5',
                  value: 'top5',
                },
              ]}
              selectedTab="trend"
              onClick={(e) => onSelectedTabChange(e)}
            />
            <ButtonGroup
              className="mr-5 h-fit"
              itemClassName="text-xs"
              selected={timeFilter}
              onChange={(value) => setTimeFilter(value)}
              options={timeFilterOptions}
            />
          </div>
          <ResponsiveContainer width="100%" height="75%">
            <ComposedChart data={data ? data.data : []}>
              <CartesianGrid strokeDasharray="2 2" />
              <Tooltip formatter={tooltipFormatter} labelFormatter={dateFormatter} />
              <Legend
                iconSize={24}
                verticalAlign="middle"
                align="left"
                layout="vertical"
                width={200}
                content={CheckboxLegend}
                onClick={onLegendClick}
              />
              <XAxis dataKey="period" scale="band" tick={{ fontSize: 10 }} tickFormatter={dateFormatter} />
              <YAxis
                tick={AxisTick}
                tickFormatter={(value) => `$${stringUtils.numberFormatter(value, 1)}`}
                label={{
                  angle: -90,
                  position: 'insideLeft',
                  fontSize: 12,
                }}
              >
                <Label
                  value="Avg. $ Amount"
                  angle={-90}
                  position="insideLeft"
                  className="text-sm font-medium"
                  style={{ textAnchor: 'middle' }}
                />
              </YAxis>
              <YAxis
                yAxisId="total"
                orientation="right"
                tick={AxisTick}
              >
                <Label
                  value="# Auths"
                  angle={90}
                  position="insideRight"
                  className="text-sm font-medium"
                  style={{ textAnchor: 'middle' }}
                />
              </YAxis>
              <Bar name="# Auths approved" isActive={active.approvedAuths} dataKey={`approvedAuths${active.approvedAuths ? '' : '_inactive'}`} seriesName="approvedAuths" stackId="total" yAxisId="total" fill="#93C5FD" barSize={27} />
              <Bar name="# Auths declined" isActive={active.declinedAuths} dataKey={`declinedAuths${active.declinedAuths ? '' : '_inactive'}`} seriesName="declinedAuths" stackId="total" yAxisId="total" fill="#1E3A8A" barSize={27} />
              <Line
                strokeWidth={1.5}
                filter="drop-shadow(0 1px 1px #818CF8AA)"
                name="Avg. $ approved"
                isActive={active.approvedAvg}
                dataKey={`approvedAvg${active.approvedAvg ? '' : '_inactive'}`}
                seriesName="approvedAvg"
                stroke="#93C5FD"
                dot={{ fill: '#93C5FD', stroke: '#1E3A8A' }}
                activeDot={{ fill: '#93C5FD', stroke: '#1E3A8A' }}
              />
              <Line
                strokeWidth={1.5}
                filter="drop-shadow(0 1px 1px #818CF8AA)"
                name="Avg. $ declined"
                isActive={active.declinedAvg}
                dataKey={`declinedAvg${active.declinedAvg ? '' : '_inactive'}`}
                seriesName="declinedAvg"
                stroke="#1E3A8A"
                dot={{ fill: '#1E3A8A', stroke: '#93C5FD', strokeDasharray: '0' }}
                activeDot={{ fill: '#1E3A8A', stroke: '#93CFD' }}
                strokeDasharray="4 4"
              />
            </ComposedChart>
          </ResponsiveContainer>

        </>
      )}
      {!isLoading && (
        <div className="mb-10 ml-2 text-xs">Chart data refreshed every 1 min</div>
      )}

    </div>
  );
}

LiveMerchantTrendChart.propTypes = {
  onSelectedTabChange: PropTypes.func,
};

LiveMerchantTrendChart.defaultProps = {
  onSelectedTabChange: (() => { }),
};
