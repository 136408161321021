import React from 'react';
import Grid from '../../components/Grid';
import Title from '../../components/headings/Title';
import HomeAuthScanned from '../home/HomeAuthScanned';
import HomeStatus from '../home/HomeStatus';
import LiveCardDetails from '../home/LiveCardDetails';

export default function ClientAlert() {
  return (
    <Grid>
      <div className="col-span-12 pt-5 " data-cy="merchants-screen">
        <div className="flex items-center justify-between mb-3 ">
          <Title>
            Client alert
            <HomeAuthScanned />
          </Title>
          <div className="flex">
            <HomeStatus />
          </div>
        </div>
        <LiveCardDetails isFromRules />
      </div>
    </Grid>
  );
}
