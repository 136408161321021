import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import MerchantKeys from './Merchant.keys';

export default function useCreateMerchantComment(options) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, comment }) =>
      api.merchants.createMerchantComment(client, id, {
        comment,
      }).then(() => id),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(MerchantKeys.log(id));
      },
      onError: (error) => {
        logger.error(error);
      },
      ...options,
    }
  );
}
