import { combineReducers } from '@reduxjs/toolkit';
import adminSlice from './admin/adminSlice';
import cardsSlice from './card/cardSlice';
import globalSlice from './global/globalSlice';
import meSlice from './me/meSlice';
import merchantSlice from './merchant/merchantSlice';
import tenantSlice from './tenant/tenantSlice';

export default combineReducers({
  card: cardsSlice,
  merchant: merchantSlice,
  me: meSlice,
  tenant: tenantSlice,
  admin: adminSlice,
  global: globalSlice,
  // add other slices/reducers here
});
