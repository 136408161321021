import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import { SearchIcon } from '@heroicons/react/outline';
import { classNames } from '../../utils/ui';
import IconButton from '../buttons/IconButton';
import HighlightText from '../HighlightText';
import testUtils from '../../utils/testUtils';

export default function FMCombobox({ data, placeholder, selected, onChange, isClearable, testSelector }) {
  const [query, setQuery] = useState('');
  const cleanQuery = query.toLowerCase();

  const filtered = query === ''
    ? data
    : data.filter((item) => item.label.toLowerCase().includes(cleanQuery));

  const testSelectorPrefix = testUtils.testSelectorFormat(testSelector || `combobox-${placeholder}`);

  return (
    <>
      <Combobox
        value={selected}
        onChange={(value) => {
          setQuery('');
          if (onChange) {
            onChange(value);
          }
        }}
      >
        {({ open }) => (
          <div className="relative">
            <div className="flex">
              <Combobox.Button
                className={classNames(
                  'rounded-sm pl-3 pr-2 py-1.5 text-sm flex items-center focus:outline-none focus:ring-0',
                  selected ? 'bg-blue-50 text-black' : 'bg-gray-100 text-gray-500'
                )}
                onClick={() => setQuery('')}
                data-cy={`${testSelectorPrefix}-button`}
              >
                <span>{selected ? selected.label : placeholder}</span>
                {(!selected || !isClearable) && <ChevronDownIcon className="ml-2 w-5" />}
              </Combobox.Button>
              {selected && isClearable && (
                <IconButton
                  onClick={() => onChange && onChange(null)}
                  buttonStyle="custom"
                  buttonSize="custom"
                  className="bg-blue-50 border-l border-blue-700 px-1.5"
                  icon={<XIcon className="px-1" />}
                />
              )}
            </div>
            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <div className="absolute top-full mt-1 rounded bg-white z-50 overflow-hidden">
                  <div className="relative">
                    <Combobox.Input
                      className="border text-sm pl-10 border-gray-200 rounded-t focus:outline-none focus:ring-0"
                      placeholder="type to search"
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    <SearchIcon className="absolute top-2.5 left-3.5 w-5 h-5 text-gray-400" />
                  </div>
                  <Combobox.Options className="max-h-60 overflow-auto border border-gray-200 border-t-0 rounded-b">
                    {filtered.map((item) => (
                      <Combobox.Option
                        key={item.value}
                        value={item}
                        className="text-sm text-gray-500 hover:text-black cursor-pointer"
                      >
                        {({ active, selected: isSelected }) => (
                          <div
                            className={classNames(
                              'flex justify-between items-center py-1.5 px-3',
                              active && 'bg-gray-50'
                            )}
                            data-cy={testUtils.testSelectorFormat(`${testSelectorPrefix}-option-${item.label}`)}
                          >
                            <HighlightText
                              text={item.label}
                              highlight={query}
                              highlightClassName="bg-transparent text-black"
                            />
                            {isSelected && <CheckIcon className="w-5 text-black" />}
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </div>
              </Transition>
            )}
          </div>
        )}
      </Combobox>
    </>
  );
}

FMCombobox.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  selected: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  testSelector: PropTypes.string,
};

FMCombobox.defaultProps = {
  data: [],
  selected: null,
  placeholder: 'Select...',
  onChange: null,
  isClearable: true,
  testSelector: '',
};
