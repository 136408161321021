import React from 'react';

export default function HomeStatus() {
  return (
    <div className="flex items-center">
      <div className="relative w-5 h-5 mr-4">
        <div className="pulsating-circle" />
      </div>
      <p className="text-sm inline-block text-gray-500">
        Arden Status:&nbsp;
        <span className="font-medium text-gray-700">Running</span>
      </p>
    </div>
  );
}
