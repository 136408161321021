import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import InvitationKeys from './Invitation.keys';

export default function useUpdateInvitation(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => api.admin.invitations.updateInvitation(client, id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(InvitationKeys.all());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
