import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import store from './store';
import 'react-datepicker/dist/react-datepicker.css';
import config from './config';
import BottomSheetContextProvider from './hooks/bottomSheet/BottomSheetProvider';
import GroupViewContextProvider from './hooks/groupViewContext/GroupViewContextProvider';
import WithAppInsights from './components/appInsights/AppInsights';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: 0,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <WithAppInsights>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            audience={process.env.REACT_APP_AUTH0_API_AUDIENCE}
            scope={config.auth0TokenParams.scope}
            cacheLocation="localstorage"
            useRefreshTokens
          >
            <Provider store={store}>
              <GroupViewContextProvider>
                <BottomSheetContextProvider>
                  <App />
                </BottomSheetContextProvider>
              </GroupViewContextProvider>
            </Provider>
          </Auth0Provider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </WithAppInsights>
  </React.StrictMode>,
);
