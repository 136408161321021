import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Merchants from './screens/Merchants';
import Login from './screens/Login';
import Cards from './screens/Cards';
import Admin from './screens/Admin';
import Insights from './screens/Insights';
import Home from './screens/home/Home';
import ClientAlert from './screens/client-alert/ClientAlert';
import Account from './screens/Account';
import CreateAccount from './screens/invitation/CreateAccount';
import OpenInvitation from './screens/invitation/OpenInvitation';
import RedirectToLogin from './screens/RedirectToLogin';
import LivePDS from './screens/pds/LivePDS';
import InactivityScreen from './screens/auth/InactivityScreen';
import NetworkErrorScreen from './screens/networkError';
import LoadingAuth from './components/LoadingAuth';

const protectedParams = {
  onRedirecting: () => <LoadingAuth />,
};

const ProtectedClientAlert = withAuthenticationRequired(ClientAlert, protectedParams);
const ProtectedPDS = withAuthenticationRequired(LivePDS, protectedParams);
const ProtectedMerchants = withAuthenticationRequired(Merchants, protectedParams);
const ProtectedCards = withAuthenticationRequired(Cards, protectedParams);
const ProtectedInsights = withAuthenticationRequired(Insights, protectedParams);
const ProtectedAdmin = withAuthenticationRequired(Admin, protectedParams);
const ProtectedAccount = withAuthenticationRequired(Account, protectedParams);
const ProtectedHome = withAuthenticationRequired(Home, protectedParams);

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={RedirectToLogin} />
      <Route exact path="/user-invitation/:id/complete" component={CreateAccount} />
      <Route exact path="/user-invitation/:id/accept" component={OpenInvitation} />
      <Route exact path="/inactivity" component={InactivityScreen} />
      <Route exact path="/network-error" component={NetworkErrorScreen} />
      <Route exact path="/client-alert" component={ProtectedClientAlert} />
      <Route exact path="/pds" component={ProtectedPDS} />
      <Route exact path="/merchants" component={ProtectedMerchants} />
      <Route exact path="/cards" component={ProtectedCards} />
      <Route exact path="/insights" component={ProtectedInsights} />
      <Route path="/admin" component={ProtectedAdmin} />
      <Route path="/account" component={ProtectedAccount} />
      <Route path="/" component={ProtectedHome} />
    </Switch>
  );
}
