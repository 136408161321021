/* eslint-disable no-unused-vars */
import { useEffect } from 'react';

import { useLocation } from 'react-router';
import useBottomSheetContext from './useBottomSheet';

export default function useDeepLinkHandler() {
  const location = useLocation();
  const bottomSheet = useBottomSheetContext();

  useEffect(() => {
    if (!location) return;
    const params = location.pathname.split('/');
    if (params.length === 0) return;

    if (location.pathname.includes('merchants/')) {
      const merchantId = params[2];
      bottomSheet.addTab({
        id: Number(merchantId),
        type: 'merchant',
      });
    }

    if(location.pathname.includes('cards/')){
        const cardId = params[2];
        bottomSheet.addTab({
          id: Number(cardId),
          type: 'card',
        });
    }
  }, []);
}
