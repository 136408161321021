import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from '../merchants/Merchant.keys';

const getCardsByMerchant = async (
  client, id, params,
) => api.merchants.fetchCardsByMerchant(client, id, params);

export default function useGetCardsByMerchant(id, params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.cards(id, params),
    async () => getCardsByMerchant(apiClient, id, params),
  );
}
