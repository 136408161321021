import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import ClosableTabsContainer from '../ClosableTabsContainer';
import BottomSheetTabContent from './BottomSheetTabContent';
import useDeepLinkHandler from '../../hooks/bottomSheet/useDeepLinkHandler';

export default function BottomSheet({
  isOpen, setOpen, tabs, onRemoveTab, onCloseAll, selectedIndex, onChangeSelectedIndex,onMinimize
}) {
  useDeepLinkHandler();

  return (
    <Transition show={isOpen} as={Fragment} afterEnter={() => ReactTooltip.rebuild()}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={isOpen} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-x-0 bottom-0 flex overflow-hidden rounded-t-lg top-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-1/2 opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <div className="w-full h-full">
                <ClosableTabsContainer
                  tabs={tabs.map((tab) => ({
                    ...tab,
                    content: <BottomSheetTabContent type={tab.type} id={tab.id} />,
                  }))}
                  onRemoveTab={onRemoveTab}
                  onCloseAll={onCloseAll}
                  onMinimize={onMinimize}
                  selectedIndex={selectedIndex}
                  onChangeSelectedIndex={onChangeSelectedIndex}
                />
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

BottomSheet.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  onRemoveTab: PropTypes.func.isRequired,
  onCloseAll: PropTypes.func.isRequired,
  onMinimize: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onChangeSelectedIndex: PropTypes.func.isRequired,
};

BottomSheet.defaultProps = {
  isOpen: false,
  setOpen: () => { },
};
