import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import UserKeys from './User.keys';

export const updateUser = (
  client,
  id,
  data,
) => {
  if (!id) {
    throw new Error('id is required');
  }
  return api.admin.users.updateUser(client, id, data);
};

export default function useUpdateUser(
  options,
) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => updateUser(client, id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(UserKeys.all());
      queryClient.invalidateQueries(UserKeys.details());
    },
    onError: (error) => {
      logger.error(error);
    },
    ...options,
  });
}
