import { UserIcon, CogIcon } from '@heroicons/react/outline';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';
import React from 'react';
import Grid from '../components/Grid';
import Title from '../components/headings/Title';
import VerticalNav from '../components/VerticalNav';
import Profile from './account/Profile';
import Settings from './account/Settings';
import ProfileActivityLog from './account/ProfileActivityLog';
// import ActivityIcon from '../assets/icons/activity-icon.svg';

function getNavItems(path) {
  return [
    {
      name: 'Profile',
      href: `${path}/profile`,
      icon: UserIcon,
    },
    {
      name: 'Activity Log',
      href: `${path}/activity`,
      icon: CogIcon,
    },
  ];
}

export default function Account() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const navItems = getNavItems(path);

  return (
    <Grid>
      <div className="col-span-12 pt-5">
        <div className="mb-4">
          <Title>Account</Title>
        </div>
        <hr className="mb-6" />
      </div>
      <div className="col-span-3">
        <VerticalNav
          location={location.pathname}
          items={navItems}
        />
      </div>
      <div className="col-span-9">
        <Switch>
          <Route exact path={path}>
            <Redirect to="/account/profile" />
          </Route>
          <Route
            path={`${path}/profile`}
            component={Profile}
          />
          <Route
            path={`${path}/settings`}
            component={Settings}
          />
          <Route
            path={`${path}/activity`}
            component={ProfileActivityLog}
          />
        </Switch>
      </div>
    </Grid>
  );
}
