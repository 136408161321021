import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDidMount } from 'beautiful-react-hooks';
import FiltersContainer from '../filters/FiltersContainer';
import FMCombobox from '../forms/FMCombobox';
import TwoRowsColumn from './columns/TwoRowsColumn';
import ResponseColumn from './columns/ResponseColumn';
import RefreshTable from '../RefreshTable';
import Spinner from '../Spinner';
import PaginationConfig from '../../config/PaginationConfig';
import DataTable, { TABLE_SORT_DESC } from './DataTable';
import DateColumn from './columns/DateColumn';
import useGetCardTransactions from '../../hooks/api/cards/useGetCardTransactions';
import TextButton from '../buttons/TextButton';
import filterUtils from '../../utils/filterUtils';
import numberUtils from '../../utils/numberUtils';
import { MERCHANT_OPEN_MERCHANT } from './MerchantsTable';
import useBottomSheetContext from '../../hooks/bottomSheet/useBottomSheet';
import useGetMe from '../../hooks/api/users/useGetMe';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import TransactionDetailsRow from './expanded/TransactionDetailsRow';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';
import CustomTimePeriodWrapper from '../forms/CustomTimePeriodWrapper'
import timeLimitUtils from '../../utils/timeLimitUtils'
import { handleCurrentDay } from '../../utils/date'
import { appendFilterifHasValue, restoreState, useCardTransactionsAction, useFiltersForCardTransactions } from './CardTransactions/CardTransactions.helpers';

const columns = [
  DateColumn('utcTxnDate', 'Transaction Date'),
  {
    key: 'merchant',
    field: 'merchantName',
    field2: 'merchantNumber',
    headerName: 'Merchant',
    headerClassName: 'text-left',
    render: TwoRowsColumn,
    valueGetter2: (field) => (field ? `ID: ${field}` : ''),
    linkGetter: (row) => `merchants/${row.id}`,
    mccField: 'merchantMcc',
    actionName: MERCHANT_OPEN_MERCHANT,
    sortable: true,
    sortField: 'merchantName',
  },
  {
    field: 'requestCodeDescription',
    headerName: 'Payment method',
    headerClassName: 'text-left',
    className: 'text-left pl-4',
    valueGetter: (field) => field || 'Not available',
    sortable: true,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    valueGetter: numberUtils.currencyFormat,
    headerClassName: 'justify-end',
    className: 'text-right',
    sortable: true,
  },
  {
    field: 'response',
    headerName: 'Response',
    headerClassName: 'text-left',
    className: 'text-left',
    render: ResponseColumn,
    sortable: true,
  },
];

const viewKey = 'cardTransactions';

export default function CardTransactions({ cardId }) {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const { data: me } = useGetMe();
  const [authTypeFilter, setAuthTypeFilter] = useState(null);
  const [merchantStatus, setMerchantStatus] = useState(null);
  const [paymentFilter, setPaymentFilter] = useState(null);
  const [responseFilter, setResponseFilter] = useState(null);
  const [authReasonCode, setAuthReasonCode] = useState(null);
  const [timeFilter, setTimeFilter] = useState(null);
  const [filtersDescription, setFiltersDescription] = useState([]);
  const [sort, setSort] = useState('utcTxnDate');
  const [sortDir, setSortDir] = useState(TABLE_SORT_DESC);
  const { responseCodes,
    reasonCodes,
    paymentMethods,
    authTypeFilterOptions,
  } = useFiltersForCardTransactions(cardId);

  const [maxEndDateLimit, setMaxEndDateLimit] = useState(new Date());
  const [dateFilter, setDateFilter] = useState(null);
  const selectedPeriod = timeFilter?.value !== 'custom' ? timeFilter?.value : null

  const { data: transactionsData, refetch, isLoading } = useGetCardTransactions(cardId, {
    page: page - 1,
    itemsPerPage,
    sort,
    sortDir,
    merchantStatusApiName: merchantStatus?.value,
    search,
    responseCode: responseFilter ? responseFilter.value : null,
    reasonCode: authReasonCode?.value,
    requestCode: paymentFilter ? paymentFilter.value : null,
    isCardPresent: filterUtils.getIsCardPresentFromAuthTypeFilter(authTypeFilter),
    isInternationalTransaction: filterUtils.getIsInternationalTransactionFromAuthTypeFilter(authTypeFilter),
    duration: dateFilter ? dateFilter.startDate : selectedPeriod,
    duringAttack: authTypeFilter && authTypeFilter.value === 'attack-auth',
    ruleId: filterUtils.getRuleIdFromAuthTypeFilter(authTypeFilter),
    endDate: dateFilter?.endDate,
  });

  const transactions = transactionsData ? transactionsData.rows : [];
  const count = transactionsData ? transactionsData.count : 0;
  const bottomSheet = useBottomSheetContext();

  const groupView = useGroupViewContext();
  const tabState = groupView.state(viewKey);
  const [mounted, setIsMounted] = useState(false);
  const onMount = useDidMount();
  onMount(() => setIsMounted(true));

  useEffect(() => {
    if (tabState === undefined) return;
    restoreState(tabState.search, setSearch);
    restoreState(tabState.merchantStatus, setMerchantStatus);
    restoreState(tabState.paymentFilter, setPaymentFilter);
    restoreState(tabState.timeFilter, setTimeFilter);
    restoreState(tabState.responseFilter, setResponseFilter);
    restoreState(tabState.filtersDescription, setFiltersDescription);
    restoreState(tabState.sortTable, setSort);
    restoreState(tabState.sortDir, setSortDir);
    restoreState(tabState.page, setPage);
    restoreState(tabState.itemsPerPage, setItemsPerPage);
  }, []);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    groupView.setState(viewKey, {
      search,
      merchantStatus,
      paymentFilter,
      timeFilter,
      responseFilter,
      filtersDescription,
      sortTable: sort,
      sortDir,
      page,
      itemsPerPage,
    });
  }, [mounted, filtersDescription, page, itemsPerPage, search]);

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const handleDateLimit = (date) => {
    handleCurrentDay(date, setMaxEndDateLimit, timeLimitUtils.TimeLimit.MBS_CBS_DATE_LIMIT)
  }

  const onAction = useCardTransactionsAction(bottomSheet.addTab, sort, sortDir, setSort, setSortDir);

  useEffect(() => {
    const newFilters = [];


    appendFilterifHasValue(timeFilter, {
        name: 'Time period',
      value: timeFilter?.label,
    }, newFilters);


    appendFilterifHasValue(paymentFilter, {
        name: 'Payment method',
      value: paymentFilter?.label,
    }, newFilters);



    appendFilterifHasValue(responseFilter, {
        name: 'Response',
      value: responseFilter?.label,
    }, newFilters);



    appendFilterifHasValue(authTypeFilter, {
        name: 'Authorization',
      value: authTypeFilter?.label,
    }, newFilters);



    appendFilterifHasValue(merchantStatus, {
        name: 'Merchant status',
      value: merchantStatus?.label,
    }, newFilters);



    appendFilterifHasValue(authReasonCode, {
        name: 'Reason description',
      value: authReasonCode?.label,
    }, newFilters)


    setFiltersDescription(newFilters);
  }, [timeFilter, paymentFilter, responseFilter, authTypeFilter, merchantStatus, authReasonCode, setFiltersDescription]);

  const clearFilters = () => {
    setSearch('');
    setTimeFilter(null);
    setPaymentFilter(null);
    setResponseFilter(null);
    setAuthTypeFilter(null);
    setMerchantStatus(null);
    setAuthReasonCode(null);
    setFiltersDescription([]);
    setDateFilter(null);
  };

  useEffect(() => {
    if (!dateFilter) setTimeFilter(null);
  }, [dateFilter]);


  const handleComboBoxChange = (e) => {
    resetPage();
    setTimeFilter(e);
  }

  const resetTimeFilter = () => {
    setTimeFilter(null);
  }

  const useExpandable = me?.featureFlags[FeatureFlagConstants.useTransactionDetails];

  return (
    <div>
      <div className="items-start justify-between lg:flex">
        <FiltersContainer
          search={search}
          onSearchChange={setSearch}
          tooltip={filtersDescription.length ? <FiltersContainer.Tooltip filters={filtersDescription} /> : null}
        >
          <CustomTimePeriodWrapper
            isClearable={false}
            timeFilter={timeFilter}
            data={filterUtils.timeFilterWithCustomOption}
            handleComboBoxChange={handleComboBoxChange}
            maxEndDateLimit={maxEndDateLimit}
            handleDateLimit={handleDateLimit}
            dateFilter={dateFilter}
            handleResetDateFilter={resetTimeFilter}
            setDateFilter={setDateFilter}
          />

          {paymentMethods && (
            <FMCombobox
              placeholder="Payment method"
              data={
                paymentMethods?.map((paymentMethod) => ({
                  label: paymentMethod.description,
                  value: paymentMethod.code,
                }))
              }
              selected={paymentFilter}
              onChange={(e) => {
                resetPage();
                setPaymentFilter(e);
              }}
            />
          )}
          {responseCodes && (
            <FMCombobox
              placeholder="Response"
              data={
                responseCodes?.map((responseCode) => ({
                  label: responseCode.description || responseCode.name,
                  value: responseCode.code,
                }))
              }
              selected={responseFilter}
              onChange={(e) => {
                resetPage();
                setResponseFilter(e);
              }}
            />
          )}
          {reasonCodes && (
            <FMCombobox
              placeholder="Reason description"
              data={
                reasonCodes?.map((reasonCode) => ({
                  label: reasonCode.description,
                  value: reasonCode.code,
                }))
              }
              selected={authReasonCode}
              onChange={(e) => {
                resetPage();
                setAuthReasonCode(e);
              }}
            />
          )}
          <FMCombobox
            placeholder="Auth type"
            data={authTypeFilterOptions}
            selected={authTypeFilter}
            onChange={(e) => {
              resetPage();
              setAuthTypeFilter(e);
            }}
          />
          <FMCombobox
            placeholder="Merchant status"
            data={[
              { value: 'trusted-merchant', label: 'Trusted' },
              { value: 'approved-merchant', label: '24h Trusted' },
              { value: 'blocked-merchant', label: 'Blocked' },
              { value: 'reviewed-merchant', label: 'Reviewed' },
              { value: 'known-merchant', label: 'Known' },
            ]}
            selected={merchantStatus}
            onChange={(e) => {
              resetPage();
              setMerchantStatus(e);
            }}
          />
          {(search.length > 0 || filtersDescription.length > 0) && (
            <TextButton onClick={() => clearFilters()}>Clear all</TextButton>
          )}
        </FiltersContainer>
        <RefreshTable setRefreshTable={refetch} />
      </div>
      {isLoading ? (
        <div className="pl-2">
          <Spinner />
        </div>
      ) : (
        <>
          <DataTable
            data={transactions}
            columns={columns}
            onAction={onAction}
            sort={sort}
            sortDirection={sortDir}
            paginationProps={{
              totalItems: count,
              currentPage: page,
              itemsPerPage,
              onPageClick,
              onItemsPerPageChange: (e) => {
                resetPage();
                setItemsPerPage(e);
              },
            }}
            ExpandedRenderer={useExpandable ? TransactionDetailsRow : null}
          />
        </>
      )}
    </div>
  );
}

CardTransactions.propTypes = {
  cardId: PropTypes.number.isRequired,
};
