import React, { useState } from 'react';
import Panel from '../../Panel';
import TabsContainer from '../../TabsContainer';
import FraudChart from './FraudChart';
import FraudReport from './FraudReport';

const tabs = [
  {
    name: 'Report',
    value: 'report',
  },
  {
    name: 'Attack Trends',
    value: 'chart',
  },
];

export default function FraudInsights() {
  const [selectedTab, setSelectedTab] = useState('report');

  const renderReportTab = () => (
    <FraudReport />
  );

  const renderChartTab = () => (
    <FraudChart />
  );

  const tabsContent = {
    report: renderReportTab,
    chart: renderChartTab,
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-2">
        Fraud
      </h2>
      <Panel containerClassName="px-6" className="mb-12">
        <TabsContainer
          className="py-4"
          tabs={tabs}
          tabsContent={tabsContent}
          selectedTab={selectedTab}
          onSelectedTabChange={setSelectedTab}
        />
      </Panel>
    </>
  );
}
