import React from 'react';
import PropTypes from 'prop-types';

import Label from '../forms/Label';
import RadioGroup from '../RadioGroup';
import Input from '../forms/Input';

export default function Reasons({
  reason,
  setReason,
  otherReason,
  setOtherReason,
  reasons,
  otherValue
}) {
  if (!reasons) {
    return null;
  }

  const handleReasonChange = (value) => {
    setReason(value);
    if (value !== otherValue) {
      setOtherReason('');
    }
  };

  return (
    <div className="mb-8">
      <Label className="inline-block mb-2" isRequired htmlFor="other-reason">
        Select reason:
      </Label>
      <RadioGroup options={reasons} selected={reason} onChange={handleReasonChange} />
      {reason === otherValue && (
        <div className="flex flex-col">
          <Label className="mb-1" isRequired htmlFor="other-reason">
            Provide reason:
          </Label>
          <Input
            type="text"
            id="other-reason"
            name="other-reason"
            rows={3}
            value={otherReason}
            onChange={({ target }) => setOtherReason(target.value)}
          />
        </div>
      )}
    </div>
  );
}

Reasons.propTypes = {
  reason: PropTypes.string,
  setReason: PropTypes.func.isRequired,
  otherReason: PropTypes.string.isRequired,
  setOtherReason: PropTypes.func.isRequired,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  otherValue: PropTypes.string.isRequired
};

Reasons.defaultProps = {
  reason: null
}
