import React from 'react';
import PropTypes from 'prop-types';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;

function getReactPlugin() {
  if (!reactPlugin) {
    reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
      }
    });
    appInsights.loadAppInsights();
  }

  return reactPlugin;
}

export default function WithAppInsights({ children }) {
  return (
    <AppInsightsContext.Provider value={getReactPlugin()}>
      <AppInsightsErrorBoundary appInsights={getReactPlugin()}>
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

WithAppInsights.propTypes = {
  children: PropTypes.node.isRequired,
}
