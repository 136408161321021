/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Switch } from '@headlessui/react';
import PropTypes from 'prop-types';

import { classNames } from '../utils/ui';

const enabledStyle = 'opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer';
const disabledStyle = 'opacity-40 cursor-default';

export default function Toggle({
  checked, onChange, showLabel, disabled,
}) {
  return (
    <div className="flex">
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={`flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 ${disabled ? disabledStyle : enabledStyle}`}
      >
        <span className="sr-only">Use setting</span>
        <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
        <span
          aria-hidden="true"
          className={classNames(
            checked ? 'bg-teal-600' : 'bg-gray-200',
            'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
          )}
        />
      </Switch>
      {showLabel && (
        <span className={
          classNames(
            'ml-2',
            checked ? 'text-gray-900' : 'text-gray-500',
          )
        }
        >
          {checked ? 'On' : 'Off'}
        </span>
      )}
    </div>
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};

Toggle.defaultProps = {
  checked: false,
  onChange: null,
  showLabel: true,
  disabled: false,
};
