import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from './Merchant.keys';

const getMerchantLog = async (
  client,
  merchantTenantId,
) => api.merchants.fetchMerchantLog(client, merchantTenantId);

export default function useGetMerchantLog(merchantTenantId) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.log(merchantTenantId),
    async () => getMerchantLog(apiClient, merchantTenantId),
  );
}
