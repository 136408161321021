import React from 'react';
import SlideOverSelectedCardTransactions from './SlideOverSelectedCardTransactions';
import SlideOverSelectedMerchantTransactions from './SlideOverSelectedMerchantTransactions';
import SlideOverSelectedTenant from './SlideOverSelectedTenant';
import SlideOverSelectedUser from './SlideOverSelectedUser';
import SlideOverManualBlockedMerchants from './SlideOverManualBlockedMerchants';
import SlideOverSelectedMerchantCards from './SlideOverSelectedMerchantCards';

export default function GlobalModals() {
  return (
    <>
      <SlideOverSelectedMerchantTransactions />
      <SlideOverSelectedMerchantCards />
      <SlideOverSelectedCardTransactions />
      <SlideOverSelectedTenant />
      <SlideOverSelectedUser />
      <SlideOverManualBlockedMerchants />
    </>
  );
}
