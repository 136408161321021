/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { CheckCircleIcon as OutlineCheckCircleIcon } from '@heroicons/react/outline';
import Label from './Label';
import { classNames } from '../../utils/ui';

export const CHECKBOX_TYPES = {
  default: 'default',
  circle: 'circle',
};

const renderIcon = (type, isChecked) => {
  let Icon = null;
  switch (type) {
    case CHECKBOX_TYPES.circle:
      Icon = isChecked ? CheckCircleIcon : OutlineCheckCircleIcon;
      break;
    default:
      Icon = null;
      break;
  }
  return Icon && <Icon className="inline-block w-5 mr-1 text-blue-800" />;
};

export default function Checkbox({
  children, className, onChange, isChecked, type, disabled, ...attrs
}) {
  return (
    <Label className={`inline-block ${className}`}>
      <input
        {...attrs}
        checked={isChecked}
        disabled={disabled}
        type="checkbox"
        onChange={(e) => onChange && onChange(e.currentTarget.checked)}
        className={classNames(
          'w-4 h-4 mr-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 cursor-pointer disabled:opacity-30',
          type !== CHECKBOX_TYPES.default && 'hidden',
        )}
      />
      {type !== CHECKBOX_TYPES.default && renderIcon(type, isChecked)}
      {children}
    </Label>
  );
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(CHECKBOX_TYPES)),
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  onChange: null,
  className: '',
  isChecked: false,
  type: CHECKBOX_TYPES.default,
  children: null,
  disabled: false,
};
