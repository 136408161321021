import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from './Merchant.keys';

const getMerchantThreatsActiveAlertFilter = async (client, params) =>
  api.merchants.fetchMerchantThreatsActiveAlertFilter(client, params);

export default function useGetMerchantThreatsActiveAlertFilter(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(MerchantKeys.list('threats', 'active-alert', params), async () =>
    getMerchantThreatsActiveAlertFilter(apiClient, params)
  );
}
