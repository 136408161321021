import React from 'react';
import useGetAllTransactionsCount from '../../hooks/api/transactions/useGetAllTransactionsCount';
import useInterval from '../../hooks/useInterval';

const INTERVAL_LIMIT = process.env.REACT_APP_HOME_METRICS_INTERVAL || 60000;

export default function HomeAuthScanned() {
  const { data, refetch } = useGetAllTransactionsCount();
  useInterval(refetch, INTERVAL_LIMIT);

  return (
    <div className="flex items-center ml-2">
      <p className="inline-block text-sm font-medium text-gray-700">
        | Total authorizations scanned:&nbsp;
        <span className="font-bold">
          {(data ? data.total : 0).toLocaleString()}
        </span>
      </p>
    </div>
  );
}
