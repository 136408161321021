import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getFraudChartReport = async (client) => api.insights.fetchFraudReport(client);

export default function useGetFraudChartReport() {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'insights',
      'fraudReport',
    ],
    async () => getFraudChartReport(apiClient),
  );
}
