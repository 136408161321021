import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as QuestionCircle } from '../assets/icons/question-circle.svg';
import Tooltip from './Tooltip';
import { classNames } from '../utils/ui';

export default function Panel({
  className, containerClassName, title, tooltip, children,
}) {
  return (
    <div className={
      classNames('bg-white border border-gray-200 rounded', className)
    }
    >
      {title && (
        <>
          <h3 className="block px-6 py-3 font-normal text-left">
            {title}
            {tooltip && (
              <>
                <button data-tip data-for="title-panel" type="button">
                  <QuestionCircle className="inline-block w-4 h-4 ml-1 text-gray-500" />
                </button>
                <Tooltip id="title-panel">{tooltip}</Tooltip>
              </>
            )}
          </h3>
          <hr />
        </>
      )}
      <div className={
        classNames(!containerClassName && 'px-6 py-6', containerClassName)
      }
      >
        {children}
      </div>
    </div>
  );
}

Panel.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Panel.defaultProps = {
  className: null,
  containerClassName: null,
  title: null,
  children: null,
  tooltip: null,
};
