import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetCardMerchants(cardId, params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'card/summary',
      params,
    ],
    async () => api.cards.fetchCardMerchants(apiClient, cardId, params),
  );
}
