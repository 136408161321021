import React from 'react';
import { classNames } from '../utils/ui';
import { dataCyValueFormatter } from '../utils/stringUtils';


export default function RadioGroup({
  options, onChange, selected, labelClassName, testId,
}) {
  return options.map((option) => (
    <div key={`option-${option.value}`} data-cy={`rg-option-${testId}`} className="mb-3">
      <label
        className={classNames(
          'font-normal',
          !labelClassName && 'text-normal mr-5',
          labelClassName,
          option.value === selected ? 'text-black' : 'text-gray-500',
        )}
      >
        <input
          className="mr-2 focus:outline-none focus:ring-0 border-gray-300 checked:bg-blue-600 checked:border-blue-600"
          type="radio"
          data-cy={dataCyValueFormatter(option.value)}
          onChange={() => onChange && onChange(option.value)}
          checked={option.value === selected}
          value={option.value}
        />
        {option.label}
      </label>
    </div>
  ));
}
