import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api';
import logger from '../../../utils/logger';
import useApiClient from '../../useApiClient';
import CardKeys from './Card.keys';

export default function useCreateCardComment(options) {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, comment }) =>
      api.cards.createCardComment(client, id, {
        comment,
      }).then(() => id),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(CardKeys.log(id));
      },
      onError: (error) => {
        logger.error(error);
      },
      ...options,
    }
  );
}
