import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export const getMerchantsMetrics = async (client) => api.insights.fetchMerchantsMetrics(client);

export default function useGetMerchantsMetrics() {
  const apiClient = useApiClient();
  return useLoggedInQuery([
    'insights/home-metrics',
  ],
  async () => getMerchantsMetrics(apiClient));
}
