import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { getBadgeColorsForExternalStatus } from '../table/columns/CardExternalStatusBadge';
import Tooltip from '../Tooltip';

interface ExternalStatusSyncIconProps {
  externalStatusName: string;
}

export const ExternalStatusSyncIcon = ({ externalStatusName }: ExternalStatusSyncIconProps) => {
  return (
    <>
      <ExclamationCircleIcon
        data-tip
        data-for="external-status-icon"
        className={`h-4 w-4 text-${getBadgeColorsForExternalStatus(externalStatusName)} cursor-pointer`}
      />
      <Tooltip id="external-status-icon">Status not synchronized</Tooltip>
    </>
  );
};
