import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SwitchHorizontalIcon, XIcon, PauseIcon, PlayIcon,
} from '@heroicons/react/outline';
import StatusConstants from '../../config/StatusConstants';
import Modal from '../Modal';
import Input from '../forms/Input';
import RadioGroup from '../RadioGroup';
import Label from '../forms/Label';
import Checkbox from '../forms/Checkbox';
import Textarea from '../forms/Textarea';
import Title from './Title';
import { CardActions } from 'src/config/CardConstants';

const otherValue = 'Other';

const modalReasonsForStatusChange = (status) => {
  switch (status) {
    case StatusConstants.suspendCardApiName:
    case StatusConstants.pFraudCardApiName:
      return [
        {
          value: '2106',
          label: 'Card may have compromised',
        },
        {
          value: '2107',
          label: 'Pending verification of authorization',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case StatusConstants.replacedCardApiName:
      return [
        {
          value: '4106',
          label: 'All card information has been compromised',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    default:
      return null;
  }
};

function modalReasons(action, status) {
  switch (action) {
    case CardActions.Reactivate:
      return [
        {
          value: '1226',
          label: 'Card confirmed not compromised',
        },
        {
          value: '1227',
          label: 'Low risk',
        },
        {
          value: '1228',
          label: 'No abnormal pattern of use',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    case CardActions.Resolve:
      return [
        {
          value: '3101',
          label: 'False positive – card not compromised',
        },
        {
          value: otherValue,
          label: otherValue,
        },
      ];
    default:
      return modalReasonsForStatusChange(status);
  }
};

const getShipingMethod = () => [
  {
    value: '15',
    label: 'First class',
  },
  {
    value: '16',
    label: 'Express mail',
  },
];

const getStatusDataForStatusChange = (status, oldStatus) => {
  const modalContents = {
    suspended:
      'All future transactions on this card will be declined until reactivated. If you are sure. Please provide relevant details if you want to proceed.',
    replaced:
      oldStatus === StatusConstants.replacedCardApiName
        ? 'The card has been already successfully replaced. This step only updates the replace information related to the card.'
        : 'All future transactions on this card will be declined and the card holder will be reissued a new card. Please provide relevant details if you want to proceed.',
  };

  const mapper = {
    [StatusConstants.suspendCardApiName]: {
      title: 'Suspend Card',
      icon: <PauseIcon className="w-5 text-blue-900" />,
      modalContent: modalContents.suspended,
    },
    [StatusConstants.pFraudCardApiName]: {
      title: 'Mark as PFraud',
      icon: <PauseIcon className="w-5 text-blue-900" />,
      modalContent: modalContents.suspended,
    },
    [StatusConstants.replacedCardApiName]: {
      title: oldStatus?.replacedCardApiName ? 'Replaced Card' : 'Replace Card',
      icon: <SwitchHorizontalIcon className="text-blue-900 mr-" />,
      modalContent: modalContents.replaced,
    },
  };

  return (
    mapper[status] || {
      title: 'Confirm',
      icon: null,
      modalContent: null,
    }
  );
};

function getStatusData(action, newStatus, oldStatus, isCompromised) {
  const isInternalStatusPfraudOrSuspended = oldStatus === StatusConstants.suspendCardApiName ||
    oldStatus === StatusConstants.pFraudCardApiName
  const toBeResolved = isCompromised && isInternalStatusPfraudOrSuspended;
  switch (action) {
    case CardActions.Reactivate:
      return {
        title: 'Reactivate Card',
        icon: <PlayIcon className="text-blue-900 mr-" />,
        modalContent: 'All future transactions will be allowed and scanned by ARDEN. If you are sure. Please provide relevant details if you want to proceed.',
      };
    case CardActions.Resolve:
      return {
        title: 'Remove Card from List',
        icon: <XIcon className="text-blue-900 mr-" />,
        modalContent: toBeResolved
          ? 'This card will be removed from the list of Compromised Cards, but the status of the card will remain the same.'
          : 'The card will no longer be considered as compromised and ARDEN will continue to scan the transactions. Please provide relevant details if you want to proceed.',
      }
    default:
      return getStatusDataForStatusChange(newStatus, oldStatus);
  }
}

export default function CardConfirmationModal({
  newStatus,
  oldStatus,
  primaryAction,
  onClose,
  isCompromised,
  action,
  ...rest
}) {
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [shippingMethod, setShippingMethod] = useState('');
  const [showComment, setShowComment] = useState(false);

  const onPredefinedReasonChange = (value) => {
    setReason(value);
    if (value !== 'Other') {
      setOtherReason('');
    }
  };

  const onShowCommentChange = (value) => {
    setShowComment(value);
    if (!value) {
      setComment('');
    }
  };

  const renderReasons = () => {
    const reasons = modalReasons(action, newStatus);
    if (!reasons) {
      return null;
    }
    return (
      <>
        <div className="mb-8">
          <Label className="inline-block mb-2" isRequired htmlFor="other-reason">
            Select reason:
          </Label>
          <RadioGroup
            options={reasons}
            selected={reason}
            onChange={onPredefinedReasonChange}
            testId='reason'
          />
          {reason === otherValue && (
            <div className="flex flex-col">
              <Label className="mb-1" isRequired htmlFor="other-reason">
                Provide reason:
              </Label>
              <Input
                type="text"
                id="other-reason"
                name="other-reason"
                rows={3}
                maxLength="250"
                value={otherReason}
                onChange={({ target }) => setOtherReason(target.value)}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const renderShipingMethod = () => (
    <>
      {newStatus === StatusConstants.replacedCardApiName && (
        <>
          <Label className="inline-block mb-2" isRequired htmlFor="other-reason">
            Shipping method:
          </Label>
          <RadioGroup
            options={getShipingMethod()}
            selected={shippingMethod}
            onChange={(value) => { setShippingMethod(value); }}
          />
        </>
      )}
    </>
  );
  const renderComment = () => (
    <>
      <Checkbox
        className="mb-3"
        isChecked={showComment}
        onChange={onShowCommentChange}
      >
        Add Comment
      </Checkbox>
      {showComment && <Textarea value={comment} onChange={setComment} maxLength="250" />}
    </>
  );

  const isValid = () => {
    if (!reason) {
      return false;
    };

    if (reason === 'Other' && !otherReason) {
      return false;
    };

    if (showComment && !comment) {
      return false;
    };

    return !(newStatus === StatusConstants.replacedCardApiName && !shippingMethod);
  };

  const resetFields = () => {
    setShippingMethod('');
    setReason('');
    setComment('');
    setOtherReason('');
    setShowComment(false);
  };

  const onPrimaryAction = () => {
    if (!primaryAction) {
      return;
    }

    const initialObject = {
      reason: reason === otherValue ? otherReason : reason,
      comment,
    };

    // is this replaced card action still a thing?
    if (newStatus === StatusConstants.replacedCardApiName) {
      primaryAction({
        ...initialObject,
        shippingMethod,
      });
    } else {
      primaryAction(initialObject);
    }

    resetFields();
  };

  const onCloseAction = () => {
    if (onClose) {
      onClose();
      resetFields();
    }
  };

  const { title, icon, modalContent } = getStatusData(action, newStatus, oldStatus, isCompromised);

  return (
    <Modal
      {...rest}
      primaryAction={onPrimaryAction}
      onClose={onCloseAction}
      primaryEnabledFunc={isValid}
      primaryText="Confirm"
      title={<Title title={title} icon={icon} />}
      testId="card-confirmation"
    >
      <div className="mt-2">
        <p className="mb-5 text-sm text-gray-500" data-cy="dialog-description-card-confirmation">
          {modalContent}
        </p>
        {renderReasons()}
        {renderShipingMethod()}
        {renderComment()}
      </div>
    </Modal>
  );
}

CardConfirmationModal.propTypes = {
  newStatus: PropTypes.string,
  oldStatus: PropTypes.string,
  primaryAction: PropTypes.func,
  onClose: PropTypes.func,
  action: PropTypes.string,
  isCompromised: PropTypes.bool,
};

CardConfirmationModal.defaultProps = {
  newStatus: '',
  oldStatus: '',
  primaryAction: null,
  onClose: null,
  action: null
};
