import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { classNames } from '../../utils/ui';

function HeaderNavItem({
  location,
  item,
  iconClasses,
  textClasses,
  baseClasses,
  selectedTextClasses,
}) {
  const isCurrent = location.startsWith(item.href);
  return (
    <li
      className={classNames(
        isCurrent
          ? 'bg-white'
          : '',
        'flex lg:inline-flex lg:py-0',
        item.disabled ? 'pointer-events-none' : '',
      )}
      key={item.name}
    >
      <Link
        to={item.href}
        className={classNames(
          isCurrent
            ? selectedTextClasses
            : 'text-white focus:bg-white hover:bg-white focus:bg-opacity-10 hover:bg-opacity-10 active:bg-opacity-20',

          baseClasses,
        )}
        aria-current={isCurrent ? 'page' : undefined}
      >
        <item.icon className={iconClasses} />
        <span className={textClasses}>{item.name}</span>
      </Link>
    </li>
  );
}

HeaderNavItem.propTypes = {
  location: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
  selectedTextClasses: PropTypes.string,
  baseClasses: PropTypes.string,
  textClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

HeaderNavItem.defaultProps = {
  location: '',
  selectedTextClasses: 'text-blue-800 font-medium border-b-2 border-gray-900 bg-gray-50 hover:text-gray-700 hover:border-gray-900',
  baseClasses: 'inline-flex items-center text-sm font-normal px-5 border-b-2 hover:text-gray-100 hover:border-gray-300 border-transparent ',
  textClasses: 'text-gray-600 ',
  iconClasses: 'w-6 h-6 mr-2 text-gray-600',
  disabled: false,
};

export default function HeaderNav({
  navigationItems,
  location,
  containerClasses,
  ...rest
}) {
  return (
    <ul className={containerClasses}>
      {navigationItems.map((item) => (
        <HeaderNavItem
          key={item.href}
          {...rest}
          item={item}
          location={location}
        />
      ))}
    </ul>
  );
}

HeaderNav.propTypes = {
  navigationItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  location: PropTypes.string,
  containerClasses: PropTypes.string,
};

HeaderNav.defaultProps = {
  location: '',
  containerClasses: 'lg:flex',
};
