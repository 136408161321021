import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetCardSummary(cardId, duration) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'card/summary',
      cardId,
      duration,
    ],
    async () => api.cards.fetchCardSummary(apiClient, cardId, { duration }),
  );
}
