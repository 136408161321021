import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlideOver from './SlideOver';
import { setShowSelectedMerchantCards } from '../../store/features/merchant/merchantSlice';
import StatusConstants from '../../config/StatusConstants';
import MerchantCards from '../table/MerchantCards';

export default function SlideOverSelectedMerchantCards() {
  const [showSelectedMerchantCards, merchantCardFilters] = useSelector(
    (state) => [
      state.merchant.showSelectedMerchantCards,
      state.merchant.merchantCardFilters,
    ],
  );
  const selectedMerchant = useSelector((state) => state.merchant.selectedMerchant);
  const dispatch = useDispatch();
  return (
    <SlideOver
      isOpen={showSelectedMerchantCards}
      setOpen={(value) => dispatch(setShowSelectedMerchantCards(value))}
      title={StatusConstants.merchantCardsTitle}
    >
      {selectedMerchant && (
        <MerchantCards
          merchantTenantId={selectedMerchant.id}
          merchantName={selectedMerchant.name}
          timeSelected={merchantCardFilters.timeSelected}
        />
      )}
    </SlideOver>
  );
}
