import React from 'react';
import PropTypes from 'prop-types';
import TextButton from '../../buttons/TextButton';
import { TABLE_CELL_CLASS } from '../Table';
import { getColumnValue } from '../DataTableUtils';
import LinkTextButton from '../../buttons/LinkTextButton';

export default function TextButtonColumn({ row, column, onAction }) {
  const { className } = column;

  const ButtonType = column.linkFormat ?  LinkTextButton : TextButton

  return (
    <td className={`${TABLE_CELL_CLASS} ${className}`}>
      <ButtonType
        className={column.childClassName}
        onClick={() => onAction && onAction(column.actionName, row)}
        link={column.linkFormat}
        row={row}
      >
        {getColumnValue(row, column)}
      </ButtonType>
    </td>
  );
}

TextButtonColumn.propTypes = {
  row: PropTypes.shape({}).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string,
    headerName: PropTypes.string,
    valueGetter: PropTypes.func,
    render: PropTypes.func,
    className: PropTypes.string,
    actionName: PropTypes.string,
    childClassName: PropTypes.string,
    linkFormat: PropTypes.string
  }).isRequired,
  onAction: PropTypes.func,
};

TextButtonColumn.defaultProps = {
  onAction: null,
};
