import { useAuth0 } from '@auth0/auth0-react';
import { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult, useQuery } from 'react-query';

/**
 * Execute query only when an auth token is available
 * @param {*} queryKeys
 * @param {*} queryFn
 * @param {*} optionsParam
 * @returns
 */
export default function useLoggedInQuery<T>(
  queryKeys: QueryKey,
  queryFn: QueryFunction<T>,
  optionsParam?: UseQueryOptions<T>
): UseQueryResult<T> {
  const { isAuthenticated } = useAuth0();
  const options = optionsParam
    ? {
        ...optionsParam,
        enabled: isAuthenticated && (optionsParam.enabled === null || !!optionsParam.enabled),
      }
    : {
        enabled: isAuthenticated,
      };
  return useQuery<T>(queryKeys, queryFn, options);
}
