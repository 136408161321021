export default function getUserNameColumn(columnName) {
  return {
    field: 'user',
    valueGetter: (field) => {
      if (!field) {
        return '';
      }
      const nameParts = [];
      if (field.firstName) {
        nameParts.push(field.firstName);
      }
      if (field.lastName) {
        nameParts.push(field.lastName);
      }

      return nameParts.join(' ');
    },
    headerName: columnName,
  };
}
