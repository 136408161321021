const fetchTenants = async (client, params) => client.get('api/tenants', {
  params,
})
  .then((response) => response.data);
const fetchTenantsFilter = async (client) => client.get('api/tenants/filter')
  .then((response) => response.data);

const fetchCurrentTenant = async (client) => client.get('api/tenants/current')
  .then((response) => response.data);

const updateTenant = async (client, tenantId, data) => client.put(`api/tenants/${tenantId}`, data)
  .then((response) => response.data);

const fetchTenantLogs = async (client, tenantId) => client.get(`api/tenants/${tenantId}/log`)
  .then((response) => response.data);
const fetchTenantDetails = async (client, tenantId) => client.get(`api/tenants/${tenantId}/details`)
  .then((response) => response.data);

const fetchStageFilter = async (client) => client.get('api/tenants/stage')
  .then((response) => response.data);

export default {
  fetchTenants,
  fetchTenantsFilter,
  fetchCurrentTenant,
  updateTenant,
  fetchTenantLogs,
  fetchTenantDetails,
  fetchStageFilter,
};
