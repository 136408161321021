import React from 'react';
import { classNames } from '../../utils/ui';
import ardenShieldOnly from '../../assets/arden-shield-only.svg';
import { ReactComponent as QuestionCircle } from '../../assets/icons/question-circle.svg';
import Tooltip from '../../components/Tooltip';
import numberUtils from '../../utils/numberUtils';

interface HomeStatusMetricProps {
  tooltip: string;
  count: number;
  foundText: string;
  notFoundText: string;
  className?: string;
}

export default function HomeStatusMetric({
  tooltip,
  count,
  foundText,
  notFoundText,
  className,
}: HomeStatusMetricProps) {
  const threatsFound = count > 0;
  return (
    <div className={classNames(
      'flex relative bg-gray-50 py-3.5 px-4 overflow-hidden border rounded-sm items-center border-l-4',
      `border-${threatsFound ? 'red' : 'green'}-600`,
      className || 'col-span-2',
    )}
    >
      <dt className="flex items-center mb-1">
        <div className="mr-2">
          {threatsFound
            ? (
              <span className="bg-red-600 text-white py-1 px-1.5 text-sm rounded-sm">
                {numberUtils.numberFormat(count)}
              </span>
            )
            : <img src={ardenShieldOnly} className="w-5" alt="arden shield only logo" />}
        </div>
        <p className="text-left font-semibold text-gray-900 truncate">
          {threatsFound
            ? (
              <>
                {' '}
                {foundText}
              </>
            )
            : notFoundText}
        </p>
        <button data-tip data-for="metric-tooltip-status" type="button" className="ml-1">
          <QuestionCircle className="w-5 text-gray-500" />
        </button>
        <Tooltip className="max-w-xs" id="metric-tooltip-status">
          {tooltip}
        </Tooltip>
      </dt>
      <dd className="text-sm text-left font-medium text-gray-900 ml-3">
        {threatsFound ? 'Review the threats below' : 'You have nothing to worry at the moment'}
      </dd>
    </div>
  );
}
