import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      type="button"
      data-cy="login-button"
      className="inline-block rounded py-2 px-5 focus:outline-none text-gray-600 text-sm focus:bg-white hover:bg-white focus:bg-opacity-10 hover:bg-opacity-10 active:bg-opacity-20"
      onClick={() => loginWithRedirect()}
    >
      Log In
    </button>
  );
};

export default LoginButton;
