import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useGetTop5Merchants from '../../../hooks/api/merchants/useGetTop5Merchants';
import DataTable from '../../../components/table/DataTable';
import { MERCHANT_OPEN_MERCHANT } from '../../../components/table/MerchantsTable';
import TextButtonColumn from '../../../components/table/columns/TextButtonColumn';
import numberUtils from '../../../utils/numberUtils';
import MerchantStatusColumn from '../../../components/table/columns/MerchantStatusColumn';
import ButtonGroup from '../../../components/ButtonGroup';
import Tabs from '../../../components/Tabs';
import useBottomSheetContext from '../../../hooks/bottomSheet/useBottomSheet';
import useGetMe from '../../../hooks/api/users/useGetMe';
import useConstant from '../../../hooks/useConstant';
import FeatureFlagConstants from '../../../config/FeatureFlagConstants';

const columns = [
  {
    field: 'rank',
    headerName: 'Rank',
  },
  {
    field: 'name',
    headerName: 'Merchant Name',
    render: TextButtonColumn,
    actionName: MERCHANT_OPEN_MERCHANT,
  },
  {
    field: 'number',
    headerName: 'Merchant ID',
  },
  {
    field: 'auths',
    headerName: '# auth',
    headerClassName: 'justify-end',
    className: 'text-right',
    valueGetter: numberUtils.numberFormat,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerClassName: 'justify-end',
    className: 'text-right',
    valueGetter: numberUtils.currencyFormat,
  },
  {
    field: 'statusApiName',
    field2: 'changedByType',
    headerName: 'Status',
    render: MerchantStatusColumn,
  },
];

function useStartDateFilterOptions() {
  const { data: me } = useGetMe();
  const timeOptions = useConstant(() => {
    const timeFilterOptionsToReturn = [
      {
        label: '1h',
        value: '1h',
      },
      {
        label: '24h',
        value: '24h',
      },
    ];
    return timeFilterOptionsToReturn;
  });

  if (!me) {
    return timeOptions;
  }
  if (me && me.featureFlags[FeatureFlagConstants.isPerformancePOC]) {
    return timeOptions.filter((tab) => tab.value !== '7d' && tab.value !== '1m'&& tab.value !== 'all');
  }

  return timeOptions;
}

export default function Top5Merchants({ onSelectedTabChange }) {
  const startDateFilterOptions = useStartDateFilterOptions();

  const [startDateFilter, setStartDateFilter] = useState(startDateFilterOptions[0].value);
  const { data, isLoading } = useGetTop5Merchants({
    timeSelected: startDateFilter,
  });
  const bottomSheet = useBottomSheetContext();
  const onTableAction = (action, args) => {
    switch (action) {
      case MERCHANT_OPEN_MERCHANT:
        bottomSheet.addTab({
          id: args.id,
          title: args.name,
          type: 'merchant',
        });
        break;
      default:
    }
  };

  return (
    <>
      <div className="flex items-center justify-between border-b border-gray-200">
        <Tabs
          buttonClassName="px-6 ml-5"
          tabs={[
            {
              name: 'Trend',
              value: 'trend',
            },
            {
              name: 'Top 5',
              value: 'top5',
            },
          ]}
          selectedTab="top5"
          onClick={(e) => onSelectedTabChange(e)}
        />
        <div className="flex space-x-2.5 mr-5">

          <span className="text-gray-600">Last</span>
          <ButtonGroup
            itemClassName="text-xs"
            className="mr-5 h-fit"
            selected={startDateFilter}
            onChange={(value) => setStartDateFilter(value)}
            options={startDateFilterOptions}
          />
        </div>
      </div>
      <DataTable
        columns={columns}
        loading={isLoading}
        data={data}
        showPagination={false}
        onAction={onTableAction}
      />
    </>
  );
}

Top5Merchants.propTypes = {
  onSelectedTabChange: PropTypes.func,
};

Top5Merchants.defaultProps = {
  onSelectedTabChange: (() => { }),
};
