import React from 'react';
import useGetFraudChartReport from '../../../hooks/api/insights/useGetFraudReport';
import numberUtils from '../../../utils/numberUtils';
import stringUtils from '../../../utils/stringUtils';
import ReportTable, { ReportTableRow } from '../ReportTable';

function calculateBreach(data) {
  return `${(data.breach / data.total).toFixed(2)}%`;
}

export default function FraudReport() {
  const { data, isLoading } = useGetFraudChartReport();

  return (
    <ReportTable
      loading={isLoading}
      data={data}
    >
      {data && data.results && (
        <>
          <ReportTableRow
            data={[
              '# Attacks',
              ...data.headers.map(({ startDate }) => (
                data.results.numberOfAttacks
                  && data.results.numberOfAttacks[startDate]
                  ? numberUtils.numberFormat(data.results.numberOfAttacks[startDate].total)
                  : '0')),
            ]}
          />
          <ReportTableRow
            data={[
              'Breach %',
              ...data.headers.map(({ startDate }) => (
                data.results.numberOfAttacks && data.results.numberOfAttacks[startDate]
                  ? calculateBreach(data.results.numberOfAttacks[startDate])
                  : '0%'
              )),
            ]}
          />
          <ReportTableRow
            data={[
              '$ Potential loss',
              ...data.headers.map(({ startDate }) => (
                data.results.numberOfAttacks
                  && data.results.numberOfAttacks[startDate]
                  ? stringUtils.currencyFormat(
                    data.results.numberOfAttacks[startDate].breachAmount,
                  )
                  : '$0')),
            ]}
          />
          <ReportTableRow
            data={[
              'Merchants blocked',
              ...data.headers.map(({ startDate }) => (
                data.results.merchantsBlocked
                  && data.results.merchantsBlocked[startDate]
                  ? numberUtils.numberFormat(data.results.merchantsBlocked[startDate].total)
                  : '0')),
            ]}
          />
          <ReportTableRow
            data={[
              '$ Auth protected',
              ...data.headers.map(({ startDate }) => (
                data.results.authProtected
                  && data.results.authProtected[startDate]
                  ? stringUtils.currencyFormat(
                    data.results.authProtected[startDate].total,
                  )
                  : '$0')),
            ]}
          />
        </>
      )}
    </ReportTable>
  );
}
