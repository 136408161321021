import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../utils/ui';

export default function LabelBadge({ title, content, className }) {
  return (
    <div
      className={classNames(
        'flex-1 h-6 px-2 py-1 bg-gray-50 border border-gray-200 rounded-xl max-w-full',
        className
      )}
    >
      <span className="text-sm leading-5 font-medium text-gray-500 flex max-w-full w-auto">
        {title}:&nbsp;<span className="truncate">{content}</span>
      </span>
    </div>
  );
}

LabelBadge.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LabelBadge.defaultProps = {
  className: null,
};
