import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDidMount } from 'beautiful-react-hooks';
import useGetMerchantSummary from '../../hooks/api/transactions/useGetMerchantSummary';
import ButtonGroup from '../ButtonGroup';
import DataTable from '../table/DataTable';
import TwoRowsColumn from '../table/columns/TwoRowsColumn';
import * as DateUtils from '../../utils/date';
import BaseColumn from '../table/columns/BaseColumn';
import useGroupViewContext from '../../hooks/groupViewContext/useGroupViewContext';
import filterUtils from '../../utils/filterUtils';

const StatColumn = (props) => {
  const {
    column,
    row: { type, name },
  } = props;
  const { field } = column;
  switch (type) {
    case 'date':
      return (
        <TwoRowsColumn
          {...props}
          column={{
            ...column,
            className: 'items-center space-x-2',
            field2: field,
            valueGetter: (valueField) => DateUtils.formatDateOnly(valueField),
            valueGetter2: (valueField) => DateUtils.formatTimeOnly(valueField),
          }}
          key={`${field}-${name}`}
        />
      );
    default:
      return <BaseColumn {...props} key={`${field}-${name}`} />;
  }
};

StatColumn.propTypes = {
  row: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};

const columns = [
  {
    field: 'name',
    headerName: '',
  },
  {
    field: 'stat',
    headerName: 'Auths',
    render: StatColumn,
  },
  {
    field: 'attackStat',
    headerName: 'Attack Auths',
    render: StatColumn,
  },
];

const viewKey = 'summary';

export default function MerchantSummary({ merchantTenantId }) {
  const timeFilterOptions = filterUtils.timeFilterWithAllOptions;

  const groupView = useGroupViewContext();
  const tabState = groupView.state(viewKey);

  const [timeFilter, setTimeFilter] = useState('12h');

  const [mounted, setIsMounted] = useState(false);
  const onMount = useDidMount();
  onMount(() => setIsMounted(true));

  useEffect(() => {
    if (tabState === undefined) return;
    if (tabState.timeFilter) setTimeFilter(tabState.timeFilter);
  }, []);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    groupView.setState('summary', {
      timeFilter,
    });
  }, [mounted, timeFilter]);

  const { data, isFetching, isLoading } = useGetMerchantSummary(merchantTenantId, timeFilter);

  return (
    <div>
      <div className="flex justify-end mb-3">
        <ButtonGroup
          itemClassName="w-16 text-xs"
          selected={timeFilter}
          onChange={(value) => setTimeFilter(value)}
          options={timeFilterOptions}
        />
      </div>
      <DataTable loading={isLoading || isFetching} showPagination={false} columns={columns} data={data} />
    </div>
  );
}

MerchantSummary.propTypes = {
  merchantTenantId: PropTypes.number.isRequired,
};
