import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Panel from '../../../components/Panel';
import DataTable from '../../../components/table/DataTable';
import InsightItems from '../../../components/insights/InsightItems';
import Badge from '../../../components/Badge';
import { TABLE_CELL_CLASS } from '../../../components/table/Table';
import { setShowManualBlockedMerchants } from '../../../store/features/global/globalSlice';
import numberUtils from '../../../utils/numberUtils';
import useGetRulesInsights from '../../../hooks/admin/rules/useGetRulesInsights';
import useInterval from '../../../hooks/useInterval';
import useGetRulesPerformanceSummary from '../../../hooks/admin/rules/useGetRulesPerformanceSummary';

const RuleStatusColumn = ({ row, column: { field } }) => (
  <td className={TABLE_CELL_CLASS}>
    <Badge
      type={row[field] ? 'success' : 'danger'}
    >
      {row[field] ? 'Active' : 'Inactive'}
    </Badge>
  </td>
);

RuleStatusColumn.propTypes = {
  row: PropTypes.shape({}).isRequired,
  column: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};

const columns = [
  {
    field: 'name',
    headerName: 'Rule',
  },
  {
    field: 'threatsIdentified',
    headerName: 'Merchants Identified',
    className: 'text-right',
    valueGetter: numberUtils.numberFormat,
  },
  {
    field: 'threatsBlocked',
    headerName: 'Blocked',
    className: 'text-right',
    valueGetter: numberUtils.numberFormat,
  },
  {
    field: 'falseThreats',
    headerName: 'False Positive',
    className: 'text-right',
    valueGetter: (field) => `${(field > 0 ? field.toFixed(2) : field)}%`,
  },
  {
    field: 'isActive',
    headerName: 'Current Status',
    render: RuleStatusColumn,
  },
];

export default function RulesPerformanceSummary({ tenantId, timeSelected, relatedModel }) {
  const { data } = useGetRulesPerformanceSummary({
    tenantId,
    timeSelected,
    relatedModel,
  });
  const dispatch = useDispatch();
  const { data: rulesInsights, refetch } = useGetRulesInsights({
    tenantId,
    timeSelected,
    relatedModel,
  });
  useInterval(refetch, 10000);

  const insightItems = rulesInsights
    ? rulesInsights.map((item) => (
      item.id === 'manualBlocked' && item.value > 0
        ? { ...item, showView: true }
        : item))
    : [];

  const handleOnItemView = (id) => {
    if (id === 'manualBlocked') {
      dispatch(setShowManualBlockedMerchants({
        show: true,
        context: {
          tenantId,
          timeSelected,
        },
      }));
    }
  };

  return (
    <>
      <div className="mb-8">
        <Panel>
          {insightItems && (
            <InsightItems
              onViewClicked={(id) => handleOnItemView(id)}
              items={insightItems}
            />
          )}
        </Panel>
      </div>
      <DataTable
        columns={columns}
        showPagination={false}
        data={data}
      />
    </>
  );
}

RulesPerformanceSummary.propTypes = {
  tenantId: PropTypes.number,
  relatedModel: PropTypes.string.isRequired,
  timeSelected: PropTypes.string.isRequired,
};

RulesPerformanceSummary.defaultProps = {
  tenantId: null,
};
