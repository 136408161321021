/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../utils/ui';

export default function HighlightText({ text, highlight, highlightClassName }) {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.filter(String).map((part, i) => (regex.test(part)
        ? (
          <mark className={classNames(highlightClassName)} key={`part-${i}`}>{part}</mark>
        ) : (
          <span key={`part-${i}`}>{part}</span>
        )
      ))}
    </span>
  );
}

HighlightText.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  highlightClassName: PropTypes.string,
};

HighlightText.defaultProps = {
  highlightClassName: null,
};
