/* eslint-disable react/jsx-props-no-spreading */
import { CheckIcon, ChevronDownIcon, XCircleIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import Checkbox, { CHECKBOX_TYPES } from './Checkbox';
import { classNames } from '../../utils/ui';
import Button from '../buttons/Button';

const DropdownIndicator = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    getStyles,
    // eslint-disable-next-line react/prop-types
    innerProps: { ref, ...restInnerProps },
    // eslint-disable-next-line react/prop-types
    hasValue,
    // eslint-disable-next-line react/prop-types
    selectProps: { isClearable, size },
  } = props;

  const styles = getStyles('dropdownIndicator', props);

  if (hasValue && isClearable) {
    styles.display = 'none';
  }

  if (size === 'small') {
    styles.padding = 4;
  }

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={styles}
    >
      <ChevronDownIcon className={`${size === 'normal' ? 'h-5 w-5' : 'h-4 w-4'} text-gray-700`} />
    </div>
  );
};

const ClearIndicator = (props) => (
  <components.ClearIndicator
    {...props}
    className="hover:bg-gray-100"
  >
    <XCircleIcon focusable="false" className="w-5 h-5 text-gray-700" />
  </components.ClearIndicator>
);

ClearIndicator.propTypes = {
  clearValue: PropTypes.func.isRequired,
};

const Option = (props) => {
  const {
    isSelected, label, isMulti, data,
  } = props;
  return (
    <components.Option {...props}>
      <div className="flex justify-between px-3 py-2 text-sm text-left text-gray-900 hover:bg-blue-100">
        {isMulti ? (
          <div className="w-full">
            <Checkbox
              className="pointer-events-none"
              type={data.isAllOption ? CHECKBOX_TYPES.circle : CHECKBOX_TYPES.default}
              isChecked={isSelected}
            >
              <span className="font-normal">
                {data.isAllOption && (isSelected ? data.deselectAllLabel : data.selectAllLabel)}
                {!data.isAllOption && label}
              </span>
            </Checkbox>
          </div>
        ) : (
          <>
            <span className={classNames(isSelected && 'font-semibold')}>{label}</span>
            {isSelected && <CheckIcon className="w-4 text-blue-600" />}
          </>
        )}
      </div>
      {data.isAllOption && <div className="mx-3 border-b border-gray-300" />}
    </components.Option>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isMulti: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    isAllOption: PropTypes.bool,
    selectAllLabel: PropTypes.string,
    deselectAllLabel: PropTypes.string,
  }).isRequired,
};

const Input = (props) => {
  const { selectProps, hasValue } = props;
  return (
    <components.Input
      {...props}
      inputClassName="focus:ring-0 mx-1"
      placeholder={selectProps.menuIsOpen && hasValue ? 'Type to search' : ''}
    />
  );
};

Input.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }).isRequired,
  hasValue: PropTypes.bool.isRequired,
};

const customStyles = {
  control: ({ minHeight, ...provided }, { selectProps: { size } }) => ({
    ...provided,
    minHeight: size === 'small' ? 28 : provided.minHeight,
    flexWrap: 'nowrap',
  }),
  valueContainer: () => ({
    display: 'flex',
    flex: '1 1 0%',
    overflow: 'auto',
    flexWrap: 'nowrap',
    paddingLeft: '3px',
    alignItems: 'center',
  }),
  input: (provided, state) => ({
    width: state.selectProps && state.selectProps.menuIsOpen ? '100%' : '0',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    position: 'static',
    transform: 'none',
    maxWidth: 'auto',
    overflow: 'auto',
    textOverflow: 'none',
  }),
  multiValue: ({ display, ...provided }, state) => ({
    ...provided,
    minWidth: 'auto',
    margin: 0,
    backgroundColor: '#fff',
    display: state.selectProps && state.selectProps.menuIsOpen ? 'none' : display,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    paddingLeft: '0',
    paddingRight: '0',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const allOptionDefault = {
  selectAllLabel: 'Select all',
  deselectAllLabel: 'Deselect all',
  value: '*',
  label: 'Select all',
  isAllOption: true,
};

export default function FMSelect({
  className, disabled, isMulti, options, allowSelectAll,
  allOption, onChange, value, prefix, menuPlacement, testSelector, ...props
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const allSelected = allowSelectAll && value.length === options.length;
  const innerOptions = allowSelectAll ? [allOption, ...options] : options;
  const innerSelected = allowSelectAll && allSelected ? [allOption, ...value] : value;

  const MultiValue = (multiValueProps) => {
    const { data } = multiValueProps;
    const index = value.findIndex((item) => item.value === data.value);
    return !data.isAllOption
      ? (
        <components.MultiValue {...multiValueProps}>
          <span className="text-sm">
            {index === 0 && `${prefix} `}
            {index > 0
              && ', '}
            {data.label}
          </span>
        </components.MultiValue>
      )
      : null;
  };

  const Menu = (menuProps) => {
    const { children } = menuProps;
    return (
      <components.Menu {...menuProps}>
        {children}
        {isMulti && (
          <div className="flex justify-end py-4 mx-4 border-t border-color-gray-300">
            <Button onClick={() => setMenuIsOpen(false)}>Done</Button>
          </div>
        )}
      </components.Menu>
    );
  };

  const selectComponents = {
    DropdownIndicator,
    ClearIndicator,
    Input,
    Option,
    Menu,
    MultiValue
  };

  const onChangeHandle = (selectedParam, event) => {
    if (!allowSelectAll) {
      return onChange && onChange(selectedParam, event);
    }
    const { action, option } = event;
    if (option && option.value === allOption.value) {
      if (action === 'select-option') {
        return onChange && onChange([...options], event);
      }
      if (action === 'deselect-option') {
        return onChange && onChange([], event);
      }
    }

    return onChange && onChange(selectedParam.filter(
      (item) => item.value !== allOption.value,
    ), event);
  };
  return (
    <div className={`block relative ${className}`} data-cy={testSelector}>
      <Select
        styles={customStyles}
        className="arden-select"
        classNamePrefix="arden-select"
        isDisabled={disabled}
        components={selectComponents}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        options={innerOptions}
        value={innerSelected}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        onChange={onChangeHandle}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        {...props}
      />
    </div>
  );
}

FMSelect.propTypes = {
  size: PropTypes.oneOf(['normal', 'small']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  menuPlacement: PropTypes.string,
  testSelector: PropTypes.string,
};

FMSelect.defaultProps = {
  size: 'normal',
  className: '',
  disabled: false,
  isMulti: false,
  allowSelectAll: false,
  options: null,
  allOption: allOptionDefault,
  onChange: null,
  value: null,
  prefix: null,
  menuPlacement: 'bottom',
  testSelector: 'fm-select'
};
