import React from 'react';
import PropTypes from 'prop-types';
import FMCombobox from '../forms/FMCombobox';
import useGetCardExternalStatuses from '../../hooks/api/cards/useGetCardExternalStatuses';

export default function CardExternalStatusFilter({ placeholder, selected, onChange }) {
  const { data, isLoading: statusLoading } = useGetCardExternalStatuses();

  if(statusLoading) return null

  return (
    <FMCombobox
      placeholder={placeholder}
      data={data?.map((item) => ({
        value: item.name,
        code: item.code,
        label: item.name,
      }))}
      selected={selected}
      onChange={onChange}
    />
  )
}

CardExternalStatusFilter.propTypes = {
  selected: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

CardExternalStatusFilter.defaultProps = {
  selected: null,
  placeholder: 'Select...',
  onChange: null,
};
