import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

const getMccs = async (client) => api.merchants.fetchMccs(client);

export default function useGetMccs() {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    [
      'mccs',
    ],
    async () => getMccs(apiClient),
  );
}
