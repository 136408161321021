const fetchCards = async (client, params) => client.get('api/cards', {
  params,
})
  .then((response) => response.data);


const fetchCompromisedCards = async (client, params) => client.get('api/cards/compromised', {
  params,
})
  .then((response) => response.data);

const fetchCompromisedCardsMerchantsFilter = async (client) => client.get('api/cards/compromised/merchants')
  .then((response) => response.data);

const fetchCompromisedCardsRulesFilter = async (client) => client.get('api/cards/compromised/rules')
  .then((response) => response.data);

const assignCard = async (client, cardId, params) => client.put(`api/cards/${cardId}/assign`, params)
  .then((response) => response.data);

const updateCard = async (client, cardId, data) => client.put(`api/cards/${cardId}`, data)
  .then((response) => response.data);

const resolveCard = async (client, cardId, data) => client.put(`api/cards/${cardId}/resolve`, data)
  .then((response) => response.data);

const reactivateCard = async (client, cardId, data) => client.put(`api/cards/${cardId}/reactivate`, data)
  .then((response) => response.data);

const fetchCardDetails = async (client, cardId) => client.get(`api/cards/${cardId}`)
  .then((response) => response.data);

const fetchCardNumber = async (client, cardId, params) => client.get(`api/cards/${cardId}/cardnumber`, {
  params,
})
  .then((response) => response.data);

const fetchActiveCardAttacks = async (client, cardId, params) => client .get(`api/cards/${cardId}/active-attacks`, {
      params,
}).then((response) => response.data);

const fetchCardLog = async (client, cardId) => client.get(`api/cards/${cardId}/log`)
  .then((response) => response.data);

const postCardLog = async (client, cardId, data) => client.post(`api/cards/${cardId}/log`, data)
  .then((response) => response.data);

const updateCardsBulkStatus = async (client, cardIds, statusApiName, reason, comment) => client.put('api/cards/bulk/status',
  {
    statusApiName,
    cardIds,
    reason,
    comment
  })
  .then((response) => response.data);
const resolveCardsBulkStatus = async (client, cardIds, statusApiName, reason, comment, action) => client.put('api/cards/bulk/resolve-status',
{
  statusApiName,
  cardIds,
  reason,
  comment,
  action
})
.then((response) => response.data);
const reactivateCardsBulkStatus = async (client, cardIds, statusApiName, reason, comment, action) => client.put('api/cards/bulk/reactivate',
  {
    statusApiName,
    cardIds,
    reason,
    comment,
    action
  })
  .then((response) => response.data);

const fetchCardSummary = async (client, cardId, params = {}) => client.get(`api/cards/${cardId}/summary`, {
  params,
})
  .then((response) => response.data);

const fetchCardMerchants = async (client, cardId, params = {}) => client.get(`api/cards/${cardId}/merchants`, {
  params,
})
  .then((response) => response.data);

const createCardComment = async (client, cardId, data) => client.post(`api/cards/${cardId}/comment`,
  data);

export default {
  fetchCards,
  fetchCompromisedCards,
  fetchCompromisedCardsMerchantsFilter,
  fetchCompromisedCardsRulesFilter,
  assignCard,
  updateCard,
  resolveCard,
  reactivateCard,
  fetchCardDetails,
  fetchCardNumber,
  fetchActiveCardAttacks,
  fetchCardLog,
  postCardLog,
  updateCardsBulkStatus,
  resolveCardsBulkStatus,
  reactivateCardsBulkStatus,
  fetchCardSummary,
  fetchCardMerchants,
  createCardComment,
};
