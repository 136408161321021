import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';

export default function useGetRulesInsights(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery([
    'rulesAdmin', 'insights', params,
  ],
  async () => api.admin.rules.fetchAdminRulesInsights(apiClient, params));
}
