import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PencilIcon } from '@heroicons/react/outline';
import { formatDateOnly, formatDayMonth, formatTimeOnly } from '../utils/date';
import Grid from './Grid';
import Spinner from './Spinner';
import TabsContainer from './TabsContainer';
import TenantActivityLogs from './TenantActivityLogs';
import Toggle from './Toggle';
import StatusConfirmationModal from './modals/StatusConfirmationModal';
import tenantUtils, { getStatusModelContent, getStatusModelTitle } from '../utils/tenantUtils';
import { getName } from '../utils/userUtil';
import { toastWithPromise } from './Toast/Toast';
import TenantConfirmationModal from './modals/TenantConfirmationModal';
import useGetTenant from '../hooks/admin/tenants/useGetTenant';
import useUpdateTenant from '../hooks/admin/tenants/useUpdateTenant';
import useGetStages from '../hooks/admin/tenants/useGetStages';
import useGetUsersFilter from '../hooks/admin/users/useGetUsersFilter';

const labelClass = 'block text-xs mb-1 text-gray-500';
const valueClass = 'block text-gray-800 font-semibold';

const tabs = [
  {
    name: 'Activity',
    value: 'activity',
  },
];

const getFormatedDateTime = (time) => (
  <div className="flex">
    <span className={valueClass}>{formatDayMonth(time)}</span>
    <span className={`${labelClass} mt-1 ml-2`}>{formatTimeOnly(time)}</span>
  </div>
);
const getSources = (selectedTenant) => {
  let name = '';
  if (selectedTenant && selectedTenant.sources) {
    selectedTenant.sources.forEach((item) => {
      name += item.name;
    });
  }
  return name;
};

export default function TenantDetails({ tenantId }) {
  const [selectedTab, setSelectedTab] = useState('activity');
  const [activeStatusModel, setActiveStatusModel] = useState({ showModal: false });
  const [updateTenantModel, setUpdateTenantModel] = useState({ showModal: false });
  const { data: stageFilter } = useGetStages();
  const { data: userFilter } = useGetUsersFilter({
    tenantId,
    removeSuperAdmin: true,
  });
  const { data: tenantDetails, isLoading } = useGetTenant(tenantId);
  const updateTenant = useUpdateTenant();
  const userFilterOptions = userFilter
    ? userFilter.rows.map((item) => ({ value: item.id, label: getName(item) })) : [];
  const stageFilterOptions = stageFilter
    ? stageFilter.map((item) => ({ value: item.id, label: item.name })) : [];
  const renderActivityTab = () => (
    <TenantActivityLogs tenantId={tenantId} />
  );
  const tabsContent = {
    activity: renderActivityTab,
  };

  function updateTenantStatus(comment, tenant) {
    setActiveStatusModel({ ...activeStatusModel, showModal: false });
    const payload = {};
    if (tenant.isActive) {
      payload.isActive = false;
    } else {
      payload.isActive = true;
    }
    if (comment) {
      payload.comment = comment;
    }
    toastWithPromise(
      updateTenant.mutateAsync({
        id: tenant.id,
        data: payload,
      }),
      {
        loading: 'Updating tenant...',
        success: 'Tenant updated successfully',
        error: 'An error occurred while updating the tenant',
      },
    );
  }
  const onStatusToggle = () => {
    setActiveStatusModel({
      ...activeStatusModel,
      tenant: tenantDetails,
      showModal: true,
    });
  };
  const onCloseUpdateModal = () => {
    setUpdateTenantModel({
      ...updateTenantModel, showModal: false,
    });
    setUpdateTenantModel({
      ...updateTenantModel, showModal: false, constantValue: null, defaultSelector: null,
    });
  };
  const onUpdateTenantModelAction = (category, data) => {
    if (!data) {
      return;
    }
    onCloseUpdateModal();
    let key = '';
    switch (category) {
      case tenantUtils.type.CARD_NUMBER: // out of scope
        key = 'cardNumber';
        break;
      case tenantUtils.type.REFERRALS:
        key = 'referrals';
        break;
      case tenantUtils.type.STAGE:
        key = 'StageId';
        break;
      case tenantUtils.type.OWNER:
        key = 'OwnerId';
        break;
      case tenantUtils.type.REVENUE:
        key = 'revenue';
        break;
      default:
        break;
    }
    const payload = {};
    payload[key] = data;
    toastWithPromise(
      updateTenant.mutateAsync({
        id: tenantId,
        data: payload,
      }),
      {
        loading: 'Updating tenant...',
        success: 'Tenant updated successfully',
        error: 'An error occurred while updating the tenant',
      },
    );
  };
  const openUpdateModal = (category) => {
    let constantValue = null;
    let defaultSelector = null;
    let defaultNumber = null;
    switch (category) {
      case tenantUtils.type.CARD_NUMBER: // out of scope
        constantValue = tenantUtils.cardNumber;
        defaultSelector = {
          value: tenantUtils.cardNumber.array[0].value,
          label: tenantUtils.cardNumber.array[0].label,
        };
        break;
      case tenantUtils.type.REFERRALS:
        constantValue = tenantUtils.referrals;
        defaultNumber = tenantDetails.referrals;
        break;
      case tenantUtils.type.STAGE:
        constantValue = tenantUtils.stage;
        constantValue.array = stageFilterOptions;
        if (tenantDetails.TenantStage) {
          defaultSelector = {
            value: tenantDetails.TenantStage.id,
            label: tenantDetails.TenantStage.name,
          };
        }
        break;
      case tenantUtils.type.OWNER:
        constantValue = tenantUtils.owner;
        constantValue.array = userFilterOptions;
        if (tenantDetails.owner) {
          defaultSelector = {
            value: tenantDetails.owner.id,
            label: getName(tenantDetails.owner),
          };
        }
        break;
      case tenantUtils.type.REVENUE:
        constantValue = tenantUtils.revenue;
        defaultNumber = tenantDetails.revenue;
        break;
      default:
        break;
    }
    setUpdateTenantModel({
      ...updateTenantModel,
      category,
      constantValue,
      defaultSelector,
      defaultNumber,
      showModal: true,
    });
  };
  return (
    <div>
      <Grid className="z-50 pb-3 sm:gap-10">
        <div className="flex flex-col col-span-5 mt-4">
          {tenantDetails && (
            <>
              <Grid className="item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Name</span>
                  <div><span className={valueClass}>{tenantDetails.name}</span></div>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>{tenantDetails.isActive ? 'Active' : 'Inactive'}</span>
                  <div>
                    <Toggle
                      checked={tenantDetails.isActive}
                      onChange={() => onStatusToggle()}
                    />
                  </div>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Inception Date</span>
                  <span className={valueClass}>{formatDateOnly(tenantDetails.updatedAt)}</span>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>Stage</span>
                  <div className="flex items-start">
                    <span className={valueClass}>
                      {tenantDetails.TenantStage ? tenantDetails.TenantStage.name : '--'}
                    </span>
                    <PencilIcon
                      className="flex w-4 h-4 mt-1 ml-6 text-gray-600 cursor-pointer"
                      onClick={() => { openUpdateModal(tenantUtils.type.STAGE); }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Owner</span>
                  <div className="flex items-start">
                    <span className={valueClass}>
                      {tenantDetails.owner ? getName(tenantDetails.owner) : '--'}
                    </span>
                    <PencilIcon
                      className="flex w-4 h-4 mt-1 ml-6 text-gray-600 cursor-pointer"
                      onClick={() => { openUpdateModal(tenantUtils.type.OWNER); }}
                    />
                  </div>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>Sources</span>
                  <span className={valueClass}>{getSources(tenantDetails)}</span>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Owner&#180;s Email</span>
                  <span className={valueClass}>{tenantDetails.owner ? tenantDetails.owner.email : '--'}</span>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>Fraud monitoring</span>
                  <span className={valueClass}>{tenantDetails.fraudMonitoring}</span>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Last modified by</span>
                  <span className={valueClass}>
                    {getName(tenantDetails.changedBy)}
                  </span>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>Total Active Roles</span>
                  <span className={valueClass}>3</span>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Last modified on</span>
                  <span className={valueClass}>
                    {getFormatedDateTime(tenantDetails.updatedAt)}
                  </span>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>Total Active Users</span>
                  <span className={valueClass}>
                    {tenantDetails.activeUsersCount}
                  </span>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Revenue</span>
                  <div className="flex items-start">
                    <span className={valueClass}>{tenantDetails.revenue}</span>
                    <PencilIcon
                      className="flex w-4 h-4 mt-1 ml-6 text-gray-600 cursor-pointer"
                      onClick={() => { openUpdateModal(tenantUtils.type.REVENUE); }}
                    />
                  </div>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>Total Auths</span>
                  <span className={valueClass}>{tenantDetails.allAuth}</span>
                </div>
              </Grid>
              <Grid className="mt-8 item-center">
                <div className="col-span-7">
                  <span className={labelClass}>Card Number Displayed</span>
                  <div className="flex items-start">
                    <span className={valueClass}>
                      {tenantDetails.cardNumber ? tenantDetails.cardNumber : '4 Digit'}
                    </span>
                    <PencilIcon
                      className="flex w-4 h-4 mt-1 ml-6 text-gray-400 cursor-default"
                    /* onClick={() => { openUpdateModal(tenantUtils.type.CARD_NUMBER); }} */
                    />
                  </div>
                </div>
                <div className="col-span-5">
                  <span className={labelClass}>Referrals</span>
                  <div className="flex items-start">
                    <span className={valueClass}>{tenantDetails.referrals}</span>
                    <PencilIcon
                      className="flex w-4 h-4 mt-1 ml-6 text-gray-600 cursor-pointer"
                      onClick={() => { openUpdateModal(tenantUtils.type.REFERRALS); }}
                    />
                  </div>
                </div>
              </Grid>
            </>
          )}
          {isLoading && (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
              <Spinner />
            </div>
          )}
        </div>
        <div className="col-span-7 pl-6">
          <TabsContainer
            tabs={tabs}
            tabsContent={tabsContent}
            selectedTab={selectedTab}
            onSelectedTabChange={setSelectedTab}
          />
        </div>
      </Grid>
      <StatusConfirmationModal
        {...activeStatusModel}
        title={getStatusModelTitle(activeStatusModel && activeStatusModel.tenant
          && activeStatusModel.tenant.isActive)}
        content={getStatusModelContent(activeStatusModel && activeStatusModel.tenant
          && activeStatusModel.tenant.isActive)}
        onClose={() => setActiveStatusModel({ ...activeStatusModel, showModal: false })}
        primaryAction={(comment) => updateTenantStatus(comment, activeStatusModel.tenant)}
      />
      <TenantConfirmationModal
        {...updateTenantModel}
        onClose={() => onCloseUpdateModal()}
        primaryAction={(category, args) => onUpdateTenantModelAction(category, args)}
      />
    </div>
  );
}

TenantDetails.propTypes = {
  tenantId: PropTypes.number.isRequired,
};
