import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlideOver from './SlideOver';
import MerchantsTable, { MERCHANT_OPEN_MERCHANT } from '../table/MerchantsTable';
import useManualBlockedMerchants from '../../hooks/admin/merchants/useManualBlockedMerchants';
import Filters from '../Filters';
import SearchFilter from '../filters/SearchFilter';
import { setShowManualBlockedMerchants } from '../../store/features/global/globalSlice';
import DateColumn from '../table/columns/DateColumn';
import TwoRowsColumn from '../table/columns/TwoRowsColumn';
import numberUtils from '../../utils/numberUtils';
import { formatLastLog } from '../../utils/merchantTableUtils';
import PaginationConfig from '../../config/PaginationConfig';

const columns = [
  DateColumn('createdAt', 'Added On'),
  {
    field: 'name',
    field2: 'number',
    headerName: 'Merchant Details',
    valueGetter2: (valueField) => `ID: ${valueField}`,
    render: TwoRowsColumn,
    actionName: MERCHANT_OPEN_MERCHANT,
    sortable: true,
  },
  {
    field: 'mcc',
    headerName: 'MCC',
    sortable: true,
  },
  {
    field: 'auths',
    headerName: 'Total Auths',
    headerClassName: 'float-right',
    className: 'text-right pr-10',
    valueGetter: (field) => numberUtils.numberFormat(field),
    sortable: true,
  },
  {
    field: 'lastLog',
    headerName: 'Added/Last Modified By',
    valueGetter: (field) => formatLastLog(field),
  },
];
export default function SlideOverManualBlockedMerchants() {
  const [showManualBlockedMerchants, manualBlockedMerchantsContext] = useSelector(
    (state) => [
      state.global.showManualBlockedMerchants,
      state.global.manualBlockedMerchantsContext,
    ],
  );
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PaginationConfig.itemsPerPage);
  const [tenantId, setTenantId] = useState(null);
  const [timeSelected, setTimeSelected] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const { isLoading, data } = useManualBlockedMerchants(
    tenantId,
    timeSelected,
    page,
    itemsPerPage,
    search,
    {
      enabled: tenantId !== null && timeSelected !== null && showManualBlockedMerchants,
    },
  );

  const onPageClick = (newPage) => {
    setPage(newPage);
  };

  const resetPage = () => {
    if (page > 1) {
      setPage(1);
    }
  };

  const onSearchChange = (value) => {
    setSearchValue(value);
    if (value && value.trim().length > 2) {
      resetPage();
      setSearch(value.trim());
    } else {
      setSearch('');
    }
  };

  useEffect(() => {
    if (manualBlockedMerchantsContext) {
      setTenantId(manualBlockedMerchantsContext.tenantId);
      setTimeSelected(manualBlockedMerchantsContext.timeSelected);
    }
  }, [manualBlockedMerchantsContext]);

  useEffect(() => {
    if (showManualBlockedMerchants) {
      resetPage();
      setSearchValue('');
      setSearch('');
    }
  }, [showManualBlockedMerchants]);

  return (
    <SlideOver
      title="Manual blocked"
      isOpen={showManualBlockedMerchants}
      setOpen={(value) => dispatch(setShowManualBlockedMerchants({ show: value }))}
    >
      <Filters>
        <SearchFilter
          onChange={onSearchChange}
          value={searchValue}
        />
      </Filters>
      <MerchantsTable
        canUpdate={false}
        columns={columns}
        data={data ? data.rows : []}
        loading={isLoading}
        paginationProps={{
          totalItems: data && data.count ? data.count : 0,
          currentPage: page,
          itemsPerPage,
          onPageClick,
          onItemsPerPageChange: (e) => { resetPage(); setItemsPerPage(e); },
        }}
      />
    </SlideOver>
  );
}
