import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import BulkEditMerchantModal from '../modals/bulkEditMerchantModal/BulkEditMerchantModal';
import CheckboxColumn from './columns/CheckboxColumn';
import {
  selectMerchant,
  setShowSelectedMerchantTransactions,
  setShowSelectedMerchantCards,
} from '../../store/features/merchant/merchantSlice';
import MerchantConfirmationModal from '../modals/MerchantConfirmationModal';
import { toastWithPromise, getMessage2, toastBulkAction } from '../Toast/Toast';
import useBulkUpdateMerchantStatus from '../../hooks/api/merchants/useBulkUpdateMerchantStatus';
import useUpdateMerchantStatus from '../../hooks/api/merchants/useUpdateMerchantStatus';
import useGetMe from '../../hooks/api/users/useGetMe';
import useBottomSheetContext from '../../hooks/bottomSheet/useBottomSheet';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import CheckboxHeader from './CheckboxHeader';
import BulkActionsButton from './BulkActionsButton';
import BulkActions from './BulkActions';
import { classNames } from '../../utils/ui';
import StatusConstants from '../../config/StatusConstants';
import { handlePlural } from './DataTableUtils';
import useTrackEvent from '../appInsights/useTrackEvent';

export const MERCHANT_UPDATE_STATUS_ACTION = 'merchant-update-status';
export const MERCHANT_BULK_ACTION_DONE = 'merchant-bulk-action-done';
export const MERCHANT_CHECK_ACTION = 'merchant-check';
export const MERCHANT_BULK_ACTION = 'merchant-bulk';
export const MERCHANT_OPEN_TRANSACTIONS = 'merchant-open-transactions';
export const MERCHANT_OPEN_CARDS = 'merchant-open-cards';
export const MERCHANT_OPEN_MERCHANT = 'merchant-open-merchant';

export default function MerchantsTable({ data, onAction, columns, canUpdate, selectedTab, ...rest }) {
  const [modal, setModal] = useState({ show: false, merchant: {} });
  const [checked, setChecked] = useState([]);
  const [showBulkModal, setShowBulkModal] = useState({ showModal: false, toStatus: 'trusted-merchant' });
  const bulkUpdateMerchantStatus = useBulkUpdateMerchantStatus();
  const dispatch = useDispatch();
  const allChecked = checked.length > 0 && checked.length === data.length;
  const isCheckboxChecked = checked.length > 0;

  const handleNotAThreatMerchantStatus = (merchant) => (
    merchant.statusApiName === StatusConstants.trustedApiName ||
    merchant.statusApiName === StatusConstants.approvedApiName ||
    merchant.statusApiName === StatusConstants.pendingReviewApiName
  )

  const toTrust = data
    .filter(
      (merchant) =>
        checked.find((id) => id === merchant.id) &&
        merchant.statusApiName !== StatusConstants.trustedApiName &&
        merchant.statusApiName !== StatusConstants.blockedApiName
    )
    .map((merchant) => merchant.id);
  const toNotAThreat = data
    .filter(
      (merchant) =>
        checked.find((id) => id === merchant.id) &&
        handleNotAThreatMerchantStatus(merchant)
    )
    .map((merchant) => merchant.id);

  const updateMerchantStatus = useUpdateMerchantStatus();
  const bottomSheet = useBottomSheetContext();
  const { data: me } = useGetMe();
  const isBlockedTab = selectedTab === StatusConstants.blockedApiName;
  const isMerchantBulkActionsEnable = me && me.featureFlags[FeatureFlagConstants.useMerchantBulkActions] && !isBlockedTab;
  const trackMerchantAction = useTrackEvent('Merchant Table Action');
  const checkColumnClassName = !allChecked && isCheckboxChecked ? 'partial' : '';
  const toggleAll = () => {
    if (allChecked) {
      setChecked([]);
    } else {
      setChecked(
        data.map((row) => row.id),
      );
    }
  };

  const checkColumn = {
    field: 'id',
    actionName: MERCHANT_CHECK_ACTION,
    headerRender: (column, onHeaderAction) =>
    <CheckboxHeader
      disabled={!canUpdate}
      className={classNames(checkColumnClassName, 'rounded-sm')}
      checked={allChecked || isCheckboxChecked}
      onChange={() => onHeaderAction(MERCHANT_CHECK_ACTION, column)}
    />,
    render: (props) =>
      CheckboxColumn({
        ...props,
        disabled: !canUpdate,
        // eslint-disable-next-line react/prop-types
        checked: !!checked.find((checkedId) => props.row.id === checkedId),
        checkBoxClassName: 'rounded-sm',
      }),
  };

  const closeConfirmationModal = () => {
    if (modal.show) {
      setModal({ ...modal, show: false });
    }
  };

  const showConfirmationModal = (action, newStatus, merchant, isAbleToRemove = false) => {
    if (merchant && !modal.show) {
      setModal({
        show: true,
        action,
        merchant,
        newStatus,
        isAbleToRemove
      });
    }
  };

  const updateMerchantTenantBulkStatus = (merchantTenantIds, statusApiName, reason, comment) => {
    toastBulkAction(
      bulkUpdateMerchantStatus.mutateAsync({
        ids: merchantTenantIds,
        statusApiName,
        reason,
        comment
       },{
        onSuccess: () => {
          onAction(MERCHANT_BULK_ACTION_DONE);
        }
      }),
      {
        loading: 'Updating merchants...',
        success: 'Merchants updated successfully',
        error: 'An error occurred while updating merchants',
      },
    );
  };

  const onTableAction = (action, args) => {
    const {ids, statusApiName, reason, comment} = args;
    if (action !== MERCHANT_UPDATE_STATUS_ACTION) {
      trackMerchantAction({
        action,
        ...args
      });
    }
    switch (action) {
      case MERCHANT_UPDATE_STATUS_ACTION:
        // eslint-disable-next-line no-case-declarations
        const [status, merchant, isAbleToRemove] = args;
        showConfirmationModal(action, status, merchant, isAbleToRemove);
        break;
      case MERCHANT_CHECK_ACTION:
        if (checked.includes(args.id)) {
          setChecked(
            checked.filter((id) => id !== args.id),
          );
        } else {
          setChecked([...checked, args.id]);
        }
        break;
      case MERCHANT_BULK_ACTION:
        updateMerchantTenantBulkStatus(ids, statusApiName, reason, comment);
        setShowBulkModal({ ...showBulkModal, showModal: false });
        setChecked([]);
        break;
      case MERCHANT_OPEN_TRANSACTIONS:
        dispatch(selectMerchant(args));
        dispatch(setShowSelectedMerchantTransactions(true));
        break;
      case MERCHANT_OPEN_CARDS:
        dispatch(selectMerchant(args));
        dispatch(setShowSelectedMerchantCards(true));
        break;
      case MERCHANT_OPEN_MERCHANT:
        bottomSheet.addTab({
          id: args.id,
          title: args.name,
          subtitle: `ID: ${args.number}`,
          type: 'merchant',
        });
        break;
      default:
        break;
      }
      onAction(action, args);
  };

  const onTableHeaderAction = (action) => {
    switch (action) {
      case MERCHANT_CHECK_ACTION:
        toggleAll();
        break;
      default:
        break;
    }
  };

  const updateMerchantStatusAction = ({
    status, merchant, reason, comment, actionById, actionByName
  }) => {
    const { id, name } = merchant;

    trackMerchantAction({
      action: MERCHANT_UPDATE_STATUS_ACTION,
      ...merchant,
      status,
    });

    const regularMerchantUpdate = {
      id,
      statusApiName: status,
      reason,
      comment,
      actionById,
      actionByName
    }

    toastWithPromise(
      updateMerchantStatus.mutateAsync(regularMerchantUpdate),
      {
        loading: `Updating merchant ${name}`,
        success: { message1: 'Merchant successfully', message2: getMessage2(status) },
        error: `An error occurred while updating merchant ${name}`,
      },
      modal.newStatus,
    );
  };

  const onModalPrimaryAction = (args) => {
    if (modal.action === MERCHANT_UPDATE_STATUS_ACTION) {
      updateMerchantStatusAction({
        status: modal.newStatus,
        ...args,
      });
    } else if (onAction) {
      onAction(
        modal.action,
        {
          status: modal.newStatus,
          ...args,
        },
      );
    }
    closeConfirmationModal();
  };

  useEffect(() => {
    setChecked([]);
  }, [data]);

  const getTableColumn = () => {
    if (canUpdate) {
      if(isMerchantBulkActionsEnable){
        return [checkColumn, ...columns];
      }
      return [...columns];
    }
    return columns;
  };

  return (
    <>
      <DataTable
        data={data}
        columns={getTableColumn()}
        {...rest}
        onAction={onTableAction}
        onHeaderAction={onTableHeaderAction}
      />
      <MerchantConfirmationModal
        showModal={modal.show}
        onClose={closeConfirmationModal}
        isAbleToRemove={modal.isAbleToRemove}
        primaryAction={onModalPrimaryAction}
        merchant={modal.merchant}
        newStatus={modal.newStatus}
      />
      {isMerchantBulkActionsEnable && (
        <>
         <BulkActions isCheckboxChecked={isCheckboxChecked}>
            {toTrust.length > 0 && (
              <BulkActionsButton
                bulkActionTitle="Trust"
                bulkSize={toTrust.length}
                onClick={() =>
                  setShowBulkModal({ showModal: true, toStatus: StatusConstants.trustedApiName })
                }
                icon={<CheckCircleIcon className="w-4 h-4 mr-3 text-green-600" aria-hidden="true" />}
                bulkType={`${handlePlural(toTrust.length, 'merchant')}`}
              />
            )}
            {toNotAThreat.length > 0 && (
              <BulkActionsButton
                className='w-96'
                bulkActionTitle="Mark"
                bulkSize={toNotAThreat.length}
                onClick={() =>
                  setShowBulkModal({ showModal: true, toStatus: StatusConstants.reviewedApiName })
                }
                icon={<XIcon className="w-4 h-4 mr-3 text-gray-600" aria-hidden="true" />}
                bulkType={`${handlePlural(toNotAThreat.length, 'merchant')} as Not a threat/Remove from list`}
              />
            )}
          </BulkActions>
          <BulkEditMerchantModal
            {...showBulkModal}
            onClose={() => setShowBulkModal({ ...showBulkModal, showModal: false })}
            primaryAction={(args) =>
              onTableAction(MERCHANT_BULK_ACTION, {
                ids: checked,
                statusApiName: showBulkModal.toStatus,
                ...args
              })
            }
          />
        </>
      )}
    </>
  );
}

MerchantsTable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  data: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  onAction: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  canUpdate: PropTypes.bool,
  selectedTab: PropTypes.string,
};

MerchantsTable.defaultProps = {
  children: null,
  onAction: null,
  columns: [],
  canUpdate: true,
  selectedTab: ''
};
