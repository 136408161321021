import React, { useEffect } from 'react';
import HomeStatusMetric from './HomeStatusMetric';
import HomeMetric from './HomeMetric';
import useGetMerchantsMetrics from '../../hooks/api/insights/useGetMerchantsMetrics';
import useInterval from '../../hooks/useInterval';

const INTERVAL_LIMIT = process.env.REACT_APP_HOME_METRICS_INTERVAL || 60000;

export default function HomeMetrics() {
  const { data: homeMetrics, refetch } = useGetMerchantsMetrics();
  const threatsFoundCount = homeMetrics
    ? homeMetrics.reduce((acc, metric) => acc + metric.value.count, 0)
    : 0;

  useEffect(() => {
    if (threatsFoundCount > 0) {
      document.title = `(${threatsFoundCount}) ARDEN`;
    } else {
      document.title = 'ARDEN';
    }
  }, [threatsFoundCount]);

  useInterval(refetch, INTERVAL_LIMIT);

  if (!homeMetrics) {
    return null;
  }

  return (
    <dl className="grid grid-cols-1 gap-5 my-5 sm:grid-cols-2">
      <HomeStatusMetric
        count={threatsFoundCount}
        foundText="Threats detected"
        notFoundText="No threats detected"
        tooltip="Arden has identified the below merchant(s) as a potential
        threat for fraud due to suspicious card activity.
        Review the details of the merchant(s) to determine what
        action should be taken (trust, approve, block, remove)."
      />
      {
        homeMetrics.filter((metric) => metric.id !== 'transactionsTotal')
          .map((metric) => (
            <HomeMetric
              metric={metric}
              firstLabel="Merchants"
              secondLabel="Attack amount"
              key={`merchant-metric-${metric.id}`}
            />
          ))
      }
    </dl>
  );
}
