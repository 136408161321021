import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import Table, { TABLE_ROW_CLASS } from '../table/Table';

const REPORT_TABLE_CELL_CLASS = 'px-6 py-4 whitespace-nowrap text-xs text-gray-900 text-left';

function parseFormatHeaderDate(date) {
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'd LLL');
}

function renderHeader(data) {
  return (
    <th key={data.startDate} className="font-semibold px-4 py-3 text-xs text-gray-900">
      <span className="whitespace-nowrap">
        {parseFormatHeaderDate(data.startDate)}
        &nbsp;-
      </span>
      <br />
      {parseFormatHeaderDate(data.endDate)}
    </th>
  );
}

export function ReportTableRow({ data }) {
  return (
    <tr className={TABLE_ROW_CLASS}>
      {data.map((text, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <td key={`cel-${index}`} className={`${REPORT_TABLE_CELL_CLASS}`}>
          {text}
        </td>
      ))}
    </tr>
  );
}

ReportTableRow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ).isRequired,
};

export default function ReportTable({ data, children, ...rest }) {
  return (
    <Table {...rest}>
      <>
        <thead className="bg-gray-50">
          <tr className="text-left">
            <td />
            {data && data.headers && data.headers.map((header) => renderHeader(header))}
          </tr>
        </thead>
        <tbody className="border-t-0 border-transparent">
          {children}
        </tbody>
      </>
    </Table>
  );
}

ReportTable.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    })),
  }),
  children: PropTypes.element,
};

ReportTable.defaultProps = {
  data: {},
  children: null,
};
