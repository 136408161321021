import React from 'react';
import PropTypes from 'prop-types';

export default function Footer({ isAuthenticated }) {
  const date = new Date().getFullYear();

  return (
    <footer>
      <div className="max-w-screen-2xl mx-auto sm:px-3 lg:px-12 py-5 md:py-0">
        <div className={`flex flex-col justify-between md:h-14 items-center text-xs text-gray-600 font-normal ${isAuthenticated ? 'md:flex-row' : ''}`}>
          <div className="py-1 md:py-0">
            &copy;
            {` ${date} `}
            Arden
          </div>
          {isAuthenticated && (
          <div className="py-1 md:py-0">
            Contact support:&nbsp;
            <a className="underline" href="mailto:help@getarden.com">help@getarden.com</a>
          </div>
          )}

        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  isAuthenticated: PropTypes.bool,
};

Footer.defaultProps = {
  isAuthenticated: false,
};
