import { GlobeAltIcon, UsersIcon } from '@heroicons/react/outline';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';
import React from 'react';
import Grid from '../components/Grid';
import Title from '../components/headings/Title';
import VerticalNav from '../components/VerticalNav';
import Rules from './admin/Rules';
import Users from './admin/Users';

function getNavItems(path) {
  return [
    {
      name: 'Rule Engine',
      href: `${path}/rules`,
      icon: GlobeAltIcon,
    },
    {
      name: 'User Management',
      href: `${path}/users`,
      icon: UsersIcon,
    },
  ];
}

export default function Admin() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const navItems = getNavItems(path);

  return (
    <Grid>
      <div className="col-span-12 pt-5" data-cy="admin-screen">
        <div className="mb-4">
          <Title>Admin</Title>
        </div>
        <hr className="mb-6" />
      </div>
      <div className="col-span-3">
        <VerticalNav
          location={location.pathname}
          items={navItems}
        />
      </div>
      <div className="col-span-9">
        <Switch>
          <Route exact path={path}>
            <Redirect to="/admin/rules" />
          </Route>
          <Route
            path={`${path}/rules`}
            component={Rules}
          />
          <Route
            path={`${path}/users`}
            component={Users}
          />
        </Switch>
      </div>
    </Grid>
  );
}
