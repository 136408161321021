import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import AdminRolesKeys from './AdminRoles.keys';

const getRolePermissions = async (
  client,
  params,
) => api.admin.roles.fetchRolePermissions(client, params);

export default function useGetRolePermissions(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(AdminRolesKeys.list('permissions', params), async () => getRolePermissions(apiClient, params));
}
