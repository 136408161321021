/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../api';

export const updateAutoBlockLevel = createAsyncThunk(
  'tenant/updateAutoBlockLevel',
  async (autoBlockLevel, { getState }) => api.updateTenant(
    getState().me.token,
    getState().tenant.currentTenant.id,
    { autoBlockLevel },
  )
    .then(() => autoBlockLevel),
);

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: {
    rulesList: [],
    lastTenantUpdated: {},
  },
  reducers: {
    setLastTenantUpdated: (state, action) => {
      state.lastTenantUpdated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAutoBlockLevel.fulfilled, (state, action) => {
      state.currentTenant.autoBlockLevel = action.payload;
    });
  },
});

export const {
  setLastTenantUpdated,
} = tenantSlice.actions;
export default tenantSlice.reducer;
