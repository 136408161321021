import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGetCard from '../../hooks/api/cards/useGetCard';
import useGetActiveCardAttacks from '../../hooks/api/cards/useGetActiveCardAttacks';
import Spinner from '../Spinner';
import CardDetailsActions from './CardDetailsActions';
import SidebarLabel from './SidebarLabel';
import { CardTextStatusBadge } from '../table/columns/CardStatusBadge';
import { checkPermission, getName } from '../../utils/userUtil';
import useGetMyPermissions from '../../hooks/api/users/useGetMyPermissions';
import PermissionConstants from '../../config/PermissionConstants';
import CardConfirmationModal from '../modals/CardConfirmationModal';
import { toastWithPromise } from '../Toast/Toast';
import { formatDateOnly, formatTimeOnly } from '../../utils/date';
import useGetMe from '../../hooks/api/users/useGetMe';
import StatusConstants from '../../config/StatusConstants';
import useGetTenantUsers from '../../hooks/api/users/useGetTenantUsers';
import useUpdateCardStatus from '../../hooks/api/cards/useUpdateCardStatus';
import useResolveCard from '../../hooks/api/cards/useResolveCard';
import useAssignCard from '../../hooks/api/cards/useAssignCard';
import numberUtils from '../../utils/numberUtils';
import CardNumber from './CardNumber';
import useCardActions from '../../hooks/actions/useCardActions';
import FeatureFlagConstants from '../../config/FeatureFlagConstants';
import { CardBorderExternalStatusBadge } from '../table/columns/CardExternalStatusBadge';
import { ExternalStatusSyncIcon } from './ExternalStatusSyncIcon';
import useBottomSheetContext from '../../hooks/bottomSheet/useBottomSheet';
import ActiveAlerts from '../activeAlerts/index';
import { classNames } from '../../utils/ui';
import useTrackEvent from '../appInsights/useTrackEvent';
import { CardAssign } from '../cardDetails/CardAssign'

const labelClass = 'block text-xs mb-1 text-gray-500';
const valueClassMedium = 'mt-1 block text-gray-800 text-sm font-semibold';
const valueClassTime = 'mt-1 block text-gray-500 text-sm';
export const CARD_UPDATE_STATUS_ACTION = 'card-update-status';

function CardSummary({
  id,
  lastFour,
  authBalance,
  isCompromised,
  externalStatusName,
  proxy,
  subProgramName,
  clientName,
  bin,
  isExternalStatusSynced,
}) {
  return (
    <>
      <CardNumber id={id} lastFour={lastFour} />
      <SidebarLabel
        title={`BIN: ${bin}`}
        titleClassName="block mt-1 text-sm text-gray-800"
      />
      <SidebarLabel
        title={`Proxy: ${proxy}`}
        titleClassName="block mt-1 text-sm text-gray-800"
      />
      <SidebarLabel
        title={`Balance: ${numberUtils.currencyFormat(authBalance)}`}
        titleClassName="block mt-1 text-sm text-gray-800"
      />
      <SidebarLabel
        title="Live scan status"
        titleClassName={classNames(labelClass, 'mt-6')}
        element={
          <CardTextStatusBadge
          statusApiName={isCompromised ? 'compromised' : 'Not Compromised'}
          statusName={isCompromised ? 'compromised' : 'Not Compromised'}
        >
          {isCompromised ? 'Compromised' : 'Not Compromised'}
        </CardTextStatusBadge>
        }
        elementClassName={valueClassMedium}
      />
      <SidebarLabel
        title="Card status"
        testId={`card-status-${externalStatusName.toLowerCase()}`}
        titleClassName={classNames(labelClass, 'mt-6')}
        element={
          <div className='flex gap-x-1 items-center'>
            <CardBorderExternalStatusBadge externalStatusName={externalStatusName} />
            {!isExternalStatusSynced && <ExternalStatusSyncIcon externalStatusName={externalStatusName} />}
          </div>
        }
        elementClassName={valueClassMedium}
      />
      <SidebarLabel
        title="Client/Program"
        titleClassName={classNames(labelClass, 'mt-6')}
        label={clientName}
        labelClassName={valueClassMedium}
        value={subProgramName}
        valueClassName={classNames(valueClassTime, 'break-words')}
      />
    </>
  );
}

CardSummary.propTypes = {
  id: PropTypes.number.isRequired,
  lastFour: PropTypes.string.isRequired,
  authBalance: PropTypes.number.isRequired,
  isCompromised: PropTypes.bool,
  externalStatusName: PropTypes.string,
  proxy: PropTypes.string,
  subProgramName: PropTypes.string,
  clientName: PropTypes.string,
  bin: PropTypes.number,
  isExternalStatusSynced: PropTypes.bool
};

CardSummary.defaultProps = {
  proxy: '',
  subProgramName: '',
  clientName: '',
  externalStatusName: null,
  bin: '',
  isExternalStatusSynced: false,
  isCompromised: false
};

export default function CardDetailsSidebar({ cardId }) {
  const { data } = useGetTenantUsers({ itemsPerPage: Number.MAX_SAFE_INTEGER });
  const tenantUsers = data ? data.rows : [];
  const userOptions = [
    { value: null, label: 'unassigned' },
    ...tenantUsers.map((userTenant) => ({
      value: userTenant.id,
      label: getName(userTenant),
    })),
  ];
  const [assignTo, setAssignTo] = useState(null);
  const { data: cardDetails, isLoading } = useGetCard(cardId, {
    onSuccess: (details) => {
      if (userOptions.length) {
        setAssignTo(userOptions.find((userOption) => userOption.value === details.userId));
      }
    }
  });
  const { data: permissions } = useGetMyPermissions();
  const { data: user } = useGetMe();
  const { data: confirmedCardAttacks, isLoading: confirmedCardAttacksLoading } = useGetActiveCardAttacks(
    cardId,
    { createdByType: 'rule' }
  );
  const { data: confirmedMerchantAttacks, isLoading: confirmedMerchantAttacksLoading } =
    useGetActiveCardAttacks(cardId, { createdByType: 'merchantAttack' });
  const updateCardStatus = useUpdateCardStatus();
  const resolveCardStatus = useResolveCard();
  const assignCard = useAssignCard();
  const [modal, setModal] = useState({ show: false });
  const cardActions = useCardActions();
  const bottomSheet = useBottomSheetContext();
  const trackCardDetailsSidebarAction = useTrackEvent('Card Details Sidebar Action');

  useEffect(() => {
    if (cardDetails) {
      bottomSheet.updateTab({
        id: cardDetails.id,
        type: 'card',
        title: `* ${cardDetails.lastFour}`,
        subtitle: `Balance: ${numberUtils.currencyFormat(cardDetails.authBalance)}`,
      });
    }
  }, [cardDetails]);


  const isToAllowAnyoneCardAction =
    user &&
    (!user.featureFlags[FeatureFlagConstants.isPerformancePOC] ||
      !user.featureFlags[FeatureFlagConstants.hideCardActions]);

  const getFormattedDateTime = (dateTime) => {
    if (!dateTime) return (<>--</>);

    const castDateTime = new Date(dateTime).toISOString();

    return (
      <div className="flex items-center">
        <span className={valueClassMedium}>{formatDateOnly(castDateTime)}</span>
        <span className={classNames(valueClassTime, 'ml-2')}>{formatTimeOnly(castDateTime)}</span>
      </div>
    );
  };

  const showConfirmationModal = (action, newStatus) => {

    if (cardDetails && !modal.show) {
      setModal({
        show: true,
        action,
        newStatus,
      });
    }
  };

  const closeConfirmationModal = () => {
    if (modal.show) {
      setModal({ ...modal, show: false });
    }
  };

  const updateCardStatusAction = (formData) => {
    closeConfirmationModal();
    const newCard = {
      ...formData,
      statusApiName: modal.newStatus,
    };
    cardActions(modal.action, cardId, cardDetails.lastFour, newCard);

    const status = modal.newStatus;

    trackCardDetailsSidebarAction({
      action: CARD_UPDATE_STATUS_ACTION,
      ...cardDetails,
      status,
    });
  };

  const updateCardAssignAction = (userId) => {
    if (userId === cardDetails.userId) {
      return;
    }

    setAssignTo(userId);

    const { value } = userId;

    toastWithPromise(
      assignCard.mutateAsync({
        id: cardId,
        data: { userId: value },
      }),
      {
        loading: `Updating card ${cardDetails.lastFour}`,
        success: `Card ${cardDetails.lastFour} updated successfully`,
        error: `An error occurred while updating Card ${cardDetails.lastFour}`,
      },
      StatusConstants.assignCard
    );
  };


  const isNotCardReplaceOrPerformancePOC =
    cardDetails && user
      ? cardDetails.statusApiName !== StatusConstants.replacedCardApiName ||
        user.featureFlags[FeatureFlagConstants.isPerformancePOC]
      : false;
  const isToShowAssignOptions = user
    ? isNotCardReplaceOrPerformancePOC || !user.featureFlags[FeatureFlagConstants.hideCardActions]
    : false;


  return (
    <>
      {cardDetails && (
        <>
          <CardSummary {...cardDetails} />
          {isToShowAssignOptions && (
            <CardAssign
              labelClass={labelClass}
              disabled={!checkPermission(permissions, PermissionConstants.REASSIGN_COMPROMISED_CARDS)}
              assignList={userOptions}
              value={assignTo}
              handleUpdateCardAssign={updateCardAssignAction}
            />
          )}

          {isToAllowAnyoneCardAction && (
            <CardDetailsActions
              cardId={cardId}
              statusApiName={cardDetails.statusApiName}
              externalStatusName={cardDetails.externalStatusName}
              isCompromised={cardDetails.isCompromised}
              onClick={(action, newStatus) => showConfirmationModal(action, newStatus)}
            />
          )}

          {confirmedMerchantAttacks && (
            <ActiveAlerts
              title="Active alerts of Confirmed threats"
              quantity={confirmedMerchantAttacks.count}
              alerts={confirmedMerchantAttacks.rows}
            />
          )}

          <div className="mt-7">
            {confirmedCardAttacks && (
              <ActiveAlerts
                title="Active alerts of Rules"
                quantity={confirmedCardAttacks.count}
                alerts={confirmedCardAttacks.rows}
              />
            )}
          </div>

          <div className="mt-6">
            <span className={labelClass}>Activation date</span>
            <div>{getFormattedDateTime(cardDetails.originalActivationDate)}</div>
          </div>

          {isToAllowAnyoneCardAction && (
            <div className="mt-6">
              <span className={labelClass}>Last compromised on</span>
              <div>{getFormattedDateTime(cardDetails.compromisedAt)}</div>
            </div>
          )}

          <div className="mt-6">
            <span className={labelClass}>Last modified on</span>
            {getFormattedDateTime(cardDetails.updatedAt)}
          </div>

          <div className="mt-6">
            <span className={labelClass}>Last modified by</span>
            <span className={valueClassMedium}>
              {cardDetails && cardDetails.changedBy === null && '--'}
              {cardDetails.changedBy}
            </span>
          </div>

          <CardConfirmationModal
            showModal={modal.show}
            action={modal.action}
            newStatus={modal.newStatus}
            oldStatus={cardDetails.statusApiName}
            isCompromised={cardDetails.isCompromised}
            onClose={closeConfirmationModal}
            primaryAction={updateCardStatusAction}
          />
        </>
      )}
      {(isLoading ||
        updateCardStatus.isLoading ||
        resolveCardStatus.isLoading ||
        assignCard.isLoading ||
        confirmedCardAttacksLoading ||
        confirmedMerchantAttacksLoading) && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center bg-gray-100 bg-opacity-50">
          <Spinner />
        </div>
      )}
    </>
  );
}

CardDetailsSidebar.propTypes = {
  cardId: PropTypes.number.isRequired,

};
