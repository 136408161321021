import api from '../../../api';
import useApiClient from '../../useApiClient';
import useLoggedInQuery from '../../useLoggedInQuery';
import MerchantKeys from './Merchant.keys';

const getMerchants = async (
  client,
  params,
) => api.merchants.fetchMerchants(client, params);

export function useGetMerchants(params) {
  const apiClient = useApiClient();
  return useLoggedInQuery(
    MerchantKeys.list('all', params),
    async () => getMerchants(apiClient, params),
  );
}

export default {
  useGetMerchants
}