/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../api';

export const updateMerchantStatus = createAsyncThunk(
  'merchants/updateStatus',
  async ({ id, ...data }, { getState }) => api.updateMerchantStatus(
    getState().me.token,
    id,
    data,
  )
    .then(() => ({ id, ...data })),
);

const merchantSlice = createSlice({
  name: 'merchant',
  initialState: {
    selectedMerchant: null,
    showSelectedMerchantTransactions: false,
    showSelectedMerchantCards: false,
    showSelectedMerchant: false,
    lastMerchantUpdated: {},
    merchantCardFilters: {},
  },
  reducers: {
    selectMerchant: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selectedMerchant = action.payload;
    },
    setShowSelectedMerchantTransactions: (state, action) => {
      state.showSelectedMerchantTransactions = action.payload;
    },
    setShowSelectedMerchantCards: (state, action) => {
      state.showSelectedMerchantCards = action.payload;
      // reset filters when modal closed
      if (!action.payload) {
        state.merchantCardFilters = {};
      }
    },
    setMerchantCardFilters: (state, action) => {
      state.merchantCardFilters = action.payload;
    },
    setShowSelectedMerchant: (state, action) => {
      state.showSelectedMerchant = action.payload;
    },
    setLastmerchantUpdated: (state, action) => {
      state.lastMerchantUpdated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateMerchantStatus.fulfilled, (state, action) => {
      state.lastMerchantUpdated = action.payload;
      if (state.selectedMerchant
        && action.payload && action.payload.id === state.selectedMerchant.id) {
        state.selectedMerchant = {
          ...state.selectedMerchant,
          statusApiName: action.payload.newStatus,
        };
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  selectMerchant,
  setShowSelectedMerchantTransactions,
  setShowSelectedMerchantCards,
  setMerchantCardFilters,
  setShowSelectedMerchant,
} = merchantSlice.actions;

export default merchantSlice.reducer;
